import React, {
  useEffect,
  useState,
  useRef
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Popover
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import ChatInfo from '../../../assets/images/chat-info.svg';

import Button from '../../button';
import Select from '../../select';
import SmallPagination from '../../small-pagination';
import LiveViewCard from '../../live-view-card';

import { DrawerWrapper } from '../../../pages/templates/drawers/drawer-style';

import { SetCampaignState } from '../../../redux/slices/campaign';
import {
  GetAllTemplatesWithSheets,
  GetTemplateForEmployee,
  SetTemplateState
} from '../../../redux/slices/template';

import { AuthState } from '../../../redux/types/auth';
import { CampaignState } from '../../../redux/types/campaign';
import { TemplateState } from '../../../redux/types/template';

import { GetS3ImageUrl } from '../../../utils/helpers';
import { LETTER_TYPE_VALUES } from '../../../constants';

interface ChooseTemplateProps {
  editCampaignId?: string;
  setCampaignHelperText: (value: any) => void;
  campaignHelperText: any;
  step: number;
  clonedCampaign: any;
}

interface Options {
  label: string,
  value: string,
  variables: string[]
}

interface Sheets {
  message: string;
  logoUrl: string | undefined;
  logoSize: any;
  qrCode: string | undefined;
  sheetType?: string;
  s3FileUrl?: string;
}

const sheetsPagination = [
  { value: 0, label: '1' },
  { value: 1, label: '2' },
  { value: 2, label: '3' }
];

const ChooseTemplate: React.FC<ChooseTemplateProps> = ({
  editCampaignId,
  step,
  setCampaignHelperText,
  campaignHelperText,
  clonedCampaign
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const { user } = useSelector((state: { auth: AuthState }) => state.auth);

  const [templates, setTemplatesWithSheets] = useState<Options[]>([{
    label: '',
    value: '',
    variables: []
  }]);
  const [sheetsData, setSheetsData] = useState<Sheets[]>([{
    message: '',
    logoUrl: '',
    logoSize: {},
    qrCode: ''
  }]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [selectedSheet, setSelectedSheet] = useState(0);
  const [splitCharacters, setSplitCharacters] = useState<number>(0);
  const [templateForEmployee, setTemplateForEmployee] = useState<any>({});
  const [firstMsgPart, setFirstMsgPart] = useState<string>('');
  const [secondMsgPart, setSecondMsgPart] = useState<string>('');

  const {
    campaigns,
    newCampaign
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const {
    getTemplateForEmployeeSuccess,
    templates: templateForSelect,
    templatesWithSheets,
    isUsed
  } = useSelector((state: { template: TemplateState }) => state.template);

  const getAllTemplatesWithVariables = () => {
    dispatch(GetAllTemplatesWithSheets({ editCampaignId }));
  };

  const editTemplateHandler = () => {
    const url = `/templates?templateId=${templateForEmployee._id}`;
    window.open(url, '_blank');
  };

  const handleSelectChange = (value: string, name: string) => {
    const sheet = templatesWithSheets.find((obj: any) => obj._id === value);
    const { sheetsData: sData = [] } = sheet || {};
    let sheetsWithLogo: any = [];

    for (let i = 0; i < sData?.length; i += 1) {
      const {
        logoUrl,
        sheetType
      } = sData[i];

      if (logoUrl) {
        const fileUrl = GetS3ImageUrl({
          bucketName: sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
          key: logoUrl
        });

        sheetsWithLogo = [...sheetsWithLogo, {
          ...sData[i],
          logoUrl: fileUrl
        }];
      } else {
        sheetsWithLogo = [...sheetsWithLogo, {
          ...sData[i]
        }];
      }
    }

    setSelectedSheet(0);
    setSheetsData(sheetsWithLogo);
    dispatch(SetCampaignState({
      field: 'newCampaign',
      value: {
        ...newCampaign,
        template: {
          id: value,
          name: sheet?.name
        }
      }
    }));

    setSelectedTemplate({
      label: sheet?.name,
      value: sheet?._id
    });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'templateId') errors[name] = t('template_is_required');
    else if (!isEmpty(value) && name === 'templateId') {
      errors[name] = '';
    }

    setCampaignHelperText({
      ...errors
    });
  };

  useEffect(() => {
    if (getTemplateForEmployeeSuccess) {
      setTemplateForEmployee(templateForSelect?.[0]);
      setSelectedTemplate({
        label: (templateForSelect?.[0])?.name,
        value: (templateForSelect?.[0])?._id
      });

      dispatch(SetTemplateState({
        field: 'getTemplateForEmployeeSuccess',
        value: false
      }));
    }
  }, [getTemplateForEmployeeSuccess]);

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    if (step === 1) {
      const currentCampaign = campaigns?.find((obj: any) => obj._id === editCampaignId);

      if (currentCampaign) {
        const { templateId } = currentCampaign;

        if (templateId) {
          dispatch(GetTemplateForEmployee({ templateId }));
        }
      }

      getAllTemplatesWithVariables();
    }
  }, [step]);

  useEffect(() => {
    if (templatesWithSheets?.length) {
      if (newCampaign?.template?.id || clonedCampaign) {
        const select = templatesWithSheets.find((obj: any) => obj._id === (
          newCampaign?.template?.id || clonedCampaign.templateId
        ));

        dispatch(SetCampaignState({
          field: 'newCampaign',
          value: {
            ...newCampaign,
            template: {
              id: newCampaign?.template?.id || clonedCampaign.templateId,
              name: select?.name
            }
          }
        }));

        if (user?.role === 'employee') {
          setSelectedTemplate({
            label: templateForEmployee?.name,
            value: templateForEmployee?._id
          });
        } else {
          setSelectedTemplate({
            label: select?.name,
            value: select?._id
          });
        }

        let sheetsWithLogo: any = [];

        const { sheetsData: sData = [] } = select || {};
        for (let i = 0; i < sData?.length; i += 1) {
          const {
            logoUrl,
            sheetType
          } = sData[i];

          if (logoUrl) {
            const fileUrl = GetS3ImageUrl({
              bucketName: sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
              key: logoUrl
            });

            sheetsWithLogo = [...sheetsWithLogo, {
              ...sData[i],
              logoUrl: fileUrl
            }];
          } else {
            sheetsWithLogo = [...sheetsWithLogo, {
              ...sData[i]
            }];
          }
        }

        setSheetsData(sheetsWithLogo);
      }

      const optionsData = templatesWithSheets?.map((item: any) => ({
        label: item?.name,
        value: item?._id
      }));

      if (optionsData?.length) {
        setTemplatesWithSheets(optionsData);
      }
    }
  }, [templatesWithSheets]);

  const content = (
    <>
      <p>In this step, you choose the letter template for this campaign.</p>
      <p>You must create it in advance to select it here.</p>
    </>
  );

  return (
    <div className="add-scroll">
      <DrawerWrapper>
        <div className="written-box">
          <Row gutter={16} className="m-0">
            <Col xs={10} className="pl-0">
              <div>
                <div className="info-box">
                  <h3>{t('choose_letter_template')}</h3>
                  <Popover className="control-width pl-1" content={content}>
                    <span className="tooltip"><div><img src={ChatInfo} alt={t('no_info')} /></div></span>
                  </Popover>
                  {user?.role !== 'employee' && (
                    <Button
                      width="100px"
                      text={t('add_template')}
                      onClick={() => navigate('/templates')}
                      type="default"
                    />
                  )}
                </div>
                <div className="template-box">
                  <Select
                    width="311px"
                    label={t('template_name')}
                    placeholder={t('select')}
                    options={user?.role === 'employee' ? [] : templates}
                    value={selectedTemplate}
                    onChange={(value) => {
                      handleSelectChange(value, 'templateId');
                    }}
                    helperText={campaignHelperText?.templateId}
                    disabled={user?.role === 'employee'}
                  />
                  {user?.role === 'employee'
                  && !isUsed
                  && (
                    <Button
                      width="100px"
                      className="edit-template-btn"
                      text={t('edit_template')}
                      type="default"
                      onClick={() => {
                        editTemplateHandler();
                      }}
                    />
                  )}
                </div>
              </div>

            </Col>
            <Col xs={14}>
              {/* <div className="preview-content"> */}
              {/* <div className="preview-box">
                  <div className="d-flex justify-content-between">
                    <h4 className="m-0">{t('preview')}</h4>
                  </div>
                  <div className="logo-box">
                    {sheetsData?.length && sheetsData[selectedSheet]?.logoUrl
                    && sheetsData[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN
                    && (
                      <div>
                        <img
                          className="image-size"
                          src={sheetsData[selectedSheet]?.logoUrl || ''}
                          alt={t('logo')}
                          style={{
                            width: sheetsData[selectedSheet]?.logoSize?.width || '150px',
                            height: sheetsData[selectedSheet]?.logoSize?.height || '45px'
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="written-content"
                    dangerouslySetInnerHTML={{
                      __html:
                      sheetsData[selectedSheet]?.message || ''
                    }}
                  />
                  <div className="qr-code">
                    {!isEmpty(sheetsData[selectedSheet]?.qrCode) ? (
                      <QRCode
                        size={70}
                        value={sheetsData[selectedSheet]?.qrCode || ''}
                      />
                    ) : null}
                  </div>
                </div> */}
              <LiveViewCard
                message={sheetsData[selectedSheet]?.message}
                qrCode={sheetsData[selectedSheet]?.qrCode}
                logo={sheetsData[selectedSheet]?.logoUrl}
                s3FileUrl={sheetsData[selectedSheet]?.s3FileUrl}
                totalSheets={sheetsData?.length || 1}
                setSplitCharacters={setSplitCharacters}
                splitCharacters={splitCharacters}
                advertisementFormat={selectedTemplate.advertisementFormat}
                sheetType={sheetsData[selectedSheet]?.sheetType}
                setFirstMsgPart={setFirstMsgPart}
                firstMsgPart={firstMsgPart}
                setSecondMsgPart={setSecondMsgPart}
                secondMsgPart={secondMsgPart}
              >
                <div className="d-flex only-pagination add-sheet-button ">
                  <SmallPagination
                    current={selectedSheet + 1}
                    total={sheetsData?.length || 0}
                    setSelectedSheet={setSelectedSheet}
                    selectedSheet={selectedSheet}
                    templateSheetsLength={sheetsData?.length - 1}
                  />
                </div>
              </LiveViewCard>
              {/* </div> */}
            </Col>
          </Row>
        </div>
      </DrawerWrapper>
    </div>
  );
};

export default ChooseTemplate;
