import styled from 'styled-components';

const PaymentWrapper = styled.div`

h2{
  margin-top:5px;
  margin-bottom:7px;
  color:${({ theme }) => theme.colors.secondaryColor};
}
.card-ui-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-top: 43px;
  height: calc(100vh - 28vh);
  overflow: auto;
}
.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    .right-actions{
      display: flex;
      grid-gap: 6px;
      width: 100%;
      max-width: 300px;
      align-items: baseline;
      p{
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color:#8D8D8D;
        white-space: nowrap;

      }
    }
}
.add-top-space{
  margin-top:16px;
  align-items: center;
}
.comparison-box{
  display: flex;
  align-items: center;
  padding:99px 34px 99px 24px;
  border-right:1px solid ${({ theme }) => theme.colors.paymentBorderColor} ;
}
.active-column{
  background-color:${({ theme }) => theme.colors.clickAbleLight};
  box-shadow: 0px 0px 89px 0px ${({ theme }) => theme.colors.boxShadow}
}
.comparison-plan{
  border:1px solid ${({ theme }) => theme.colors.paymentBorderColor};
  border-radius:4px;
  background-color:${({ theme }) => theme.colors.whiteColor};
  margin-top:26px;
  margin-bottom:24px;
  height: calc(100vh - 240px);
  overflow: auto;
  .basic-plan{
    padding: 20px 22px 34px 16px;
    display: flex;
    flex-direction: column;
    position:relative;
    align-items: center;
    border-right:1px solid ${({ theme }) => theme.colors.paymentBorderColor};
    .badge{
      background-color:${({ theme }) => theme.colors.backgroundPrimary}10;
      color:${({ theme }) => theme.colors.backgroundPrimary};
      border-radius:50px;
      padding:8px 16px;
      font-size:${({ theme }) => theme.colors.baseFontSizeBody};
      font-weight:600;
      margin-left: -5px;
      line-height:16px;
    }
    .price{
      padding-top: 35px;
      margin-left: -6px;
      margin-bottom:7px;
     sup{
      margin-top:-9px;
      color:${({ theme }) => theme.colors.lightGrey};
     }
      h1{
        font-size:48px;
        color:${({ theme }) => theme.colors.backgroundPrimary};
      }
      sub{
        margin-top:22px;
        color:#9E9DA1;
        padding-left:4px;
      }
    }
    .price-plan{
      margin-bottom:12px;
      .price-title{
        font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
        color:${({ theme }) => theme.colors.secondaryColorGrey};
        font-weight:500;
      }
      .price-value{
        font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
        color:${({ theme }) => theme.colors.secondaryColorGrey};
        font-weight:600;
      }
    }
  }
  .branding-plan{
    .most-popular{
      background-color:#FFFFF6;
      color:${({ theme }) => theme.colors.backgroundPrimary};
      text-align:center;
      padding:8px;
      font-weight:bold;
      margin-bottom:16px;
    }
    .add-padding{
      padding: 0px 22px 4px 16px;
    }
    .badge{
      background-color:#FFFFF7;
      color:${({ theme }) => theme.colors.backgroundPrimary};
      border-radius:50px;
      padding:8px 16px;
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
      font-weight:600;
      margin-left: -5px;
      line-height:16px;
    }
      .price{
      padding-top: 35px;
      margin-left: -6px;
      margin-bottom:7px;
     sup{
      margin-top:-9px;
      color:${({ theme }) => theme.colors.lightGrey};
     }
      h1{
        font-size:48px;
        color:${({ theme }) => theme.colors.backgroundPrimary};
      }
      sub{
        margin-top:22px;
        color:#9E9DA1;
        padding-left:4px;
      }
    }
    .price-plan{
      margin-bottom:12px;
      .price-title{
        font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
        color:${({ theme }) => theme.colors.secondaryColorGrey};
        font-weight:500;
      }
      .price-value{
        font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
        color:${({ theme }) => theme.colors.secondaryColorGrey};
        font-weight:600;
      }
      &.brand-plan{
        span {
          color:${({ theme }) => theme.colors.backgroundPrimary};
        }
      }
    }
  }
}
.plan-info{
  font-size:${({ theme }) => theme.fonts.baseFontSize};
  color:${({ theme }) => theme.colors.darkGrey};
  border:1px solid #E6E9F5;
  border-left:none;
  border-bottom:none;
  padding: 16px 32px;
  min-height:53px;
  max-height: 53px;
  &.selected{
    background-color:${({ theme }) => theme.colors.clickAbleLight};
  }
  &.right-border-none{
    border-right:none;
  }
  &.fw-bold{
    font-weight:600;
  }
  &.color-change{
    color:#71717A;
  }
}
.purchase-tag{
  position: absolute;
    left: 1px;
    top: 0px;
}

/* new page style 15 jun 2024 */
.header-overlay {
    display: flex;
    align-items: center;
    grid-gap: 18px;
    .header-label {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    h5{
      font-size: ${({ theme }) => theme.fonts.baseFontSizeH3};
      font-weight:  ${({ theme }) => theme.fontWeight.bold};
      line-height: ${({ theme }) => theme.lineHeight.baseLineHeightH3};
      text-align: right;
      color: ${({ theme }) => theme.colors.primaryColorGold};
      margin: 0 !important;
    }
    p{
      font-size:  ${({ theme }) => theme.fonts.baseFontSizeSm};
      font-weight:  ${({ theme }) => theme.fontWeight.normal};
      line-height: ${({ theme }) => theme.lineHeight.baseLineHeightXs};
      text-align: left;
      color: ${({ theme }) => theme.colors.extraTextColor};
      margin: 0 !important;;
    }
  }
  h6{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeH3};
    font-weight:  ${({ theme }) => theme.fontWeight.bold};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightH3};
    text-align: right;
    color: ${({ theme }) => theme.colors.labelHeading};
    margin: 0 !important;
  }
  .ant-switch{
    height:34px;
    .ant-switch-inner {
      padding-inline-start: 20px;
      padding-inline-end: 46px;
    }
}
.custom-switch{
  .ant-switch {
  width: 66px;
  height: 34px;
}
span.ant-switch-inner {
    background:  ${({ theme }) => theme.colors.primaryColorGold};
}
.ant-switch-handle {
  width: 30px;
  height: 30px;
  &::before{
    border-radius: 23px;
  }
}
}
}
.ant-switch-checked .ant-switch-handle {
  &::before{
    left: -12px;
    right: 12px;
  }
}


/* new page style 15 jun 2024 */
/* payment-cart-style */
.payment-cart-wrapper{
  .cart-title{
    display: flex;
    grid-gap: 16px;
    img{
      width: 24px;
      cursor: pointer;
    }
    h6{
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      margin: 0;
    }
  }
}
.overlay-wrapper.w-100 {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 48px 64px;
    border-radius: 8px;
    margin-top: 16px;
    height: calc(100vh - 15vh);
    overflow: auto;
input.ant-input.css-dev-only-do-not-override-1vb74mt.ant-input-outlined.custom-style {
    font-weight: 300;
    color:#9094A0;
}
    .payment-form-ui{
      max-width: 418px;
      position: sticky;
      top: 0;
      left: 0;
    }
  }
  .header-wrapper-ui{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5{
          font-size: 20px;
          font-weight: 600;
          line-height: 24.2px;
          text-align: left;
          margin: 0px;
          color:#000000;
        }
        svg{
          font-size: 48px;
          color: #6772E5;
        }
      }
  .ant-select-selection-item {
    color: #5E718D;
  }
  span.ant-select-selection-item{
  color: #5E718D;
}
.strip-st-ui-wrapper{
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  .elements-wrapper{
    grid-gap: 24px;
  }



  .ant-select {
    width: 100%;
    margin-bottom: 0px;
    font-weight: 300;
    span{
      color:#9094A0;
    }
}


  span.error{
    font-size: 12px;
    color: red;
  }
  .card-number-wrapper{
   label{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color:#97A1A6;
    display: block;
    padding-bottom: 6px;
   }

  }
  .card-number-input {
    background: #fff;
    width: 100%;
    padding: 7px 12px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
}
.card-number-expiry{
  width: 100%;
  label{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color:#97A1A6;
    display: block;
    padding-bottom: 6px;
   }
  .card-number-stripe {
    background: #fff;
    width: 100%;
    padding: 7px 12px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
}
}
}
.strip-st-ui-wrapper-modal{
  .card-number-input {
    width: auto;
    padding: 10px 12px;
}
.card-number-stripe {
    width: auto !important;
}
}
/* payment-cart-style */
`;

export default PaymentWrapper;
