import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import Input from '../../../components/input';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';

import { EmployeeState } from '../../../redux/types/employee';
import { SetOtherNotifyState } from '../../../redux/slices/other';
import { SetEmployeeState } from '../../../redux/slices/employee';

import {
  ApplyValidation,
  DecryptCredentials,
  EncryptCredentials
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

interface AddEmployeeProps {
  onCancel?: (
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>
  ) => void;
  onSubmit?: (
    email: string,
    name: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>
  ) => void;
  open?: boolean;
  title?: string;
  saveText?: string;
  employeeId?: string;
  loading?: boolean;
}

const AddEmployeeModal: React.FC<AddEmployeeProps> = ({
  onCancel,
  onSubmit,
  open,
  title,
  saveText,
  employeeId,
  loading
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    employees,
    isEmployeeAdded
  } = useSelector((state: { employee: EmployeeState }) => state.employee);

  const [currentEmployee, setCurrentEmployee] = useState<{
    name: string;
    email: string;
  }>({
    name: '',
    email: ''
  });

  const [formData, setFormData] = useState<{
    name: string;
    email: string;
  }>({
    name: '',
    email: ''
  });

  const [formHelperText, setFormHelperText] = useState<{
    name: string;
    email: string;
  }>({
    name: '',
    email: ''
  });

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'email' && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_EMAIL);
    } else {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName]
    });
  };

  const handleSubmit = () => {
    const nameError = validateField('name', formData.name).name;
    const emailError = validateField('email', formData.email).email;
    let { name } = formData;
    let email = EncryptCredentials(formData.email);

    if (nameError || emailError) {
      setFormHelperText({
        name: nameError || '',
        email: emailError || ''
      });
      return;
    }

    if (saveText === t('update')) {
      if (formData.email === DecryptCredentials(currentEmployee.email)) {
        email = '';
      }
      if (formData.name === currentEmployee.name) {
        name = '';
      }
    }

    if (name === '' && email === '') {
      dispatch(SetOtherNotifyState({
        message: t('no_changes_made'),
        type: 'info'
      }));

      return;
    }

    setFormData({
      name,
      email
    });

    onSubmit?.(email, name, setFormData, setFormHelperText);
  };

  useEffect(() => {
    if (isEmployeeAdded) {
      dispatch(SetEmployeeState({
        field: 'employees',
        value: []
      }));
    }
  }, [isEmployeeAdded]);

  useEffect(() => {
    if (saveText === t('submit')) {
      setFormData({
        name: '',
        email: ''
      });
    }
    if (saveText === t('update') && employeeId && employees) {
      const selectedEmployee = employees.find((employee) => employee._id === employeeId);

      if (selectedEmployee) {
        setCurrentEmployee({
          email: selectedEmployee.email,
          name: selectedEmployee.name
        });

        setFormData({
          email: DecryptCredentials(selectedEmployee.email),
          name: selectedEmployee.name
        });
      }
    }
  }, [employeeId, employees, open]);

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => onCancel?.(setFormData, setFormHelperText)}
      onSubmitClick={handleSubmit}
      saveText={saveText}
      width={361}
      open={open}
      title={title}
    >
      {loading && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            label={t('name')}
            marginBottom="16px"
            placeholder={t('enter')}
            className="form-input add-employee-input"
            value={formData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('name', e.target.value)}
            helperText={formHelperText.name}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            label={t('email')}
            marginBottom="24px"
            placeholder={t('enter')}
            className="form-input add-employee-input"
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('email', e.target.value)}
            helperText={formHelperText.email}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AddEmployeeModal;
