import React from 'react';
import { useTranslation } from 'react-i18next';

import Trash from '../../assets/icons/trash-1.svg';
import Refresh from '../../assets/icons/refresh.svg';

interface ActionButtonProps {
  setRequeueJobs?: (value: boolean) => void;
  setDeleteJobs?: (value: boolean) => void;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  setRequeueJobs,
  setDeleteJobs
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-1 align-items-center">
      {renderActionButton(
        <img className="pointer" width="12px" src={Refresh} alt={t('no_check_icon')} />,
        () => setRequeueJobs?.(true)
      )}
      {renderActionButton(
        <img className="pointer" width="14px" src={Trash} alt={t('no_check_icon')} />,
        () => setDeleteJobs?.(true)
      )}
    </div>
  );
};

export default ActionButtons;
