/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import CrossIcon from '../../assets/icons/cross.svg';
import Welcome from '../../assets/images/welcome-brief-adler.svg';

import Modal from './index';
import Button from '../button/index';
import VideoPlayer from './video-player';

import { UpdateUser } from '../../redux/slices/user';
import { UserState } from '../../redux/types/user';

interface QuickTutorialProps {
  onCancel?: () => void;
  onClick?: (status: string) => void;
  open?: boolean;
  tutorialText?: boolean;
  heading?: string;
  loginFirstTime?: boolean;
  videoPlayerModal?: boolean;
  setVideoPlayerModal?: (value: boolean) => void;
  videoPlayerModalLink?: string;
}

const QuickTutorial: React.FC<QuickTutorialProps> = ({
  onCancel,
  onClick,
  open,
  tutorialText,
  heading,
  loginFirstTime,
  videoPlayerModal,
  setVideoPlayerModal,
  videoPlayerModalLink
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector((state: { user: UserState }) => state.user);

  useEffect(() => {
    if (user && loginFirstTime) {
      const {
        _id: userId,
        notification = {}
      } = user || {};
      const { loginFirstTime: isLoginFirstTime } = notification || {};
      if (isLoginFirstTime) {
        dispatch(UpdateUser({
          userId: userId as string,
          updateParams: {
            notification: {
              ...user?.notification,
              loginFirstTime: true
            }
          }
        }));
      }
    }
  }, [loginFirstTime]);

  return (
    <>
      <Modal
        onCancelClick={() => {
          if (onCancel) {
            onCancel();
          }
        }}
        width={416}
        open={open}
      >
        <div
          className="cross-icon"
          role="button"
          tabIndex={0}
          onClick={onCancel}
          onKeyDown={onCancel}
        >
          <img src={CrossIcon} alt={t('no_cross_icon')} />
        </div>
        <div className="text-center">
          {(tutorialText && loginFirstTime) && <img src={Welcome} alt="no-icon" />}
          {(heading && loginFirstTime) && <h2>{heading}</h2>}
          {(tutorialText && loginFirstTime) && <p className="quick-tutorial">{t('quick_tutorial_text')}</p>}
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                width="100%"
                text={t('english')}
                type="default"
                onClick={() => onClick?.('English')}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                width="100%"
                text={t('german')}
                type="default"
                onClick={() => onClick?.('German')}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <VideoPlayer
        open={videoPlayerModal}
        onCancel={() => {
          setVideoPlayerModal?.(false);
        }}
        link={videoPlayerModalLink}
      />
    </>
  );
};

export default QuickTutorial;
