import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

interface ErrorState {
  errorMessage: string;
}

const initialState: ErrorState = {
  errorMessage: ''
};

const errorSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    SetErrorState(state: any, action: PayloadAction<{ field: keyof ErrorState; value: string | null }>) {
      const { field, value } = action.payload;
      state[field] = value;
    }
  }
});

const { actions } = errorSlice;

export const { SetErrorState } = actions;

export default errorSlice.reducer;
