import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Heading from './heading-box';

import DinA5 from '../../../../assets/images/Template-Creation-Basic-1.jpg';
import DinA4 from '../../../../assets/images/Template-Creation-Branding-1.jpg';
import Maxi from '../../../../assets/images/Template-Creation-Plus-1.jpg';
import ChatInfo from '../../../../assets/images/chat-info.svg';
import ChooseRibbon from '../../../../assets/images/badge_most_popular.svg';

import Button from '../../../../components/button';

import {
  AddTemplate as AddTemplateInterface
} from '../../../../redux/types/template';
import { UserState } from '../../../../redux/types/user';

import { DrawerWrapper } from '../drawer-style';
import ChoosePlanWrapper from './style';

import {
  CHOOSE_PLAN_VALUES,
  ADVERTISEMENT_FORMAT_VALUES,
  ENVELOPE_FORMAT_VALUES,
  LETTER_STYLE_VALUES,
  PLAN_TYPES
} from '../../../../constants';

interface ChoosePlanProps {
  addTemplate: AddTemplateInterface;
  setAddTemplate: (value: AddTemplateInterface) => void;
  handleNext: () => void;
  templateAction?: string;
}

interface ChoosePlanItem {
  image: string;
  id: string;
  title: string;
  secondTitle: string;
  caption: string;
  description: string;
}

const ChoosePlan: React.FC<ChoosePlanProps> = ({
  addTemplate,
  setAddTemplate,
  handleNext,
  templateAction
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user: userData } = useSelector((state: { user: UserState }) => state.user);

  const plans = [{
    image: DinA5,
    id: CHOOSE_PLAN_VALUES.BASIC,
    title: t('basic') || CHOOSE_PLAN_VALUES.BASIC,
    secondTitle: t('basic_second_heading') || CHOOSE_PLAN_VALUES.BASIC,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.BASIC
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}€`
      : '0€',
    description: t('basic_message'),
    showInfoWithTitle: false
  }, {
    image: DinA4,
    id: CHOOSE_PLAN_VALUES.BRANDING,
    title: t('branding') || CHOOSE_PLAN_VALUES.BRANDING,
    secondTitle: t('branding_second_heading') || CHOOSE_PLAN_VALUES.BRANDING,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.BRANDING
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}€`
      : '0€',
    description: t('branding_message'),
    showInfoWithTitle: false
  }, {
    image: Maxi,
    id: CHOOSE_PLAN_VALUES.BIG,
    title: t('big') || CHOOSE_PLAN_VALUES.BIG,
    secondTitle: t('big_second_heading') || CHOOSE_PLAN_VALUES.BIG,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.MAXI
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}€`
      : '0€',
    description: t('big_message')
  }];

  const handlePlanOptionClick = (plan: { title: string; id: string; }) => {
    setAddTemplate({
      ...addTemplate,
      plan: plan.id,
      advertisementFormat: plan.id === CHOOSE_PLAN_VALUES.BASIC
        ? ADVERTISEMENT_FORMAT_VALUES.DIN_A5
        : '',
      // envelopeColor: userData?.purchasedBundle?.planType === PLAN_TYPES.BASIC
      //   && plan.id === CHOOSE_PLAN_VALUES.BASIC
      //   ? 'White'
      //   : '',
      // envelopeColor: (templateAction === 'edit'
      //   ? ([CHOOSE_PLAN_VALUES.BASIC, PLAN_TYPES.MAXI].includes(addTemplate?.plan
      //     || '') ? 'White' : 'Marble')
      //   : ''),

      envelopeColor: plan.id === CHOOSE_PLAN_VALUES.BASIC
        ? 'White'
        : (templateAction === 'edit'
          && addTemplate?.envelopeColor !== ''
          && addTemplate?.plan === plan.id
          ? addTemplate?.envelopeColor
          : ''),
      letterStyle: LETTER_STYLE_VALUES.BASIC,
      envelopeFormat: plan.id === CHOOSE_PLAN_VALUES.BASIC
        ? ENVELOPE_FORMAT_VALUES.DIN_C6
        : ''
    });
  };

  return (
    <ChoosePlanWrapper>
      <div className="add-scroll">
        <div className="card-header-wrapper">
          <Heading
            title={t('choose_plan')}
            infoDetails={t('choose_format_tip')}
          />
        </div>
        <DrawerWrapper>
          <div className="d-flex">
            <div className="d-flex gap-11  add-top-space">
              {plans.map((plan: ChoosePlanItem, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={index}
                  className={addTemplate?.plan === plan.id
                    ? 'advertisee-box active'
                    : 'advertisee-box'}
                  onClick={() => handlePlanOptionClick(plan)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleNext();
                    }
                  }}
                >
                  {plan.title === 'Branding'
                  && (
                  <div className="add-ribbon">
                    <img src={ChooseRibbon} alt="no-svg" />
                  </div>
                  ) }
                  <div className="d-flex justify-content-center">
                    <div className="credits-counter">
                      <span className="success-credit credit" />
                      <p>
                        {t('credits')}
                        :
                        {` ${plan.caption}`}
                      </p>
                    </div>
                  </div>
                  <h3 className="text-center">{plan.title}</h3>
                  <img className="type" src={plan.image} alt={plan.title} />
                  <div className="d-flex bottom-space">
                    <h4 className="d-flex align-items-center">
                      {plan.secondTitle}
                    </h4>
                  </div>
                  <span className="description">
                    {plan.description}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </DrawerWrapper>
        <div className="compare-plans-button-div">
          <Button
            text={t('compare_plans')}
            type="primary"
            onClick={() => {
              navigate('/payment');
            }}
          />
        </div>
      </div>
    </ChoosePlanWrapper>
  );
};

export default ChoosePlan;
