import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import Input from '../../components/input';
import Button from '../../components/button/index';
import ProfileImg from '../../assets/images/profile.svg';
import EditIcon from '../../assets/icons/edit-icon.svg';

import ProfileWrapper from './style';

const Index = () => {
  const { t } = useTranslation();
  return (
    <ProfileWrapper>
      <h3>Profile</h3>
      <div className="profile-box">

        <Row gutter={[16, 16]}>
          <Col xs={2} sm={4} md={6} lg={8} xl={4}>
            <div className="profile-img">
              <img src={ProfileImg} alt={t('no_profile')} />
              <span className="edit-box">
                <img src={EditIcon} alt={t('no_edit_icon')} />
              </span>
            </div>
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={8}>
            <Input label={t('name')} placeholder="John Doe" marginBottom="24px" />
            <Input label={t('password')} placeholder="Qwerty123" marginBottom="24px" type="password" />
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={8}>
            <Input label={t('email')} placeholder="johndoe@gmail.com" marginBottom="24px" />
            <Input label={t('confirm_password')} placeholder="Qwerty123" marginBottom="24px" type="password" />
          </Col>
        </Row>

        <div className="profile-buttons">
          <Button text={t('cancel')} type="default" width="91px" />
          <Button text={t('update')} type="primary" width="91px" />
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Index;
