import React from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';

import DraggerStyleWrapper from './style';

const { Dragger } = Upload;

interface UploadDraggerProps {
  label?: string;
  handleChangeAttachment: any,
  accept?: string;
}

const props: UploadProps = {
  name: 'file',
  showUploadList: false,
  onDrop() { }
};

const UploadDragger: React.FC<UploadDraggerProps> = ({ handleChangeAttachment, accept = '' }) => {
  const { t } = useTranslation();

  return (
    <DraggerStyleWrapper>
      <Dragger
        onChange={handleChangeAttachment}
        customRequest={handleChangeAttachment}
        accept={accept}
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">{t('drag_sheet')}</p>
      </Dragger>
    </DraggerStyleWrapper>
  );
};

export default UploadDragger;
