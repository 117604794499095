import styled from 'styled-components';

const ConnectionWrapper = styled.div`
.header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
  margin-top: -6px;
  h3{
    margin:0px;
  }
  &::after{
    content:"";
    position:absolute;
    width:112%;
    height:1px;
    background-color:#DFDEDE;
    right: 0px;
    left: -24px;
    bottom: -20px;
  }
  .arrow-box{
    display:flex;
    gap:8px;
  }
}
.store-added{
  margin-top:44px;
  h5{
    font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
    margin: 0px;
    margin-bottom: 14px;
  }
  .added-store{
  border:1px solid #D9D9D9;
  border-radius:8px;
  max-height:500px;
  overflow:auto;
  .store{
    display:flex;
    padding:14px 18px 6px 15px;
    gap:18px;
    border-bottom:1px solid #DFDEDE;
    &:last-child{
      border:none;
    }
    .store-details{
      display:flex;
      flex-direction:column;
      span:first-child{
        font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
        color:${({ theme }) => theme.colors.secondaryColor400};
        font-weight:600
      }
      span:nth-child(2){
        font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
        color:${({ theme }) => theme.colors.placeholderColor};
      }
    }
  }
}
.add-store-btn{
  margin-top:35px;
}
}
.button-box{
  display:flex;
  justify-content:flex-end;
}

`;

const StrengthWrapper = styled.div`
width: 210px;
.strength-title {
  font-weight:  ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.bodyColor};
  line-height: 18px;
  margin-bottom: 7px;
  display: block;
   font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
}
.ant-space{
  width: 100%;
}
.progress-wrapper{
  padding-top: 2px;
  position: relative;
  padding-bottom: 12px;
  .ant-progress{
    top: -8px;
    margin-bottom:3px;
  }
  .progress-message{
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    height: 24px;
    line-height: 12px;
    position: absolute;
    bottom: 0;
    display: block;
  }
}
.progress-header-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
  }
}
.password-check {
    display: flex;
    align-items: start;
    gap: 8px;
    span {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
      color: ${({ theme }) => theme.colors.placeholderColor};
      line-height: 12px;
    }
    &.check-pass {
      img {
        filter: invert(53%) sepia(89%) saturate(437%) hue-rotate(56deg) brightness(101%) contrast(93%);
      }
      span {
        color: ${({ theme }) => theme.colors.successColor};
      }
    }
  }
  .strength-bar {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 16px 0 8px;
    &.margin-remove{
      margin:0px;
      span{
        margin-bottom:8px;
      }
    }
    span {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
      color: ${({ theme }) => theme.colors.labelLightColor};
      line-height: 12px;
      margin-top: 3px;
    }
  }
  .password-strength {
    p {
      margin-top: -22px !important;
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs} !important;
    }
    div {
      width: 206px;
      margin-top: 0 !important;
      div {
        height: 4px !important;
        margin: 0;
        position: relative;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
        &:nth-child(2) {
          width: 0 !important;
        }
        &:nth-child(4) {
          width: 0 !important;
        }
        &:nth-child(6) {
          width: 0 !important;
        }
      }
    }
  }
  .strength-text {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    color: ${({ theme }) => theme.colors.labelColor};
    line-height: 12px;
    display: block;
  }
`;

export {
  ConnectionWrapper,
  StrengthWrapper
};
