import React, {
  useEffect,
  useState
} from 'react';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import {
  Popover,
  Space,
  Progress
} from 'antd';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Loader from '../../../components/loader';

import { StrengthWrapper } from '../style';

import {
  SetAuthState,
  Signup
} from '../../../redux/slices/auth';
import { AuthState } from '../../../redux/types/auth';

import {
  SignUpHelperTextState,
  SignUpDataState,
  SignupData
} from '../../../utils/types/pages/auth';

import {
  AddToLocalStorage,
  ApplyValidation,
  CheckPasswordStrength,
  EncryptCredentials,
  TestPasswordStrength
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

import TickIcon from '../../../assets/icons/tick-icon.svg';

const SignUp: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isSignedUp,
    loading
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const [signUpData, setSignUpData] = useState<SignUpDataState>({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [signUpHelperText, setSignUpHelperText] = useState<SignUpHelperTextState>({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null);

  const validateField = (
    fieldName: keyof SignUpDataState,
    value: string
  ): Partial<SignUpHelperTextState> => {
    const errors: Partial<SignUpHelperTextState> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'email' && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_EMAIL);
    } else if (fieldName === 'password' && !ApplyValidation(REGEX_PATTERNS.PASSWORD_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_PASSWORD);
    } else {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleConfirmPasswordChange = (value: string) => {
    setSignUpData({
      ...signUpData,
      confirmPassword: value
    });

    if (value === '') {
      setPasswordMatch(null);
    } else {
      setPasswordMatch(signUpData.password === value);
    }
  };

  const handleInputChange = (
    fieldName: keyof SignUpDataState,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setSignUpData({
      ...signUpData,
      [fieldName]: value
    });

    setSignUpHelperText({
      ...signUpHelperText,
      [fieldName]: errors[fieldName]
    });

    if (fieldName === 'password' && signUpData.confirmPassword !== '') {
      setPasswordMatch(value === signUpData.confirmPassword);
    }

    if (fieldName === 'confirmPassword') {
      handleConfirmPasswordChange(value);
    }

    const strength: number = TestPasswordStrength(value);
    setPasswordStrength(strength);
  };

  const handleSignUp = () => {
    setSignUpHelperText({
      name: validateField('name', signUpData.name).name || '',
      email: validateField('email', signUpData.email).email || '',
      password: validateField('password', signUpData.password).password || '',
      confirmPassword: validateField('confirmPassword', signUpData.confirmPassword).confirmPassword || ''
    });

    handleConfirmPasswordChange(signUpData.confirmPassword);

    if (
      signUpHelperText.name
      || signUpHelperText.email
      || signUpHelperText.password
      || signUpHelperText.confirmPassword
      || !passwordMatch
    ) {
      return;
    }

    const encryptedData = {
      encryptedEmail: EncryptCredentials(signUpData.email),
      encryptedPassword: EncryptCredentials(signUpData.password)
    };

    AddToLocalStorage(['resendVerificationEmail', encryptedData.encryptedEmail]);

    const data = {
      name: signUpData.name,
      email: encryptedData.encryptedEmail,
      password: encryptedData.encryptedPassword
    };

    dispatch(Signup(data as SignupData));
  };

  const strengthData = [{
    title: t('at_least_8_characters'),
    check: signUpData.password.length >= 8
  }, {
    title: (
      <>
        {t('have_uppercase_lowercase_letters')}
        <br />
        {t('numbers_and_special_characters')}
      </>
    ),
    check: CheckPasswordStrength(signUpData.password)
  }];

  useEffect(() => {
    if (isSignedUp) {
      navigate('/account-verification');
      dispatch(SetAuthState({
        field: 'isSignedUp',
        value: false
      }));
    }
  }, [isSignedUp]);

  return (
    <>
      {loading && <Loader />}
      <AuthFormWrapper
        className="sign-in-form"
        heading={`${t('welcome_to_brief_adler')} 👋`}
        subtext={t('sign_up_phrase')}
      >
        <Input
          label={t('full_name')}
          className="form-input"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('name', e.target.value);
          }}
          helperText={signUpHelperText.name}
        />

        <Input
          label={t('email')}
          className="form-input"
          type="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('email', e.target.value);
          }}
          helperText={signUpHelperText.email}
        />

        <Popover
          overlayClassName="auth-password-popover"
          content={(
            <StrengthWrapper>
              <span className="strength-title">{t('your_password_must_contain')}</span>
              <Space direction="vertical">
                {strengthData.map((data, ind) => (
                  <div
                    className={`password-check ${data.check && 'check-pass'}`}
                    key={ind}
                  >
                    <img src={TickIcon} alt={t('tick')} />
                    <span>{data.title}</span>
                  </div>
                ))}
                <div className="progress-wrapper">
                  <div className="progress-header-wrapper">
                    <span>{t('strength')}</span>
                    <span
                      style={{
                        marginRight: 8,
                        color: (passwordStrength === 1 || passwordStrength === 2)
                          ? '#D40001'
                          : (passwordStrength === 3 || passwordStrength === 4)
                            ? '#F17400'
                            : passwordStrength === 5
                              ? '#0FB600'
                              : '#D40001'
                      }}
                    >
                      {(passwordStrength === 1 || passwordStrength === 2)
                        ? t('weak')
                        : (passwordStrength === 3 || passwordStrength === 4)
                          ? t('normal')
                          : passwordStrength === 5 && t('fantastic')}
                    </span>
                  </div>
                  <Progress
                    percent={(passwordStrength === 1 || passwordStrength === 2)
                      ? 20
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? 60
                        : passwordStrength === 5
                          ? 100
                          : 0}
                    status={(passwordStrength === 1 || passwordStrength === 2)
                      ? 'exception'
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? 'active'
                        : passwordStrength === 5
                          ? 'success'
                          : 'exception'}
                    showInfo={false}
                    strokeColor={(passwordStrength === 1 || passwordStrength === 2)
                      ? '#D40001'
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? '#F17400'
                        : passwordStrength === 5
                          ? '#0FB600'
                          : '#D40001'}
                    trailColor="#E7E7E7"
                  />
                  <span className="progress-message">
                    {(passwordStrength === 1 || passwordStrength === 2)
                      ? t('this_password_is_too_short')
                      : passwordStrength > 2 ? t('avoid_sequence') : ''}
                  </span>
                </div>
              </Space>
            </StrengthWrapper>
          )}
          trigger="click"
          placement="bottomRight"
        >
          <div>
            <Input
              authInput
              type="password"
              label={t('password')}
              className="form-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange('password', e.target.value);
              }}
              helperText={signUpHelperText.password}
            />
          </div>
        </Popover>
        <Input
          authInput
          label={t('confirm_password')}
          className="form-input confirm-password"
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('confirmPassword', e.target.value);
          }}
          helperText={signUpHelperText.confirmPassword}
        />
        <div className="input-message-wrapper">
          {passwordMatch !== null && (
            <div className={`input-message ${passwordMatch ? 'password-match' : 'password-no-match'}`}>
              {passwordMatch ? t('password_matched') : t('password_doesnt_match')}
            </div>
          )}
        </div>
        <Button
          text={t('sign_up')}
          type="primary"
          width="100%"
          borderRadius="12px"
          disabled={false}
          className="custom-button"
          onClick={handleSignUp}
        />
        <div className="ba-text-align-center">
          <p>
            {t('already_have_an_account')}
            <Link to="/sign-in">{t('sign_in')}</Link>
          </p>
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default SignUp;
