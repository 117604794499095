import React, {
  useState,
  useEffect
} from 'react';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Loader from '../../../components/loader';
import Select from '../../../components/select';
import Modal from '../../../components/modal';

import { EmployeeState } from '../../../redux/types/employee';

interface Option {
  label: string;
  value: string;
}
interface AssignCustomerProps {
  onCancel?: () => void;
  open?: boolean;
  title?: string;
  saveText? : string;
  employeeId?: string | null;
  onSave?: (
    customer: string,
    saveText: string | undefined,
    setSelectedCustomer: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => void;
  selectHelperText?: string;
  setSelectHelperText?: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading?: boolean;
}

const AssignCustomerModal: React.FC<AssignCustomerProps> = ({
  onCancel,
  onSave,
  open,
  title,
  saveText,
  employeeId,
  selectHelperText,
  setSelectHelperText,
  loading
}) => {
  const { t } = useTranslation();
  const {
    customers,
    employees
  } = useSelector((state: { employee: EmployeeState }) => state.employee);

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>();

  const handleSubmit = () => {
    onSave?.(selectedCustomer as string, saveText, setSelectedCustomer);
  };

  useEffect(() => {
    if (open && customers) {
      const alreadyAssignedCustomers = employeeId
        ? employees.find((employee) => employee._id === employeeId)?.customers
        : [];

      if (saveText === t('assign')) {
        const options = customers.filter(
          (customer) => !alreadyAssignedCustomers?.includes(customer._id)
        ).map((customer) => ({
          label: customer.name,
          value: customer._id
        }
        ));

        setCustomerOptions(options);
      } else if (saveText === t('unassign')) {
        const options = customers.filter(
          (customer) => alreadyAssignedCustomers?.includes(customer._id)
        ).map((customer) => ({
          label: customer.name,
          value: customer._id
        }));

        setCustomerOptions(options);
      }
    }
  }, [open, customers, employees, employeeId, saveText]);

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => {
        if (onCancel) {
          onCancel();
          setSelectedCustomer(undefined);
        }
      }}
      onSubmitClick={handleSubmit}
      saveText={saveText}
      width={361}
      open={open}
      title={title}
    >
      {loading && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Select
            label={t('customer')}
            marginBottom="24px"
            placeholder={t('select')}
            className="form-input assign-employee-input"
            options={customerOptions}
            onChange={(value) => {
              setSelectedCustomer(value);
              if (setSelectHelperText) {
                setSelectHelperText('');
              }
            }}
            value={selectedCustomer}
            helperText={selectHelperText}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AssignCustomerModal;
