import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  AddMaxiInsertPayload,
  EditMaxiInsertPayload,
  GetAllMaxiInsertsData,
  GetMaxiInsertByTemplateIdPayload,
  MaxiInsert,
  MaxiInsertIdPayload,
  MaxiInsertState,
  ReceiveMaxiInsertPayload
} from '../types/maxi';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: MaxiInsertState = {
  error: null,
  getAllMaxiInsertsSuccess: false,
  getAllMaxiInsertsLoading: false,
  getMaxiInsertByTemplateIdLoading: false,
  getMaxiInsertByTemplateIdSuccess: false,
  deleteMaxiInsertLoading: false,
  receiveMaxiInsertLoading: false,
  isMaxiInsertAdded: false,
  isMaxiInsertDeleted: false,
  isMaxiInsertEdited: false,
  isMaxiInsertReceived: false,
  loading: false,
  maxiInsertActionLoading: false,
  maxiInserts: [],
  maxiInsert: null,
  notify: false,
  notifyMessage: '',
  notifyType: '',
  success: false,
  totalMaxiInserts: 0
};

export const AddMaxiInsert = createAsyncThunk(
  'maxi-insert/add-maxi-insert',
  async (data: AddMaxiInsertPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/maxi-insert/add-maxi-insert', {
        addMaxiInsert: data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const DeleteMaxiInsertById = createAsyncThunk(
  'maxi-insert/delete-maxi-insert',
  async (data: MaxiInsertIdPayload, { rejectWithValue }) => {
    try {
      const { maxiInsertId } = data;

      const response = await axios.delete('/maxi-insert/delete-maxi-insert', {
        data: {
          maxiInsertId
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const EditMaxiInsert = createAsyncThunk(
  'maxi-insert/edit-maxi-insert',
  async (data: EditMaxiInsertPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/maxi-insert/edit-maxi-insert', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetAllMaxiInserts = createAsyncThunk(
  'maxi-insert/get-all-maxi-inserts',
  async (data: GetAllMaxiInsertsData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;

      const response = await axios.get('/maxi-insert/get-all-maxi-inserts', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetMaxiInsertByTemplateId = createAsyncThunk(
  'maxi-insert/get-maxi-insert-by-template-id',
  async (data: GetMaxiInsertByTemplateIdPayload, { rejectWithValue }) => {
    try {
      const { templateId } = data;

      const response = await axios.get('/maxi-insert/get-maxi-insert-by-template-id', {
        params: {
          templateId
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const ReceiveMaxiInsert = createAsyncThunk(
  'maxi-insert/receive-maxi-insert',
  async (data: ReceiveMaxiInsertPayload, { rejectWithValue }) => {
    try {
      const {
        maxiInsertId,
        receivedQuantity
      } = data;

      const response = await axios.patch('/maxi-insert/receive-maxi-insert', {
        maxiInsertId,
        receivedQuantity
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const maxiInsertSlice = createSlice({
  name: 'maxiInsert',
  initialState,
  reducers: {
    SetMaxiInsertState(state, action: PayloadAction<{
      field: keyof MaxiInsertState;
      value: MaxiInsertState[keyof MaxiInsertState];
    }>) {
      const updateMaxiInsert = <T extends keyof MaxiInsertState>(field: T, value: MaxiInsertState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateMaxiInsert(field, value as MaxiInsertState[keyof MaxiInsertState]);
    },
    SetMaxiInsertNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddMaxiInsert.pending, (state) => {
        state.maxiInsertActionLoading = true;
        state.success = false;
        state.isMaxiInsertAdded = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(AddMaxiInsert.fulfilled, (state, action) => {
        state.maxiInsertActionLoading = false;
        state.success = true;
        state.isMaxiInsertAdded = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
          state.maxiInserts.push(action.payload.data.newMaxiInsert);
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(AddMaxiInsert.rejected, (state, action) => {
        state.maxiInsertActionLoading = false;
        state.isMaxiInsertAdded = false;
        state.error = action.payload as string;
        const payload = action.payload as { error? : string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(DeleteMaxiInsertById.pending, (state) => {
        state.deleteMaxiInsertLoading = true;
        state.success = false;
        state.isMaxiInsertDeleted = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(DeleteMaxiInsertById.fulfilled, (state, action) => {
        state.deleteMaxiInsertLoading = false;
        state.success = true;
        state.isMaxiInsertDeleted = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(DeleteMaxiInsertById.rejected, (state, action) => {
        state.deleteMaxiInsertLoading = false;
        state.isMaxiInsertDeleted = false;
        state.error = action.payload as string;
        const payload = action.payload as { error? : string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(EditMaxiInsert.pending, (state) => {
        state.maxiInsertActionLoading = true;
        state.success = false;
        state.isMaxiInsertEdited = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(EditMaxiInsert.fulfilled, (state, action) => {
        state.maxiInsertActionLoading = false;
        state.success = true;
        state.isMaxiInsertEdited = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(EditMaxiInsert.rejected, (state, action) => {
        state.maxiInsertActionLoading = false;
        state.isMaxiInsertEdited = false;
        state.error = action.payload as string;
        const payload = action.payload as { error? : string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllMaxiInserts.pending, (state) => {
        state.getAllMaxiInsertsLoading = true;
        state.getAllMaxiInsertsSuccess = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(GetAllMaxiInserts.fulfilled, (state, action) => {
        state.getAllMaxiInsertsLoading = false;
        state.getAllMaxiInsertsSuccess = true;
        state.maxiInserts = action.payload.data;
        state.error = null;
        const payload = action.payload.data as { maxiInserts: MaxiInsert[]; totalMaxiInserts: number } | undefined;
        if (payload) {
          state.maxiInserts = payload.maxiInserts;
          state.totalMaxiInserts = payload.totalMaxiInserts;
        }
      })
      .addCase(GetAllMaxiInserts.rejected, (state, action) => {
        state.getAllMaxiInsertsLoading = false;
        state.getAllMaxiInsertsSuccess = false;
        state.error = action.payload as string;
        const payload = action.payload as { error? : string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetMaxiInsertByTemplateId.pending, (state) => {
        state.getMaxiInsertByTemplateIdLoading = true;
        state.getMaxiInsertByTemplateIdSuccess = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(GetMaxiInsertByTemplateId.fulfilled, (state, action) => {
        state.getMaxiInsertByTemplateIdLoading = false;
        state.getMaxiInsertByTemplateIdSuccess = true;
        state.error = null;
        const payload = action.payload.data as { maxiInsert: MaxiInsert | null } | undefined;
        if (payload) {
          state.maxiInsert = payload.maxiInsert;
        }
        state.notify = false;
      })
      .addCase(GetMaxiInsertByTemplateId.rejected, (state, action) => {
        state.getMaxiInsertByTemplateIdLoading = false;
        state.getMaxiInsertByTemplateIdSuccess = false;
        state.error = action.payload as string;
        state.notify = false;
      })
      .addCase(ReceiveMaxiInsert.pending, (state) => {
        state.receiveMaxiInsertLoading = true;
        state.success = false;
        state.isMaxiInsertReceived = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(ReceiveMaxiInsert.fulfilled, (state, action) => {
        state.receiveMaxiInsertLoading = false;
        state.success = true;
        state.isMaxiInsertReceived = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ReceiveMaxiInsert.rejected, (state, action) => {
        state.receiveMaxiInsertLoading = false;
        state.isMaxiInsertReceived = false;
        state.error = action.payload as string;
        const payload = action.payload as { error? : string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const {
  reducer,
  actions
} = maxiInsertSlice;

export const {
  SetMaxiInsertState,
  SetMaxiInsertNotifyState
} = actions;

export default reducer;
