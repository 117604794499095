import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

interface CardItemProps {
  heading?: React.ReactNode;
  className?: string;
  subItem?: any[]
}
const CardItem: React.FC<CardItemProps> = ({
  heading,
  className,
  subItem
}) => {
  const { t } = useTranslation();

  return (
    <div className={className || 'content-area-card-item'}>
      {heading ? <h6>{heading}</h6> : null}
      {heading ? <div className="divider" /> : null}
      {subItem?.map((item: any, index: number) => (
        <div className="card-items" key={index}>
          <p>
            {item.name}
            {item.imagePath ? (
              <Tooltip
                overlayClassName="content-tooltip-ba"
                placement="top"
                title={
                item.name === 'Fully automated processing' ? (
                  <div className="ul-list-text">
                    <p>
                      {t('fully_automated_processing_p1')}
                    </p>
                    <p>{t('fully_automated_processing_p2')}</p>
                  </div>
                ) : (
                  <p>{item.title}</p>
                )
              }
              >
                <img src={item.imagePath} alt="imagePath" />
              </Tooltip>
            ) : null}
          </p>
          {item.descriptionImage
            ? <img src={item.descriptionImage} alt={item.descriptionImage} />
            : <p>{item.description}</p>}
        </div>
      ))}
    </div>
  );
};

export default CardItem;
