import { Spin } from 'antd';
import LoaderWrapper from './style';

const Loader = () => (
  <LoaderWrapper>
    <Spin
      size="large"
    />
  </LoaderWrapper>
);

export default Loader;
