import React from 'react';

import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDown from '../../assets/icons/select-icon.svg';

import SelectWrapper from './select.style';

const { SHOW_PARENT } = TreeSelect;

interface CustomSelectProps {
  defaultValue?: string | number;
  onChange?: (value: any) => void;
  placeholder?: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  value?: any;
  treeSelect?: boolean,
  labelVertical?: boolean,
  treeData?: any
}

const MultipleSelect: React.FC<CustomSelectProps> = ({
  placeholder,
  onChange,
  value,
  treeData,
  disabled
}) => {
  const { t } = useTranslation();

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder,
    disabled
  };

  return (
    <SelectWrapper width="237px">
      <TreeSelect suffixIcon={<img src={ArrowDown} alt={t('no_icon')} />} {...tProps} />
    </SelectWrapper>
  );
};

export default MultipleSelect;
