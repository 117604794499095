import React, { useEffect } from 'react';
import {
  Navigate,
  createBrowserRouter
} from 'react-router-dom';

// Components
import AccountVerification from '../pages/auth/components/account-verification';
import AccountVerified from '../pages/auth/components/account-verified';
import Auth from '../pages/auth';
import ScanQRCode from '../pages/dashboard/scan-qr';
import ForgotPassword from '../pages/auth/components/forgot-password';
import PassChanged from '../pages/auth/components/password-changed';
import ResetPassword from '../pages/auth/components/reset-password';
import SetPassword from '../pages/auth/components/set-password';
import SignIn from '../pages/auth/components/sign-in';
import SignUp from '../pages/auth/components/sign-up';
import VerificationEmail from '../pages/auth/components/check-your-email';

const router = createBrowserRouter([{
  path: '/',
  element: !window.location.href.includes('scan') ? <Auth /> : null,
  children: [{
    path: '/',
    element: <Navigate to="sign-in" replace />
  }, {
    path: '*',
    element: <Navigate to="sign-in" replace />
  }, {
    path: 'sign-up',
    element: <SignUp />
  }, {
    path: 'sign-in',
    element: <SignIn />
  }, {
    path: 'forgot-password',
    element: <ForgotPassword />
  }, {
    path: 'verification-email',
    element: <VerificationEmail />
  }, {
    path: 'reset-password/:token',
    element: <ResetPassword />
  }, {
    path: 'set-password/:token',
    element: <SetPassword />
  }, {
    path: 'password-changed',
    element: <PassChanged />
  }, {
    path: 'account-verification',
    element: <AccountVerification />
  }, {
    path: 'account-verified/:token',
    element: <AccountVerified />
  }, {
    path: 'scan',
    element: <ScanQRCode />
  }]
}]);

export default router;
