import styled from 'styled-components';

interface SelectWrapperProps {
  width?: string;
  authInput?: string;
  marginBottom?: string | number;
  children?: React.ReactNode;
}

const SelectWrapper = styled.div<SelectWrapperProps>`
   position: relative;
   margin-bottom: 12;
   width: ${(props) => (props.width ? props.width : '100%')};
   position:relative;
   .ant-select {
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '12px')};
    &:not(.ant-select-customize-input) .ant-select-selector{
      border: 1px solid ${({ theme }) => theme.colors.fieldBorderColor};
      border-radius: 4px;
      font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    }
    &:not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
      border: 1px solid ${({ theme }) => theme.colors.fieldBorderColor};
    }
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.colors.placeholderColor};
    }
    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

SelectWrapper.displayName = 'SelectWrapper';

const CustomSelectWrapper: React.FC<SelectWrapperProps> = ({
  children,
  ...rest
}) => (
  <SelectWrapper {...rest}>
    {children}
  </SelectWrapper>
);

export default CustomSelectWrapper;
