/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../../assets/icons/add-icon-1.svg';

import Button from '../../components/button';
import DeleteModal from '../templates/modals/delete';
import Loader from '../../components/loader';
import PaymentCard from '../../components/payment-card';

import PaymentModal from './modal/payment-card';

import {
  GetPaymentCards,
  SetPaymentState
} from '../../redux/slices/payment';
import {
  DeleteCard,
  SetCardAsDefault,
  SetStripeState
} from '../../redux/slices/stripe';

import { PaymentState } from '../../redux/types/payment';
import { StripeState } from '../../redux/types/stripe';
import { UserState } from '../../redux/types/user';

import {
  ConnectSocket,
  DisconnectSocket,
  HandleCurrentUser,
  HandleGetPaymentCards
} from '../../utils/socket';

import PaymentMethodWrapper from './style';

const publishKey = process.env.REACT_APP_BRIEF_ADLER_STRIPE_PUBLISH_KEY || '';
const stripePromise = loadStripe(publishKey);
const reactAppSocketServer = process.env.REACT_APP_API_SOCKET_SERVER;

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defaultCardId,
    paymentCards,
    loading
  } = useSelector((state: { payment: PaymentState }) => state.payment) || {};
  const {
    cardDeleted,
    defaultCard,
    loading: stripeLoading
  } = useSelector((state: { stripe: StripeState }) => state.stripe) || {};
  const { user = {} } = useSelector((state: { user: UserState }) => state.user) || {};

  const [cardToDeleteId, setCardToDeleteId] = useState<string>('');
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [defaultPaymentCard, setDefaultPaymentCard] = useState<string>('');
  const [editCard, setEditCard] = useState<string>('add');
  const [updateCardId, setUpdateCardId] = useState<string>('');
  const [editCardDetails, setEditCardDetails] = useState<object>({
    name: '',
    country: '',
    postalCode: ''
  });

  const handleEditCardModal = (card: any, cardId: string) => {
    setEditCardDetails({
      name: card?.name,
      country: card?.country,
      postalCode: card?.postalCode
    });

    setEditCard('edit');
    setPaymentModal(true);
    setUpdateCardId(cardId);
  };

  const handleSetAsDefault = (cardId: string) => {
    setDefaultPaymentCard(cardId);
    dispatch(SetCardAsDefault({ cardId }));
  };

  const handleDeleteModal = (cardId: string) => {
    setDeleteCard(true);
    setCardToDeleteId(cardId);
  };

  const handleCardDelete = () => {
    dispatch(DeleteCard({ cardId: cardToDeleteId }));
    setDeleteCard(false);
  };

  useEffect(() => {
    const socket = ConnectSocket({
      url: reactAppSocketServer || '',
      userId: user?._id || ''
    });

    HandleGetPaymentCards(socket, dispatch);
    HandleCurrentUser(socket, dispatch);

    dispatch(GetPaymentCards());

    return () => {
      DisconnectSocket(socket);
    };
  }, []);

  useEffect(() => {
    if (defaultCard) {
      dispatch(SetPaymentState({ field: 'defaultCardId', value: defaultPaymentCard }));
      dispatch(SetStripeState({ field: 'defaultCard', value: false }));
    } else if (cardDeleted) {
      dispatch(SetStripeState({ field: 'cardDeleted', value: false }));
    }
  }, [defaultCard, cardDeleted]);

  return (
    <PaymentMethodWrapper>
      {(loading || stripeLoading) && <Loader />}
      <header>
        <h2>{t('payment_method')}</h2>
        <Button
          text={t('add_card')}
          icon={
            <img width="14px" src={PlusIcon} alt="PlusIcon" />
          }
          type="primary"
          onClick={() => setPaymentModal(true)}
        />
      </header>
      <section>
        {paymentCards?.length
          ? paymentCards.map((card: any, index: number) => (
            <PaymentCard
              key={index}
              title={card.name}
              description={`xxxx-xxxx-xxxx-${card?.cardLast4Digits}`}
              date={`${card?.expMonth}/${card?.expYear?.toString()?.slice(2, card?.expYear?.toString()?.length)}`}
              editButton={() => handleEditCardModal(card, card.cardId)}
              deleteButton={() => handleDeleteModal(card.cardId)}
              isActive={card.cardId === defaultCardId}
              onClick={() => handleSetAsDefault(card.cardId)}
            />
          ))
          : <></>}
      </section>
      <DeleteModal
        heading={t('delete_card_description')}
        open={deleteCard}
        onCancel={() => setDeleteCard(false)}
        onConfirm={() => handleCardDelete()}
      />
      <Elements stripe={stripePromise}>
        <PaymentModal
          open={paymentModal}
          setPaymentModal={setPaymentModal}
          editCard={editCard}
          setEditCard={setEditCard}
          updateCardId={updateCardId}
          setEditCardDetails={setEditCardDetails}
          editCardDetails={editCardDetails}
        />
      </Elements>
    </PaymentMethodWrapper>
  );
};

export default PaymentMethod;
