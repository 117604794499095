import styled from 'styled-components';

const DraggerStyleWrapper = styled.div`
.ant-upload-drag {
    border-radius: 4px;
    border: 1px dashed var(--Text-Field, #DFDEDE);
    background: var(--Color-Light-Light, #FCFCFD);
    .ant-upload{
        padding: 16px 12px;
    }
}
span.anticon.anticon-cloud-upload svg {
    color: #E2C786;
    font-size: 22px;
}

p.ant-upload-drag-icon {
    width: 36px;
    margin: auto;
    margin-bottom: 0px !important;
    background: #FCFBF2;
    border-radius: 23px;
    height: 36px;
    padding: 6.3px;
}
p.ant-upload-text {
    color: #7E8A90;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px !important;
    margin-bottom: 2px !important;
}
p.ant-upload-hint {
    color: var(--Mid-Light-Grey, #979797);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}



`;
export default DraggerStyleWrapper;
