import styled from 'styled-components';

interface WarningModalProps {
  authInput? : string;
}

const WarningModalWrapper = styled.div<WarningModalProps>`
    width:100%;
 display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align:center;
    font-size:20px;
    margin:16px 12px 0 12px;;
  }
  .warning-desc {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    line-height: 18px;
    display: block;
    text-align: center;
    max-width: 350px;
    margin-top:8px; 
  }
  .margin-top{
    margin-top:24px;
  }
`;
export default WarningModalWrapper;
