import React, {
  useState
} from 'react';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import Input from '../../../components/input';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';

import {
  ADD_ADDRESS_ERROR_MESSAGE,
  AUTH_FORMS_ERROR_MESSAGES
} from '../../../constants';

import { IsLengthValidForWishNotListed } from '../../../utils/helpers';

interface AddMaxiAddressProps {
  onCancel?: (
    setFormData: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>
  ) => void;
  onSubmit?: (
    address: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>
  ) => void;
  open?: boolean;
  title?: string;
  saveText?: string;
  loading?: boolean;
}

const AddMaxiAddressModal: React.FC<AddMaxiAddressProps> = ({
  onCancel,
  onSubmit,
  open,
  title,
  saveText,
  loading
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<{
    address: string;
  }>({
    address: ''
  });

  const [formHelperText, setFormHelperText] = useState<{
    address: string;
  }>({
    address: ''
  });

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'address' && !IsLengthValidForWishNotListed({
      value,
      lowerLimit: 50,
      upperLimit: 100
    })) {
      errors[fieldName] = t(ADD_ADDRESS_ERROR_MESSAGE.INVALID_ADDRESS);
    } else {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName] as string
    });
  };

  const handleSubmit = () => {
    const subject = 'Support Request'; // Customize the subject as needed
    const body = encodeURIComponent(`Address:\n\n${formData.address}`);
    const mailtoLink = `mailto:support@brief-adler.de?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => onCancel?.(setFormData, setFormHelperText)}
      onSubmitClick={handleSubmit}
      saveText={saveText}
      width={361}
      open={open}
      title={title}
    >
      {loading && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            label={t('address')}
            rows={4}
            type="textarea"
            placeholder={t('enter')}
            className="form-input"
            value={'Niklas Müller\n\nLuisenstraBe 95\n\n40215 Düsseldorf'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('address', e.target.value)}
            helperText={formHelperText.address}
            style={{
              height: '117px'
            }}
            disabled
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AddMaxiAddressModal;
