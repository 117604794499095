import styled from 'styled-components';
import { Radio } from 'antd';

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.visualLight};
  border-radius: 25px;
  overflow: hidden;
  width: fit-content;
  padding:3px;
  margin-left:3px;
`;

const StyledRadioButton = styled(Radio.Button)`
  flex: 1;
  text-align: center;
  padding: 8px 16px;
  border-radius: 25px !important;
  &.ant-radio-button-wrapper {
    border: none;
    font-size:${({ theme }) => theme.fonts.baseFontSize};
    white-space: nowrap;
    line-height: 16px;
    background-color:transparent;
    padding:8px 20px 0px 16px;
    &::before{
      display:none;
    }
    img{
      margin-left:4px;
    }
  }
  &.ant-radio-button-wrapper-checked {
    background-color:${({ theme }) => theme.colors.backgroundPrimary}!important;
    color: ${({ theme }) => theme.colors.whiteColor};
    font-weight: 600;
  }
  &.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    color: ${({ theme }) => theme.colors.switchColor};
  }
  &:hover {
    color:  ${({ theme }) => theme.colors.switchColor};
  }
`;

export { StyledRadioButton, StyledRadioGroup };
