import styled, { css } from 'styled-components';

interface ContainerProps {
  minWidth?: string;
}

const dynamicColorMixin = (color: string) => css`
  background: ${color};
  &::before {
    background: ${color};
  }
  &::after {
    border-left: 30px solid ${color};
  }
`;

const Container = styled.div<ContainerProps>`
 .container{
  position: sticky;
    top: -26px;
    background: #ffff;
    z-index: 99;
    padding: 8px 0px;
    width: 100%;
 }
 .title {
    font-size: 17px;
  }

  ul {
    list-style: none;
    display: flex;
    width: 100%;
    padding: 0;
    grid-gap: 16px;
    margin-top:-15px;
  }

  li {
     min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : '158px')};
    max-width: 100%;
    height: 56px;
    margin-right: 5px;
    display: inline-flex;
    position: relative;
    color:#000;
    ${dynamicColorMixin('#F2F2F2  ')}

    &.current {
      ${dynamicColorMixin('#E2C786')}
      .content {
        p{
          color:#fff !important;
        }
      }

    }

    &.completed {
      ${dynamicColorMixin('#F2F2EC')}
    }
  }

  li::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 28px solid transparent;
    border-left: 28px solid white;
    border-bottom: 28px solid transparent;
  }

  li::after {
    content: "";
    display: inline-block;
    background: transparent;
    width: 0;
    height: 0;
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
    right: -25px;
    position: absolute;
    z-index: 1;
    transform: scaleX(0.7);
  }

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0px 8px 0px 8px;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: 4px;
    p{
      margin: 0;
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      &:nth-child(2){
        font-weight: 400;
      }

    }
  }

  li:first-child {
    &::before {
    }
  } 

  li:last-child {
    margin-right: 0px;
  }

  .status-name {
    font-size: 12px;
    line-height: 15px;
    display: inline-block;
    word-break: break-word;
  }

  .mat-icon.status-done {
    height: 20px;
    width: 20px;
    font-size: 21px;
    color: white;
  }

  @media (max-width: 1366px) {
    .status-name {
      font-size: 11px;
    }

    li:first-child {
      max-width: 70px;
    }
  }

`;
export default Container;
