import styled from 'styled-components';

const MaxiInsertsWrapper = styled.div`
  .info-box{
    background-color:#00B2FF0D;
    border-radius:8px;
    padding:12px;
    display:flex;
    gap:16px;
    align-items:center;
    margin-bottom:24px;
    .info-caption{
      flex-direction:column;
      gap:2px;
    }
    span{
      color:#4E4E55;
      font-size:13px;
    }
  }
`;

export default MaxiInsertsWrapper;
