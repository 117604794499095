import React from 'react';
import { Popover } from 'antd';

import TooltipIcon from '../../assets/icons/tooltip-icon.svg';

import { StyledRadioButton, StyledRadioGroup } from './style';

interface Button {
  title: string;
  value: string;
  isPopover?: boolean;
}

interface SwitchButtonProps {
  buttons: Button[];
  onChange?: (value: string) => void
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ buttons, onChange }) => {
  const content = (
    <ul className="purchase-list">
      <li>Subsequently cancelable on a monthly basis</li>
      <li>You will receive credits every 30 days, which you can use for mailings.</li>
      <li>Includes postage costs for letters within Germany.</li>
    </ul>
  );

  return (
    <StyledRadioGroup defaultValue={buttons[0]?.value} buttonStyle="solid" onChange={(e) => onChange?.(e.target.value)}>
      {buttons.map((tab, i) => (
        <StyledRadioButton key={i} value={tab.value}>
          {tab.title}
          {tab.isPopover && (
            <Popover content={content} trigger="hover">
              <img src={TooltipIcon} alt="tooltip icon" />
            </Popover>
          )}
        </StyledRadioButton>
      ))}
    </StyledRadioGroup>
  );
};

export default SwitchButton;
