import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import EditIcon from '../../assets/icons/edit.svg';
import Trash from '../../assets/icons/trash-1.svg';
import Clone from '../../assets/icons/clone.svg';

import { SetTemplateState } from '../../redux/slices/template';
import { TemplateState } from '../../redux/types/template';
import { UserState } from '../../redux/types/user';

interface ActionButtonProps {
  templateId: string;
  setClone?: (value: boolean) => void;
  setTemplateAction?: (value: string) => void;
  setDeleteTemplate?: (value: boolean) => void;
  setDeleteTemplateId?: (value: string) => void;
  setEditTemplateId?: (value: string) => void;
  setCloneTemplateId?: (value: string) => void;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  templateId,
  setClone,
  setTemplateAction,
  setDeleteTemplate,
  setDeleteTemplateId,
  setEditTemplateId,
  setCloneTemplateId
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector((state: { user: UserState }) => state.user);
  const { templates } = useSelector((state: { template: TemplateState }) => state.template);

  return (
    <div className="d-flex gap-1 align-items-center">
      {user?.role !== 'employee' && renderActionButton(
        <img className="pointer" width="14px" src={Clone} alt={t('no_check_icon')} />,
        () => {
          setClone?.(true);
          if (setCloneTemplateId) {
            setCloneTemplateId(templateId);
          }
        }
      )}
      {!(templates?.find((template) => template._id === templateId)?.isUsed) && renderActionButton(
        <img className="pointer" width="14px" src={EditIcon} alt={t('no_check_icon')} />,
        () => {
          setTemplateAction?.('edit');
          if (setEditTemplateId && templateId) {
            setEditTemplateId(templateId);

            dispatch(SetTemplateState({
              field: 'isTemplateSaved',
              value: false
            }));
          }
        }
      )}
      {user?.role !== 'employee' && renderActionButton(
        <img className="pointer" width="14px" src={Trash} alt={t('no_check_icon')} />,
        () => {
          setDeleteTemplate?.(true);
          if (setDeleteTemplateId) {
            setDeleteTemplateId(templateId);
          }
        }
      )}
    </div>
  );
};

export default ActionButtons;
