import styled from 'styled-components';
import { Modal } from 'antd';

interface ModalWrapperProps {
  height?: string;
}

const ModalWrapper = styled(Modal)<ModalWrapperProps>`
.ant-modal-footer {
    margin-top:0px;
    .ant-btn-default {
      border-color: ${({ theme }) => theme.colors.primaryColor};
      color: ${({ theme }) => theme.colors.primaryColor};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 4px;
      &:not(.ant-btn-disabled) {
        &:hover {
          border-color: ${({ theme }) => theme.colors.primaryColor};
          color: ${({ theme }) => theme.colors.primaryColor};
        }
      }
    }
    .ant-btn-primary {
      background-color: ${({ theme }) => theme.colors.primaryColor};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 4px;
      &:not(.ant-btn-disabled) {
        &:hover {
          background-color: ${({ theme }) => theme.colors.primaryColor};
        }
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.placeholderColor};
        color: ${({ theme }) => theme.colors.whiteColor};
        &:hover {
          background-color: ${({ theme }) => theme.colors.placeholderColor};
        }
      }
    }
  }
  .ant-modal-footer >.ant-btn+.ant-btn {
    margin-inline-start: 16px;
  }
  .ant-modal-content{
    padding:16px;
  }
`;

export default ModalWrapper;
