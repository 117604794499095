import React, {
  useEffect,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Loader from '../../../components/loader';

import {
  ResendForgotPasswordEmail,
  SetAuthState
} from '../../../redux/slices/auth';
import { AuthState } from '../../../redux/types/auth';

import {
  DecryptCredentials,
  GetLocalStorageItem,
  HideEmailCharacters
} from '../../../utils/helpers';

import {
  EMAIL_ASTERISK,
  EMAIL_FORMAT_WITH_PREFIX
} from '../../../constants';

import EmailImage from '../../../assets/images/email-image.svg';

const CheckYourEmail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visibleEmail, setVisibleEmail] = useState('');

  const {
    emailResent,
    resendForgotPasswordEmailLoading,
    user
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const ResendEmail = () => {
    const email = user?.email || GetLocalStorageItem('resendForgotPasswordEmail');
    const expiredToken = GetLocalStorageItem('expiredToken');

    if (!isEmpty(email)) {
      dispatch(ResendForgotPasswordEmail({ email: email as string }));
    } else if (!isEmpty(expiredToken)) {
      dispatch(ResendForgotPasswordEmail({ expiredToken: expiredToken as string }));
    }
  };

  useEffect(() => {
    if (emailResent) {
      dispatch(SetAuthState({
        field: 'emailResent',
        value: false
      }));
    }
  }, [emailResent]);

  useEffect(() => {
    const decryptedEmail = DecryptCredentials(user?.email
    || GetLocalStorageItem('resendForgotPasswordEmail')
    || '');

    const hiddenEmail = HideEmailCharacters({
      email: decryptedEmail,
      regex: EMAIL_FORMAT_WITH_PREFIX,
      replacement: EMAIL_ASTERISK
    });

    setVisibleEmail(hiddenEmail);
  }, []);

  return (
    <>
      {resendForgotPasswordEmailLoading && <Loader />}
      <AuthFormWrapper
        heading={t('check_your_email')}
        subtext={`${t('we_will_send_an_email_to')}
          ${visibleEmail} ${t('if_its_associated_with_your_account')}`}
      >
        <div className="ba-text-align-center">
          <img className="auth-email-img" src={EmailImage} alt={t('email')} />
          <p>
            {t('if_you_did_not_receive_email')}
            <Link
              to="#"
              onClick={() => ResendEmail()}
            >
              {t('resend')}
            </Link>
          </p>
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default CheckYourEmail;
