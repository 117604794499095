import React, {
  ChangeEvent,
  useEffect
} from 'react';
import { Checkbox, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import QrCodeModal from '../../../components/generator';
import Input from '../../../components/input';
import AmazonIcon from '../../../assets/images/icon_review_Amazon.svg';
import WebsiteIcon from '../../../assets/images/icon_website.svg';

interface GenerateQRCodeProps {
  qrCodeText: string;
  setQRCodeText: (value: string) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  open?: boolean;
  heading?: string;
  isAmazonChecked?: boolean;
  setIsAmazonChecked: React.Dispatch<React.SetStateAction<boolean>>
  isIndividualChecked?: boolean;
  setIsIndividualChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const GenerateQRCode: React.FC<GenerateQRCodeProps> = ({
  qrCodeText,
  setQRCodeText,
  onConfirm,
  onCancel,
  heading,
  open,
  isAmazonChecked,
  setIsAmazonChecked,
  isIndividualChecked,
  setIsIndividualChecked
}) => {
  const { t } = useTranslation();

  const handleAmazonCheckboxChange = () => {
    if (!isIndividualChecked) {
      setQRCodeText('https://amazon.de/ryp');
    } else {
      setIsIndividualChecked(false);
    }
    setIsAmazonChecked(!isAmazonChecked);
  };

  const handleIndividualCheckboxChange = () => {
    if (!isAmazonChecked) {
      setQRCodeText('');
    } else {
      setIsAmazonChecked(false);
    }
    setIsIndividualChecked(!isIndividualChecked);
  };

  useEffect(() => {
    if (!isAmazonChecked) {
      setQRCodeText('');
    } else {
      setQRCodeText('https://amazon.de/ryp');
    }
  }, [isAmazonChecked]);

  return (
    <QrCodeModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      open={open}
      heading={heading}
      buttonDisable={!(isAmazonChecked || isIndividualChecked)}
    >
      <Row gutter={[24, 24]}>
        <Col xs={12} lg={12} className="d-flex flex-column">
          <div className="qr-modal-box">
            <img src={AmazonIcon} alt="no-svg" />
            <Checkbox checked={isAmazonChecked} onChange={handleAmazonCheckboxChange}>
              {t('amazon_review_page')}
            </Checkbox>
            <p>
              {t('amazon_review_page_details')}
            </p>
          </div>
        </Col>
        <Col xs={12} lg={12} className="d-flex flex-column">
          <div className="qr-modal-box">
            <img src={WebsiteIcon} alt="no-svg" />
            <Checkbox checked={isIndividualChecked} onChange={handleIndividualCheckboxChange}>
              {t('individual_website')}
            </Checkbox>
            <p>
              {t('individual_website_details')}
            </p>
            <Input
              width="100%"
              value={(isAmazonChecked && !isIndividualChecked) ? '' : qrCodeText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setQRCodeText(e.target.value);
              }}
              placeholder={t('enter_individual_link')}
              editable={isIndividualChecked}
            />
          </div>
        </Col>
      </Row>

    </QrCodeModal>
  );
};
export default GenerateQRCode;
