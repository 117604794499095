import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import { AddPaymentPlansPayload, GetPaymentPlansPayload, PaymentPlanState } from '../types/payment-plan';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: PaymentPlanState = {
  paymentPlans: null,
  updatedPlan: null,
  error: null,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: '',
  success: false
};

export const AddOrUpdatePaymentPlans = createAsyncThunk(
  'payment-plan/add-or-update-payment-plans',
  async (data: AddPaymentPlansPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment-plan/add-or-update-payment-plans', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetPaymentPlans = createAsyncThunk(
  'payment-plan/get-payment-plans',
  async (data: GetPaymentPlansPayload, { rejectWithValue }) => {
    try {
      const response = await axios.get('/payment-plan/get-payment-plans', {
        params: data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const paymentSlice = createSlice({
  name: 'paymentPlans',
  initialState,
  reducers: {
    SetPaymentPlanState(state, action: PayloadAction<{
      field: keyof PaymentPlanState;
      value: PaymentPlanState[keyof PaymentPlanState];
    }>) {
      const updateMaxiInsert = <T extends keyof PaymentPlanState>(field: T, value: PaymentPlanState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateMaxiInsert(field, value as PaymentPlanState[keyof PaymentPlanState]);
    },
    SetPaymentPlanNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddOrUpdatePaymentPlans.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(AddOrUpdatePaymentPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.updatedPlan = action.payload.data.newPaymentPlan;
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(AddOrUpdatePaymentPlans.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
    builder
      .addCase(GetPaymentPlans.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(GetPaymentPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.paymentPlans = action.payload.data.paymentPlans;
      })
      .addCase(GetPaymentPlans.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const {
  reducer,
  actions
} = paymentSlice;

export const {
  SetPaymentPlanState,
  SetPaymentPlanNotifyState
} = actions;

export default reducer;
