import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonWrapper from './style';

interface ButtonProps {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'text' | 'primary' | 'secondary' | 'link' | 'default' | 'dashed' | undefined;
  width?: string;
  rightIcon?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  largePadding?: boolean;
  borderRadius?: string | undefined;
  height?:string | undefined;
}

const Index: React.FC<ButtonProps> = ({
  text,
  onClick,
  type = 'text',
  width,
  rightIcon,
  disabled,
  icon,
  className,
  largePadding,
  borderRadius,
  height
}) => {
  const { t } = useTranslation();

  return (
    <ButtonWrapper
      onClick={onClick}
      type={type}
      width={width}
      disabled={disabled}
      icon={icon}
      height={height}
      className={className}
      largePadding={largePadding}
      borderRadius={borderRadius}
    >
      {text}
      {rightIcon && <img src={rightIcon} alt={t('icon')} className="btn-right-icon" />}
    </ButtonWrapper>
  );
};

export default Index;
