/* eslint-disable max-len */
import React, {
  useEffect,
  useState
} from 'react';
import {
  Space,
  Tooltip
} from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsPlusCircle } from 'react-icons/bs';

import Heading from './heading-box';
import Button from '../../../../components/button/index';
import MaxiBox from '../../../../assets/images/max-insert.svg';
import Loader from '../../../../components/loader';
import Select from '../../../../components/select';

import { DrawerWrapper } from '../drawer-style';

import {
  MaxiInsert,
  MaxiInsertState
} from '../../../../redux/types/maxi';
import { AddTemplate as AddTemplateInterface } from '../../../../redux/types/template';
import { UserState } from '../../../../redux/types/user';

interface ChooseMaxiInsertProps {
  selectHelperText?: string;
  setSelectHelperText?: React.Dispatch<React.SetStateAction<string | undefined>>;
  addTemplate: AddTemplateInterface;
  setAddTemplate: (value: AddTemplateInterface) => void;
}

const InsertMaxi: React.FC<ChooseMaxiInsertProps> = ({
  selectHelperText,
  setSelectHelperText,
  addTemplate,
  setAddTemplate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<boolean>(false);

  const {
    getAllMaxiInsertsLoading,
    maxiInserts
  } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);
  const [selectedMaxiInsert, setSelectedMaxiInsert] = useState<MaxiInsert | undefined>(undefined);
  const { user } = useSelector((state: { user: UserState }) => state.user);

  interface OptionData {
    name: string;
    dimensions: string;
    weight: string;
  }

  // ---- Commenting for client feedback ----
  // const envelopeFormatOptions = [{
  //   label: 'DIN A4',
  //   value: 'DIN A4 MAX'
  // }, {
  //   label: 'DIN A5',
  //   value: 'DIN A5 MAX'
  // }];

  type RenderOption = (option: { data: OptionData }) => JSX.Element;

  const renderOption: RenderOption = (option) => (
    <div className="custom-select">
      <div className="label">
        {option.data.name}
      </div>
      <Space>
        <span className="size">
          Size:
          <span className="add-between-space">
            {option.data.dimensions}
          </span>
        </span>
        <div className="divider" />
        <span className="size">
          Weight:
          <span className="add-between-space">{option.data.weight}</span>
        </span>
      </Space>
    </div>
  );

  const handleSelectChange = (value: string) => {
    const temp = maxiInserts.find((maxiInsert) => maxiInsert._id === value);
    setSelectedMaxiInsert(temp || undefined);
    setAddTemplate({
      ...addTemplate,
      maxiInsertId: value
    });
    setSelectHelperText?.('');
  };

  // ---- Commenting for client feedback ----
  // const handleEnvelopeSelectChange = (value: string) => {
  //   setAddTemplate({
  //     ...addTemplate,
  //     envelopeFormat: value
  //   });
  //   setSelectHelperText?.('');
  // };

  const viewInsertHandler = () => {
    const url = `/maxi?maxiInsertId=${selectedMaxiInsert?._id}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (redirect) {
      navigate('/maxi');
    }
  }, [redirect]);

  useEffect(() => {
    if (maxiInserts.length > 0 && addTemplate.maxiInsertId) {
      if (user?.role === 'employee') {
        setSelectedMaxiInsert(maxiInserts[0]);
      } else {
        const temp = maxiInserts.find((maxiInsert) => maxiInsert._id === addTemplate.maxiInsertId);
        setSelectedMaxiInsert(temp || undefined);
      }
    }
  }, [maxiInserts, addTemplate.maxiInsertId]);

  return (
    <>
      {getAllMaxiInsertsLoading && <Loader /> }
      <div className="add-scroll">
        <Heading
          title={t('select_the_insert_type')}
          width="128px"
          infoDetails={t('title_info_details')}
        />
        <div className="select-type">
          <DrawerWrapper>
            <div className="template-box">
              <Select
                width="342px"
                label={t('insert')}
                placeholder={t('select')}
                options={user?.role === 'employee' ? [] : maxiInserts.map((maxiInsert) => ({
                  label: maxiInsert.name,
                  value: maxiInsert._id,
                  name: maxiInsert.name,
                  dimensions: `${maxiInsert.dimensions.length} x ${maxiInsert.dimensions.width} x ${maxiInsert.dimensions.height}`,
                  weight: maxiInsert.weight
                }))}
                value={selectedMaxiInsert?.name || ''}
                onChange={handleSelectChange}
                helperText={selectHelperText}
                renderOption={renderOption}
                marginBottom="20px"
                enableCustomRender
                disabled={user?.role === 'employee'}
              />
              {user?.role === 'employee' && (
                <Button
                  width="100px"
                  text={t('view_insert')}
                  type="default"
                  onClick={() => {
                    viewInsertHandler();
                  }}
                />
              )}
            </div>
          </DrawerWrapper>
          {/* ---- Commenting for client feedback ---- */}
          {/* <Select
            width="342px"
            label={t('envelope_format')}
            placeholder={t('select')}
            value={addTemplate?.envelopeFormat || ''}
            options={envelopeFormatOptions}
            onChange={handleEnvelopeSelectChange}
          /> */}
        </div>
        {user?.role !== 'employee' && (
          <Button
            className="inserts-right-btn"
            width="120px"
            icon={<BsPlusCircle />}
            type="primary"
            text={t('create_insert')}
            onClick={() => {
              setRedirect(true);
            }}
          />
        )}
        <DrawerWrapper>
          <div className="internal-summary internal-maxi ">
            <div className="image-box maxi-image-box">
              <img src={MaxiBox} alt={t('no_summary')} />
            </div>
            <div className="information-box maxi-information-box">
              <div className="data-box">
                <span>Name</span>
                {selectedMaxiInsert && selectedMaxiInsert?.name?.length > 13 ? (
                  <Tooltip title={selectedMaxiInsert?.name}>
                    <span className="ellipsis-added">
                      {`${selectedMaxiInsert?.name.substring(0, 13)}...`}
                    </span>
                  </Tooltip>
                ) : (
                  <span>{selectedMaxiInsert?.name}</span>
                )}
              </div>
              <div className="data-box">
                <span>Dimensions</span>
                <span>
                  {(selectedMaxiInsert && `${selectedMaxiInsert?.dimensions.length} x ${selectedMaxiInsert?.dimensions.width} x ${selectedMaxiInsert?.dimensions.height}`) || ''}
                </span>
              </div>
              <div className="data-box">
                <span>Weight</span>
                <span>{selectedMaxiInsert?.weight || ''}</span>
              </div>
            </div>
          </div>
        </DrawerWrapper>
      </div>
    </>
  );
};

export default InsertMaxi;
