import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  AddTemplatePayload,
  AddTemplateSheetPayload,
  CloneTemplatePayload,
  GetAllTemplatesData,
  ReadFilePayload,
  SendWishNotListedEmailPayload,
  Template,
  TemplateIdPayload,
  TemplateState,
  UpdateTemplatePayload
} from '../types/template';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: TemplateState = {
  addTemplate: {
    name: '',
    advertisementFormat: 'DIN A5'
  },
  deleteTemplateLoading: false,
  cloneTemplateLoading: false,
  fileContent: '',
  getTemplateLoading: false,
  addTemplateHelperText: null,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  readFileLoading: false,
  success: false,
  templates: [],
  templatesWithVariables: [],
  totalTemplates: 0,
  template: null,
  templateSheets: [],
  isTemplateAdded: false,
  isTemplateSaved: false,
  isTemplateUpdated: false,
  isTemplateSheetAdded: false,
  isTemplateCloned: false,
  isTemplateDeleted: false,
  newTemplate: null,
  clonedTemplate: null,
  newTemplateSheet: null,
  sendWishNotListedEmailLoading: false,
  wishNotListedEmailSent: false,
  updatedTemplate: null,
  isFileInProcess: false,
  getAllTemplatesSuccess: false,
  templatesWithSheets: [],
  getTemplateForEmployeeLoading: false,
  getTemplateForEmployeeSuccess: false,
  isUsed: false
};

export const AddTemplate = createAsyncThunk(
  'template/add-template',
  async (data: AddTemplatePayload, { rejectWithValue }) => {
    try {
      const { addTemplate } = data;

      const response = await axios.post('/template/add-template', {
        addTemplate
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const AddTemplateSheet = createAsyncThunk(
  'template-sheet/add-template-sheet',
  async (data: AddTemplateSheetPayload, { rejectWithValue }) => {
    try {
      const { templateSheets } = data;

      const response = await axios.post('/template-sheet/add-template-sheet', {
        templateSheets
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const CloneTemplate = createAsyncThunk(
  'template/clone-template',
  async (data: CloneTemplatePayload, { rejectWithValue }) => {
    try {
      const {
        templateId,
        name
      } = data;

      const response = await axios.post('/template/clone-template', {
        templateId,
        name
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const DeleteTemplateById = createAsyncThunk(
  'template/delete-template',
  async (data: TemplateIdPayload, { rejectWithValue }) => {
    try {
      const {
        templateId
      } = data;
      const response = await axios.delete('/template/delete-template', {
        data: {
          templateId
        }

      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllTemplates = createAsyncThunk(
  'template/get-all-templates',
  async (data: GetAllTemplatesData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;
      const response = await axios.get('/template/get-all-templates', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllTemplatesByUserId = createAsyncThunk(
  'template/get-all-templates-by-user-id',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/template/get-all-templates-by-userId');

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllTemplatesWithSheets = createAsyncThunk(
  'template/get-all-templates-with-sheets',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get('/template/get-all-templates-with-sheets', {
        params: {
          editCampaignId: data.editCampaignId
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllTemplatesWithVariables = createAsyncThunk(
  'template/get-all-templates-with-variables',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/template/get-all-templates-with-variables', {});

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetTemplateForEmployee = createAsyncThunk(
  'template/get-template-for-employee',
  async (data: TemplateIdPayload, { rejectWithValue }) => {
    try {
      const {
        templateId
      } = data;
      const response = await axios.get('/template/get-template-by-id', {
        params: {
          templateId
        }

      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetTemplateById = createAsyncThunk(
  'template/get-template-by-id',
  async (data: TemplateIdPayload, { rejectWithValue }) => {
    try {
      const {
        templateId
      } = data;
      const response = await axios.get('/template/get-template-by-id', {
        params: {
          templateId
        }

      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ReadFile = createAsyncThunk(
  'template/read-file',
  async (data: ReadFilePayload, { rejectWithValue }) => {
    try {
      const {
        fileUrl
      } = data;
      const response = await axios.get('/template/read-file', {
        params: {
          fileUrl
        }
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const SendWishNotListedEmail = createAsyncThunk(
  'template/send-wish-not-listed-email',
  async (data: SendWishNotListedEmailPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/template/send-wish-not-listed-email', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const UpdateTemplateById = createAsyncThunk(
  'template/update-template',
  async (data: UpdateTemplatePayload, { rejectWithValue }) => {
    try {
      const {
        templateId,
        updateParams,
        templateSheets
      } = data;
      const response = await axios.patch('/template/update-template', {
        templateId,
        updateParams,
        templateSheets
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    SetTemplateState(state, action: PayloadAction<{
      field: keyof TemplateState;
      value: TemplateState[keyof TemplateState]
    }>) {
      const updateTemplate = <T extends keyof TemplateState>(field: T, value: TemplateState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateTemplate(field, value as TemplateState[keyof TemplateState]);
    },
    SetTemplateNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddTemplate.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(AddTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isTemplateAdded = true;
        state.isTemplateSaved = true;
        state.newTemplate = action.payload.data.newTemplate;
      })
      .addCase(AddTemplate.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(AddTemplateSheet.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(AddTemplateSheet.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
        state.isTemplateSheetAdded = true;
        state.newTemplateSheet = action.payload.data.newTemplateSheet;
      })
      .addCase(AddTemplateSheet.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(CloneTemplate.pending, (state) => {
        state.success = false;
        state.cloneTemplateLoading = true;
      })
      .addCase(CloneTemplate.fulfilled, (state, action) => {
        state.cloneTemplateLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
        state.isTemplateCloned = true;
        state.clonedTemplate = action.payload.data.cloneTemplate;
      })
      .addCase(CloneTemplate.rejected, (state, action) => {
        state.cloneTemplateLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(DeleteTemplateById.pending, (state) => {
        state.success = false;
        state.deleteTemplateLoading = true;
      })
      .addCase(DeleteTemplateById.fulfilled, (state, action) => {
        state.deleteTemplateLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isTemplateDeleted = true;
      })
      .addCase(DeleteTemplateById.rejected, (state, action) => {
        state.deleteTemplateLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllTemplates.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.getAllTemplatesSuccess = false;
        state.notify = false;
      })
      .addCase(GetAllTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllTemplatesSuccess = true;
        state.success = true;
        const payload = action.payload.data as { templates: Template[], totalTemplates: number } | undefined;
        if (payload) {
          state.templates = payload.templates;
          state.totalTemplates = payload.totalTemplates;
        }
        state.notify = false;
      })
      .addCase(GetAllTemplates.rejected, (state, action) => {
        state.loading = false;
        state.getAllTemplatesSuccess = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllTemplatesByUserId.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.getAllTemplatesSuccess = false;
        state.notify = false;
      })
      .addCase(GetAllTemplatesByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllTemplatesSuccess = true;
        state.success = true;
        const payload = action.payload.data as { templates: any[] } | undefined;
        if (payload) {
          state.templates = payload.templates;
        }
        state.notify = false;
      })
      .addCase(GetAllTemplatesByUserId.rejected, (state, action) => {
        state.loading = false;
        state.getAllTemplatesSuccess = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllTemplatesWithSheets.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(GetAllTemplatesWithSheets.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.templatesWithSheets = action.payload.data.templatesWithSheets;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(GetAllTemplatesWithSheets.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllTemplatesWithVariables.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(GetAllTemplatesWithVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.templatesWithVariables = action.payload.data.templates;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(GetAllTemplatesWithVariables.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetTemplateForEmployee.pending, (state) => {
        state.getTemplateForEmployeeLoading = true;
        state.getTemplateForEmployeeSuccess = false;
        state.isUsed = false;
      })
      .addCase(GetTemplateForEmployee.fulfilled, (state, action) => {
        state.getTemplateForEmployeeLoading = false;
        state.getTemplateForEmployeeSuccess = true;
        state.templates = [action.payload.data.template];
        state.templateSheets = action.payload.data.templateSheets;
        state.isUsed = action.payload.data.isUsed;
      })
      .addCase(GetTemplateForEmployee.rejected, (state) => {
        state.getTemplateLoading = false;
        state.success = false;
        state.isUsed = false;
      })
      .addCase(GetTemplateById.pending, (state) => {
        state.success = false;
        state.getTemplateLoading = true;
      })
      .addCase(GetTemplateById.fulfilled, (state, action) => {
        state.getTemplateLoading = false;
        state.success = true;
        state.template = action.payload.data.template;
        state.templateSheets = action.payload.data.templateSheets;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(GetTemplateById.rejected, (state, action) => {
        state.getTemplateLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ReadFile.pending, (state) => {
        state.success = false;
        state.readFileLoading = true;
        state.isFileInProcess = true;
      })
      .addCase(ReadFile.fulfilled, (state, action) => {
        state.readFileLoading = false;
        state.success = true;
        state.fileContent = action.payload.data.content;
        state.isFileInProcess = false;
      })
      .addCase(ReadFile.rejected, (state, action) => {
        state.readFileLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.isFileInProcess = false;
      })
      .addCase(SendWishNotListedEmail.pending, (state) => {
        state.success = false;
        state.sendWishNotListedEmailLoading = true;
        state.wishNotListedEmailSent = false;
      })
      .addCase(SendWishNotListedEmail.fulfilled, (state, action) => {
        state.sendWishNotListedEmailLoading = false;
        state.wishNotListedEmailSent = true;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(SendWishNotListedEmail.rejected, (state, action) => {
        state.sendWishNotListedEmailLoading = false;
        state.wishNotListedEmailSent = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateTemplateById.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(UpdateTemplateById.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isTemplateUpdated = true;
        state.updatedTemplate = action.payload.data.updatedTemplate;
        state.clonedTemplate = null;
      })
      .addCase(UpdateTemplateById.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = templateSlice;

export const { SetTemplateState, SetTemplateNotifyState } = actions;

export default templateSlice.reducer;
