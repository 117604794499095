/* eslint-disable max-len */
import React, {
  useState,
  useEffect
} from 'react';
import AWS from 'aws-sdk';
import { BsPlusCircle } from 'react-icons/bs';

import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Col,
  Row
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import moment from 'moment';
import {
  isEmpty,
  startCase
} from 'lodash';

import { ResizeCallbackData } from 'react-resizable';
import { useTranslation } from 'react-i18next';

import CheckModal from '../../../../components/check-modal';
import Select from '../../../../components/select';
import Button from '../../../../components/button/index';
import Input from '../../../../components/input';
import Upload from '../../../../components/upload-logo';
import Loader from '../../../../components/loader';
import UploadDragger from '../../../../components/Dragger';
import WarningModal from '../../../../components/warning-modal';
import SmallPagination from '../../../../components/small-pagination';
import AddSheetModal from '../../modals/add-sheet';
import GenerateQRCode from '../../modals/generate-qr-code';
import LiveViewCard from '../../../../components/live-view-card';
import TemplateSheetsCheckModal from '../../modals/check';

import AddSheet from '../../../../assets/images/add-sheet.svg';
import Trash from '../../../../assets/icons/trash-1.svg';
import Download from '../../../../assets/icons/download-icon.svg';
import fileIcon from '../../../../assets/icons/file-icon.svg';
import WarningIcon from '../../../../assets/icons/warning.svg';

import { DrawerWrapper } from '../drawer-style';

import {
  GetS3PreSignedUrl,
  SetOtherNotifyState,
  SetOtherState
} from '../../../../redux/slices/other';
import {
  ReadFile,
  SetTemplateState
} from '../../../../redux/slices/template';

import { OtherState } from '../../../../redux/types/other';
import { UserState } from '../../../../redux/types/user';

import {
  BUCKET_NAME_VALUES,
  BUCKET_NAMES,
  DOC_EXTENSION_LIST,
  LOGO_DEFAULT_SIZE,
  LOGO_EXTENSION_LIST,
  LETTER_STYLE_VALUES,
  LETTER_TYPE_VALUES,
  TEMPLATE_FORMATS,
  TEMPLATE_FONTS,
  PLAN_TYPES,
  TEMPLATE_SHEETS_INITIAL_STATE
} from '../../../../constants';

import {
  CheckFileExtension,
  ExtractUniqueVariablesFromText,
  GetS3ImageUrl,
  UpdateArrayByIndex,
  UploadDocumentOnS3
} from '../../../../utils/helpers';

import {
  AddTemplate,
  AddTemplateSheet,
  HelperText,
  TemplateState
} from '../../../../redux/types/template';
import '../../imageResize.css';

interface Option {
  value: string;
  label: string;
}

interface HandWrittenProps {
  addTemplate: AddTemplate;
  handleNext: () => void;
  templateSheets: AddTemplateSheet[];
  setTemplateSheets: (value: any) => void;
  templateSheetsHelperText: HelperText[];
  setTemplateSheetsHelperText: (value: any) => void;
  selectedSheet: number;
  setSelectedSheet: (value: number) => void;
  openTemplateSheetsLimitPopup: boolean;
  setOpenTemplateSheetsLimitPopup: (value: boolean) => void;
  setCharacterLimitExceeds: (value: boolean) => void;
}

const HandWritten: React.FC<HandWrittenProps> = ({
  addTemplate,
  handleNext,
  templateSheets,
  setTemplateSheets,
  templateSheetsHelperText,
  setTemplateSheetsHelperText,
  selectedSheet,
  setSelectedSheet,
  openTemplateSheetsLimitPopup,
  setOpenTemplateSheetsLimitPopup,
  setCharacterLimitExceeds
}) => {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    preSignedUrl,
    fileUploadKey
  } = useSelector((state: { other: OtherState }) => state.other);
  const {
    fileContent,
    isFileInProcess
  } = useSelector((state: { template: TemplateState }) => state.template);
  const { user } = useSelector((state: { user: UserState }) => state.user);

  const defaultVariableOptions: Option[] = [{
    label: 'First Name',
    value: 'firstName'
  }, {
    label: 'Last Name',
    value: 'lastName'
  }, {
    label: 'Mr / Ms',
    value: 'surname'
  }, {
    label: 'Product',
    value: 'product'
  }, {
    label: 'City',
    value: 'cityOfTheRecipient'
  }];

  const [generateQrCode, setGenerateQrCode] = useState(false);
  const [addSheetModal, setAddSheetModal] = useState(false);
  const [creditWarningModal, setCreditWarningModal] = useState<boolean>(false);
  const [warningModalTitle, setWarningModalTitle] = useState<string>('');
  const [warningModalDescription, setWarningModaDescription] = useState<string>('');
  const [logoUploading, setLogoUploading] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<any | undefined>(undefined);
  const [deleteFile, setDeleteFile] = useState(false);
  const [variablesWarning, setVariablesWarning] = useState(false);
  const [deleteSheet, setDeleteSheet] = useState(false);
  const [qrCodeText, setQRCodeText] = useState<string>('');
  const [isAmazonChecked, setIsAmazonChecked] = useState(false);
  const [isIndividualChecked, setIsIndividualChecked] = useState(false);
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(LOGO_DEFAULT_SIZE);
  const [cursorXPosition, setCursorXPosition] = useState<number>(0);
  const [cursorYPosition, setCursorYPosition] = useState<number>(0);
  const [splitCharacters, setSplitCharacters] = useState<number>(0);
  const [firstMsgPart, setFirstMsgPart] = useState<string>('');
  const [secondMsgPart, setSecondMsgPart] = useState<string>('');
  const [s3FileAccessible, setS3FileAccessible] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([{
    label: '➕ First Name',
    value: 'First Name'
  }, {
    label: '➕ Last Name',
    value: 'Last Name'
  }, {
    label: '➕ Mr / Ms',
    value: 'Mr / Ms'
  }, {
    label: '➕ Product',
    value: 'Product'
  }, {
    label: '➕ City',
    value: 'City'
  }]);

  const content = (
    <div className="max-width-269">
      <p>
        {t('variable_info')}
      </p>
    </div>
  );

  const processTextForOptions = ({
    text,
    setSelectOptions
  }: {
    text: string;
    setSelectOptions: (options: {
      value: string;
      label: string
    }[]) => void
  }) => {
    if (text?.trim() !== '') {
      const uniqueMatches = ExtractUniqueVariablesFromText(text);
      const newOptions = uniqueMatches?.map((match: string) => ({
        value: match,
        label: match
      }));
      const newOptionsToAdd = newOptions.filter((newOption) => !options.some((option) => option.value === newOption.value));
      if (newOptionsToAdd.length > 0) setSelectOptions([...options, ...newOptionsToAdd]);
    } else {
      setSelectOptions([...defaultVariableOptions]);
    }
  };

  const checkFileOnS3 = (fileName: string, bucketName: string, intervalId: any) => {
    const params = {
      Bucket: BUCKET_NAMES[bucketName],
      Key: fileName
    };

    try {
      s3.getObject(params, (err: any, data: any) => {
        if (err) {
          // do nothing
        } else {
          setS3FileAccessible(true);
          clearInterval(intervalId);
        }
      });
    } catch (err: any) {
      // do nothing
    }
  };

  const handleChangeAttachment = async (info: UploadChangeParam, uploadBucket: string) => {
    const { file } = info;
    if (!file?.status) {
      const { name } = file;
      const extension = name.split('.').pop();

      const notValidExtension = CheckFileExtension({
        extension: extension as string,
        extensionList: BUCKET_NAME_VALUES.logo === uploadBucket ? LOGO_EXTENSION_LIST : DOC_EXTENSION_LIST,
        attachmentType: BUCKET_NAME_VALUES.logo === uploadBucket ? 'images' : 'docs'
      });

      if (notValidExtension) {
        dispatch(SetOtherNotifyState({
          message: notValidExtension,
          type: 'error'
        }));
        return;
      }
      setAttachment(file);
      const fileName = name.split('.')[0];
      const formattedFileName = fileName.replace(/\s/g, '-');
      setLogoUploading(true);
      if (BUCKET_NAME_VALUES.template_sheets === uploadBucket) {
        dispatch(SetTemplateState({
          field: 'isFileInProcess',
          value: true
        }));
      }

      dispatch(GetS3PreSignedUrl({
        fileName: `${formattedFileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}.${extension}`,
        fileType: file.type || '',
        fileExtension: extension || '',
        uploadBucket,
        id: addTemplate?.name
      }));
    }
  };

  const handleUploadAttachmentOnS3 = async () => {
    if (attachment) {
      try {
        await UploadDocumentOnS3({
          preSignedUrl,
          fileToUpload: attachment,
          onProgress: () => { }
        });
      } catch (error) {
        dispatch(SetOtherNotifyState({
          message: t('file_uploading_failed_on_S3'),
          type: 'error'
        }));
      }
    }
  };

  const handleMessageChange = (e: any) => {
    setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
      const updatedHelperText = [...prevHelperText];
      updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], message: '' };
      return updatedHelperText;
    });
    const regex = /\(\((.*?)\)\)/g;
    const { value, name } = e?.target;

    if (value?.length > 0 && templateSheets.length === 0 && addTemplate.letterType !== 'Printed') {
      setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
    }
    const updatedSheets = UpdateArrayByIndex(templateSheets, selectedSheet, value, [name]);
    if (cursorXPosition && cursorYPosition) {
      setCursorXPosition(0);
      setCursorYPosition(0);
    }

    const matches = value?.match(regex) || [];
    if (addTemplate?.plan === 'Basic' && value && matches.length) {
      setVariablesWarning(true);
      return;
    }

    setTemplateSheets(updatedSheets);

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'message') errors[name] = `${startCase(name)} is required!`;
    else if (!isEmpty(value) && name === 'message') {
      errors[name] = '';
    }

    setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
      const updatedHelperText = [...prevHelperText];
      updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], ...errors };
      return updatedHelperText;
    });
    // processTextForOptions({ text: value, setSelectOptions: setOptions });
  };

  const handleSelectChange = (value: string, name: string) => {
    if (user?.purchasedBundle) {
      const { credits, creditsUsed } = user?.purchasedBundle;
      if (credits - creditsUsed <= 0) {
        setWarningModalTitle(t('template_warning_title'));
        setWarningModaDescription(t('template_warning_description'));
        setCreditWarningModal(true);
        return;
      }
    }
    const updatedSheets = UpdateArrayByIndex(templateSheets, selectedSheet, value, [name]);
    setTemplateSheets(updatedSheets);

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'font') errors[name] = `${startCase(name)} is required!`;
    else if (!isEmpty(value) && name === 'font') {
      errors[name] = '';
    }

    setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
      const updatedHelperText = [...prevHelperText];
      updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], ...errors };
      return updatedHelperText;
    });
  };

  const DeleteSheet = () => {
    const copiedSheets = templateSheets ? [...templateSheets] : [];
    copiedSheets.splice(selectedSheet, 1);
    setTemplateSheets(copiedSheets);
    setTemplateSheetsHelperText(UpdateArrayByIndex(templateSheetsHelperText, selectedSheet, '', ['message', 's3FileUrl']));
    setSelectedSheet(0);
  };

  const handleDelete = () => {
    DeleteSheet();
  };

  const onResize = (event: React.SyntheticEvent, data: ResizeCallbackData) => {
    setImageSize({ width: data.size.width, height: data.size.height });
    setTemplateSheets(UpdateArrayByIndex(
      templateSheets,
      selectedSheet,
      {
        width: data.size.width,
        height: data.size.height
      },
      ['logoSize']
    ));
  };

  const readFile = (url: string) => {
    const extension = url.split('.').pop();
    if (extension?.includes('doc') || extension?.includes('.docx')) {
      dispatch(ReadFile({
        fileUrl: url
      }));
    }
  };

  const handleVariableChange = (e: any) => {
    const regex = /\(\((.*?)\)\)/g;
    const matches = (templateSheets[selectedSheet]?.message)?.match(regex) || [];

    setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
      const updatedHelperText = [...prevHelperText];
      updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], message: '' };
      return updatedHelperText;
    });

    if (e === 'Product' && matches.includes('((Product))' as never)) {
      setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
        const updatedHelperText = [...prevHelperText];
        updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], message: 'Product Variable is already added' };
        return updatedHelperText;
      });

      return;
    }
    if (addTemplate?.plan === 'Basic') {
      setVariablesWarning(true);
      return;
    }

    const currentSheetData = {
      ...templateSheets[selectedSheet]
    };

    let updatedMessage = '';
    if (cursorXPosition && cursorYPosition) {
      updatedMessage = `${currentSheetData.message.slice(0, cursorXPosition)} ((${e})) ${currentSheetData.message.slice(cursorYPosition)}`;
      currentSheetData.message = updatedMessage;

      setCursorXPosition(0);
      setCursorYPosition(0);
    } else {
      updatedMessage = ` ((${e}))`;
      currentSheetData.message += updatedMessage;
    }

    templateSheets[selectedSheet] = currentSheetData;
    setTemplateSheets(templateSheets);

    const errors: Partial<Record<string, string>> = {};

    setTemplateSheetsHelperText((prevHelperText: HelperText[]) => {
      const updatedHelperText = [...prevHelperText];
      updatedHelperText[selectedSheet] = { ...updatedHelperText[selectedSheet], ...errors };
      return updatedHelperText;
    });
  };

  const handleCursorPosition = () => {
    const ctl = document.getElementById('message_area');
    if (ctl && (ctl instanceof HTMLTextAreaElement || ctl instanceof HTMLInputElement)) {
      const startPos = ctl.selectionStart;
      const endPos = ctl.selectionEnd;

      setCursorXPosition(startPos || 0);
      setCursorYPosition(endPos || 0);
    }
  };

  useEffect(() => {
    if (preSignedUrl !== '') {
      if (templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN
        && !isEmpty(templateSheets[selectedSheet]?.logoUrl)) {
        dispatch(SetOtherNotifyState({
          message: t('logo_replaced_message'),
          type: 'success'
        }));
      } else if (templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.PRINTED
        && !isEmpty(templateSheets[selectedSheet]?.fileKey)) {
        dispatch(SetOtherNotifyState({
          message: t('file_replaced_message'),
          type: 'success'
        }));
      }
      handleUploadAttachmentOnS3();
      dispatch(SetOtherState({
        field: 'preSignedUrl',
        value: ''
      }));
    } else if (preSignedUrl === '') {
      setLogoUploading(false);
    }
  }, [preSignedUrl]);

  useEffect(() => {
    if (s3FileAccessible) {
      const sheetType = templateSheets[selectedSheet]?.sheetType;
      if (sheetType === LETTER_TYPE_VALUES.HANDWRITTEN) {
        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUploadKey, ['logoUrl']);
          return [...updatedSheet];
        });
      } else {
        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUploadKey, ['fileKey']);
          return [...updatedSheet];
        });
      }
      const fileUrl = GetS3ImageUrl({
        bucketName: sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
        key: fileUploadKey
      });

      if (fileUrl) {
        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUrl, ['logoImage']);
          return [...updatedSheet];
        });

        if (sheetType === LETTER_TYPE_VALUES.PRINTED) {
          const extension = fileUrl.split('.').pop();
          if (extension?.toLowerCase() !== 'pdf') {
            readFile(fileUrl);
          } else {
            setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
              const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUrl, ['s3FileUrl']);
              return [...updatedSheet];
            });
            // setTemplateSheets(UpdateArrayByIndex(templateSheets, selectedSheet, fileUrl, ['s3FileUrl']));
            // setTemplateSheetsHelperText(UpdateArrayByIndex(templateSheetsHelperText, selectedSheet, '', ['s3FileUrl']));
            dispatch(SetTemplateState({
              field: 'isFileInProcess',
              value: false
            }));

            setLogoUploading(false);
          }
        }
        setLogoUploading(false);
      }

      dispatch(SetOtherState({
        field: 'fileUploadKey',
        value: ''
      }));

      setS3FileAccessible(false);
    }
  }, [s3FileAccessible]);

  useEffect(() => {
    if (!isEmpty(fileUploadKey)) {
      const sheetType = templateSheets[selectedSheet]?.sheetType;
      const intervalId = setInterval(() => {
        checkFileOnS3(fileUploadKey, sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets', intervalId);
      }, 3000);
    }
  }, [fileUploadKey]);

  useEffect(() => {
    if (!isEmpty(fileContent)) {
      if (!isEmpty(templateSheets[selectedSheet]?.fileKey) && !templateSheets[selectedSheet].fileKey?.includes('.docx')) {
        setTemplateSheets(UpdateArrayByIndex(templateSheets, selectedSheet, fileContent, ['message']));
        setTemplateSheetsHelperText(UpdateArrayByIndex(templateSheetsHelperText, selectedSheet, '', ['message']));
      } else if (!isEmpty(templateSheets[selectedSheet]?.fileKey)) {
        const fileUrl = GetS3ImageUrl({
          bucketName: templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
          key: templateSheets[selectedSheet]?.fileKey || ''
        });

        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUrl, ['s3FileUrl']);
          return [...updatedSheet];
        });
        const regex = /\(\((.*?)\)\)/g;
        const matches = fileContent.match(regex) || [];

        const values = matches.map((match: any) => match.slice(2, -2).trim());
        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, values, ['variables']);
          return [...updatedSheet];
        });

        dispatch(SetTemplateState({
          field: 'isFileInProcess',
          value: false
        }));

        setLogoUploading(false);
      }

      dispatch(SetTemplateState({
        field: 'fileContent',
        value: ''
      }));
    }
  }, [fileContent]);

  useEffect(() => {
    if (templateSheets[selectedSheet]?.logoUrl) {
      setImageSize({
        width: templateSheets[selectedSheet]?.logoSize?.width,
        height: templateSheets[selectedSheet]?.logoSize?.height
      });
      setQRCodeText(templateSheets[selectedSheet]?.qrCode || '');
      const sheetType = templateSheets[selectedSheet]?.sheetType;
      const fileUrl = GetS3ImageUrl({
        bucketName: sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
        key: templateSheets[selectedSheet]?.logoUrl || ''
      });
      if (fileUrl) {
        setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
          const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUrl, ['logoImage']);
          return [...updatedSheet];
        });
        if (sheetType === LETTER_TYPE_VALUES.PRINTED) {
          if (!templateSheets[selectedSheet].s3FileUrl) {
            readFile(fileUrl);
          }
        }
        setLogoUploading(false);
      }
    } else {
      const regex = /\(\((.*?)\)\)/g;
      const matches = templateSheets[selectedSheet]?.message?.match(regex) || [];
      if (addTemplate?.plan === 'Basic' && templateSheets[selectedSheet]?.message && matches.length) {
        setVariablesWarning(true);
        document.getElementById('delete-file')?.click();
      }
    }

    if (addTemplate.advertisementFormat === 'DIN A5' && templateSheets[selectedSheet]?.message?.length > 1100) {
      setCharacterLimitExceeds(true);

      dispatch(SetOtherNotifyState({
        message: 'Cannot exceed from 1100 characters',
        type: 'error'
      }));
    } else if ((addTemplate.advertisementFormat === 'DIN A4' || addTemplate.advertisementFormat === 'Maxi') && templateSheets[selectedSheet]?.message?.length > 2000) {
      setCharacterLimitExceeds(true);

      dispatch(SetOtherNotifyState({
        message: 'Cannot exceed from 2000 characters',
        type: 'error'
      }));
    } else {
      setCharacterLimitExceeds(false);
    }
  }, [templateSheets[selectedSheet]?.message]);

  useEffect(() => {
    const sheetType = templateSheets[selectedSheet]?.sheetType;
    setQRCodeText(templateSheets[selectedSheet]?.qrCode || '');
    const fileUrl = GetS3ImageUrl({
      bucketName: sheetType === LETTER_TYPE_VALUES.HANDWRITTEN ? 'logo' : 'template_sheets',
      key: templateSheets[selectedSheet]?.logoUrl || ''
    });
    if (fileUrl) {
      setTemplateSheets((prevSheets: [AddTemplateSheet]) => {
        const updatedSheet = UpdateArrayByIndex(prevSheets, selectedSheet, fileUrl, ['logoImage']);
        return [...updatedSheet];
      });
      if (sheetType === LETTER_TYPE_VALUES.PRINTED) {
        const extension = fileUrl.split('.').pop();
        if (extension?.toLowerCase() !== 'pdf' && !templateSheets[selectedSheet]?.s3FileUrl) {
          readFile(fileUrl);
        }
      }
      setLogoUploading(false);
    }
  }, [selectedSheet]);

  useEffect(() => {
    if (templateSheets.length) {
      console.log('\n templateSheets', templateSheets);
    }
  }, [templateSheets]);
  return (
    <div className="add-scroll">
      <DrawerWrapper>
        <div className="written-box">
          <Row gutter={16} className="m-0">
            <Col xs={12} className="pl-0" style={{ paddingTop: '10px' }}>
              <div className={(addTemplate?.letterStyle === LETTER_STYLE_VALUES.BRANDED
                && templateSheets?.length < 3)
                ? 'live-view-with-sheet'
                : 'live-view'}
              >
                {(isFileInProcess || logoUploading) && <Loader />}
                {((templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN
                  || (templateSheets.length === 0 && addTemplate.letterType !== 'Printed'))) ? (
                    <>
                      <div className="d-flex gap-15">
                        <Select
                          width="153px"
                          label={t('template')}
                          placeholder={t('select')}
                          options={TEMPLATE_FORMATS}
                          value={templateSheets[selectedSheet]?.templateName}
                          onChange={(value) => {
                            handleSelectChange(value, 'templateName');
                          }}
                          helperText={templateSheetsHelperText[selectedSheet]?.templateName}
                        />
                        <Select
                          width="153px"
                          label={t('font')}
                          placeholder={t('select')}
                          options={TEMPLATE_FONTS}
                          defaultValue={templateSheets[selectedSheet]?.font}
                          onChange={(value) => handleSelectChange(value, 'font')}
                        />
                        <Select
                          tooltip
                          content={content}
                          width="153px"
                          label={t('variable')}
                          placeholder={t('select')}
                          options={options}
                          value={templateSheets[selectedSheet]?.message?.match(/\(\(([^)]+)\)\)/g)?.pop()?.replace(/\(\(|\)\)/g, '')}
                          onChange={(e) => handleVariableChange(e)}
                        />
                      </div>
                      <div className="text-area-box">
                        <Input
                          name="message"
                          rows={24}
                          type="textarea"
                          className="add-employee-input"
                          placeholder={t('enter_message')}
                          label={t('message')}
                          onChange={(e: any) => {
                            handleMessageChange(e);
                          }}
                          id="message_area"
                          helperText={templateSheetsHelperText[selectedSheet]?.message}
                          value={templateSheets[selectedSheet]?.message}
                          onClick={handleCursorPosition}
                        />
                      </div>
                      <div className="upload-logo">
                        <div className="d-flex add-space-25">
                          <Upload
                            handleChangeAttachment={(info: UploadChangeParam) => handleChangeAttachment(
                              info,
                              BUCKET_NAME_VALUES.logo
                            )}
                            accept=".jpeg, .jpg, .png"
                          />
                          {/* Commenting for Client Feedback */}
                          {/* <Button
                            text={showLiveView ? t('close_preview') : t('get_preview')}
                            width="117px"
                            type="default"
                            onClick={() => setShowLiveView(!showLiveView)}
                          /> */}
                          <Button
                            onClick={() => setGenerateQrCode(true)}
                            text={t('generate_qr_code')}
                            width="117px"
                            type="default"
                            icon={<BsPlusCircle />}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="upload-file-container">
                      <p>{t('upload_sheet')}</p>
                      <UploadDragger
                        accept=".docx, .pdf"
                        handleChangeAttachment={
                          (info: UploadChangeParam) => handleChangeAttachment(
                            info,
                            BUCKET_NAME_VALUES.template_sheets
                          )
                        }
                        label={t('upload_sheet')}
                      />
                      {templateSheetsHelperText[selectedSheet]?.message && (
                        <div className="helper-text">{templateSheetsHelperText[selectedSheet]?.message}</div>
                      )}
                      {!isEmpty(templateSheets[selectedSheet]?.fileKey) ? (
                        <div className="file-data-wrapper">
                          <p>
                            {t('uploaded_sheet')}
                          </p>
                          <div className="file-bar">
                            <div className="title">
                              <img src={fileIcon} alt={t('fileIcon')} />
                              <p>{templateSheets[selectedSheet]?.fileKey}</p>
                            </div>
                            <div className="actions">
                              <button
                                type="button"
                                onClick={() => {
                                  if (templateSheets[selectedSheet]?.logoImage) {
                                    window.open(templateSheets[selectedSheet]?.logoImage);
                                  }
                                }}
                              >
                                <img
                                  src={Download}
                                  alt={t('download')}
                                />
                              </button>
                              <div
                                role="button"
                                tabIndex={0}
                                className="cursor-pointer"
                                id="delete-file"
                                onClick={handleDelete}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === ' ') {
                                    handleDelete();
                                  }
                                }}
                              >
                                <img src={Trash} alt={t('trash')} />
                              </div>
                            </div>
                          </div>
                          {/* Commenting for Client Feedback */}
                          {/* <Button
                            text={showLiveView ? t('close_preview') : t('get_preview')}
                            width="117px"
                            type="default"
                            onClick={() => setShowLiveView(!showLiveView)}
                          /> */}
                        </div>
                      )
                        : (
                          <div className="no-data-wrapper">
                            <p>Uploaded Sheet</p>
                            <div className="no-data-bar">
                              <p>
                                {t('no_uploaded_yet')}
                              </p>
                            </div>
                            {/* Commenting for Client Feedback */}
                            {/* <Button
                              text={showLiveView ? t('close_preview') : t('get_preview')}
                              width="117px"
                              type="default"
                              onClick={() => setShowLiveView(!showLiveView)}
                            /> */}
                          </div>
                        )}
                    </div>
                  )}
              </div>
              <GenerateQRCode
                open={generateQrCode}
                onCancel={() => {
                  setQRCodeText('');
                  // setTemplateSheets(UpdateArrayByIndex(templateSheets, selectedSheet, '', ['qrCode']));
                  setGenerateQrCode(false);
                  setIsAmazonChecked(false);
                  setIsIndividualChecked(false);
                }}
                onConfirm={() => {
                  setTemplateSheets(UpdateArrayByIndex(templateSheets, selectedSheet, qrCodeText, ['qrCode']));
                  setGenerateQrCode(false);
                  setIsAmazonChecked(false);
                  setIsIndividualChecked(false);
                }}
                qrCodeText={qrCodeText}
                setQRCodeText={setQRCodeText}
                isAmazonChecked={isAmazonChecked}
                setIsAmazonChecked={setIsAmazonChecked}
                isIndividualChecked={isIndividualChecked}
                setIsIndividualChecked={setIsIndividualChecked}
                heading={t('generate_qr_code')}
              />
              <WarningModal
                heading={deleteFile ? t('delete_sheet_confirmation') : variablesWarning ? t('variables_warning_message_header') : ''}
                desc={deleteFile ? t('you_wont_be_able_to_revert') : variablesWarning ? t('variables_warning_message') : ''}
                open={deleteFile || variablesWarning}
                modalFor={variablesWarning ? 'variablesWarning' : ''}
                onCancel={() => (deleteFile ? setDeleteFile(false) : setVariablesWarning(false))}
                onConfirm={() => (deleteFile ? setDeleteFile(false) : setVariablesWarning(false))}
              />
            </Col>
            <Col xs={12}>
              {/* <div className="preview-content">
                {(isFileInProcess || readFileLoading) && <Loader />}
                <div className="preview-box">
                  <div
                    role="button"
                    tabIndex={0}
                    className="trash-icon-wrapper"
                    onClick={DeleteSheet}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        DeleteSheet();
                      }
                    }}
                  >
                    {templateSheets?.length > 1
                      && (
                        <img
                          src={Trash}
                          alt={t('trash')}
                        />
                      )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <h4 className="m-0">
                      {t('live_view')}
                    </h4>
                  </div>
                  <div className="logo-box">
                    {templateSheets[selectedSheet]?.logoImage
                      && templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN
                      && (
                        <ResizableBox
                          className="resizeable-box"
                          width={imageSize.width}
                          height={imageSize.height}
                          onResize={onResize}
                          draggableOpts={{ grid: [25, 25] }}
                        >
                          <div>
                            <img
                              className="image-size"
                              src={templateSheets[selectedSheet]?.logoImage}
                              alt={t('logo')}
                            />
                          </div>
                        </ResizableBox>
                      )}
                  </div> */}
              {/* {templateSheets[selectedSheet]?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN */}
              {/* ? ( */}
              {/* <div className="written-content">
                        {templateSheets[selectedSheet]?.message}
                      </div> */}
              {/* ) */}
              {/* : ( */}
              {/* <div
                    className="written-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        templateSheets[selectedSheet]?.message
                    }}
                  /> */}
              {/* )} */}
              {/* <div className="qr-code">
                    {!isEmpty(templateSheets[selectedSheet]?.qrCode) ? (
                      <QRCode
                        size={70}
                        value={templateSheets[selectedSheet]?.qrCode || ''}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="add-sheet-button">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (addTemplate?.plan === PLAN_TYPES.BASIC) {
                        setWarningModalTitle(t('selection_does_not_match_credits'));
                        setWarningModaDescription(t('sheet_does_not_match_credits_basic_description'));
                        setCreditWarningModal(true);
                      } else {
                        setAddSheetModal(true);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleNext();
                      }
                    }}
                    className="d-flex sheet-box"
                  >
                    {templateSheets?.length < 3
                      && (
                        <>
                          <img src={AddSheet} alt={t('no_sheet')} />
                          <span>{t('add_sheet')}</span>
                        </>
                      )}
                  </div>
                  <SmallPagination
                    current={selectedSheet + 1}
                    total={templateSheets?.length || 0}
                    setSelectedSheet={setSelectedSheet}
                    selectedSheet={selectedSheet}
                    templateSheetsLength={templateSheets?.length - 1}
                  />
                </div>
              </div>
                </div> */}
              {/* {addTemplate?.letterStyle === LETTER_STYLE_VALUES.BRANDED
                  && (
                    <div className="add-sheet-button">
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setAddSheetModal(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleNext();
                          }
                        }}
                        className="d-flex sheet-box"
                      >
                        {templateSheets?.length < 3
                          && (
                            <>
                              <img src={AddSheet} alt={t('no_sheet')} />
                              <span>{t('add_sheet')}</span>
                            </>
                          )}
                      </div>
                      <SmallPagination
                        current={selectedSheet + 1}
                        total={templateSheets?.length || 0}
                        setSelectedSheet={setSelectedSheet}
                        selectedSheet={selectedSheet}
                        templateSheetsLength={templateSheets?.length - 1}
                      />
                    </div>
                  )} */}
              {/* </div> */}
              <WarningModal
                heading={t('delete_sheet_confirmation')}
                desc={t('you_wont_be_able_to_revert')}
                open={deleteSheet}
                onCancel={() => setDeleteSheet(false)}
                onConfirm={() => setDeleteSheet(false)}
              />
              <LiveViewCard
                message={templateSheets[selectedSheet]?.message?.replace(/\(\(([^)]+)\)\)/g, '<b style="font-weight: 900; font-size: 1em;">$1</b>')}
                qrCode={templateSheets[selectedSheet]?.qrCode}
                logo={templateSheets[selectedSheet]?.logoImage}
                sheetType={templateSheets[selectedSheet]?.sheetType}
                advertisementFormat={addTemplate.advertisementFormat}
                s3FileUrl={templateSheets[selectedSheet]?.s3FileUrl}
                setSplitCharacters={setSplitCharacters}
                splitCharacters={splitCharacters}
                onClick={DeleteSheet}
                setFirstMsgPart={setFirstMsgPart}
                firstMsgPart={firstMsgPart}
                setSecondMsgPart={setSecondMsgPart}
                secondMsgPart={secondMsgPart}
                qrCodeText={qrCodeText}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    DeleteSheet();
                  }
                }}
                totalSheets={templateSheets?.length || 1}
              >
                <div className="add-sheet-button">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (addTemplate?.plan === PLAN_TYPES.BASIC) {
                        setWarningModalTitle(t('selection_does_not_match_credits'));
                        setWarningModaDescription(t('sheet_does_not_match_credits_basic_description'));
                        setCreditWarningModal(true);
                      } else {
                        setAddSheetModal(true);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleNext();
                      }
                    }}
                    className="d-flex sheet-box"
                  >
                    {templateSheets?.length < 3
                  && (
                    <>
                      <img src={AddSheet} alt={t('no_sheet')} />
                      <span>{t('add_sheet')}</span>
                    </>
                  )}
                  </div>
                  <SmallPagination
                    current={selectedSheet + 1}
                    total={templateSheets?.length || 0}
                    setSelectedSheet={setSelectedSheet}
                    selectedSheet={selectedSheet}
                    templateSheetsLength={templateSheets?.length - 1}
                  />
                </div>
              </LiveViewCard>
            </Col>
          </Row>
        </div>
      </DrawerWrapper>
      <AddSheetModal
        title={t('add_sheet')}
        open={addSheetModal}
        onCancel={() => setAddSheetModal(false)}
        setSelectedSheet={setSelectedSheet}
        setTemplateSheets={setTemplateSheets}
        setAddSheetModal={setAddSheetModal}
      />
      <CheckModal
        heading={warningModalTitle}
        desc={warningModalDescription}
        open={creditWarningModal}
        onCancel={() => {
          setCreditWarningModal(false);
        }}
        icon={<img src={WarningIcon} alt={t('no_icon')} />}
        okayText="OK"
      />
      <TemplateSheetsCheckModal
        heading={t('template_sheets_limit_heading')}
        desc={t('template_sheets_limit_text')}
        open={openTemplateSheetsLimitPopup}
        onCancel={() => setOpenTemplateSheetsLimitPopup(false)}
        okayText="OK"
        noDesc
      />
    </div>
  );
};

export default HandWritten;
