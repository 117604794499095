import React, { ReactNode } from 'react';

interface AuthFormWrapperProps {
  heading: string;
  subtext?: string;
  className?: string;
  children?: ReactNode;
}

const AuthFormWrapper: React.FC<AuthFormWrapperProps> = ({
  heading,
  subtext,
  className,
  children
}) => (
  <div className="auth-inner-form">
    <form name="sign-in-form" className={`auth-form ${className || ''}`}>
      <div className="form-header">
        <h1>{heading}</h1>
        <p>{subtext}</p>
      </div>
      {children}
    </form>
  </div>
);

export default AuthFormWrapper;
