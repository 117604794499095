import styled from 'styled-components';
import media from '../../theme/media-queries';

const AuthWrapper = styled.div`
display: flex;
.auth-left-col{
  width: 51%;
  position: relative;
  overflow-x: hidden;
  .auth-header{
    height: 42px;
    padding: 24px 33px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: start;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.whiteColor} ;
    .header-logo{
      height: 38px;
      width: auto;
    }
  }
  .input-message-wrapper{
    height: 6px;
    position: relative;
  }
  .input-message{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    font-weight:  ${({ theme }) => theme.fontWeight.medium};
    position: absolute;
    top: -12px;
    &.password-match {
      color: #0FB600;
    }
    &.password-no-match {
      color: #D40001;
    }
  }
  .auth-inner-form{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    form{
      max-width: 417px;
      padding: 0 32px;
      width: 100%;
    }
    @media (max-height: 670px){
      form{
        padding-top: 20px;
        padding-bottom: 20px;
        height: calc(100vh - 140px);
        padding-top: 90px;
        &.sign-in-form{
          padding-top: 90px;
        }
      }
    }
    .form-header{
      margin-bottom: 29px;
    }
    .form-input{
      margin-bottom: 14px;
      position: relative;
    }
    .confirm-password{
      margin-top: 28px;
    }
    .form-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 0 1px;
      top: -2px;
      .link-text{
        color: ${({ theme }) => theme.colors.iconLinkColor};
        font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
        cursor: pointer;
        font-weight:  ${({ theme }) => theme.fontWeight.normal};
      }
    }
  }
  .auth-email-img{
    margin-bottom: 36px;
    margin-top: 10px;
    width: 194px;
    height: auto;
  }
  .auth-pass-change-img{
    margin-bottom: 16px;
    margin-top: 11px;
    width: 240px;
    height: auto;
  }
  .auth-account-verified-img{
    margin-bottom: 16px;
    margin-top: 10px;
    width: 194px;
    height: auto;
  }
}
.auth-right-col{
  width: 49%;
  height: 100vh;
  border-top-left-radius: 67px;
  overflow: hidden;
  border-bottom-left-radius: 67px;
  .right-cover{
    height: 100%;
    width: 100%;
    object-fit: fill;
    object-position: center;
  }
}
.custom-button{
  margin-top: 20px;
  margin-bottom: 12px;
  display: inline;
  &.forgot-pass-btn{
    margin-top: 12px;
  }
  &.reset-pass-btn{
    margin-bottom: 0;
  }
}
${media.lg`
    align-item: center;
    justify-content-center: center;
    min-height: 100vh;
    .auth-left-col{
      width: 100%;
    }
    .auth-right-col{
      display: none;
    }
  `}
`;

const StrengthWrapper = styled.div`
width: 210px;
.strength-title {
  font-weight:  ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.bodyColor};
  line-height: 18px;
  margin-bottom: 7px;
  display: block;
   font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
}
.ant-space{
  width: 100%;
}
.progress-wrapper{
  padding-top: 2px;
  position: relative;
  padding-bottom: 12px;
  .ant-progress{
    top: -8px;
    margin-bottom:3px;
  }
  .progress-message{
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    height: 24px;
    line-height: 12px;
    position: absolute;
    bottom: 0;
    display: block;
  }
}
.progress-header-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
  }
}
.password-check {
    display: flex;
    align-items: start;
    gap: 8px;
    span {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
      color: ${({ theme }) => theme.colors.placeholderColor};
      line-height: 12px;
    }
    &.check-pass {
      img {
        filter: invert(53%) sepia(89%) saturate(437%) hue-rotate(56deg) brightness(101%) contrast(93%);
      }
      span {
        color: ${({ theme }) => theme.colors.successColor};
      }
    }
  }
  .strength-bar {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 16px 0 8px;
    &.margin-remove{
      margin:0px;
      span{
        margin-bottom:8px;
      }
    }
    span {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
      color: ${({ theme }) => theme.colors.labelLightColor};
      line-height: 12px;
      margin-top: 3px;
    }
  }
  .password-strength {
    p {
      margin-top: -22px !important;
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs} !important;
    }
    div {
      width: 206px;
      margin-top: 0 !important;
      div {
        height: 4px !important;
        margin: 0;
        position: relative;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
        &:nth-child(2) {
          width: 0 !important;
        }
        &:nth-child(4) {
          width: 0 !important;
        }
        &:nth-child(6) {
          width: 0 !important;
        }
      }
    }
  }
  .strength-text {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    color: ${({ theme }) => theme.colors.labelColor};
    line-height: 12px;
    display: block;
  }
`;
export { AuthWrapper, StrengthWrapper };
