import { AxiosResponse } from 'axios';
import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  EditNickNamePayload,
  GetAllProductsData,
  GetProductsDataForAutoCampaign,
  Product,
  ProductState,
  UploadBulkNicknamesPayload
} from '../types/product';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: ProductState = {
  products: [],
  parentProducts: null,
  productsForAutoCampaign: [],
  selectedProductIds: [],
  loading: false,
  error: null,
  getAllProductsSuccess: false,
  isNickNameEdited: false,
  notify: false,
  notifyMessage: '',
  totalProducts: 0,
  editNickNameLoading: false,
  addBulkNicknamesFileLoading: false,
  isBulkNicknamesFileAdded: false,
  getProductsSuccess: false,
  notifyType: 'error',
  success: false
};

export const EditNickName = createAsyncThunk(
  'product/edit-nick-name',
  async (data: EditNickNamePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/product/edit-nickname', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetAllProducts = createAsyncThunk(
  'product/get-all-products',
  async (data: GetAllProductsData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;

      const response = await axios.get('/product/get-all-products', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetProductsForAutoCampaign = createAsyncThunk(
  'product/get-products-for-auto-campaign',
  async (data: GetProductsDataForAutoCampaign, { rejectWithValue }) => {
    try {
      const {
        sortBy,
        filters,
        editCampaignId
      } = data;

      const response = await axios.get('/product/get-products-for-auto-campaign', {
        params: {
          sortBy,
          filters: JSON.stringify(filters),
          editCampaignId
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetProductsList = createAsyncThunk(
  'product/get-products-list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/product/get-products-list');
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const UploadBulkNicknames = createAsyncThunk(
  'product/upload-bulk-nicknames',
  async (data: UploadBulkNicknamesPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/product/upload-bulk-nicknames', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    SetProductState(state, action: PayloadAction<{
      field: keyof ProductState;
      value: ProductState[keyof ProductState]
    }>) {
      const updateProduct = <T extends keyof ProductState>(field: T, value: ProductState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProduct(field, value as ProductState[keyof ProductState]);
    },
    SetProductNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(EditNickName.pending, (state) => {
        state.editNickNameLoading = true;
        state.error = null;
        state.isNickNameEdited = false;
        state.notify = false;
      })
      .addCase(EditNickName.fulfilled, (state, action) => {
        state.editNickNameLoading = false;
        state.error = null;
        state.isNickNameEdited = true;
        const payload = action.payload as { message?: string };
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.products = state.products.map((product) => {
          if (product._id === action.payload.data.updatedProduct._id) {
            return action.payload.data.updatedProduct;
          }
          return product;
        });
      })
      .addCase(EditNickName.rejected, (state, action) => {
        state.editNickNameLoading = false;
        state.isNickNameEdited = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllProductsSuccess = false;
        state.notify = false;
      })
      .addCase(GetAllProducts.fulfilled, (state, action: PayloadAction<AxiosResponse>) => {
        state.loading = false;
        state.error = null;
        state.getAllProductsSuccess = true;
        const payload = action.payload.data as { products: Product[], totalProducts: number };
        if (payload) {
          state.products = payload.products;
          state.totalProducts = payload.totalProducts;
        }
      })
      .addCase(GetAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.getAllProductsSuccess = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetProductsList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(GetProductsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.parentProducts = action.payload.data.parentProducts;
      })
      .addCase(GetProductsList.rejected, (state, action) => {
        state.loading = false;
        state.getAllProductsSuccess = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UploadBulkNicknames.pending, (state) => {
        state.addBulkNicknamesFileLoading = true;
        state.error = null;
        state.isBulkNicknamesFileAdded = false;
        state.notify = false;
      })
      .addCase(UploadBulkNicknames.fulfilled, (state, action) => {
        state.addBulkNicknamesFileLoading = false;
        state.error = null;
        state.isBulkNicknamesFileAdded = true;
        const payload = action.payload as { message?: string };
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(UploadBulkNicknames.rejected, (state, action) => {
        state.addBulkNicknamesFileLoading = false;
        state.isBulkNicknamesFileAdded = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetProductsForAutoCampaign.pending, (state) => {
        state.success = false;
        state.getProductsSuccess = false;
        state.loading = true;
      })
      .addCase(GetProductsForAutoCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.productsForAutoCampaign = action.payload.data;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.getProductsSuccess = true;
        state.notify = false;
      })
      .addCase(GetProductsForAutoCampaign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.getProductsSuccess = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = productSlice;

export const { SetProductState, SetProductNotifyState } = actions;

export default productSlice.reducer;
