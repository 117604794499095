import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  OtherState,
  S3PreSignedUrlData
} from '../types/other';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: OtherState = {
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  success: false,
  preSignedUrl: '',
  fileUploadKey: '',
  preSignedUrlLoading: false
};

export const GetS3PreSignedUrl = createAsyncThunk(
  'others/get-s3-pre-signed-url',
  async (data: S3PreSignedUrlData, { rejectWithValue }) => {
    try {
      const {
        fileType,
        fileExtension,
        fileName,
        uploadBucket,
        id = ''
      } = data;

      const response = await axios.get('/others/get-s3-pre-signed-url', {
        params: {
          id,
          fileType,
          extension: fileExtension,
          fileName,
          uploadBucket
        }
      });

      return {
        data: response.data
      };
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const otherSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    SetOtherState(state, action: PayloadAction<{
      field: keyof OtherState;
      value: OtherState[keyof OtherState]
    }>) {
      const updateOther = <T extends keyof OtherState>(field: T, value: OtherState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateOther(field, value as OtherState[keyof OtherState]);
    },
    SetOtherNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetS3PreSignedUrl.pending, (state) => {
        state.success = false;
        state.preSignedUrlLoading = true;
      })
      .addCase(GetS3PreSignedUrl.fulfilled, (state, action) => {
        state.preSignedUrlLoading = false;
        state.success = true;
        state.preSignedUrl = action.payload.data.data.preSignedUrl;
        state.fileUploadKey = action.payload.data.data.fileUploadKey;
      })
      .addCase(GetS3PreSignedUrl.rejected, (state, action) => {
        state.preSignedUrlLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = otherSlice;

export const { SetOtherState, SetOtherNotifyState } = actions;

export default otherSlice.reducer;
