import React, {
  ReactNode
} from 'react';
import { useTranslation } from 'react-i18next';

import DrawerWrapper from './drawer.style';

import CloseIcon from '../../assets/icons/close-icon.svg';

interface DrawerProps {
  width?: string;
  title?: any;
  children?: ReactNode,
  open?: boolean,
  changeHeight?: string,
  closable?: boolean,
  onClose?: ()=>void,
}

const Index: React.FC<DrawerProps> = ({
  width,
  children,
  open,
  onClose,
  title,
  closable,
  changeHeight
}) => {
  const { t } = useTranslation();

  return (
    <DrawerWrapper
      closeIcon={<img src={CloseIcon} alt={t('no_close')} />}
      width={width}
      title={title}
      onClose={onClose}
      open={open}
      closable={closable}
      className={`add-template-drawer ${changeHeight || ''}`}
    >
      {children}
    </DrawerWrapper>
  );
};

export default Index;
