import styled from 'styled-components';

interface WarningModalProps {
  authInput? : string;
}

const QrCodeModalWrapper = styled.div<WarningModalProps>`
  width:100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-size:20px;
    margin: 0 0 16px;
  }
  .warning-desc {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    line-height: 18px;
    display: block;
    text-align: center;
    max-width: 350px;
    margin-top:8px; 
  }
  .margin-top{
    margin-top:24px;
    width: 100%;
    justify-content: end;
    display: flex;
  }
  .ant-modal-footer{
    justify-content: center;
  }
  .generate-modal-input {
    margin-top: 16px;
  }
  .checkbox-and-checklist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-left: 8px;
    }
  }
  .qr-modal-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid ${({ theme }) => theme.colors.dividerColor};
    padding:16px 19px;
    border-radius:8px;
    flex:1;
    img{
      margin-bottom:8px;
    }
    .ant-checkbox-wrapper{
      margin-bottom:12px;
      span{
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
      color:${({ theme }) => theme.colors.secondaryColor};
      font-weight:600;
    } 
    }
    p{
      font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
      color:${({ theme }) => theme.colors.bodyLight};
      text-align:center;
      margin-bottom:16px;
    }
  }
`;
export default QrCodeModalWrapper;
