import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import PlanType from './component/basic-plan';
import BrandingPlan from './component/branding-plan';
import MaxiPlan from './component/maxi-plan';

import PaymentSettingWrapper from './style';

const Index = () => {
  const { t } = useTranslation();

  const items: TabsProps['items'] = [{
    key: '1',
    label: t('basic_plan'),
    children: <PlanType />
  }, {
    key: '2',
    label: t('branding_plan'),
    children: <BrandingPlan />
  }, {
    key: '3',
    label: t('maxi_plan'),
    children: <MaxiPlan />
  }];

  return (
    <PaymentSettingWrapper>
      <h2>{t('payment_plan_setting')}</h2>
      <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane />
    </PaymentSettingWrapper>
  );
};

export default Index;
