/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/modal';
import CrossIcon from '../../../assets/icons/cross.svg';
import Pause from '../../../assets/icons/pause.svg';
import Permanently from '../../../assets/icons/permanently.svg';

import { CAMPAIGN_STATUS } from '../../../constants';

interface SelectCampaignProps {
  onCancel?: () => void;
  onClick: (campaignId: string, status: string) => void;
  open?: boolean;
  pauseOrStopCampaignId: string;
  pauseOrStopCampaignStatus: string;
}

const SelectCampaign: React.FC<SelectCampaignProps> = ({
  onCancel,
  onClick,
  pauseOrStopCampaignId,
  pauseOrStopCampaignStatus,
  open
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancelClick={() => {
        if (onCancel) {
          onCancel();
        }
      }}
      width={656}
      open={open}
    >
      <div
        className="cross-icon"
        role="button"
        tabIndex={0}
        onClick={onCancel}
        onKeyDown={onCancel}
      >
        <img src={CrossIcon} alt={t('no_cross_icon')} />
      </div>
      <div className="campaign-box">
        <Row gutter={16}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={pauseOrStopCampaignStatus === CAMPAIGN_STATUS.PAUSED ? 'disabled-column' : ''}
          >
            <div className="select-campaign" onClick={() => onClick(pauseOrStopCampaignId, CAMPAIGN_STATUS.PAUSED)}>
              <img src={Pause} alt={t('no_icon')} />
              <h3>{t('pausing')}</h3>
              <span>{t('temporary_description')}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="select-campaign" onClick={() => onClick(pauseOrStopCampaignId, CAMPAIGN_STATUS.STOPPED)}>
              <img
                src={Permanently}
                alt={t('no_icon')}
              />
              <h3>{t('stopping')}</h3>
              <span>{t('permanently_description')}</span>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SelectCampaign;
