import styled from 'styled-components';

const LayoutWrapper = styled.div`
  .ant-layout{
    background: ${({ theme }) => theme.colors.backgroundColor};
    .ant-layout-sider {
      background-color: ${({ theme }) => theme.colors.whiteColor};
      border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
    }
    .sidebar-logo {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      .img-closed{
        width: 32px;
      }
      .img-open{
        width: 140px;
      }
    }
    .sidebar-menu-wrapper{
      border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
      padding-left: 16px;
      padding-right: 16px;
      label{
        display: block;
        padding-top: 32px;
        padding-left: 10px;
        line-height: ${({ theme }) => theme.fonts.baseFontSizeSm};
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .ant-menu{
      border-right: 0;
    }
    .active{
        background-color: #7B80431A !important;
        color: ${({ theme }) => theme.colors.iconLinkColor};
        .ant-menu-item-icon{
          filter: invert(52%) sepia(47%) saturate(318%) hue-rotate(25deg) brightness(103%) contrast(89%)
        }
    }
    .ant-menu-item{
      padding: 10px 8px !important;
      margin: 0;
      width: 100%;
      height: 36px;
      margin-top: 8px;
      border-radius: 4px;
      &:hover{
        background-color: #7B80431A !important;
        color: ${({ theme }) => theme.colors.iconLinkColor};
          .ant-menu-item-icon{
          filter: invert(52%) sepia(47%) saturate(318%) hue-rotate(25deg) brightness(103%) contrast(89%)
        }
      }
      &.ant-menu-item-active,
      &.ant-menu-item-selected{
        background-color: transparent;
      }
      .ant-menu-item-icon{
        display: flex;
        margin: auto;
      }
      .ant-menu-title-content{
        margin-inline-start: 8px;
        a{
          font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          color: ${({ theme }) => theme.colors.secondaryColor400};
        }
      }
    }
    .sidebar-label{
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.labelLightColor};
    }
    .ant-layout-header {
      height: 48px;
      display: flex;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.colors.whiteColor};
      padding-left: 24px;
      padding-right: 24px;
      .collapse-btn{
        height: 100%;
        line-height: 0;
        font-size: 16px;
        width: 24px;
      }
    }
    .header-right{
      font-size: ${({ theme }) => theme.fonts.baseFontSizeLabel};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.secondaryColor400};
      display: flex;
      align-items: center;
      .header-icon-label{
        display: flex;
        align-items: center;
      }
      .label-icon{
        height: 12px;
      }
    }
    .credits-counter{
      padding: 6px 11px;
      background-color: ${({ theme }) => theme.colors.tableHeaderColor400};
      display: flex;
      align-items: center;
      height: 24px;
      border-radius: 4px;
      margin-left: 4px;
      .credit{
        font-size: ${({ theme }) => theme.fonts.baseFontSizeLabel};
        line-height: ${({ theme }) => theme.fonts.baseFontSizeXs};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        color: ${({ theme }) => theme.colors.labelColor};
        padding: 0 8px;
        border-left: 1px solid ${({ theme }) => theme.colors.dividerColor};
        display: flex;
        align-items: center;
        gap: 4px;
        &::before{
          content: '';
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 30px;
        }
        &:first-child{
          padding-left: 0;
          border-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
      .success-credit::before{
        background-color: ${({ theme }) => theme.colors.successColor};
      }
      .planned-credit::before{
        background-color: ${({ theme }) => theme.colors.infoColor};
      }
      .active-credit::before{
        background-color: ${({ theme }) => theme.colors.errorColor};
      }
    }
    .header-buttons{
      display: flex;
      align-items: center;
      gap: 16px;
      padding-left: 12px;
    }
    .header-icon-btn{
      position: relative;
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.tableHeaderColor};
      border-radius: 90px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      // &.badge::after{
      //   content: '1';
      //   position: absolute;
      //   width: 6px;
      //   height: 6px;
      //   border-radius: 10px;
      //   top: 0;
      //   right: 0;
      //   display: block;
      //   background-color: red;
      // }
      &:active,
      &:hover{
        border-color: ${({ theme }) => theme.colors.tableHeaderColor} !important;
      }
      .header-icon-btn-img{
        width: 14px;
        height: 14px;
      }
    }
    .notification-badge{
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      border-radius: 60%;
      padding: 2px 6px;
      font-size: 12px;
      line-height: 1;
      transform: translate(50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-avatar-btn{
      border-radius: 90px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      .avatar{
        height: 24px;
        width: 24px;
      }
    }
    .header-divider{
      height: 16px;
      width: 1px;
      display: inline-block;
      background-color: ${({ theme }) => theme.colors.dividerColor};
    }
    .inner-content-wrapper{
      padding: 24px;
      padding-bottom: 0;
      overflow: auto;
      height: calc(100vh - 100px);
      .content-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        .heading{
          margin: 0;
          font-size: 20px;
        }
        .ant-btn{
          border-radius: 8px;
          font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
          font-weight: ${({ theme }) => theme.fontWeight.semiBold};
          gap: 4px;
          padding: 8px 12px;
          .ant-btn-icon{
            margin: 0;
          }
        }
      }
    }
    .ant-menu-submenu-vertical{
      .ant-menu-submenu-title{
        margin-left:8px!important
      }
    }
    .ant-menu-submenu-title{
      padding-left:8px!important;
      width:100%;
      margin:0px;
      .ant-menu-submenu-arrow{
             color: ${({ theme }) => theme.colors.iconLinkColor};
             right:5px;
      }
    }
    .ant-menu-item-group-title{
      padding:0px!important;
      padding-inline-start:8px!important;
    }

    .logout-menu{
      .ant-menu-item{
        &:last-child{
        position:absolute;
        bottom:16px;
        width:86%;
      }
    }
    }
        .collapse-menu{
          .ant-menu-sub{
            background:transparent;
          }
     .ant-menu-title-content{
        margin-inline-start: 8px;
          font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          color: ${({ theme }) => theme.colors.secondaryColor400};

      }
            }
  }
`;
export default LayoutWrapper;
