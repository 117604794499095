/* eslint-disable max-len */
import CryptoJS from 'crypto-js';
import {
  camelCase,
  isEmpty,
  startCase
} from 'lodash';

import {
  BUCKET_NAMES,
  REGEX_PATTERNS
} from '../constants';

import { SortBy } from '../redux/types/template';
import { SignupData } from '../redux/types/auth';

const AddToLocalStorage = (...args: [string, string][]) => {
  args.forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

const ApplyValidation = (regex: RegExp, value: string): boolean => regex.test(value);

const CheckFileExtension = ({
  extension,
  extensionList,
  attachmentType
}: {
  extension: string,
  extensionList: string[],
  attachmentType: string,
}) => {
  if (!extensionList.includes(extension?.toLowerCase())) {
    const message = `Only accept ${attachmentType} with the following extensions: ${extensionList.join(', ')}`;
    return message;
  }
  return null;
};

const CheckPasswordStrength = (password: string): boolean => (
  ApplyValidation(REGEX_PATTERNS.UPPERCASE_REGEX, password)
  && ApplyValidation(REGEX_PATTERNS.LOWERCASE_REGEX, password)
  && ApplyValidation(REGEX_PATTERNS.DIGIT_REGEX, password)
  && ApplyValidation(REGEX_PATTERNS.SPECIAL_CHAR_REGEX, password)
);

const ConvertToTitleCase = (str: string) => str?.replace(/\b\w/g, (char) => char
  ?.toUpperCase())?.replace(/([A-Z])/g, ' $1')?.trim();

const DecryptCredentials = (email: string): string => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_HASHING_SECRET_KEY as string);
  const fixedIV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_INITIALIZATION_VECTOR as string);
  const decryptedEmail = CryptoJS.AES.decrypt(email, key, {
    iv: fixedIV
  }).toString(CryptoJS.enc.Utf8);

  return decryptedEmail;
};

const FormatColumnName = (columnName: string) => startCase(columnName);

const DownloadSampleFile = ({
  columnsToDownload = [],
  mandatoryColumns,
  sampleFileName,
  type
}: {
  columnsToDownload?: string[];
  mandatoryColumns: string[];
  sampleFileName: string;
  type: string;
}) => {
  const concatenatedColumns = [...columnsToDownload, ...mandatoryColumns];
  const formattedColumns = concatenatedColumns.map((column) => FormatColumnName(column));

  let csvContent = 'data:text/csv;charset=utf-8,';

  if (type !== 'product') {
    csvContent += '"Tutorial Link","=HYPERLINK(""https://brief-adler.de/"",""https://brief-adler.de/"")"\n\n'; // Header for link column
  }

  csvContent += `${formattedColumns.join(',')}\n`;
  const sampleRow = columnsToDownload.map(() => 'Sample Data');
  csvContent += `${sampleRow.join(',')}\n`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${sampleFileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const EncryptCredentials = (credential: string): string => {
  const key = process.env.REACT_APP_HASHING_SECRET_KEY;
  const vector = process.env.REACT_APP_CRYPTO_INITIALIZATION_VECTOR;

  const encryptedCredential = CryptoJS.AES.encrypt(
    credential,
    CryptoJS.enc.Utf8.parse(key as string),
    {
      iv: CryptoJS.enc.Utf8.parse(vector as string)
    }
  ).toString();

  return encryptedCredential;
};

const ExtractHeadersFromFile = async (file: any): Promise<string[]> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    if (!event.target) {
      reject(new Error('Error reading file'));
      return;
    }
    const { result } = event.target;
    if (typeof result !== 'string') {
      reject(new Error('Invalid file content'));
      return;
    }

    const headers = result
      .split('\n')[result.toLowerCase().includes('tutorial') ? 2 : 0].split(','); // 2 because of Tutorial link otherwise 0.
    resolve(headers);
  };
  reader.onerror = () => {
    reject(new Error('Error reading file'));
  };
  reader.readAsText(file);
});

const ExtractUniqueVariablesFromText = (text: string): string[] => {
  const regex = REGEX_PATTERNS.EXTRACT_VARIABLES;
  const matches = text?.match(regex);
  const uniqueMatches: string[] = [];

  if (matches) {
    matches?.forEach((match) => {
      if (!uniqueMatches?.includes(match)) {
        uniqueMatches?.push(match);
      }
    });
  }

  return uniqueMatches?.map((match) => match?.replace('((', '')?.replace('))', ''));
};

const GetLocalStorageItem = (key: string): string | null => {
  const item = localStorage.getItem(key);
  return item;
};

const RemoveLocalStorageItems = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const HandleLogout = (user: SignupData | null) => {
  if (user !== null) {
    AddToLocalStorage(['user', JSON.stringify(user)]);

    const isSignedIn = JSON.parse(GetLocalStorageItem('user') as string)?.isSignedIn;

    if (!isSignedIn) {
      RemoveLocalStorageItems(['user', 'token']);
      window.location.href = '/sign-in';
    }
  }
};

const HideEmailCharacters = ({
  email,
  regex,
  replacement
}: {
  email: string,
  regex: RegExp,
  replacement: string
}): string => {
  const hiddenEmail = email.replace(regex, (_, regExp, hidden, domain) => `${regExp}${replacement}${domain}`);
  return hiddenEmail;
};

const GetS3ImageUrl = ({ bucketName, key }: { bucketName: string, key: string }) => {
  const nameOfBucket = BUCKET_NAMES[bucketName];
  let url;
  if (key) {
    url = `https://${nameOfBucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;
  }

  return url;
};

const HandleArrowClick = (
  arrow: 'up' | 'down',
  prevArrow: 'up' | 'down' | null,
  setSortValue: (value: SortBy | null) => void,
  sortField: string
) => {
  if (prevArrow === arrow) {
    setSortValue(null);
    return null;
  } if (prevArrow === null) {
    setSortValue({ [camelCase(sortField)]: 'asc' });
    return 'up';
  } if (prevArrow === 'up') {
    setSortValue({ [camelCase(sortField)]: 'desc' });
    return 'down';
  }
  setSortValue(null);
  return null;
};

const HandleCatchBlock = (err: any) => {
  if (err.response && err.response.data) {
    return {
      error: err.response.data.error,
      status: err.response.status
    };
  }
  return {
    error: 'Network Error'
  };
};

const IsLengthValidForWishNotListed = ({
  value,
  lowerLimit,
  upperLimit
}: {
  value: string,
  lowerLimit: number,
  upperLimit: number
}): boolean => {
  const characterCount = value.replace(/\r?\n/g, '').length;
  return characterCount >= lowerLimit && characterCount <= upperLimit;
};

const TestPasswordStrength = (value: string): number => {
  const lengthStrength: number = ApplyValidation(REGEX_PATTERNS.LENGTH_REGEX, value) ? 1 : 0;
  const uppercaseStrength: number = ApplyValidation(REGEX_PATTERNS.UPPERCASE_REGEX, value) ? 1 : 0;
  const lowercaseStrength: number = ApplyValidation(REGEX_PATTERNS.LOWERCASE_REGEX, value) ? 1 : 0;
  const digitStrength: number = ApplyValidation(REGEX_PATTERNS.DIGIT_REGEX, value) ? 1 : 0;
  const specialCharStrength: number = ApplyValidation(REGEX_PATTERNS.SPECIAL_CHAR_REGEX, value) ? 1 : 0;

  return lengthStrength + uppercaseStrength + lowercaseStrength + digitStrength + specialCharStrength;
};

const UploadDocumentOnS3 = async ({
  preSignedUrl,
  fileToUpload,
  onProgress
}: {
  preSignedUrl: string;
  fileToUpload: File;
  onProgress: (progress: number) => void;
}): Promise<void> => new Promise<void>((resolve, reject) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        onProgress(progress);
      }
    });
    xhr.upload.addEventListener('load', () => {
      resolve();
    });
    xhr.upload.addEventListener('error', () => {
      reject(new Error('Upload failed'));
    });
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', fileToUpload.type);
    xhr.send(fileToUpload);
  } catch (err) {
    reject(new Error('Upload failed'));
  }
});

const UpdateArrayByIndex = (
  sheets: Array<{ [key: string]: any }>,
  index: number,
  value: any,
  keys: string[]
): Array<{ [key: string]: string }> => {
  const updatedSheets = sheets?.map((sheet, i) => {
    if (i === index) {
      const updatedSheet = { ...sheet };
      keys.forEach((key) => {
        updatedSheet[key] = value;
      });
      return updatedSheet;
    }
    return sheet;
  });
  return updatedSheets;
};

const ValidateHeaders = (
  target: string[],
  pattern: string[] | undefined
): boolean => {
  if (!pattern || target?.length !== pattern?.length) return false;

  const normalizedTarget = target?.map((value) => value?.toLowerCase()?.trim());
  const normalizedPattern = pattern?.map((value) => value?.toLowerCase()?.trim());

  return normalizedPattern?.every((header, index) => header === normalizedTarget[index]);
};

export {
  AddToLocalStorage,
  ApplyValidation,
  CheckFileExtension,
  CheckPasswordStrength,
  ConvertToTitleCase,
  DecryptCredentials,
  DownloadSampleFile,
  EncryptCredentials,
  ExtractUniqueVariablesFromText,
  ExtractHeadersFromFile,
  FormatColumnName,
  HandleLogout,
  HideEmailCharacters,
  GetLocalStorageItem,
  GetS3ImageUrl,
  HandleArrowClick,
  HandleCatchBlock,
  IsLengthValidForWishNotListed,
  RemoveLocalStorageItems,
  TestPasswordStrength,
  UploadDocumentOnS3,
  UpdateArrayByIndex,
  ValidateHeaders
};
