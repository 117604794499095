/* eslint-disable max-len */
import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import StoreConnectivityLoader from '../../../components/store-connectivity-loader/loader';

import ConnectAmazon from '../modal/store-connected';

import {
  GetSPAPIRefreshToken,
  SetStoreState
} from '../../../redux/slices/store';

import { StoreState } from '../../../redux/types/store';

const WaitingScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    amazonStoreInfo,
    success,
    notifyMessage,
    notify
  } = useSelector((state: { store: StoreState }) => state.store);
  const params = new URLSearchParams(window.location.search);

  const [isStoreConnected, setIsStoreConnected] = useState(false);

  useEffect(() => {
    if (params.get('selling_partner_id')) {
      if (amazonStoreInfo?.state === params.get('state')) {
        dispatch(GetSPAPIRefreshToken({
          authCode: params.get('spapi_oauth_code'),
          sellerId: params.get('selling_partner_id'),
          region: 'eu'
        }));
      } else {
        dispatch(SetStoreState({
          field: 'notifyMessage',
          value: 'Error in Store Connection'
        }));
      }
    } else {
      dispatch(SetStoreState({
        field: 'notifyMessage',
        value: 'Error in Store Connection'
      }));
    }
  }, []);

  useEffect(() => {
    if (success && notifyMessage) {
      setIsStoreConnected(true);

      dispatch(SetStoreState({
        field: 'success',
        value: false
      }));
    }
  }, [success, notifyMessage]);

  useEffect(() => {
    if (notify && notifyMessage) {
      dispatch(SetStoreState({
        field: 'amazonStoreInfo',
        value: {}
      }));

      navigate(amazonStoreInfo.appPage);
    }
  }, [notify, notifyMessage]);

  return (
    <div>
      <StoreConnectivityLoader />
      <ConnectAmazon
        open={isStoreConnected}
        onConfirm={() => {
          dispatch(SetStoreState({
            field: 'amazonStoreInfo',
            value: {}
          }));
          dispatch(SetStoreState({
            field: 'notifyMessage',
            value: ''
          }));
          setIsStoreConnected(false);
          navigate(amazonStoreInfo.appPage);
        }}
        heading={notifyMessage}
      />
    </div>
  );
};

export default WaitingScreen;
