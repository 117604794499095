import React from 'react';
import { Pagination } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import TablePaginationWrapper from './pagination.style';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  pageSize: number;
  pageSizes: number[];
  onChange: (page: number, pageSize: number) => void;
}

const Index: React.FC<PaginationProps> = (props) => {
  const
    {
      totalPages,
      currentPage,
      pageSize,
      pageSizes,
      onChange
    } = props;

  const { t } = useTranslation();

  return (
    <TablePaginationWrapper>
      <Pagination
        totalItems={totalPages || 1}
        page={currentPage}
        pageSize={pageSize}
        pageSizes={pageSizes}
        itemsPerPageText={t('items_per_page')}
        onChange={(data) => onChange(data.page, data.pageSize)}
      />
    </TablePaginationWrapper>
  );
};

export default Index;
