import React, {
  ReactNode
} from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '../button/index';
import Modal from '../modal';
import CheckIcon from '../../assets/icons/check-icon.svg';

import WarningModalWrapper from '../warning-modal/warning.style';

import Loader from '../loader';

interface CheckModalProps {
  loading?: boolean;
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  desc?: string;
  heading?: string;
  icon?: ReactNode;
  okayText?: string;
  confirmText?: string;
}

const CheckModal: React.FC<CheckModalProps> = ({
  loading,
  open,
  onConfirm,
  onCancel,
  desc,
  heading,
  icon,
  okayText,
  confirmText
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} width="364px">
      <WarningModalWrapper>
        {icon || <img src={CheckIcon} alt={t('no_icon')} />}
        <h3>{heading || t('warning')}</h3>
        {desc && <span className="warning-desc">{desc}</span>}
        <div className="margin-top">
          {loading && <Loader />}
          <Space size="middle">
            <Button text={okayText || t('cancel')} type="default" onClick={onCancel} width="114px" />
            {onConfirm
              && (
                <Button
                  text={confirmText || t('confirm')}
                  type="primary"
                  width="119px"
                  onClick={onConfirm}
                />
              )}
          </Space>
        </div>
      </WarningModalWrapper>
    </Modal>
  );
};

export default CheckModal;
