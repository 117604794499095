import React, {
  useState,
  ChangeEvent
} from 'react';
import { Popover } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import Input from '../input';

import SearchIcon from '../../assets/icons/search.svg';
import UpIcon from '../../assets/icons/up.svg';
import DownIcon from '../../assets/icons/down.svg';
import TopArrow from '../../assets/icons/top-arrow.svg';
import DownArrow from '../../assets/icons/down-arrow.svg';

import {
  MaxiFilters,
  SortBy
} from '../../redux/types/maxi';

import { HandleArrowClick } from '../../utils/helpers';

interface MaxiProps {
  displayName: string;
  setSortValue: (sortValue: SortBy | null) => void;
  fieldName: string;
  showArrow: null | 'up' | 'down';
  filters: MaxiFilters;
  setFilters: (filters: MaxiFilters) => void;
  getAllMaxiInserts?: (applyFilters: any) => void;
  showSearch?: boolean;
}

const Maxi: React.FC<MaxiProps> = (props) => {
  const { t } = useTranslation();

  const {
    displayName,
    setSortValue,
    fieldName,
    showArrow,
    filters,
    setFilters,
    getAllMaxiInserts,
    showSearch = true
  } = props;

  const [search, setSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const handleSortArrowClick = (direction: 'up' | 'down') => {
    HandleArrowClick(direction, showArrow, setSortValue, fieldName);
  };

  const handleFilterRemove = () => {
    setFilterValue('');
    setFilters({
      ...filters,
      searchByKeyWords: {
        ...filters?.searchByKeyWords,
        [fieldName]: ''
      }
    });

    getAllMaxiInserts?.({
      applyFilters: {
        ...filters,
        searchByKeyWords: {
          ...filters?.searchByKeyWords,
          [fieldName]: ''
        }
      }
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const handleApplyFilters = () => {
    const updatedFilters = {
      ...filters,
      searchByKeyWords: {
        ...filters?.searchByKeyWords,
        [fieldName]: filterValue
      }
    };

    setFilters(updatedFilters);

    if (updatedFilters.searchByKeyWords && (updatedFilters.searchByKeyWords as any)[fieldName]) {
      const fieldValue = (updatedFilters.searchByKeyWords as any)[fieldName];
      const hasNonEmptyField = (typeof fieldValue === 'string' && fieldValue.trim() !== '')
        || (typeof fieldValue === 'number' && (fieldValue !== null || undefined));

      if (hasNonEmptyField) {
        getAllMaxiInserts?.({
          applyFilters: updatedFilters
        });
      }
    }

    setSearch(false);
  };

  return (
    <>
      <div className="custom-header-wrapper-tb align-items-center d-flex justify-content-between w-100">
        <span>{displayName}</span>
        <div className="custom-header-item-tb d-flex gap-1">
          <div className="d-flex flex-column">
            {showArrow === 'up' ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleSortArrowClick('down')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSortArrowClick('down');
                  }
                }}
              >
                <img src={TopArrow} className="pointer" alt={t('icon')} />
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleSortArrowClick('up')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSortArrowClick('up');
                  }
                }}
              >
                <img src={UpIcon} className="pointer" alt={t('icon')} />
              </div>
            )}
            {showArrow === 'down' ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleSortArrowClick('up')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSortArrowClick('up');
                  }
                }}
              >
                <img src={DownArrow} className="pointer" alt={t('icon')} />
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleSortArrowClick('down')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSortArrowClick('down');
                  }
                }}
              >
                <img src={DownIcon} className="pointer" alt={t('icon')} />
              </div>
            )}
          </div>
          {showSearch && (
            <div
              role="button"
              tabIndex={0}
              className="pt-7"
              onClick={() => setSearch(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSearch(true);
                }
              }}
            >
              {filters?.searchByKeyWords
                && filters?.searchByKeyWords[fieldName as keyof MaxiFilters['searchByKeyWords']] === ''
                ? (
                  <img src={SearchIcon} className="pointer" alt={t('no_search')} />
                ) : (
                  <FaRegTimesCircle
                    className="filter-remove-icon"
                    onClick={handleFilterRemove}
                  />
                )}
            </div>
          )}
        </div>
      </div>
      <Popover
        content={(
          <div>
            <Input
              marginBottom="12px"
              placeholder={t('enter')}
              value={filterValue}
              onChange={handleInputChange}
            />
            <Button
              text={t('apply')}
              width="100%"
              onClick={handleApplyFilters}
              type="primary"
            />
          </div>
        )}
        title={t('search_by')}
        placement="bottom"
        trigger="click"
        open={search}
        onOpenChange={() => setSearch(false)}
      />
    </>
  );
};

export default Maxi;
