/* eslint-disable max-len */
import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Row,
  Col,
  Popover,
  Space
} from 'antd';
import { useTranslation } from 'react-i18next';

import ChatInfo from '../../../assets/images/chat-info.svg';
import InfoIcon from '../../../assets/images/info.svg';

import Input from '../../../components/input/index';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';

import { StrengthWrapper } from '../../auth/style';
import MaxiInsertsWrapper from '../style';

import { MaxiInsertState } from '../../../redux/types/maxi';
import { SetOtherNotifyState } from '../../../redux/slices/other';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS,
  MAXI_INSERTS_ERROR_MESSAGES
} from '../../../constants';
import { SetMaxiInsertState } from '../../../redux/slices/maxi';

interface InsertMaxiModalProps {
  onCancel?: (
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>
  ) => void;
  onSubmit?: (
    name: string,
    dimensions: string,
    weight: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>
  ) => void;
  open?: boolean;
  title?: string;
  text?:string;
  saveText? : string;
  loading?: boolean;
  maxiInsertId?: string;
}

const content = (
  <div className="max-width-269">
    <p>
      To create campaigns with inserts, you must first set up the insert.
      Please provide us with the quantity of inserts you plan to send to us.
      We also need the dimensions and weight of the box containing the inserts.
      If you have multiple boxes, please set up multiple inserts.
    </p>
  </div>
);

const InsertMaxiModal: React.FC<InsertMaxiModalProps> = ({
  onCancel,
  onSubmit,
  open,
  title,
  saveText,
  maxiInsertId,
  loading,
  text
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isMaxiInsertAdded,
    maxiInserts
  } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);

  const [currentMaxiInsert, setCurrentMaxiInsert] = useState<{
    name: string;
    dimensions: string;
    weight: string;
  }>({
    name: '',
    dimensions: '',
    weight: ''
  });

  const [formData, setFormData] = useState<{
    name: string;
    dimensions: string;
    weight: string;
  }>({
    name: '',
    dimensions: '',
    weight: ''
  });

  const [formHelperText, setFormHelperText] = useState<{
    name: string;
    dimensions: string;
    weight: string;
  }>({
    name: '',
    dimensions: '',
    weight: ''
  });

  const validateDimensions = (value: string) => {
    const dimensionsArray = value.split('x').map((item) => item.trim());

    if (dimensionsArray.length !== 3) {
      return t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_DIMENSIONS);
    }

    const [length, width, height] = dimensionsArray;

    if (!REGEX_PATTERNS.MAXI_LENGTH_REGEX.test(length)) {
      return t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_LENGTH);
    }
    if (!REGEX_PATTERNS.MAXI_WIDTH_REGEX.test(width)) {
      return t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_WIDTH);
    }
    if (!REGEX_PATTERNS.MAXI_HEIGHT_REGEX.test(height)) {
      return t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_HEIGHT);
    }

    return '';
  };

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (fieldName === 'weight') {
      value = value.toString();
    }

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    }
    if (fieldName === 'dimensions') {
      errors[fieldName] = validateDimensions(value);
    } else if (fieldName === 'weight' && !REGEX_PATTERNS.MAXI_WEIGHT_REGEX.test(value)) {
      errors[fieldName] = t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_WEIGHT);
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName] || ''
    });
  };

  const handleSubmit = () => {
    const nameError = validateField('name', formData.name).name;
    const dimensionsError = validateField('dimensions', formData.dimensions).dimensions;
    const weightError = validateField('weight', formData.weight).weight;

    if (nameError || dimensionsError || weightError) {
      setFormHelperText({
        name: nameError || '',
        dimensions: dimensionsError || '',
        weight: weightError || ''
      });

      return;
    }

    const tempDimensions = formData.dimensions.split('x').map((item) => item.trim()).join('x');

    if (saveText === t('update')) {
      if (formData.name === currentMaxiInsert.name
        && tempDimensions === currentMaxiInsert.dimensions
        && (formData.weight).toString() === (currentMaxiInsert.weight).toString()) {
        dispatch(SetOtherNotifyState({
          open: true,
          message: t('no_changes_made'),
          type: 'info'
        }));

        return;
      }
    }

    onSubmit?.(
      formData.name,
      formData.dimensions,
      formData.weight,
      setFormData,
      setFormHelperText
    );
  };

  useEffect(() => {
    if (isMaxiInsertAdded) {
      dispatch(SetMaxiInsertState({
        field: 'maxiInserts',
        value: []
      }));
    }
  }, [isMaxiInsertAdded]);

  useEffect(() => {
    if (saveText === t('update') && maxiInsertId && maxiInserts) {
      const selectedMaxiInsert = maxiInserts?.find((maxiInsert) => maxiInsert._id === maxiInsertId);

      if (selectedMaxiInsert) {
        setCurrentMaxiInsert({
          name: selectedMaxiInsert.name,
          dimensions: `${selectedMaxiInsert.dimensions.length}x${selectedMaxiInsert.dimensions.width}x${selectedMaxiInsert.dimensions.height}`,
          weight: selectedMaxiInsert.weight
        });

        setFormData({
          name: selectedMaxiInsert.name,
          dimensions: `${selectedMaxiInsert.dimensions.length} x ${selectedMaxiInsert.dimensions.width} x ${selectedMaxiInsert.dimensions.height}`,
          weight: selectedMaxiInsert.weight
        });
      }
    }
  }, [maxiInsertId, maxiInserts, open]);

  const strengthData = [{
    length: t('length_1_to_35.3'),
    width: t('width_1_to_25'),
    height: t('height_1_to_5')
  }];

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => onCancel?.(setFormData, setFormHelperText)}
      onSubmitClick={handleSubmit}
      saveText={saveText}
      width={345}
      open={open}
      title={title}
    >
      {loading && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3 className="text-spacing mt-0">
            <span className="mr-4">{text}</span>
            {t('insert')}
            <Popover trigger="click" className="tooltip-icon" content={content}>
              <span className="tooltip"><img src={ChatInfo} width="16px" height="16px" alt={t('no_info')} /></span>
            </Popover>
          </h3>
          <MaxiInsertsWrapper>
            <div className="info-box">
              <div>
                <img src={InfoIcon} alt={t('no_info_icon')} />
              </div>
              <div className="d-flex info-caption">
                <span>{t('maxi_insert_modal_info_box')}</span>
              </div>
            </div>
          </MaxiInsertsWrapper>
          <Input
            placeholder={t('enter')}
            label={t('insert_name')}
            marginBottom="16px"
            value={formData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('name', e.target.value)}
            helperText={formHelperText.name}
          />
          <Popover
            overlayClassName="auth-password-popover"
            content={(
              <StrengthWrapper>
                <span className="strength-title">{t('your_dimensions_must_contain')}</span>
                <Space direction="vertical">
                  {strengthData.map((data, ind) => (
                    <div
                      key={ind}
                      className="strength-bar margin-remove"
                    >
                      <span>{data.length}</span>
                      <span>{data.width}</span>
                      <span>{data.height}</span>
                    </div>
                  ))}
                </Space>
              </StrengthWrapper>
          )}
            trigger="click"
            placement="bottomRight"
          >
            <div>
              <Input
                label={t('dimensions')}
                optionalLabel="(cm)"
                placeholder={t('enter_2x3x2')}
                marginBottom="16px"
                value={formData.dimensions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('dimensions', e.target.value)}
                helperText={formHelperText.dimensions}
              />
            </div>
          </Popover>

          <Input
            label={t('weight')}
            optionalLabel="(g)"
            placeholder={t('enter')}
            marginBottom="24px"
            value={formData.weight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('weight', e.target.value)}
            helperText={formHelperText.weight}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default InsertMaxiModal;
