/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isEmpty } from 'lodash';

import Loader from '../../components/loader';
import Modal from '../../components/warning-modal';
import SwitchButton from '../../components/switch-button';
import Select from '../../components/select';
import PriceCard from '../../components/price-card';

import CheckIcon from '../../assets/icons/payment-check-gold.svg';
import FaTimeIcon from '../../assets/icons/crose-icon-1.svg';
import InfoIcon from '../../assets/icons/chat-info-1.svg';
import PurchaseBundle from '../../assets/icons/purchase-bundle.svg';

import PaymentCart from './payment-cart';

import PaymentWrapper from './style';

import { GetPaymentPlans } from '../../redux/slices/payment-plan';
import {
  CreateSubscription,
  DirectPurchase,
  GetPurchasedPlan,
  SetPaymentNotifyState,
  SetPaymentState
} from '../../redux/slices/payment';

import { UserState } from '../../redux/types/user';
import { PaymentPlanState } from '../../redux/types/payment-plan';
import { PaymentState } from '../../redux/types/payment';

import { SubItem } from '../../utils/types/pages/payment-card';
import {
  ConnectSocket,
  DisconnectSocket,
  HandleNotificationCreation,
  HandleCurrentUser
} from '../../utils/socket';

interface DirectPurchaseDropDown {
  label: string;
  value: string
}

interface BundlePricesProps {
  pricePerLetter: number;
  totalPrice: number;
  productId: string;
}

const publishKey = process.env.REACT_APP_BRIEF_ADLER_STRIPE_PUBLISH_KEY || '';
const stripePromise = loadStripe(publishKey);
const reactAppSocketServer = process.env.REACT_APP_API_SOCKET_SERVER;

const Index = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user = {} } = useSelector((state: { user: UserState }) => state.user) || {};
  const { paymentPlans } = useSelector((state: { paymentPlan: PaymentPlanState }) => state.paymentPlan) || {};
  const {
    subscriptionCreated,
    directPurchase,
    loading
  } = useSelector((state: { payment: PaymentState }) => state.payment) || {};

  const [selectedValue, setSelectedValue] = useState<string>('monthly');
  const [selectedMonths, setSelectedMonths] = useState<string>('6 Month');
  const [paymentModal, setPaymentModal] = useState(false);
  const [directPurchaseDropDownData, setDirectPurchaseDropDownData] = useState<DirectPurchaseDropDown[]>([]);
  const [monthlyDropDownData, setMonthlyDropDownData] = useState<any[]>([]);
  const [bundleProductId, setBundleProductId] = useState<string>('');
  const [bundlePlanType, setBundlePlanType] = useState<string>('');
  const [isPurchasedPlan, setIsPurchasedPlan] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<boolean>(false);
  const [purchasedPlan, setPurchasedPlan] = useState<string>('');
  const [basicTypePrice, setBasicTypePrice] = useState<BundlePricesProps>({
    pricePerLetter: 0,
    totalPrice: 0,
    productId: ''
  });
  const [brandedTypePrice, setBrandedTypePrice] = useState<BundlePricesProps>({
    pricePerLetter: 0,
    totalPrice: 0,
    productId: ''
  });
  const [maxiTypePrice, setMaxiTypePrice] = useState<BundlePricesProps>({
    pricePerLetter: 0,
    totalPrice: 0,
    productId: ''
  });

  const months = [{
    title: '6 Month',
    value: '6 Month'
  }, {
    title: '12 Month',
    value: '12 Month'
  }];

  const ITEMS: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: FaTimeIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }
  ];

  const ITEMS1: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const ITEMS2: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const handleSwitchChange = (value: boolean) => {
    setIsPurchasedPlan(false);
    setPurchasedPlan('');
    setBasicTypePrice({
      ...basicTypePrice,
      productId: ''
    });

    setBrandedTypePrice({
      ...brandedTypePrice,
      productId: ''
    });

    setMaxiTypePrice({
      ...maxiTypePrice,
      productId: ''
    });
    setMonthlyDropDownData([]);
    if (value) {
      setSelectedValue('monthly');
    } else {
      setSelectedValue('purchase');
    }
  };

  const handleMonthChange = (value: string) => {
    setIsPurchasedPlan(false);
    setPurchasedPlan('');
    setBundleProductId('');
    setSelectedMonths(value);
  };

  const handlePayment = (planType: string) => {
    let pId = '';
    if (planType === 'Basic') {
      pId = basicTypePrice.productId;
    } else if (planType === 'Branding') {
      pId = brandedTypePrice.productId;
    } else if (planType === 'Maxi') {
      pId = maxiTypePrice.productId;
    }

    setBundlePlanType(planType);
    if (pId) {
      setBundleProductId(pId);
    }
  };

  const handlePurchaseBundle = () => {
    if (bundleProductId) {
      if (selectedValue === 'monthly') {
        dispatch(CreateSubscription({
          productId: bundleProductId,
          planType: bundlePlanType
        }));
      } else {
        dispatch(DirectPurchase({
          productId: bundleProductId,
          planType: bundlePlanType
        }));
      }

      setIsPurchasedPlan(true);
      setPurchasedPlan(bundlePlanType);
      setBundleProductId('');
    } else {
      dispatch(SetPaymentNotifyState({
        message: 'Please Select a bundle value',
        type: 'error'
      }));

      setPaymentModal?.(false);
    }
  };

  const setBundleValues = (bundlePlan: any) => {
    const { purchasedBundle } = user || {};
    const basicPlan = bundlePlan.find((obj: any) => obj.category === 'Basic');
    if (basicPlan) {
      const {
        pricePerLetter,
        totalPrice,
        productId
      } = basicPlan;

      if (purchasedBundle?.purchasedProductId === productId) {
        setIsPurchasedPlan(true);
        setPurchasedPlan('Basic');
      }

      setBasicTypePrice({
        pricePerLetter,
        totalPrice,
        productId
      });
    }

    const brandedPlan = bundlePlan.find((obj: any) => obj.category === 'Branding');
    if (brandedPlan) {
      const {
        pricePerLetter,
        totalPrice,
        productId
      } = brandedPlan;

      if (purchasedBundle?.purchasedProductId === productId) {
        setIsPurchasedPlan(true);
        setPurchasedPlan('Branding');
      }

      setBrandedTypePrice({
        pricePerLetter,
        totalPrice,
        productId
      });
    }

    const maxiPlan = bundlePlan.find((obj: any) => obj.category === 'Maxi');
    if (maxiPlan) {
      const {
        pricePerLetter,
        totalPrice,
        productId
      } = maxiPlan;

      if (purchasedBundle?.purchasedProductId === productId) {
        setIsPurchasedPlan(true);
        setPurchasedPlan('Maxi');
      }

      setMaxiTypePrice({
        pricePerLetter,
        totalPrice,
        productId
      });
    }
  };

  const handleBundleChange = (e: any) => {
    const productIds = e.split('@');
    setIsPurchasedPlan(false);
    if (selectedValue === 'monthly') {
      const monthlyPlan = paymentPlans?.filter(
        (obj: any) => obj.planType === selectedMonths && productIds.includes(obj.productId)
      );

      if (monthlyPlan?.length) {
        setBundleValues(monthlyPlan);
      }
    } else {
      const directPurchasePlan = paymentPlans?.filter(
        (obj: any) => obj.planType === 'Direct' && productIds.includes(obj.productId)
      );

      setBundleValues(directPurchasePlan);
    }
  };

  useEffect(() => {
    const socket = ConnectSocket({
      url: reactAppSocketServer || '',
      userId: user?._id || ''
    });

    HandleCurrentUser(socket, dispatch);
    HandleNotificationCreation(socket, dispatch);

    dispatch(GetPaymentPlans({}));
    dispatch(GetPurchasedPlan());

    return () => {
      DisconnectSocket(socket);
    };
  }, []);

  useEffect(() => {
    if (paymentPlans?.length) {
      if (selectedValue === 'monthly') {
        const monthlyData = paymentPlans.filter((obj: any) => obj.planType !== 'Direct' && obj.planType === selectedMonths);
        const monthDataDropDown: any = [];

        for (let i = 0; i < monthlyData.length; i += 1) {
          const {
            planType: title,
            totalLetter,
            name,
            productId
          } = monthlyData[i];

          const matchedPlanIndex = monthDataDropDown.findIndex((obj: any) => obj.title === title);
          const { children = [] } = monthDataDropDown[matchedPlanIndex] || {};

          if (children?.length) {
            // const childrenMatchedPlanIndex = children.findIndex((obj: any) => obj.title === `${totalLetter}/Month`);
            const childrenMatchedPlanIndex = children.findIndex((obj: any) => obj.title === `${name}/Month`);
            if (childrenMatchedPlanIndex !== -1) {
              children[childrenMatchedPlanIndex] = {
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: `${children[childrenMatchedPlanIndex].value}@${productId}`,
                key: `${productId}-${i}`
              };
            } else {
              children.push({
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: productId,
                key: `${productId}-${i}`
              });
            }

            monthDataDropDown[matchedPlanIndex] = {
              title: monthDataDropDown[matchedPlanIndex].title,
              value: `${i}-${matchedPlanIndex}`,
              key: `${i}-${matchedPlanIndex}`,
              children
            };
          } else {
            monthDataDropDown.push({
              title,
              value: `${i}-${matchedPlanIndex}`,
              key: `${i}-${matchedPlanIndex}`,
              children: [{
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: productId,
                key: `${productId}-${i}`
              }]
            });
          }
        }

        setMonthlyDropDownData(monthDataDropDown);
      } else {
        const directPurchasePlan = paymentPlans.filter((obj: any) => obj.planType === 'Direct');
        const directPlans: any = [];
        for (let i = 0; i < directPurchasePlan.length; i += 1) {
          const {
            name,
            productId
          } = directPurchasePlan[i];

          const directMatchedPlan = directPlans?.findIndex((obj: any) => obj.label === name);
          if (directMatchedPlan !== -1) {
            directPlans[directMatchedPlan] = {
              label: name,
              value: `${directPlans[directMatchedPlan].value}@${productId}`
            };
          } else {
            directPlans.push({
              label: name,
              value: productId
            });
          }
        }

        setDirectPurchaseDropDownData(directPlans || []);
      }
    }
  }, [paymentPlans]);

  useEffect(() => {
    if (paymentPlans?.length) {
      if (selectedValue === 'monthly') {
        const monthlyData = paymentPlans.filter((obj: any) => obj.planType !== 'Direct' && obj.planType === selectedMonths);
        const monthDataDropDown: any = [];

        for (let i = 0; i < monthlyData.length; i += 1) {
          const {
            planType: title,
            name,
            productId
          } = monthlyData[i];

          const matchedPlanIndex = monthDataDropDown.findIndex((obj: any) => obj.title === title);
          const { children = [] } = monthDataDropDown[matchedPlanIndex] || {};

          if (children?.length) {
            // const childrenMatchedPlanIndex = children.findIndex((obj: any) => obj.title === `${totalLetter}/Month`);
            const childrenMatchedPlanIndex = children.findIndex((obj: any) => obj.title === `${name}/Month`);
            if (childrenMatchedPlanIndex !== -1) {
              children[childrenMatchedPlanIndex] = {
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: `${children[childrenMatchedPlanIndex].value}@${productId}`,
                key: `${productId}-${i}`
              };
            } else {
              children.push({
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: productId,
                key: `${productId}-${i}`
              });
            }

            monthDataDropDown[matchedPlanIndex] = {
              title: monthDataDropDown[matchedPlanIndex].title,
              value: `${i}-${matchedPlanIndex}`,
              key: `${i}-${matchedPlanIndex}`,
              children
            };
          } else {
            monthDataDropDown.push({
              title,
              value: `${i}-${matchedPlanIndex}`,
              key: `${i}-${matchedPlanIndex}`,
              children: [{
                // title: `${totalLetter}/Month`,
                title: `${name}/Month`,
                value: productId,
                key: `${productId}-${i}`
              }]
            });
          }
        }

        setMonthlyDropDownData(monthDataDropDown);
      } else {
        const directPurchasePlan = paymentPlans.filter((obj: any) => obj.planType === 'Direct');
        const directPlans: any = [];
        for (let i = 0; i < directPurchasePlan.length; i += 1) {
          const {
            name,
            productId
          } = directPurchasePlan[i];

          const directMatchedPlan = directPlans?.findIndex((obj: any) => obj.label === name);
          if (directMatchedPlan !== -1) {
            directPlans[directMatchedPlan] = {
              label: name,
              value: `${directPlans[directMatchedPlan].value}@${productId}`
            };
          } else {
            directPlans.push({
              label: name,
              value: productId
            });
          }
        }

        setDirectPurchaseDropDownData(directPlans || []);
      }
    }
  }, [selectedValue, selectedMonths]);

  useEffect(() => {
    if (subscriptionCreated || directPurchase) {
      setPaymentModal?.(false);

      dispatch(SetPaymentState({
        field: 'subscriptionCreated',
        value: false
      }));

      dispatch(SetPaymentState({
        field: 'directPurchase',
        value: false
      }));

      setPurchasedPlan(bundlePlanType);
      setIsPurchasedPlan(true);
    }
  }, [directPurchase, subscriptionCreated]);

  return (
    <PaymentWrapper>
      {loading && <Loader />}
      {subscription && bundleProductId && user && isEmpty(user?.payment) ? (
        <Elements stripe={stripePromise}>
          <PaymentCart
            basicTypePrice={basicTypePrice}
            brandedTypePrice={brandedTypePrice}
            bundlePlanType={bundlePlanType}
            maxiTypePrice={maxiTypePrice}
            setSubscription={setSubscription}
            bundleProductId={bundleProductId}
            selectedValue={selectedValue}
            setBundleProductId={setBundleProductId}
            setIsPurchasedPlan={setIsPurchasedPlan}
            setPurchasedPlan={setPurchasedPlan}
          />
        </Elements>
      ) : (
        <>
          <h2>{t('plan_and_pricing')}</h2>
          <div className="header-wrapper">
            <div className="header-overlay">
              <div className="header-label">
                <h5>Instant</h5>
                <p>(Save 15%)</p>
              </div>
              <Switch className="custom-switch" defaultChecked onClick={handleSwitchChange} />
              <h6>Monthly</h6>
            </div>
            {selectedValue === 'monthly'
              ? (
                <div className="d-flex gap-3">
                  <SwitchButton buttons={months} onChange={handleMonthChange} />
                </div>
              ) : null}
            <div className="right-actions">
              <p>{selectedValue === 'monthly' ? t('monthly_letter_amount') : t('direct_purchase_letter_amount')}</p>
              <Select
                treeSelect={selectedValue === 'monthly'}
                labelVertical
                defaultValue="Select"
                dropDownData={selectedValue === 'monthly' ? monthlyDropDownData : directPurchaseDropDownData}
                onChange={handleBundleChange}
                placeholder="Please select an option"
                {...((!basicTypePrice?.productId && !brandedTypePrice?.productId
                  && !maxiTypePrice?.productId && selectedValue === 'monthly') ? { value: 'Choose' } : (
                    basicTypePrice?.productId || brandedTypePrice?.productId
                  || maxiTypePrice?.productId || selectedValue === 'monthly'
                  ) ? {
                      value: monthlyDropDownData?.find(
                        (obj) => obj.title === selectedMonths
                      )?.children.find((childObj: any) => childObj.value.includes(
                        basicTypePrice?.productId || brandedTypePrice?.productId || maxiTypePrice?.productId
                      ))?.title
                    } : {})}
              />
            </div>
          </div>
          <div className="card-ui-wrapper">
            <PriceCard
              items={ITEMS}
              planText="Basic"
              // planPrice="2.6€"
              planPrice={basicTypePrice?.pricePerLetter ? `${basicTypePrice?.pricePerLetter}€` : '780€'}
              cardHeading="Reach your customers as cost-effectively and personally as possible."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5"
              cardDisc2="Envelope: DIN C6 white"
              cardDisc3="Number of pages: 1"
              cardDisc4="Number of characters: unlimited"
              handlePayment={handlePayment}
              setSubscription={setSubscription}
              setPaymentModal={setPaymentModal}
              user={user}
              basicPriceId={basicTypePrice}
              brandedPriceId={brandedTypePrice}
              maxiPriceId={maxiTypePrice}
              isPurchasedPlan={isPurchasedPlan}
              purchasedPlan="Basic"
            />
            <PriceCard
              items={ITEMS1}
              isBest
              planText="Branding"
              planPrice={brandedTypePrice?.pricePerLetter ? `${brandedTypePrice?.pricePerLetter}€` : '300€'}
              cardHeading="Send letters that are even more personal and rely on premium-quality materials."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5 / A4"
              cardDisc2="Envelope: DIN C6 / Lang Premium"
              cardDisc3="Number of pages: 1-3"
              cardDisc4="Number of characters: unlimited"
              handlePayment={handlePayment}
              setSubscription={setSubscription}
              setPaymentModal={setPaymentModal}
              user={user}
              basicPriceId={basicTypePrice}
              brandedPriceId={brandedTypePrice}
              maxiPriceId={maxiTypePrice}
              isPurchasedPlan={isPurchasedPlan}
              purchasedPlan="Branding"
            />
            <PriceCard
              items={ITEMS2}
              planText="Plus"
              planPrice={maxiTypePrice?.pricePerLetter ? `${maxiTypePrice?.pricePerLetter}€` : '500€'}
              cardHeading="The Plus packaging allows the addition of inserts, such as brochures,
               catalogs, small gifts, samples or informational materials."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5 / A4"
              cardDisc2="Envelope: DIN C6 / Lang Premium"
              cardDisc3="Number of pages: 1-3"
              cardDisc4="Number of characters: unlimited"
              handlePayment={handlePayment}
              setSubscription={setSubscription}
              setPaymentModal={setPaymentModal}
              user={user}
              basicPriceId={basicTypePrice}
              brandedPriceId={brandedTypePrice}
              maxiPriceId={maxiTypePrice}
              isPurchasedPlan={isPurchasedPlan}
              purchasedPlan="Maxi"
            />
          </div>
          <Modal
            heading={t('purchase_bundle_heading')}
            icon={<img src={PurchaseBundle} alt="no-check" />}
            onCancel={() => {
              setPaymentModal?.(false);
            }}
            saveText={t('purchase_bundle')}
            onConfirm={handlePurchaseBundle}
            open={paymentModal}
            loading={loading}
          />
        </>
      )}
      {/* <div className="d-flex justify-content-between add-top-space">
        <Select
          treeSelect={selectedValue === 'monthly'}
          labelVertical
          width="337px"
          label={selectedValue === 'monthly' ? t('monthly_letter_amount') : t('direct_purchase_letter_amount')}
          dropDownData={selectedValue === 'monthly' ? monthlyDropDownData : directPurchaseDropDownData}
          onChange={handleBundleChange}
          marginBottom="0px"
        />
      </div>
      <div className="comparison-plan">
        <Row>
          <Col xs={2} sm={4} md={6} lg={8} xl={6} className="width-change">
            <div className="comparison-box">
              <h2>{t('comparison_plan')}</h2>
            </div>
            <PlanInfo />
          </Col>
          <Col xs={20} sm={16} md={12} lg={8} xl={6}>
            <div className="basic-plan">
              {user?.purchasedBundle?.planType === 'Basic' && isPurchasedPlan
                ? <img src={PurchaseTag} alt="purchase-tag" className="purchase-tag" />
                : null}
              <span className="badge">{t('basic')}</span>
              <div className="d-flex price">
                <sup>
                  {t('from')}
                </sup>
                <h1>{basicTypePrice?.pricePerLetter ? `${basicTypePrice?.pricePerLetter}€` : '2.6€'}</h1>
                <sub>{t('per_letter')}</sub>
              </div>
              <div className="d-flex price-plan">
                <span className="price-title">
                  {selectedMonths === '12 Month' ? t('monthly_price') : t('price')}
                </span>
                <span className="price-value">{basicTypePrice?.totalPrice ? `${basicTypePrice?.totalPrice}€` : '780€'}</span>
              </div>
              <Button width="100%" text="Choose plan" type="default" onClick={() => handlePayment('Basic')} />
            </div>
            <div className="plan-info selected" />
            <div className="plan-info text-center color-change">{t('din_a5')}</div>
            <div className="plan-info text-center color-change">{t('c5_white')}</div>
            <div className="plan-info text-center color-change">1</div>
            <div className="plan-info text-center unlimited">1</div>
            <div className="plan-info selected" />
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <div key={key} className="plan-info" />
            ))}
            <div className="plan-info selected" />
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <div key={key} className="plan-info text-center"><img src={PaymentCheck} alt="no-check" /></div>
            ))}
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={6} className="active-column">
            <div className="branding-plan">
              <div className="most-popular">
                {user?.purchasedBundle?.planType === 'Branding' && isPurchasedPlan
                  ? <img src={PurchaseTag} alt="purchase-tag" className="purchase-tag" />
                  : null}
                {t('most_popular')}
              </div>
              <div className="d-flex flex-column align-items-center add-padding">
                <span className="badge">{t('branding')}</span>
                <div className="d-flex price">
                  <sup>
                    {t('from')}
                  </sup>
                  <h1>{brandedTypePrice?.pricePerLetter ? `${brandedTypePrice.pricePerLetter}€` : '3.4€'}</h1>
                  <sub>{t('per_letter')}</sub>
                </div>
                <div className={`d-flex price-plan ${selectedValue === 'monthly' ? 'brand-plan' : ''}`}>
                  <span className="price-title ">
                    {selectedValue === 'monthly' ? t('monthly_price') : t('price')}
                  </span>
                  <span className="price-value">
                    {brandedTypePrice?.totalPrice ? `${brandedTypePrice?.totalPrice}€` : '300€'}
                  </span>
                </div>
                <Button width="100%" text="Choose plan" type="primary" onClick={() => handlePayment('Branding')} />
              </div>
              <div className="plan-info" />
              {['DIN A4', 'C4 White', '1-3', 'unlimited'].map((value, i) => (
                <div key={i} className="plan-info text-center color-change">{value}</div>
              ))}
              <div className="plan-info" />
              {[1, 2, 3, 4, 5, 6].map((key) => (
                <div key={key} className="plan-info text-center"><img src={PaymentCheck} alt="no-check" /></div>
              ))}
              <div className="plan-info" />
              {[1, 2, 3, 4, 5, 6].map((key) => (
                <div key={key} className="plan-info text-center"><img src={PaymentCheck} alt="no-check" /></div>
              ))}
            </div>
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={6}>
            <div className="basic-plan">
              {user?.purchasedBundle?.planType === 'Big' && isPurchasedPlan
                ? <img src={PurchaseTag} alt="purchase-tag" className="purchase-tag" />
                : null}
              <span className="badge">{t('big')}</span>
              <div className="d-flex price">
                <sup>
                  {t('from')}
                </sup>
                <h1>{maxiTypePrice?.pricePerLetter ? `${maxiTypePrice.pricePerLetter}€` : '5€'}</h1>
                <sub>{t('per_letter')}</sub>
              </div>
              <div className="d-flex price-plan">
                <span className="price-title">
                  {t('price')}
                </span>
                <span className="price-value">{maxiTypePrice?.totalPrice ? `${maxiTypePrice?.totalPrice}€` : '500€'}</span>
              </div>
              <Button width="100%" text="Choose plan" type="default" onClick={() => handlePayment('Maxi')} />
            </div>
            <div className="plan-info selected" />
            {['DIN A5, DIN A4', ' C5 premium-design din lang premium-design', '1-3', 'unlimited'].map((value, i) => (
              <div key={i} className="plan-info text-center color-change right-border-none">{value}</div>
            ))}
            <div className="plan-info selected" />
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <div
                key={key}
                className="plan-info text-center right-border-none"
              >
                <img src={PaymentCheck} alt="no-check" />
              </div>
            ))}
            <div className="plan-info selected" />
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <div
                key={key}
                className="plan-info text-center right-border-none"
              >
                <img src={PaymentCheck} alt="no-check" />
              </div>
            ))}
          </Col>
        </Row>
      </div>
      <div>
        {
          user && isEmpty(user?.payment) ? (
            <Elements stripe={stripePromise}>
              <PaymentModal
                open={paymentModal}
                setPaymentModal={setPaymentModal}
                bundleProductId={bundleProductId}
                selectedValue={selectedValue}
                setBundleProductId={setBundleProductId}
                basicTypePrice={basicTypePrice}
                brandedTypePrice={brandedTypePrice}
                maxiTypePrice={maxiTypePrice}
                setBasicTypePrice={setBasicTypePrice}
                setBrandedTypePrice={setBrandedTypePrice}
                setMaxiTypePrice={setMaxiTypePrice}
                bundlePlanType={bundlePlanType}
              />
            </Elements>
          ) : (
            <Modal
              heading={t('purchase_bundle_heading')}
              icon={<img src={PurchaseBundle} alt="no-check" />}
              onCancel={() => {
                setPaymentModal?.(false);
              }}
              saveText={t('purchase_bundle')}
              onConfirm={handlePurchaseBundle}
              open={paymentModal}
              loading={loading}
            />
          )
        }
      </div> */}
    </PaymentWrapper>
  );
};

export default Index;
