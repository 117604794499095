import styled from 'styled-components';

const ButtonGroupWrapper = styled.div`
  .ant-radio-group  {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    color: ${({ theme }) => theme.colors.bodyColor};
    line-height: 12px;
    padding: 3px 8px;
    .ant-radio-button-wrapper  {
      background-color:#F8F8FF;
      border:none;
      &::before{
        width:0;
      }
      &:first-child{
        .ant-radio-button-checked{
          background-color:#E2C786;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        }
      }
      &:nth-child(2){
        border-radius:8px;
        .ant-radio-button-checked{
          background-color:#E2C786;
        border-radius: 8px;
        &:hover{
          color: ${({ theme }) => theme.colors.whiteCOlor}; 
        }
        }
      }
      &:nth-child(3){
        .ant-radio-button-checked{
          background-color:#E2C786;
          border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    
        }
      }
    }
  }
`;

export default ButtonGroupWrapper;
