import styled from 'styled-components';

const PriceCardWrapper = styled.div`
  box-shadow: 0px 8px 30px 0px #0000000F;
  background-color: ${({ theme }) => theme.colors.whiteColor};
  border-radius: 14px;
  /* overflow: hidden; */
  min-width: 356px;
  transform: translateY(0px);
  transition: all 0.3s linear;
  &.overflow-wrapper {
    overflow: visible;
    min-height: 950px;
    height: 100%;
}
.purchase-tag{
  position: absolute;
  top: 28px !important;
  right: 360px !important;
}
  &:hover{
    transform: translateY(-10px);
    transition: all 0.3s linear;
  }
 .card-header{
  background-color: ${({ theme }) => theme.colors.whiteColor};
  padding: 24px;
  position: relative;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  img{
    position: absolute;
    right: -24px;
    top:-24px;
  }
  p.plan-text{
    background: #000;
    padding: 8px 24px;
    width: fit-content;
    color:#fff;
    border-radius: 100px;
    margin: auto;
  }
  .amount-text {
    display: flex;
    justify-content: center;
    grid-gap: 2px;
    margin: 24px 0px 16px;
    h5{
      font-size: 48px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      margin: 0px;
      color:#E3C786;
    }
    p{
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color:#959595;

      &.end-text{
        align-self: end;
        padding-left: 3px;
        &::before{
          content: '/';
          font-size: 32px;
          position: relative;
          top: 6px;
          font-weight: 100;
          left: -2px;
        }
      }
    }
}
h6{
font-size: 14px;
font-weight: 600;
line-height: 18px;
text-align: center;
color:#5F5F5F;
margin: 0;
}
h5{
  font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin: 32px 0px 12px;
    color: #5F5F5F;
}
p{
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: center;
margin: 0px;
margin-top: 8px;
color:#7E8A90;
}
 }
 .divider {
    width: 100%;
    height: 1px;
    background: #B9B9B9;
}
  .letter-card-sec{
    background-color: ${({ theme }) => theme.colors.whiteColor};
    .card-items-first {
    padding: 16px 24px;
    background-color: #FFF5DD !important;
    .card-items{
      border-bottom:1px solid #ECEFF1;
    }
  }
  .button-wrapper{
    padding: 8px 24px 16px;
    button{
      width: 100%;
    }
  }
  .content-area-card-item {
    padding: 16px 24px;
  }
    h6{
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color:#190F0F;
      margin: 0;
      margin-bottom: 12px;
    }
    .card-items {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #ECEFF1;
    img{
      cursor: pointer;
    }
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color:#18181B;
      margin: 0;
      display: flex;
      align-items: center;
      grid-gap: 4px;
      &:nth-child(2){
        font-weight: 400;
        color:#71717A;
      }
    }
  }
  }
`;
export default PriceCardWrapper;
