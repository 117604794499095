import styled from 'styled-components';

interface UploadLogoProps {
  authInput?: string;
}

const UploadLogoWrapper = styled.div<UploadLogoProps>`
 border:1px dashed #DFDEDE;
 height: 31px;
    .ant-upload-select{
        padding:0px 73px 0 62px;
        border-radius:4px;
    }
    .upload-box{
        display: flex;
        align-items: center;
        font-size:12px;
        color:#7E8A90
    }
    .cursor-pointer{
        cursor: pointer;
}
`;
export default UploadLogoWrapper;
