import styled from 'styled-components';

interface PaginationWrapperProps {
  authInput? : string;
}

const PaginationWrapper = styled.div<PaginationWrapperProps>` 
    display:flex;
    .small-pagination{
        display:flex; 
         color: ${({ theme }) => theme.colors.labelColor};
        font-size: ${({ theme }) => theme.fonts.baseFontSizeH5};
        align-items:center;
        .ant-select-selection-item{
          font-size: ${({ theme }) => theme.fonts.baseFontSizeH5};  
          color: ${({ theme }) => theme.colors.labelColor};
        }
        .ant-select {
            margin-left: 10px;
        }
        .total-count{
            gap:17px;
            margin-left: 5px;
        }
        .arrow-box{
            gap: 9px;
            button{
                background: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }


`;
export default PaginationWrapper;
