import React, { useState } from 'react';
import { Popover } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Select from '../select';
import Button from '../button';

import FilterICon from '../../assets/icons/filter.svg';
import UpIcon from '../../assets/icons/up.svg';
import DownIcon from '../../assets/icons/down.svg';
import TopArrow from '../../assets/icons/top-arrow.svg';
import DownArrow from '../../assets/icons/down-arrow.svg';

import { MaxiFilters } from '../../redux/types/maxi';
import { SortBy } from '../../redux/types/template';

import { HandleArrowClick } from '../../utils/helpers';

interface Option {
  value: string;
  label: string;
}

interface MaxiProps {
  displayName: string;
  setSortValue: (sortValue: SortBy | null) => void;
  fieldName: string;
  showArrow: null | 'up' | 'down';
  options?: Option[];
  filters: MaxiFilters;
  setFilters: (filters: MaxiFilters) => void;
  getAllMaxi?: (applyFilters: any) => void;
  showFilter?: boolean;
}

const Maxi: React.FC<MaxiProps> = (props) => {
  const { t } = useTranslation();

  const {
    displayName,
    setSortValue,
    fieldName,
    showArrow,
    options,
    filters,
    setFilters,
    getAllMaxi,
    showFilter = true
  } = props;
  const [filter, setFilter] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const handleApplyFilter = () => {
    setIsFilterApplied(true);

    if (filters?.searchByKeyWords && (filters?.searchByKeyWords as any)[fieldName]) {
      const fieldValue = (filters?.searchByKeyWords as any)[fieldName];
      const hasNonEmptyField = (typeof fieldValue === 'string' && fieldValue.trim() !== '')
        || (typeof fieldValue === 'number' && (fieldValue !== null || undefined));

      if (hasNonEmptyField) {
        getAllMaxi?.({
          applyFilters: filters
        });
      }

      setFilter(false);
    }
  };

  const handleClearFilter = () => {
    setIsFilterApplied(false);

    setFilters({
      ...filters,
      searchByKeyWords: {
        ...filters?.searchByKeyWords,
        [fieldName]: ''
      }
    });

    getAllMaxi?.({
      applyFilters: {
        ...filters,
        searchByKeyWords: {
          ...filters?.searchByKeyWords,
          [fieldName]: ''
        }
      }
    });
  };

  return (
    <>
      <div className="custom-header-wrapper-tb align-items-center d-flex justify-content-between w-100">
        <span>{displayName}</span>
        <div className="custom-header-item-tb d-flex gap-1 ">
          <div className="d-flex flex-column">
            {showArrow === 'up' ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => HandleArrowClick('down', showArrow, setSortValue, fieldName)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    HandleArrowClick('down', showArrow, setSortValue, fieldName);
                  }
                }}
              >
                <img src={TopArrow} className="pointer" alt={t('icon')} />
              </div>

            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => HandleArrowClick('up', showArrow, setSortValue, fieldName)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    HandleArrowClick('up', showArrow, setSortValue, fieldName);
                  }
                }}
              >
                <img src={UpIcon} className="pointer" alt={t('icon')} />
              </div>

            )}
            {showArrow === 'down' ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => HandleArrowClick('up', showArrow, setSortValue, fieldName)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    HandleArrowClick('up', showArrow, setSortValue, fieldName);
                  }
                }}
              >
                <img src={DownArrow} className="pointer" alt={t('icon')} />
              </div>

            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => HandleArrowClick('down', showArrow, setSortValue, fieldName)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    HandleArrowClick('down', showArrow, setSortValue, fieldName);
                  }
                }}
              >
                <img src={DownIcon} className="pointer" alt={t('icon')} />
              </div>

            )}
          </div>
          {showFilter ? (
            <div
              role="button"
              className="pt-7"
              tabIndex={0}
              onClick={() => setFilter(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setFilter(true);
                }
              }}
            >
              {filters?.searchByKeyWords
                && (filters?.searchByKeyWords[fieldName as keyof MaxiFilters['searchByKeyWords']] === ''
                || isFilterApplied === false)
                ? (
                  <img src={FilterICon} className="pointer" alt={t('no_search')} />
                ) : (
                  isFilterApplied
                  && (
                    <FaRegTimesCircle
                      className="filter-remove-icon"
                      onClick={handleClearFilter}
                    />
                  )
                )}
            </div>
          ) : null}
        </div>
      </div>
      <Popover
        content={(
          <div>
            <Select
              placeholder={t('select')}
              options={options}
              value={filters?.searchByKeyWords?.[fieldName as keyof typeof filters.searchByKeyWords]}
              onChange={(selectedValue: string | number | undefined) => {
                if (selectedValue !== undefined) {
                  setFilters({
                    ...filters,
                    searchByKeyWords: {
                      ...filters.searchByKeyWords,
                      [fieldName]: selectedValue
                    }
                  });
                }
              }}
            />
            <Button
              text={t('apply')}
              width="100%"
              onClick={handleApplyFilter}
              type="primary"
            />
          </div>
        )}
        title={t('filter_by')}
        placement="bottom"
        trigger="click"
        open={filter}
        onOpenChange={() => setFilter(false)}
      />
    </>
  );
};

export default Maxi;
