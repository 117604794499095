import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/table';
import ActionButtons from './action-buttons';
import Pagination from '../../components/pagination';
import JobsModal from './modals/jobs-delete';
import { JobsFilterComponent } from '../../components/filter-column-header';
import { JobsSearchComponent } from '../../components/search-column-header';

import CampaignsWrapper from '../manual-campaigns/style';

const FilesTable: React.FC = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(1);
  const [deleteJobs, setDeleteJobs] = useState<boolean>(false);
  const [requeueJobs, setRequeueJobs] = useState<boolean>(false);

  const rowData = [{
    store: 'Store Name 1',
    name: 'Title Name Here',
    status: 'Completed',
    progress: '100',
    run: '5 Jan 2024 at 11:41 PM',
    located: '5 Jan 2024 at 11:41 PM',
    actions: ''
  }];

  interface StatusCellRendererParams {
    value: string;
  }

  const actionCellRenderer = () => (
    <ActionButtons
      setRequeueJobs={setRequeueJobs}
      setDeleteJobs={setDeleteJobs}
    />
  );

  const getStatusCellRenderer = (params: StatusCellRendererParams) => {
    const { value } = params;

    let statusClass = '';

    switch (value) {
      case 'Completed':
        statusClass = 'status-completed';
        break;
      default:
        statusClass = '';
    }

    return <span className={`status-cell ${statusClass}`}>{value}</span>;
  };

  const columnDefs = [
    {
      headerName: 'Store name',
      field: 'store',
      headerComponent: JobsFilterComponent,
      pinned: 'left',
      flex: 1
    },
    {
      headerName: 'Name', field: 'name', headerComponent: JobsSearchComponent, minWidth: 170, flex: 1
    },
    {
      headerName: 'Status',
      field: 'status',
      headerComponent: JobsFilterComponent,
      minWidth: 80,
      cellRenderer: getStatusCellRenderer
    },
    {
      headerName: t('progress'), field: 'progress', headerComponent: JobsFilterComponent, minWidth: 130, flex: 1
    },
    {
      headerName: 'Next run at', field: 'run', headerComponent: JobsFilterComponent, minWidth: 170, flex: 1
    },
    {
      headerName: 'Located at', field: 'located', headerComponent: JobsFilterComponent, minWidth: 160, flex: 1
    },
    {
      headerName: 'Actions',
      field: 'actions',
      pinned: 'right',
      flex: 1,
      cellRenderer: actionCellRenderer
    }
  ];

  const onPageChange = (page: number, pageNumber: number) => {
    setCurrentPage(page);
    setPageSize(pageNumber);
  };

  return (
    <>
      <CampaignsWrapper>
        <div className="content-header">
          <h2 className="heading">Jobs</h2>
        </div>
        <Table
          rowData={rowData}
          columnDefs={columnDefs}
          sortable
          resizable
          editable
          filter
          height="164px"
          rowHeight={36}
          headerHeight={40}
          className="custom-table"
        />
        <Pagination
          totalPages={10}
          currentPage={currentPage}
          onChange={onPageChange}
          pageSize={pageSize}
          pageSizes={[1, 2, 5, 10]}
        />
      </CampaignsWrapper>
      <JobsModal
        heading={t('delete_job_confirmation')}
        open={deleteJobs}
        onCancel={() => setDeleteJobs(false)}
      />
      <JobsModal
        heading={t('requeue_job_confirmation')}
        open={requeueJobs}
        onCancel={() => setRequeueJobs(false)}
      />
    </>
  );
};

export default FilesTable;
