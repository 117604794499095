import React from 'react';
import { Progress } from 'antd';
import ProgressWrapper from './progress.style';

interface AppProps {
  percent: number;
}

const App: React.FC<AppProps> = ({ percent }) => (
  <ProgressWrapper>
    <Progress strokeColor="#50C878" percent={percent} />
  </ProgressWrapper>
);

export default App;
