import styled from 'styled-components';
import { Button } from 'antd';

interface ButtonWrapperProps {
  type?: 'text' | 'primary' | 'secondary' | 'link' | 'default' | 'dashed' | undefined;
  width?: string;
  largePadding?: boolean;
  borderRadius: string | undefined,
  height?:string | undefined,
}

const ButtonWrapper = styled(Button)<ButtonWrapperProps>`
  width: ${(props) => (props.width ? `${props.width}` : 'auto')};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  &.ant-btn {
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}` : '4px')};  
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
    justify-content:center;
    height: 40px;
    // width: fit-content;
    height: ${(props) => (props.height ? `${props.height}` : '32px')};  
    .ant-btn-icon:not(:last-child) {
      margin-inline-end: 4px;
    }
  }

  &.ant-btn-primary {
    background-color:${({ theme }) => theme.colors.iconLinkColor} ;

    &.inserts-right-btn {
      left: 222px
    }

    &:not(.ant-btn-disabled) {
      &:hover {
        background-color:${({ theme }) => theme.colors.iconLinkColor} ;
        filter: brightness(95%);
      }
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.placeholderColor};
      color: ${({ theme }) => theme.colors.whiteColor};

      &:hover {
        background-color: ${({ theme }) => theme.colors.placeholderColor};
      }
    }
  }

  &.ant-btn-default {
    border-color: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.primaryColor};
    &:not(.ant-btn-disabled) {
      &:hover {
        border-color: ${({ theme }) => theme.colors.iconLinkColor};
        color: ${({ theme }) => theme.colors.iconLinkColor};
      }
    }
  }

  .btn-right-icon {
    margin-left: 8px;
  }

  &.ant-btn-text {
    color: ${({ theme }) => theme.colors.primaryColor};

    &:not(.ant-btn-disabled) {
      &:hover {
        color: ${({ theme }) => theme.colors.primaryColor};
        background-color: transparent;
      }
    }
  }
`;

export default ButtonWrapper;
