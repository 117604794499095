import {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Typography } from 'antd';
import {
  toast,
  ToastContainer
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

import { SetAuthState } from '../../redux/slices/auth';
import { SetCampaignState } from '../../redux/slices/campaign';
import { SetEmployeeState } from '../../redux/slices/employee';
import { SetFileState } from '../../redux/slices/file';
import { SetErrorState } from '../../redux/slices/error';
import { SetMaxiInsertState } from '../../redux/slices/maxi';
import { SetProductState } from '../../redux/slices/product';
import { SetPaymentPlanState } from '../../redux/slices/payment-plan';
import { SetProfileState } from '../../redux/slices/profile';
import { SetTemplateState } from '../../redux/slices/template';
import { SetOtherState } from '../../redux/slices/other';
import { SetPaymentState } from '../../redux/slices/payment';
import { SetStoreState } from '../../redux/slices/store';
import { SetStripeState } from '../../redux/slices/stripe';
import { SetUserState } from '../../redux/slices/user';

import { CampaignNotify } from '../../redux/types/campaign';
import { EmployeeNotify } from '../../redux/types/employee';
import { FileNotify } from '../../redux/types/files';
import {
  AuthNotify,
  TemplateNotify
} from '../../redux/types/template';
import { MaxiInsertNotify } from '../../redux/types/maxi';
import {
  OtherNotify
} from '../../redux/types/other';
import { PaymentPlanNotify } from '../../redux/types/payment-plan';
import { PaymentNotify } from '../../redux/types/payment';
import { ProductNotify } from '../../redux/types/product';
import { ProfileNotify } from '../../redux/types/profile';
import { StoreNotify } from '../../redux/types/store';
import { StripeNotify } from '../../redux/types/stripe';
import { UserNotify } from '../../redux/types/user';

import translations from '../../locales/en.json';

const { Title } = Typography;

type ToastNotifyFunction = {
  [key: string]: (content: JSX.Element,
    options?: any) => void;
};

const Notify = ({
  message,
  notifyType
}: {
  message: string;
  notifyType: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const keys = Object.keys(translations) as Array<keyof typeof translations>;
    const matchedKey = keys.find((key) => translations[key] === message);

    if (matchedKey) {
      setErrorMessage(t(matchedKey));
    } else {
      setErrorMessage(message);
    }
  }, [message]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: null
      }));

      const notifyFunction = (toast as unknown as ToastNotifyFunction)[notifyType];

      if (notifyFunction) {
        notifyFunction(
          <>
            <Title level={4} className="customTitle">
              {notifyType}
            </Title>
            <span>{errorMessage}</span>
          </>,
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }
        );
      }
    }
  }, [errorMessage, notifyType, dispatch]);

  return null;
};

const AlertError = () => {
  const dispatch = useDispatch();
  const [toastType, setToastType] = useState<string>('success');
  const [sliceName, setSliceName] = useState('');

  const {
    notify: authNotify,
    notifyMessage: authNotifyMessage,
    notifyType: authNotifyType = 'error'
  } = useSelector((state: AuthNotify) => state?.auth || {});

  const {
    notify: campaignNotify,
    notifyMessage: campaignNotifyMessage,
    notifyType: campaignNotifyType = 'error'
  } = useSelector((state: CampaignNotify) => state?.campaign || {});

  const {
    notify: employeeNotify,
    notifyMessage: employeeNotifyMessage,
    notifyType: employeeNotifyType = 'error'
  } = useSelector((state: EmployeeNotify) => state?.employee || {});

  const {
    notify: fileNotify,
    notifyMessage: fileNotifyMessage,
    notifyType: fileNotifyType
  } = useSelector((state: FileNotify) => state?.file);

  const {
    notify: maxiInsertNotify,
    notifyMessage: maxiInsertNotifyMessage,
    notifyType: maxiInsertNotifyType = 'error'
  } = useSelector((state: MaxiInsertNotify) => state?.maxiInsert || {});

  const {
    notify: paymentPlanNotify,
    notifyMessage: paymentPlanNotifyMessage,
    notifyType: paymentPlanNotifyType = 'error'
  } = useSelector((state: PaymentPlanNotify) => state?.paymentPlan || {});

  const {
    notify: productNotify,
    notifyMessage: productNotifyMessage,
    notifyType: productNotifyType = 'error'
  } = useSelector((state: ProductNotify) => state?.product || {});

  const {
    notify: profileNotify,
    notifyMessage: profileNotifyMessage,
    notifyType: profileNotifyType = 'error'
  } = useSelector((state: ProfileNotify) => state?.profile || {});

  const {
    notify: templateNotify,
    notifyMessage: templateNotifyMessage,
    notifyType: templateNotifyType = 'error'
  } = useSelector((state: TemplateNotify) => state?.template || {});

  const {
    notify: otherNotify,
    notifyMessage: otherNotifyMessage,
    notifyType: otherNotifyType = 'error'
  } = useSelector((state: OtherNotify) => state?.other || {});

  const {
    notify: paymentNotify,
    notifyMessage: paymentNotifyMessage,
    notifyType: paymentNotifyType = 'error'
  } = useSelector((state: PaymentNotify) => state?.payment || {});

  const {
    notify: storeNotify,
    notifyMessage: storeNotifyMessage,
    notifyType: storeNotifyType = 'error'
  } = useSelector((state: StoreNotify) => state?.store || {});

  const {
    notify: stripeNotify,
    notifyMessage: stripeNotifyMessage,
    notifyType: stripeNotifyType = 'error'
  } = useSelector((state: StripeNotify) => state?.stripe || {});

  const {
    notify: userNotify,
    notifyMessage: userNotifyMessage,
    notifyType: userNotifyType = 'error'
  } = useSelector((state: UserNotify) => state?.user || {});

  const { errorMessage } = useSelector(((state: any) => state.error || {}));

  useEffect(() => {
    if (authNotify) {
      setToastType(authNotifyType);
      setSliceName('authNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: authNotifyMessage
      }));
      dispatch(SetAuthState({
        field: 'notify',
        value: false
      }));
    }

    if (campaignNotify) {
      setToastType(campaignNotifyType);
      setSliceName('campaignNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: campaignNotifyMessage
      }));
      dispatch(SetCampaignState({
        field: 'notify',
        value: false
      }));
    }

    if (employeeNotify) {
      setToastType(employeeNotifyType);
      setSliceName('employeeNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: employeeNotifyMessage
      }));
      dispatch(SetEmployeeState({
        field: 'notify',
        value: false
      }));
    }

    if (fileNotify) {
      setToastType(fileNotifyType);
      setSliceName('fileNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: fileNotifyMessage
      }));
      dispatch(SetFileState({
        field: 'notify',
        value: false
      }));
    }

    if (maxiInsertNotify) {
      setToastType(maxiInsertNotifyType);
      setSliceName('maxiInsertNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: maxiInsertNotifyMessage
      }));
      dispatch(SetMaxiInsertState({
        field: 'notify',
        value: false
      }));
    }

    if (paymentPlanNotify) {
      setToastType(paymentPlanNotifyType);
      setSliceName('paymentPlanNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: paymentPlanNotifyMessage
      }));
      dispatch(SetPaymentPlanState({
        field: 'notify',
        value: false
      }));
    }

    if (productNotify) {
      setToastType(productNotifyType);
      setSliceName('productNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: productNotifyMessage
      }));
      dispatch(SetProductState({
        field: 'notify',
        value: false
      }));
    }

    if (profileNotify) {
      setToastType(profileNotifyType);
      setSliceName('profileNotify');
      dispatch(SetErrorState({
        field: 'errorMessage',
        value: profileNotifyMessage
      }));
      dispatch(SetProfileState({
        field: 'notify',
        value: false
      }));
    }

    if (templateNotify) {
      setToastType(templateNotifyType);
      setSliceName('templateNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: templateNotifyMessage }));
      dispatch(SetTemplateState({ field: 'notify', value: false }));
    }

    if (otherNotify) {
      setToastType(otherNotifyType);
      setSliceName('otherNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: otherNotifyMessage }));
      dispatch(SetOtherState({ field: 'notify', value: false }));
    }

    if (paymentNotify) {
      setToastType(paymentNotifyType);
      setSliceName('paymentNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: paymentNotifyMessage }));
      dispatch(SetPaymentState({ field: 'notify', value: false }));
    }

    if (storeNotify) {
      setToastType(storeNotifyType);
      setSliceName('storeNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: storeNotifyMessage }));
      dispatch(SetStoreState({ field: 'notify', value: false }));
    }

    if (stripeNotify) {
      setToastType(stripeNotifyType);
      setSliceName('stripeNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: stripeNotifyMessage }));
      dispatch(SetStripeState({ field: 'notify', value: false }));
    }

    if (userNotify) {
      setToastType(userNotifyType);
      setSliceName('userNotify');
      dispatch(SetErrorState({ field: 'errorMessage', value: userNotifyMessage }));
      dispatch(SetUserState({ field: 'notify', value: false }));
    }
  }, [authNotify,
    campaignNotify,
    employeeNotify,
    fileNotify,
    maxiInsertNotify,
    paymentPlanNotify,
    productNotify,
    profileNotify,
    templateNotify,
    otherNotify,
    paymentNotify,
    storeNotify,
    stripeNotify,
    userNotify]);

  return (
    <div>
      <ToastContainer />
      {errorMessage && errorMessage !== '' && (
        <Notify
          message={errorMessage}
          notifyType={toastType}
        />
      )}
    </div>
  );
};

export default AlertError;
