import React, {
  ReactNode
} from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '../button/index';
import Modal from '../modal';

import QrCodeModalWrapper from './style';

interface QrCodeProps {
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  heading?: string;
  children?: ReactNode;
  buttonDisable: boolean;
}

const QrCodeModal: React.FC<QrCodeProps> = ({
  open,
  onConfirm,
  onCancel,
  heading,
  children,
  buttonDisable = true
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      width="830px"
      centered
    >
      <QrCodeModalWrapper>
        <h3>{heading || t('warning')}</h3>
        {children}
        <div className="margin-top">
          <Space align="end" size="middle">
            <Button
              text={t('cancel')}
              type="default"
              onClick={onCancel}
              width="114px"
            />
            <Button
              text={t('add')}
              type="primary"
              width="119px"
              onClick={onConfirm}
              disabled={buttonDisable}
            />
          </Space>
        </div>
      </QrCodeModalWrapper>
    </Modal>
  );
};

export default QrCodeModal;
