import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router';
import {
  Layout,
  Button,
  Popover,
  Tooltip
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import Drawer from '../../components/drawer';
import Switch from '../../components/switch';
import Connection from './drawer/connections';
import Profile from './drawer/profile';
import TypePassword from './drawer/type-password';
import UpdatePassword from './drawer/update-password';

import HeaderCollapseClose from '../../assets/icons/header-collapse.svg';
import Thunder from '../../assets/icons/thunder.svg';
import HeaderInfo from '../../assets/icons/header-info.svg';
import BellIcon from '../../assets/icons/bell-icon.svg';
import HeaderNotification from '../../assets/icons/notification.svg';

import {
  GetUserById,
  SetAuthState
} from '../../redux/slices/auth';
import {
  GetAllNotifications,
  MarkNotificationsAsRead
} from '../../redux/slices/notification';
import {
  SetUserState,
  UpdateUser
} from '../../redux/slices/user';
import {
  GetRightToUpdateProfileSettings,
  UpdateProfileSettings
} from '../../redux/slices/profile';
import { ProfileState } from '../../redux/types/profile';
import { AuthState } from '../../redux/types/auth';
import {
  NotificationState,
  Notification
} from '../../redux/types/notification';
import { UserState } from '../../redux/types/user';

import {
  EncryptCredentials,
  GetLocalStorageItem,
  HandleLogout,
  RemoveLocalStorageItems
} from '../../utils/helpers';

const { Header: AntdHeader } = Layout;

interface HeaderProps {
  collapsed: boolean;
  onToggleCollapse: () => void;
}

const NotificationPopoverTitle = ({
  onToggle,
  isToggled,
  setIsToggled,
  isNotificationPopoverOpened
}: {
  onToggle: () => void;
  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
  isNotificationPopoverOpened: boolean;
}) => {
  const dispatch = useDispatch();
  const hasMounted = useRef(true);
  const isInitialMount = useRef(true);

  const {
    updateUserSuccess,
    user
  } = useSelector((state: { user: UserState }) => state.user);

  const { notifications } = useSelector((state: { notification: NotificationState }) => state.notification);

  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    if (hasMounted.current) {
      hasMounted.current = false;
    } else if (currentUser) {
      dispatch(UpdateUser({
        userId: currentUser._id,
        updateParams: {
          notification: {
            ...user?.notification,
            showNotifications: isToggled
          }
        }
      }));
    }
  }, [isToggled]);

  useEffect(() => {
    if (isNotificationPopoverOpened) {
      dispatch(MarkNotificationsAsRead({
        notificationIds: notifications.map((notification) => notification._id)
      }));
    }
  }, []);

  useEffect(() => {
    setIsToggled(user?.notification?.showNotifications as boolean);
  }, [user]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (updateUserSuccess) {
      dispatch(SetUserState({
        field: 'user',
        value: {
          ...user,
          notification: {
            ...user?.notification,
            showNotifications: isToggled
          }
        }
      }));
    }
  }, [updateUserSuccess]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="popover-title">
        <span>Notifications</span>
      </div>
      <Switch
        onChange={onToggle}
        value={isToggled}
      />
    </div>
  );
};

const Header: React.FC<HeaderProps> = ({ collapsed, onToggleCollapse }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const token = GetLocalStorageItem('token');

  const { user: userState } = useSelector((state: { user: UserState }) => state.user);

  const {
    notifications,
    totalNotifications,
    unreadNotificationsCount
  } = useSelector((state: { notification: NotificationState }) => state.notification);

  const [connection, setConnection] = useState(false);
  const [profile, setProfile] = useState(false);
  const [typePassword, setTypePassword] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [addStoreDrawer, setAddStoreDrawer] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [updatedPassword, setUpdatedPassword] = useState<string>('');
  const [isToggled, setIsToggled] = useState(userState?.notification?.showNotifications as boolean);
  const [isNotificationPopoverOpened, setIsNotificationPopoverOpened] = useState(false);
  const [currentNotifications, setCurrentNotifications] = useState<Notification[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [localSkip, setLocalSkip] = useState(0);
  const scrollableDivRef = useRef(null);

  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  const [nameInitials, setNameInitials] = useState(currentUser?.name?.split(' ').map(
    (n: string) => n[0]
  ).join('').slice(0, 2)
    .toUpperCase() || 'A');

  const {
    currentPassword,
    isCurrentPasswordValid,
    isPasswordUpdated
  } = useSelector((state: { profile: ProfileState }) => state.profile);

  const { user } = useSelector((state: { auth: AuthState }) => state.auth);

  const infoPopover = (
    <div>
      <p>Some information goes here.</p>
    </div>
  );

  const FetchNotifications = () => {
    dispatch(GetAllNotifications({
      skip: localSkip,
      limit: 5
    }));
  };

  const HandleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, clientHeight, scrollHeight } = div;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        if (localSkip <= totalNotifications && hasMore) {
          setLocalSkip(localSkip + 5);
        } else {
          setHasMore(false);
        }
      }
    }
  };

  useEffect(() => {
    const div = scrollableDivRef.current as HTMLDivElement | null;
    div?.addEventListener('scroll', HandleScroll);
    return () => {
      div?.removeEventListener('scroll', HandleScroll);
    };
  }, [localSkip, scrollableDivRef.current]);

  useEffect(() => {
    if (hasMore) {
      FetchNotifications();
    }
  }, [localSkip]);

  useEffect(() => {
    if (notifications.length === 0) {
      setHasMore(false);
    } else {
      // filter unique notifications
      setCurrentNotifications((prevNotifications) => {
        const uniqueNotifications = notifications.filter((notification) => (
          prevNotifications.findIndex((prevNotification) => prevNotification._id === notification._id) === -1
        ));

        return [...prevNotifications, ...uniqueNotifications];
      });
      setHasMore(true);

      if (isNotificationPopoverOpened) {
        dispatch(MarkNotificationsAsRead({
          notificationIds: notifications.map((notification) => notification._id)
        }));
      }
    }
  }, [notifications, isNotificationPopoverOpened]);

  const notificationPopover = (
    <div className="notification-popover" ref={scrollableDivRef}>
      {currentNotifications
        .sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())
        .map((notification, key) => (
          <div key={key} className="notification-box">
            <img src={BellIcon} alt="no-bell-icon" />
            <div className="d-flex flex-column info">
              <span className="title">{notification?.notificationHeading}</span>
              <span className="description">
                {notification?.message?.length > 180 ? (
                  <Tooltip title={notification?.message}>
                    {notification?.message?.substring(0, 180)}
                    ...
                  </Tooltip>
                ) : (
                  notification?.message
                )}
              </span>
              <span className="time">{moment(notification?.createdAt).format('MMM DD, h:mm A')}</span>
            </div>
          </div>
        ))}

    </div>
  );
  const hasInfo = false;
  const hasNotification = true;

  const getRightToUpdateProfile = () => {
    const encryptedPassword = EncryptCredentials(password);

    dispatch(GetRightToUpdateProfileSettings({ password: encryptedPassword }));
  };

  const updateProfileSettings = () => {
    const encryptedPassword = EncryptCredentials(updatedPassword);

    dispatch(UpdateProfileSettings({
      currentPassword,
      newPassword: encryptedPassword
    }));

    setUpdatedPassword('');
    setUpdatePassword(false);
  };

  useEffect(() => {
    setIsNotificationPopoverOpened(false);
  }, [location.pathname]);

  useEffect(() => {
    setNameInitials(currentUser?.name?.split(' ').map(
      (n: string) => n[0]
    ).join('').slice(0, 2)
      .toUpperCase() || 'A');
  }, [currentUser]);

  useEffect(() => {
    if (isCurrentPasswordValid) {
      setPassword('');
      setTypePassword(false);
      setUpdatePassword(true);
    }
  }, [isCurrentPasswordValid]);

  useEffect(() => {
    if (isPasswordUpdated) {
      dispatch(GetUserById(currentUser._id));
    }
  }, [isPasswordUpdated]);

  // Logout User on Click of Profile Icon if
  // Password is Updated
  // or Token is Expired
  useEffect(() => {
    if (connection) {
      const userId = JSON.parse(GetLocalStorageItem('user') as string)?._id;
      dispatch(GetUserById(userId));

      if (!isEmpty(token)) {
        const decodedToken = jwtDecode(token as string);
        const currentTime = parseInt((Date.now() / 1000).toFixed(0), 10);

        if (decodedToken.exp && decodedToken.exp < currentTime) {
          RemoveLocalStorageItems(['token', 'user']);

          dispatch(SetAuthState({
            field: 'authToken',
            value: ''
          }));

          dispatch(SetAuthState({
            field: 'user',
            value: null
          }));

          window.location.href = '/sign-in';
        }
      }

      if (user) {
        HandleLogout(user);
      }
    }
  }, [connection]);

  return (
    <>
      <AntdHeader>
        <Button
          type="text"
          icon={collapsed
            ? <img src={HeaderCollapseClose} alt={t('sidebar_collapse_icon')} />
            : <img src={HeaderCollapseClose} alt={t('sidebar_collapse_icon')} />}
          onClick={onToggleCollapse}
          className="collapse-btn"
        />
        <div className="header-right">
          <span className="header-icon-label">
            <img className="label-icon" src={Thunder} alt={t('thunder')} />
            {`${t('credits')}:`}
          </span>
          <div className="credits-counter">
            <span className="success-credit credit">10</span>
            <span className="planned-credit credit">10</span>
            <span className="active-credit credit">10</span>
          </div>
          <div className="header-buttons">
            <Popover content={infoPopover} title={t('popover_title')} trigger="hover">
              <Button className={`header-icon-btn ${hasInfo ? 'badge' : ''}`}>
                <img className="header-icon-btn-img" src={HeaderInfo} alt={t('info')} />
              </Button>
            </Popover>
            <Popover
              content={notificationPopover}
              title={(
                <NotificationPopoverTitle
                  onToggle={
                    () => setIsToggled(!isToggled)
                  }
                  isToggled={isToggled}
                  setIsToggled={setIsToggled}
                  isNotificationPopoverOpened
                />
                )}
              placement="bottomRight"
              trigger="click"
              onOpenChange={(visible) => {
                setIsNotificationPopoverOpened(visible);
              }}
            >
              <Button className={`header-icon-btn ${hasNotification ? 'badge' : ''}`}>
                <img className="header-icon-btn-img" src={HeaderNotification} alt={t('notification')} />
                {unreadNotificationsCount > 0 && (
                  <p className="notification-badge">{unreadNotificationsCount}</p>
                )}
              </Button>
            </Popover>
            <div className="header-divider" />
            <Button
              className="header-avatar-btn"
              onClick={() => {
                setConnection(true);
                setAddStoreDrawer(true);
              }}
            >
              <h5>{nameInitials}</h5>
            </Button>
          </div>
        </div>
      </AntdHeader>
      <Drawer open={connection} width="455px" closable={false} changeHeight="change-connection-height">
        <Connection
          onClose={() => {
            setConnection(false);
            setAddStoreDrawer(false);
          }}
          goProfile={() => {
            setProfile(true);
            setConnection(false);
          }}
          addStoreDrawer={addStoreDrawer}
        />
      </Drawer>
      <Drawer
        open={profile}
        width="455px"
        onClose={() => {
          setProfile(false);
          setConnection(true);
          setAddStoreDrawer(true);
        }}
        title="Profile"
        changeHeight="change-profile-height"
      >
        <Profile onIconClick={() => {
          setProfile(false);
          setTypePassword(true);
        }}
        />
      </Drawer>
      <Drawer open={typePassword} width="345px" closable={false} changeHeight="change-type-height">
        <TypePassword
          password={password}
          setPassword={setPassword}
          onCancel={() => {
            setTypePassword(false);
          }}
          onUpdate={getRightToUpdateProfile}
        />
      </Drawer>
      <Drawer
        open={updatePassword}
        width="345px"
        onClose={() => {
          setUpdatePassword(false);
          setProfile(true);
          setTypePassword(false);
          setConnection(false);
          setUpdatedPassword('');
        }}
        title="Update Profile"
        changeHeight="change-update-height"
      >
        <UpdatePassword
          password={updatedPassword}
          setPassword={setUpdatedPassword}
          onCancel={() => {
            setUpdatePassword(false);
          }}
          onUpdate={updateProfileSettings}
        />
      </Drawer>
    </>
  );
};

export default Header;
