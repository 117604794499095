import React from 'react';
import { useTranslation } from 'react-i18next';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { isEmpty } from 'lodash';
import UploadImg from '../../assets/images/upload-icon.svg';
import UploadWrapper from './upload.style';

const { Dragger } = Upload;

interface UploadLogoProps {
  label?: string;
  handleChangeAttachment: (file: any) => void;
  handleDownloadSampleFile: () => void;
  templateId?: string;
  uploadNickname?: boolean;
}

const props: UploadProps = {
  name: 'file',
  onDrop() { }
};

const App: React.FC<UploadLogoProps> = ({
  handleChangeAttachment,
  handleDownloadSampleFile,
  templateId,
  uploadNickname
}) => {
  const { t } = useTranslation();

  return (
    <>
      <UploadWrapper>
        <Dragger
          showUploadList={false}
          onChange={handleChangeAttachment}
          customRequest={handleChangeAttachment}
          {...props}
        >
          <p className="ant-upload-drag-icon">
            <img src={UploadImg} alt={t('no_upload_icon')} />
          </p>
          <p className="ant-upload-text">
            <span>{t('click_to_upload')}</span>
            <span className="drag-drop">{t('or_drag_and_drop')}</span>
          </p>
          <p className="support-csv">{t('support_format_tsv_and_csv')}</p>
        </Dragger>

      </UploadWrapper>

      {(uploadNickname || !isEmpty(templateId)) && (
        <button
          type="button"
          className="download-text"
          onClick={handleDownloadSampleFile}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleDownloadSampleFile();
            }
          }}
        >
          {t('download_sample_file')}
        </button>
      )}
    </>
  );
};

export default App;
