import React from 'react';
import { useTranslation } from 'react-i18next';

import WarningModal from '../../../components/warning-modal';

interface WarningModalProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  open?: boolean;
  heading?: string;
}

const Modal: React.FC<WarningModalProps> = ({
  onConfirm,
  onCancel,
  heading,
  open
}) => {
  const { t } = useTranslation();

  return (
    <WarningModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      open={open}
      heading={heading}
      desc={t('you_wont_be_able_to_revert')}
    />
  );
};

export default Modal;
