import React from 'react';
import { Switch } from 'antd';

import SwitchWrapper from './style';

interface SwitchProps {
  onChange: (value: any) => void;
  value: any;
  label?: string;
}

const SwitchButton: React.FC<SwitchProps> = ({
  onChange,
  value,
  label
}) => (
  <SwitchWrapper>
    <label>{label}</label>
    <Switch
      checkedChildren="ON"
      unCheckedChildren="OFF"
      onChange={onChange}
      checked={value}
    />
  </SwitchWrapper>
);

export default SwitchButton;
