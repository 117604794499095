// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Briefadlerniklas-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Brief-adler-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}
`, "",{"version":3,"sources":["webpack://./src/font/font.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,+DAA6D;AACjE","sourcesContent":["@font-face {\n    font-family: 'Brief-adler-Regular';\n    src: url('./Briefadlerniklas-Regular.ttf') format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
