import React, {
  useEffect,
  useState
} from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/button/index';

import CongratulationsImage from '../../../../assets/images/congratulations-image.svg';

import { DrawerWrapper } from '../drawer-style';

interface CongratulationsProps {
  templateAction: string;
  setTemplateAction?: (value: string) => void;
  editTemplateId: string | null;
}

const Congratulations: React.FC<CongratulationsProps> = ({
  templateAction,
  setTemplateAction,
  editTemplateId
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [redirect, setRedirect] = useState<boolean>(false);
  const [redirectRoute, setRedirectRoute] = useState<string>('');

  // const resetValues = () => {
  //   setAddTemplate(ADD_TEMPLATE_INITIAL_STATE);
  //   setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
  //   setStep(0);
  //   setSelectedSheet(0);
  // };

  useEffect(() => {
    if (isEmpty(templateAction) && redirect) {
      navigate(redirectRoute);
    }
  }, [templateAction]);

  return (
    <div>
      <DrawerWrapper>
        <div className="congratulations-box">
          <div className="image-box">
            <img src={CongratulationsImage} alt={t('no_summary')} />
            <h2>{t('congratulations')}</h2>
            <p className="paragraph">
              {!isEmpty(editTemplateId)
                ? t('edited_template_message')
                : t('added_template_message')}
            </p>
            <div className="d-flex buttons-box">
              <Button
                width="180px"
                onClick={() => {
                  setRedirect(true);
                  setRedirectRoute('/campaigns');
                  setTemplateAction?.('');
                }}
                text={t('go_to_campaign_page_button')}
                type="default"
              />
              <Button
                width="181px"
                onClick={() => {
                  setRedirect(true);
                  setRedirectRoute('/payment');
                  setTemplateAction?.('');
                }}
                text={t('buy_credits')}
                type="primary"
              />
            </div>
          </div>
        </div>
      </DrawerWrapper>
    </div>
  );
};

export default Congratulations;
