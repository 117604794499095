import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import Input from '../../input';

import SummaryBox from '../../../assets/images/summary-box.svg';

import { CampaignState } from '../../../redux/types/campaign';

import { DrawerWrapper } from '../../../pages/templates/drawers/drawer-style';
import { SetCampaignState } from '../../../redux/slices/campaign';

interface SummaryProps {
  campaignHelperText: any;
  setCampaignHelperText: (value: any) => void;
}

const Summary: React.FC<SummaryProps> = ({
  campaignHelperText,
  setCampaignHelperText
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newCampaign } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const handleInputChange = (value: string, key: string) => {
    dispatch(SetCampaignState({
      field: 'newCampaign',
      value: {
        ...newCampaign,
        [key]: value
      }
    }));

    setCampaignHelperText({
      ...campaignHelperText,
      [key]: ''
    });
  };

  return (
    <div className="add-scroll">
      <h3>{t('summary')}</h3>
      <div className="template-input">
        <Input
          name="name"
          display="block"
          width="276px"
          label={t('modal_campaign_name')}
          placeholder={t('enter')}
          value={newCampaign?.name}
          onChange={(e: any) => handleInputChange(e.target.value, 'name')}
          helperText={campaignHelperText?.name}
        />
      </div>
      <DrawerWrapper>
        <div className="internal-summary">
          <div className="image-box">
            <img src={SummaryBox} alt={t('no_summary')} />
          </div>
          <div className="information-box">
            <div className="data-box">
              <span>{t('template_selected')}</span>
              <span>
                {newCampaign?.template?.name?.length > 15 ? (
                  <Tooltip title={newCampaign?.template?.name}>
                    <span className="ellipsis-added">
                      {newCampaign?.template?.name?.substring(0, 15)}
                      ...
                    </span>
                  </Tooltip>
                ) : <span className="ellipsis-added">{newCampaign?.template?.name}</span>}
              </span>
            </div>
            <div className="data-box">
              <span>{t('no_of_products')}</span>
              <span>{newCampaign?.productIds?.length || 0}</span>
            </div>
            <div className="data-box">
              <span>{t('timing')}</span>
              <span>
                {newCampaign?.timing || 0}
                {' '}
                {t('days')}
              </span>
            </div>
            <div className="data-box">
              <span>{t('frequency')}</span>
              <span>
                {newCampaign?.letterIntervalFrequency || 0}
              </span>
            </div>
            <div className="data-box">
              <span>{t('onboarding_phase')}</span>
              <span>{newCampaign?.onboardingPhase ? t('activated') : t('deactivated')}</span>
            </div>
            <div className="data-box">
              <span>{t('max_letters')}</span>
              <span>{newCampaign?.lettersQuantity}</span>
            </div>
            <div className="data-box">
              <span>{t('start')}</span>
              <span>{newCampaign?.startImmediately ? t('immediately') : t('save_as_draft')}</span>
            </div>
          </div>
        </div>

      </DrawerWrapper>
    </div>
  );
};

export default Summary;
