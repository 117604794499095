import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  CreateCustomerPayload,
  StripeState
} from '../types/stripe';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: StripeState = {
  loading: false,
  error: null,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  success: false,
  customerCreated: false,
  defaultCard: false,
  cardDeleted: false,
  cardUpdated: false
};

export const CreateCustomer = createAsyncThunk(
  'stripe/create-customer',
  async (data: CreateCustomerPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/stripe/create-customer', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const DeleteCard = createAsyncThunk(
  'stripe/delete-card',
  async (data: { cardId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/stripe/delete-card', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const SetCardAsDefault = createAsyncThunk(
  'stripe/set-card-as-default',
  async (data: { cardId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/stripe/set-card-as-default', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateCard = createAsyncThunk(
  'stripe/update-card',
  async (data: {
    token?: any;
    cardId?: string;
    cardHolderName?: string;
    country?: string;
    postalCode?: string;
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/stripe/update-card', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    SetStripeState(state, action: PayloadAction<{
      field: keyof StripeState;
      value: StripeState[keyof StripeState]
    }>) {
      const updateProduct = <T extends keyof StripeState>(field: T, value: StripeState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProduct(field, value as StripeState[keyof StripeState]);
    },
    SetStripeNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateCustomer.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(CreateCustomer.fulfilled, (state, action) => {
        state.error = null;
        state.customerCreated = action.payload.data.customerCreated;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(CreateCustomer.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(DeleteCard.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(DeleteCard.fulfilled, (state, action) => {
        state.error = null;
        state.cardDeleted = action.payload.data.cardDeleted;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(DeleteCard.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(SetCardAsDefault.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(SetCardAsDefault.fulfilled, (state, action) => {
        state.error = null;
        state.defaultCard = action.payload.data.defaultCard;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(SetCardAsDefault.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(UpdateCard.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(UpdateCard.fulfilled, (state, action) => {
        state.error = null;
        state.cardUpdated = action.payload.data.cardUpdated;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(UpdateCard.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      });
  }
});

const { actions } = stripeSlice;

export const { SetStripeState, SetStripeNotifyState } = actions;

export default stripeSlice.reducer;
