import React from 'react';
import { useTranslation } from 'react-i18next';

import Heading from './heading-box';

// import HandWritten from '../../../../assets/images/handwritten.jpg';
// import Printed from '../../../../assets/images/printed.jpg';
import HandWritten from '../../../../assets/images/handwritten-1.jpg';
import Printed from '../../../../assets/images/printed-1.jpg';

import { DrawerWrapper } from '../drawer-style';

import {
  AddTemplate,
  AddTemplateSheet,
  HelperText
} from '../../../../redux/types/template';

import {
  HAND_WRITTEN_INITIAL_OBJECT,
  LETTER_TYPE_VALUES,
  PRINTED_INITIAL_OBJECT
} from '../../../../constants';

import { UpdateArrayByIndex } from '../../../../utils/helpers';

interface ChooseLetterTypeProps {
  addTemplate: AddTemplate;
  setAddTemplate: (value: AddTemplate) => void;
  handleNext: () => void;
  setTemplateSheets: (value: any) => void;
  templateSheets: AddTemplateSheet[];
  templateSheetsHelperText: HelperText[];
  setTemplateSheetsHelperText: (value: any) => void;
  selectedSheet: number;
  setSelectedSheet: (value: number) => void;
  setOpenTemplateSheetsLimitPopup: (value: boolean) => void;
}

const ChooseLetterType: React.FC<ChooseLetterTypeProps> = ({
  addTemplate,
  setAddTemplate,
  handleNext,
  templateSheets,
  setTemplateSheets,
  templateSheetsHelperText,
  setTemplateSheetsHelperText,
  selectedSheet,
  setSelectedSheet,
  setOpenTemplateSheetsLimitPopup
}) => {
  const { t } = useTranslation();

  const letterType = [{
    image: HandWritten,
    secondTitle: t('personal'),
    id: 'Handwritten',
    title: t('handwritten'),
    description: t('personal_description')
  }, {
    image: Printed,
    secondTitle: t('informative'),
    id: 'Printed',
    title: t('pdf_print'),
    description: t('informative_description')
  }];

  const handleLetterTypeChange = (selectedLetterType: string) => {
    if (addTemplate?.letterType !== selectedLetterType) {
      const filteredSheets = templateSheets.filter((sheet) => sheet.message !== '');

      if (templateSheets.length < 3) {
        setSelectedSheet(templateSheets.length);

        if (selectedLetterType === LETTER_TYPE_VALUES.HANDWRITTEN) {
          if (filteredSheets.length > 0) {
            setTemplateSheets([
              ...templateSheets,
              HAND_WRITTEN_INITIAL_OBJECT
            ]);
          } else {
            setTemplateSheets([
              HAND_WRITTEN_INITIAL_OBJECT
            ]);

            setSelectedSheet(0);
          }
        } else if (selectedLetterType === LETTER_TYPE_VALUES.PRINTED) {
          if (filteredSheets.length > 0) {
            setTemplateSheets([
              ...templateSheets,
              PRINTED_INITIAL_OBJECT
            ]);
          } else {
            setTemplateSheets([
              PRINTED_INITIAL_OBJECT
            ]);

            setSelectedSheet(0);
          }
        }
      } else if (templateSheets.length >= 3) {
        setOpenTemplateSheetsLimitPopup(true);
      }

      setTemplateSheetsHelperText(UpdateArrayByIndex(
        templateSheetsHelperText,
        selectedSheet,
        '',
        ['message']
      ));

      setAddTemplate({ ...addTemplate, letterType: selectedLetterType });
    }
  };

  return (
    <div className="add-scroll">
      <Heading
        title={t('choose_letter_type')}
        infoDetails={`${t('letter_type_note1')}\n${t('letter_type_note2')}`}
      />
      <DrawerWrapper>
        <div className="d-flex gap-11 add-top-space">
          {letterType.map((letter, index) => (
            <div
              role="button"
              tabIndex={0}
              key={index}
              className={
                addTemplate?.letterType === letter.id
                  ? 'advertisee-box letter-box active'
                  : 'advertisee-box letter-box'
              }
              onClick={() => handleLetterTypeChange(letter.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNext();
                }
              }}
            >
              <h3 className="text-center">{letter.title}</h3>
              <img className="type" src={letter.image} alt={letter.title} />
              <div className="d-flex bottom-space">
                <h4>{letter.secondTitle}</h4>
              </div>
              <span className="description">
                {letter.description}
              </span>
            </div>
          ))}

        </div>
      </DrawerWrapper>
    </div>
  );
};

export default ChooseLetterType;
