import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Button from '../../../components/button';
import Loader from '../../../components/loader';

import EmailImage from '../../../assets/images/email-image.svg';

import {
  ResendVerificationEmail,
  SetAuthState,
  VerifyEmail
} from '../../../redux/slices/auth';
import { AuthState } from '../../../redux/types/auth';

import {
  AddToLocalStorage,
  GetLocalStorageItem,
  RemoveLocalStorageItems
} from '../../../utils/helpers';

const AccountVerified: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();
  const expiredToken = GetLocalStorageItem('expiredToken');

  const {
    emailResent,
    isTokenExpired,
    loading
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const handleButtonClick = () => {
    navigate('/sign-in');
  };

  useEffect(() => {
    RemoveLocalStorageItems(['resendVerificationEmail', 'expiredToken']);

    if (token) {
      dispatch(VerifyEmail(token as string));
      token = '';
    }
  }, []);

  useEffect(() => {
    if (isTokenExpired) {
      if (!expiredToken) {
        AddToLocalStorage(['expiredToken', token as string]);
      }

      if (!emailResent) {
        dispatch(ResendVerificationEmail({ expiredToken: token || expiredToken as string }));
      }

      if (emailResent) {
        dispatch(SetAuthState({
          field: 'isTokenExpired',
          value: false
        }));

        dispatch(SetAuthState({
          field: 'emailResent',
          value: false
        }));

        navigate('/account-verification');
      }
    }
  }, [isTokenExpired, emailResent]);

  return (
    <>
      {loading && <Loader />}
      <AuthFormWrapper heading={t('account_verified')}>
        <div className="ba-text-align-center">
          <img className="auth-account-verified-img" src={EmailImage} alt={t('email')} />
          <Button
            text={t('sign_in')}
            type="primary"
            width="100%"
            borderRadius="12px"
            disabled={false}
            className="custom-button reset-pass-btn"
            onClick={handleButtonClick}
          />
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default AccountVerified;
