import styled from 'styled-components';

interface InputWrapperProps {
  width?: string;
  authInput? : boolean;
  marginBottom? : string | undefined;
  display? : string | undefined
}

const InputWrapper = styled.div<InputWrapperProps>`
    width: ${(props) => (props.width ? `${props.width}` : '100%')};
   margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}` : '')};
  .ant-input, .ant-input-password,.ant-input-affix-wrapper {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    color: ${({ theme }) => theme.colors.placeholderColor};
    height: ${(props) => (props.authInput ? '40px' : '32px')};
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.textFieldColor};
    width: ${(props) => (props.width ? `${props.width}` : '100%')};
    position:relative;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholderColor};
    }
    .ant-input{
      height:auto;
    }
  }
  label{
    color: ${({ theme }) => theme.colors.labelColor};
    padding-bottom: ${(props) => (props.authInput ? '6px' : '')};
    padding-bottom:6px;
    display: ${(props) => (props.display ? `${props.display}` : 'inline-block')};
  }
  .italic-letter{
    color: ${({ theme }) => theme.colors.labelColor};
    padding-bottom:6px;
    margin-left:2px;
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
  }
  .ant-input-password {
    padding-right: 8px;
    .ant-input {
      height: auto;
    }
    &:focus-within {
      box-shadow: none;
    }
    svg {
      color: ${({ theme }) => theme.colors.iconLinkColor};
      width: 14px;
      height: 14px;
    }
  }
  .ant-input-search {
    .ant-input {
      &:hover, &:focus {
        border-color: ${({ theme }) => theme.colors.textFieldColor};
        border-right: none;
      }
    }
  }
  .ant-input-group-addon {
    .ant-btn {
      border-left: 0;
      &:hover {
        border-color: ${({ theme }) => theme.colors.textFieldColor};
      }
      svg {
        color: ${({ theme }) => theme.colors.primaryColor};
      }
    }
  }
  .helper-text {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    line-height: 12px;
    color: ${({ theme }) => theme.colors.errorColor};
    position:absolute;
    margin-top: 4px;
  }
  .ant-picker {
    color: ${({ theme }) => theme.colors.textColor};
    width: ${(props) => (props.width ? `${props.width}` : '100%')};
    border-radius: 4px;
    height: 32px;
    input {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    }
    &:hover {
      border-color: ${({ theme }) => theme.colors.textFieldColor};
    }
    &.ant-picker-focused {
      border-color: ${({ theme }) => theme.colors.textFieldColor};
      box-shadow: none;
    }
    .anticon-calendar {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  &.secondary-input {
    .ant-input {
      height: 36px;
      border-radius: 2px;
      border-color: ${({ theme }) => theme.colors.dividerColor};
    }
  }
  &.selected-input{
    .ant-input {
      border-color: ${({ theme }) => theme.colors.backgroundPrimary};
      height:24px;
    }
  }
  &.batch-search {
    .ant-input {
      border-radius: 2px;
      border-color: ${({ theme }) => theme.colors.dividerColor};
      &:hover, &:focus {
        border-color: ${({ theme }) => theme.colors.dividerColor};
      }
    }
    .ant-btn {
      border-color: ${({ theme }) => theme.colors.dividerColor};
      &:hover {
        border-color: ${({ theme }) => theme.colors.dividerColor};
      }
    }
  }
  .input-wrapper{
    position:relative;
    .error-text {
    position: absolute;
    bottom: -2px;
}
.input-password {
    position: absolute;
    bottom: -14px;
}
  }
  &.add-employee-input{
      .error-text {
    position: absolute;
    bottom: -16px;
}
    }
`;

export default InputWrapper;
