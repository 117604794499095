import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  AuthState,
  LogoutPayload,
  SignupData
} from '../types/auth';

import { HandleCatchBlock } from '../../utils/helpers';
import { SignInDataState } from '../../utils/types/pages/auth';

const axios = axiosBaseUrl();

const initialState: AuthState = {
  authToken: '',
  emailResent: false,
  error: null,
  forgotPasswordEmailSent: false,
  isSignedUp: false,
  isTokenExpired: false,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  passwordReset: false,
  resendForgotPasswordEmailLoading: false,
  success: false,
  user: null
};

export const CheckSetPasswordTokenExpiry = createAsyncThunk<AxiosResponse, string, { rejectValue: { error: string } }>(
  'auth/check-set-password-token-expiry',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/auth/check-set-password-token-expiry/?token=${token}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ForgetPassword = createAsyncThunk<AxiosResponse, string, { rejectValue: { error: string } }>(
  'auth/forgot-password',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/forgot-password', { email });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetUserById = createAsyncThunk<AxiosResponse, string, { rejectValue: { error: string } }>(
  'auth/get-user-by-id',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/auth/get-user-by-id/?userId=${userId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const LogoutApi = createAsyncThunk<AxiosResponse, LogoutPayload, { rejectValue: { error: string } }>(
  'auth/logout',
  async (data: LogoutPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/auth/logout', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ResendForgotPasswordEmail = createAsyncThunk<AxiosResponse, {
  email?: string,
  expiredToken?: string
}, { rejectValue: { error: string } }
>(
  'auth/resend-forgot-password-email',
  async (data: {
    email?: string,
    expiredToken?: string
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/resend-forgot-password-email', {
        email: data.email,
        expiredToken: data.expiredToken
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ResendVerificationEmail = createAsyncThunk<AxiosResponse, {
  email?: string,
  expiredToken?: string
}, { rejectValue: { error: string } }
>(
  'auth/resend-verification-email',
  async (data: {
    email?: string,
    expiredToken?: string
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/resend-verification-email', {
        email: data.email,
        expiredToken: data.expiredToken
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ResetPasswordd = createAsyncThunk<AxiosResponse, {
  password: string,
  token: string
}, { rejectValue: { error: string } }
>(
  'auth/reset-password',
  async (data: {
    password: string,
    token: string
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/reset-password', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const Signin = createAsyncThunk<AxiosResponse, SignInDataState, { rejectValue: { error: string } }>(
  'auth/sign-in',
  async (data: SignInDataState, { rejectWithValue }) => {
    try {
      const {
        email,
        password,
        rememberMe
      } = data;

      const response = await axios.post('/auth/signin', {
        email,
        password,
        rememberMe
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const Signup = createAsyncThunk<AxiosResponse, SignupData, { rejectValue: { error: string } }>(
  'auth/sign-up',
  async (data: SignupData, { rejectWithValue }) => {
    try {
      const {
        name,
        email,
        password
      } = data;

      const response = await axios.post('/auth/signup', {
        name,
        email,
        password
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const VerifyEmail = createAsyncThunk<AxiosResponse, string, { rejectValue: { error: string } }>(
  'auth/verify-email',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/auth/verify-email/?token=${token}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SetAuthState(state: any, action: PayloadAction<{ field: string, value: any }>) {
      const { field, value } = action.payload;
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CheckSetPasswordTokenExpiry.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
        state.isTokenExpired = false;
      })
      .addCase(CheckSetPasswordTokenExpiry.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = null;
        state.isTokenExpired = false;
      })
      .addCase(CheckSetPasswordTokenExpiry.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        if (action.payload?.error && action.payload.error === 'Token expired!') {
          state.isTokenExpired = true;
          state.notify = false;
          state.notifyMessage = '';
        } else {
          const payload = action.payload as { error?: string } | undefined;
          if (payload) {
            state.notifyMessage = payload.error || 'An error occurred!';
          }
          state.notifyType = 'error';
          state.notify = true;
        }
      })
      .addCase(ForgetPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.forgotPasswordEmailSent = false;
      })
      .addCase(ForgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.forgotPasswordEmailSent = true;
        state.user = action.payload.data;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ForgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetUserById.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(GetUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.data.user;
      })
      .addCase(GetUserById.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
      })
      .addCase(LogoutApi.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(LogoutApi.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(LogoutApi.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = 'An error occurred';
      })
      .addCase(ResendForgotPasswordEmail.pending, (state) => {
        state.resendForgotPasswordEmailLoading = true;
        state.success = false;
        state.error = null;
        state.forgotPasswordEmailSent = false;
        state.emailResent = false;
      })
      .addCase(ResendForgotPasswordEmail.fulfilled, (state, action) => {
        state.resendForgotPasswordEmailLoading = false;
        state.success = true;
        state.error = null;
        state.forgotPasswordEmailSent = true;
        state.user = action.payload.data;
        state.emailResent = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ResendForgotPasswordEmail.rejected, (state, action) => {
        state.resendForgotPasswordEmailLoading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ResendVerificationEmail.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.emailResent = false;
      })
      .addCase(ResendVerificationEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.emailResent = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ResendVerificationEmail.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ResetPasswordd.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.passwordReset = false;
      })
      .addCase(ResetPasswordd.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.passwordReset = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ResetPasswordd.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.notify = true;
        state.notifyType = 'error';
        state.error = action.payload?.error || 'An error occurred';
        if (action.payload?.error && action.payload.error === 'Token expired!') {
          state.isTokenExpired = true;
          state.notifyMessage = 'Please ask your admin to re-invite you';
        } else {
          const payload = action.payload as { error?: string } | undefined;
          if (payload) {
            state.notifyMessage = payload.error || 'An error occurred!';
          }
        }
      })
      .addCase(Signin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
      })
      .addCase(Signin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.data.user;
        state.authToken = action.payload.data.user.token;
      })
      .addCase(Signin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(Signup.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
      })
      .addCase(Signup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload.data;
        state.isSignedUp = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(Signup.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(VerifyEmail.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
        state.isTokenExpired = false;
      })
      .addCase(VerifyEmail.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = null;
        state.isTokenExpired = false;
      })
      .addCase(VerifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.error || 'An error occurred';
        if (action.payload?.error && action.payload.error === 'Token expired!') {
          state.isTokenExpired = true;
          state.notify = false;
          state.notifyMessage = '';
        } else {
          const payload = action.payload as { error?: string } | undefined;
          if (payload) {
            state.notifyMessage = payload.error || 'An error occurred!';
          }
          state.notifyType = 'error';
          state.notify = true;
        }
      });
  }
});

const { reducer, actions } = authSlice;

export const { SetAuthState } = actions;

export default reducer;
