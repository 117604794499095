import styled from 'styled-components';

const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: #eae8e8ad;
    z-index: 3;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

`;
export default LoaderWrapper;
