import styled from 'styled-components';

const PaymentSettingWrapper = styled.div`
.ant-tabs-nav{
  margin-bottom:0px;
}
  .ant-tabs {
    background-color:${({ theme }) => theme.colors.whiteColor};
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    .ant-tabs-tab{
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
      color:#535E63;
      padding: 16px 12px;
      &.ant-tabs-tab-active{
        .ant-tabs-tab-btn{
          color:${({ theme }) => theme.colors.backgroundPrimary};
          font-weight:600;
        
      }
    }

  }
  .ant-tabs-ink-bar {
    background-color:${({ theme }) => theme.colors.backgroundPrimary};  
    }
  }
  .payment-table{
    width:100%;
    border-collapse: collapse;
    thead{
      tr{
        th{
          border:1px solid ${({ theme }) => theme.colors.dividerStroke};
          font-size:${({ theme }) => theme.fonts.baseFontSizeSm};
          color:${({ theme }) => theme.colors.placeholderColor};
          text-align:start;
          padding:11px 12px;
          line-height:14px;
          font-weight:500;
          min-height:36px;
          max-height:36px;
        }
      }
    }
    tbody{
      tr{
        td{
          border:1px solid ${({ theme }) => theme.colors.dividerStroke};
          font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
          color:${({ theme }) => theme.colors.bodyColor};
          text-align:start;
          padding:6px 12px;
          line-height:14px;
          font-weight:500;
          min-height:36px;
          max-height:36px;
          &.add-bg{
            background-color:#F2F3E2;
            font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
            min-width: 180px;
          }
          .icon-button{
            border: none;
            background-color: transparent;
          }
          .icon-button:disabled{
            pointer-events: none;
          }

          .icon-button img.disabled {
            filter: grayscale(100%);
            opacity: 0.5;
          }
        }
        &.empty-row{
            td{
              height:32px; 
            }
          }
      }
    }
  }
  input.no-spin[type='number'] {
    -moz-appearance: textfield;
  }
  input.no-spin::-webkit-outer-spin-button,
  input.no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
export default PaymentSettingWrapper;
