import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import LogoImgOpen from '../../assets/images/brief-adler-new-logo.png';
import LogoImgClosed from '../../assets/images/brief-adler-new-logo-favicon.png';
import DashboardIcon from '../../assets/icons/sidebar-dashboard.svg';
import TemplatesIcon from '../../assets/icons/sidebar-templates.svg';
import CampaignIcon from '../../assets/icons/sidebar-campaign.svg';
import ProductsIcon from '../../assets/icons/sidebar-products.svg';
import EmployeeIcon from '../../assets/icons/employee.svg';
import Jobs from '../../assets/icons/jobs.svg';
import PaymentIcon from '../../assets/icons/sidebar-payment.svg';
import SettingsIcon from '../../assets/icons/sidebar-settings.svg';
import HelpIcon from '../../assets/icons/sidebar-help.svg';
import FilesIcon from '../../assets/icons/files-icon.svg';
import Logout from '../../assets/icons/logout.svg';

import { LogoutApi } from '../../redux/slices/auth';

import { GetLocalStorageItem } from '../../utils/helpers';

import { USER_ROLES } from '../../constants';

interface SidebarProps {
  collapsed: boolean;
}
const Sidebar: React.FC<SidebarProps> = ({ collapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(GetLocalStorageItem('user') || '{}');
  const items: MenuProps['items'] = [
    {
      label: t('settings'),
      key: 'SubMenu',
      icon: <img src={SettingsIcon} alt={t('help')} />,
      children: [{
        type: 'group',
        key: 'maxi',
        label: (
          <Menu.Item
            className={location.pathname === '/maxi' ? 'active' : ''}
            icon={<img src={SettingsIcon} alt={t('help')} />}
          >
            <Link to="/maxi">{t('inserts')}</Link>
          </Menu.Item>
        )
      },
      {
        type: 'group',
        key: 'Payment Method',
        label: (
          <Menu.Item
            className={location.pathname === '/payment-method' ? 'active' : ''}
            icon={<img src={PaymentIcon} alt={t('payment')} />}
          >
            <Link to="/payment-method">{t('Payment Method')}</Link>
          </Menu.Item>
        )
      }]
    }
  ];

  const handleLogout = () => {
    dispatch(LogoutApi({
      userId: user._id
    }));
    localStorage.clear();
    window.location.href = '/sign-in';
  };

  return (
    <div>
      <div className="sidebar-logo">
        {collapsed ? (
          <img className="img-closed" src={LogoImgClosed} alt={t('brief_adler_closed')} />
        ) : (
          <img className="img-open" src={LogoImgOpen} alt={t('brief_adler_open')} />
        )}
      </div>
      <div className="sidebar-menu-wrapper">
        <label className="sidebar-label">{t('main_menu')}</label>
        <Menu mode="inline" defaultSelectedKeys={['1']}>
          {(user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.CUSTOMER) && (
            <Menu.Item
              className={location.pathname === '/templates' ? 'active' : ''}
              key="3"
              icon={<img src={TemplatesIcon} alt={t('templates')} />}
            >
              <Link to="/templates">{t('templates')}</Link>
            </Menu.Item>
          )}
          <Menu.Item
            className={location.pathname === '/campaigns' ? 'active' : ''}
            key="2"
            icon={<img src={CampaignIcon} alt={t('campaign')} />}
          >
            <Link to="/campaigns">{t('campaigns')}</Link>
          </Menu.Item>
          {(user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.CUSTOMER) && (
            <Menu.Item
              className={location.pathname === '/' ? 'active' : ''}
              key="1"
              icon={<img src={DashboardIcon} alt={t('dashboard')} />}
            >
              <Link to="/">{t('dashboard')}</Link>
            </Menu.Item>
          )}
          {(user?.role === USER_ROLES.EMPLOYEE || user?.role === USER_ROLES.ADMIN) && (
            <Menu.Item
              className={location.pathname === '/files' ? 'active' : ''}
              key="4"
              icon={<img src={FilesIcon} alt={t('employee')} />}
            >
              <Link to="/files">{t('files')}</Link>
            </Menu.Item>
          )}
          { user?.role === USER_ROLES.ADMIN && (
            <>
              <Menu.Item
                className={location.pathname === '/employees' ? 'active' : ''}
                key="5"
                icon={<img src={EmployeeIcon} alt={t('employee')} />}
              >
                <Link to="/employees">{t('employees')}</Link>
              </Menu.Item>
              <Menu.Item
                className={location.pathname === '/jobs' ? 'active' : ''}
                key="6"
                icon={<img src={Jobs} alt={t('employee')} />}
              >
                <Link to="/jobs">{t('jobs')}</Link>
              </Menu.Item>
            </>
          )}
          {(user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.CUSTOMER) && (
            <>
              <Menu.Item
                className={location.pathname === '/products' ? 'active' : ''}
                key="7"
                icon={<img src={ProductsIcon} alt={t('product')} />}
              >
                <Link to="/products">{t('products')}</Link>
              </Menu.Item>
              <Menu.Item
                className={location.pathname === '/payment' ? 'active' : ''}
                key="8"
                icon={<img src={PaymentIcon} alt={t('payment')} />}
              >
                <Link to="/payment">{t('Subscription')}</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
        {user?.role !== USER_ROLES.EMPLOYEE && (
          <>
            <label className="sidebar-label">{t('preferences')}</label>
            <Menu mode="inline" className="collapse-menu" items={items} defaultSelectedKeys={['1']} />
          </>
        )}
        <Menu mode="inline" className="logout-menu" defaultSelectedKeys={['1']}>
          {user?.role !== USER_ROLES.EMPLOYEE && (
            <Menu.Item
              className={location.pathname === '/videos' ? 'active' : ''}
              key="1"
              icon={<img src={HelpIcon} alt={t('help')} />}
            >
              <Link to="/videos">{t('help_and_support')}</Link>
            </Menu.Item>
          )}
          <Menu.Item
            className={location.pathname === '' ? 'active' : ''}
            key="2"
            icon={<img src={Logout} alt={t('logout')} />}
          >
            <Link to="/" onClick={handleLogout}>{t('logout')}</Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
