import React from 'react';
import { useTranslation } from 'react-i18next';

import CalenderIcon from '../../assets/icons/calender-icon.svg';

interface ActionButtonProps {
  setNewDate?: (value: boolean) => void;
  date:boolean
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="calender-button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  setNewDate,
  date
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-1 align-items-center icons-box">
      {renderActionButton(
        <img className="pointer" width="14px" src={CalenderIcon} alt={t('no_check_icon')} />,
        () => setNewDate?.(!date)
      )}
    </div>
  );
};

export default ActionButtons;
