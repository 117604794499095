import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  Tooltip
} from 'antd';
import {
  extend,
  isEmpty
} from 'lodash';

import ChatInfo from '../../assets/images/chat-info.svg';
import Button from '../../components/button';
import Loader from '../../components/loader';
import Table from '../../components/table';
import Pagination from '../../components/pagination';
import { MaxiSearchComponent } from '../../components/search-column-header';
import { MaxiFilterComponent } from '../../components/filter-column-header';
import ActionButtons from './actions-buttons';

import InsertModal from './models/insert-maxi-modal';
import DeleteModal from './models/delete-modal';

import {
  AddMaxiInsert,
  DeleteMaxiInsertById,
  EditMaxiInsert,
  GetAllMaxiInserts,
  SetMaxiInsertState
} from '../../redux/slices/maxi';

import {
  MaxiInsert,
  MaxiFilters,
  MaxiInsertState,
  SortBy
} from '../../redux/types/maxi';
import { UserState } from '../../redux/types/user';

import { PAGE_SIZES } from '../../constants';

interface CellRendererProps {
  value: {
    length: string;
    width: string;
    height: string;
  } | undefined;
}

interface MaxiNameCellRendererProps {
  value: string;
}

const MaxiNameCustomCellRenderer:React.FC<MaxiNameCellRendererProps> = ({ value }) => {
  if (value) {
    return (
      value.length > 50 ? <Tooltip title={value}>{value}</Tooltip> : value
    );
  }

  return 'N/A';
};

const content = (
  <div className="max-width-269">
    <p>
      To create campaigns with inserts, you must first set up the insert.
      Please provide us with the quantity of inserts you plan to send to us.
      We also need the dimensions and weight of the box containing the inserts.
      If you have multiple boxes, please set up multiple inserts.
    </p>
  </div>
);

const MaxiTable: React.FC = () => {
  const { t } = useTranslation();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [currentInsertId, setCurrentInsertId] = useState<string>(query.get('maxiInsertId') || '');

  const {
    deleteMaxiInsertLoading,
    maxiInsertActionLoading,
    maxiInserts,
    getAllMaxiInsertsSuccess,
    isMaxiInsertAdded,
    isMaxiInsertDeleted,
    isMaxiInsertEdited,
    isMaxiInsertReceived,
    loading,
    totalMaxiInserts
  } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);
  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [openMaxiLimitModal, setOpenMaxiLimitModal] = useState<boolean>(false);
  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(PAGE_SIZES[0]);
  const [deleteMaxi, setDeleteMaxi] = useState<boolean>(false);
  const [maxiInsertId, setMaxiInsertId] = useState<string | null>('');
  const [maxiInsertAction, setMaxiInsertAction] = useState<string>('');
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [data, setData] = useState<MaxiInsert[]>([]);
  const [filters, setFilters] = useState<MaxiFilters>({
    searchByKeyWords: {
      name: '',
      weight: ''
    }
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [openPopoverKey, setOpenPopoverKey] = useState<string | null>(null);

  const actionCellRenderer = (params: { data: MaxiInsert }) => {
    const { _id: maxiInsertActionId } = params.data;
    return (
      <ActionButtons
        maxiInsertId={maxiInsertActionId}
        setDeleteMaxi={setDeleteMaxi}
        setMaxiInsertId={setMaxiInsertId}
        setMaxiInsertAction={setMaxiInsertAction}
        setOpenMaxiLimitModal={setOpenMaxiLimitModal}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        setOpenPopoverKey={setOpenPopoverKey}
        openPopoverKey={openPopoverKey}
      />
    );
  };

  const customCellRenderer: React.FC<CellRendererProps> = ({ value }) => {
    if (value) {
      return (
        <div>
          <span>{value.length}</span>
          <span> x </span>
          <span>{value.width}</span>
          <span> x </span>
          <span>{value.height}</span>
        </div>
      );
    }
    return <span>N/A</span>;
  };

  const getAllMaxiInserts = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;

    dispatch(GetAllMaxiInserts({
      skip,
      limit,
      filters: applyFilters,
      sortBy: sortValue || {}
    }));
  };

  const addMaxiInsertHandler = ({
    name,
    dimensions,
    weight
  }: {
    name: string;
    dimensions: string;
    weight: string;
  }) => {
    const [length, width, height] = dimensions.split('x').map((item) => item?.trim());

    if (maxiInsertAction === 'edit') {
      const updateParams = {};

      if (!isEmpty(name)) {
        extend(updateParams, { name });
      }

      if (!isEmpty(dimensions)) {
        extend(updateParams, {
          dimensions: {
            length: Number(length),
            width: Number(width),
            height: Number(height)
          }
        });
      }

      if (!isEmpty(weight)) {
        extend(updateParams, { weight });
      }

      dispatch(EditMaxiInsert({
        maxiInsertId: maxiInsertId as string,
        updateParams
      }));
    } else {
      dispatch(AddMaxiInsert({
        name,
        dimensions: {
          length: Number(length),
          width: Number(width),
          height: Number(height)
        },
        weight
      }));
    }
  };

  const deleteMaxiInsertHandler = () => {
    dispatch(DeleteMaxiInsertById({
      maxiInsertId: maxiInsertId as string
    }));
  };

  const onCancel = (
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>
  ) => {
    setFormData({
      name: '',
      dimensions: '',
      weight: ''
    });

    setFormHelperText({
      name: '',
      dimensions: '',
      weight: ''
    });

    setMaxiInsertAction('');
  };

  const onSubmit = (
    name: string,
    dimensions: string,
    weight: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      dimensions: string;
      weight: string;
    }>>
  ) => {
    setFormData({
      name: '',
      dimensions: '',
      weight: ''
    });

    setFormHelperText({
      name: '',
      dimensions: '',
      weight: ''
    });

    addMaxiInsertHandler({
      name,
      dimensions,
      weight
    });
  };

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  const columnDefs = [{
    headerName: t('name'),
    field: 'name',
    headerComponent: MaxiSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'name',
      filters,
      showSearch: user?.role !== 'employee',
      setFilters,
      getAllMaxiInserts,
      showArrow: sortValue?.name === 'asc'
        ? 'up'
        : sortValue?.name === 'desc'
          ? 'down'
          : null
    },
    minWidth: 300,
    flex: 1,
    cellRenderer: MaxiNameCustomCellRenderer
  // }, {
  //   headerName: t('dimensions'),
  //   field: 'dimensions',
  //   minWidth: 160,
  //   flex: 1,
  //   cellRenderer: customCellRenderer
  // }, {
  //   headerName: t('weight'),
  //   field: 'weight',
  //   headerComponent: MaxiSearchComponent,
  //   headerComponentParams: {
  //     setSortValue,
  //     fieldName: 'weight',
  //     filters,
  //     setFilters,
  //     getAllMaxiInserts,
  //     showArrow: sortValue?.weight === 'asc'
  //       ? 'up'
  //       : sortValue?.weight === 'desc'
  //         ? 'down'
  //         : null
  //   },
  //   minWidth: 180,
  //   flex: 1
  }, {
    headerName: t('amount_of_inserts'),
    field: 'amountOfInserts',
    headerComponent: MaxiFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'amountOfInserts',
      filters,
      showFilter: false,
      setFilters,
      getAllMaxiInserts,
      showArrow: sortValue?.amountOfInserts === 'asc'
        ? 'up'
        : sortValue?.amountOfInserts === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('received_quantity'),
    field: 'receivedQuantity',
    headerComponent: MaxiFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'receivedQuantity',
      filters,
      showFilter: false,
      setFilters,
      getAllMaxiInserts,
      showArrow: sortValue?.receivedQuantity === 'asc'
        ? 'up'
        : sortValue?.receivedQuantity === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('letter_sent_quantity'),
    field: 'letterSentQuantity',
    headerComponent: MaxiFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'letterSentQuantity',
      filters,
      showFilter: false,
      setFilters,
      getAllMaxiInserts,
      showArrow: sortValue?.letterSentQuantity === 'asc'
        ? 'up'
        : sortValue?.letterSentQuantity === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('actions'),
    field: 'actions',
    minWidth: 180,
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    if (user?.role === 'employee') {
      getAllMaxiInserts({
        applyFilters: {
          ...filters,
          _id: currentInsertId
        }
      });
    } else {
      getAllMaxiInserts({ applyFilters: filters });
    }
  }, [
    pageNumber,
    pageLimit,
    sortValue
  ]);

  useEffect(() => {
    if (maxiInserts?.length) {
      setData(maxiInserts);
    } else {
      setData([]);
    }
  }, [maxiInserts]);

  useEffect(() => {
    if (getAllMaxiInsertsSuccess) {
      if (user?.role === 'employee') {
        setCurrentInsertId(maxiInserts[0]?._id || '');
      }
      setIsDataRetrievalSuccessful(true);

      dispatch(SetMaxiInsertState({
        field: 'getAllMaxiInsertsSuccess',
        value: false
      }));
    }
  }, [getAllMaxiInsertsSuccess]);

  useEffect(() => {
    if (isMaxiInsertAdded) {
      setMaxiInsertAction('');
      getAllMaxiInserts({ applyFilters: filters });
      dispatch(SetMaxiInsertState({
        field: 'isMaxiInsertAdded',
        value: false
      }));
    }

    if (isMaxiInsertDeleted) {
      setDeleteMaxi(false);
      getAllMaxiInserts({ applyFilters: filters });
      dispatch(SetMaxiInsertState({
        field: 'isMaxiInsertDeleted',
        value: false
      }));
    }

    if (isMaxiInsertEdited) {
      setMaxiInsertAction('');
      getAllMaxiInserts({ applyFilters: filters });
      dispatch(SetMaxiInsertState({
        field: 'isMaxiInsertEdited',
        value: false
      }));
    }

    if (isMaxiInsertReceived) {
      if (user?.role === 'employee') {
        getAllMaxiInserts({
          applyFilters: {
            ...filters,
            _id: currentInsertId
          }
        });
      } else {
        getAllMaxiInserts({ applyFilters: filters });
      }

      dispatch(SetMaxiInsertState({
        field: 'isMaxiInsertReceived',
        value: false
      }));
    }
  }, [
    isMaxiInsertAdded,
    isMaxiInsertDeleted,
    isMaxiInsertEdited,
    isMaxiInsertReceived
  ]);

  return (
    <>
      {loading && <Loader />}
      <div className="content-header">
        <h2
          className="heading"
        >
          {t('inserts')}
          <Popover trigger="hover" className="tooltip-icon" content={content}>
            <span className="tooltip"><img src={ChatInfo} width="16px" height="16px" alt={t('no_info')} /></span>
          </Popover>
        </h2>
        {user?.role !== 'employee' && (
          <Button
            type="primary"
            icon={<BsPlusCircle />}
            text={t('add_insert')}
            onClick={() => setMaxiInsertAction('add')}
            width="120px"
          />
        )}
      </div>
      <Table
        rowData={data}
        columnDefs={columnDefs}
        sortable
        resizable
        editable
        filter
        height="164px"
        rowHeight={36}
        headerHeight={40}
        className="custom-table"
        isDataRetrievalSuccessful={isDataRetrievalSuccessful}
      />
      <Pagination
        totalPages={totalMaxiInserts}
        currentPage={pageNumber}
        onChange={onPageChange}
        pageSize={pageLimit}
        pageSizes={PAGE_SIZES}
      />
      <InsertModal
        open={maxiInsertAction === 'edit' || maxiInsertAction === 'add'}
        saveText={maxiInsertAction === 'edit' ? t('update') : t('submit')}
        text={maxiInsertAction === 'edit' ? t('edit') : t('add')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={maxiInsertActionLoading}
        maxiInsertId={maxiInsertId as string}
      />
      <DeleteModal
        heading={t('delete_insert_confirmation')}
        open={deleteMaxi}
        onCancel={() => {
          setDeleteMaxi(false);
          setMaxiInsertId(null);
        }}
        onConfirm={() => {
          deleteMaxiInsertHandler();
        }}
        loading={deleteMaxiInsertLoading}
        desc={t('you_wont_be_able_to_revert')}
      />
      <DeleteModal
        heading={t('maxi_inserts_exceeded')}
        open={openMaxiLimitModal}
        onCancel={() => {
          setOpenMaxiLimitModal(false);
          setOpenPopoverKey(
            maxiInserts.find((maxiInsert) => maxiInsert._id === maxiInsertId)?._id || null
          );
          setIsPopoverOpen(true);
        }}
        onConfirm={() => {
          setOpenMaxiLimitModal(false);
          navigate('/videos');
        }}
        loading={false}
        desc={t('maxi_limit_modal_desc')}
        cancelText="Edit"
        confirmText="Support"
      />
    </>
  );
};

export default MaxiTable;
