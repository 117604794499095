import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Loader from '../../../components/loader';

import {
  ResendVerificationEmail,
  SetAuthState
} from '../../../redux/slices/auth';
import { AuthState } from '../../../redux/types/auth';

import EmailImage from '../../../assets/images/email-image.svg';
import { GetLocalStorageItem } from '../../../utils/helpers';

const AccountVerification: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    emailResent,
    loading,
    user
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const ResendEmail = () => {
    const email = user?.email || GetLocalStorageItem('resendVerificationEmail');
    const expiredToken = GetLocalStorageItem('expiredToken');

    if (!isEmpty(email)) {
      dispatch(ResendVerificationEmail({ email: email as string }));
    } else if (!isEmpty(expiredToken)) {
      dispatch(ResendVerificationEmail({ expiredToken: expiredToken as string }));
    }
  };

  useEffect(() => {
    if (emailResent) {
      dispatch(SetAuthState({
        field: 'emailResent',
        value: false
      }));
    }
  }, [emailResent]);

  return (
    <>
      {loading && <Loader />}
      <AuthFormWrapper
        heading={t('account_verification')}
        subtext={t('account_verification_phrase')}
      >
        <div className="ba-text-align-center">
          <img className="auth-email-img" src={EmailImage} alt={t('email')} />
          <p>
            {t('if_you_did_not_receive_email')}
            <Link
              to="#"
              onClick={() => ResendEmail()}
            >
              {t('resend')}
            </Link>
          </p>
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default AccountVerification;
