import React from 'react';
import { useTranslation } from 'react-i18next';

import LeftArrow from '../../assets/icons/left-arrow.svg';
import RightArrow from '../../assets/icons/right-arrow.svg';

import PaginationWrapper from './pagination-style';

interface UploadLogoProps {
  current?: number;
  total?: number;
  setSelectedSheet: (value: number) => void;
  selectedSheet: number;
  templateSheetsLength: number
}

const Index: React.FC<UploadLogoProps> = ({
  current,
  total,
  setSelectedSheet,
  selectedSheet,
  templateSheetsLength
}) => {
  const { t } = useTranslation();

  return (
    <PaginationWrapper>
      <div className="small-pagination">
        {t('page')}
        {' '}
        <div className="total-count d-flex">
          {current}
          {' '}
          -
          {total}
          <div className="d-flex arrow-box">
            <button
              type="button"
              onClick={() => {
                if (selectedSheet > 0) {
                  setSelectedSheet(selectedSheet - 1);
                }
              }}
            >
              <img src={LeftArrow} alt={t('left_arrow')} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (selectedSheet < templateSheetsLength) {
                  setSelectedSheet(selectedSheet + 1);
                }
              }}
            >
              <img src={RightArrow} alt={t('right_arrow')} />
            </button>
          </div>
        </div>
      </div>
    </PaginationWrapper>
  );
};

export default Index;
