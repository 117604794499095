import React from 'react';
import type { UploadProps } from 'antd';
import { Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';

import UploadLogoWrapper from './upload-style';

import UploadIcon from '../../assets/images/upload-file.svg';

interface UploadLogoProps {
  label?: string;
  handleChangeAttachment: any;
  accept: string;
}
const props: UploadProps = {
  name: 'file',
  onDrop() { }
};

const UploadLogo: React.FC<UploadLogoProps> = ({
  label,
  handleChangeAttachment,
  accept = ''
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      <label>{label}</label>
      <UploadLogoWrapper>
        <Upload
          showUploadList={false}
          onChange={handleChangeAttachment}
          customRequest={handleChangeAttachment}
          accept={accept}
          {...props}
        >
          <Tooltip title={t('upload_logo_details')}>
            <div className="upload-box cursor-pointer">
              <img src={UploadIcon} alt={t('no_upload')} />
              <span className="pl-5">{t('drop_logo_file')}</span>
            </div>
          </Tooltip>

        </Upload>
      </UploadLogoWrapper>
    </div>
  );
};

export default UploadLogo;
