import React from 'react';
import {
  Outlet,
  Link
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthWrapper } from './style';

import LogoImg from '../../assets/images/brief-adler-new-logo.png';
import RightImg from '../../assets/images/auth-right-cover.png';

const Auth: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <div className="auth-left-col">
        <header className="auth-header">
          <Link to="/" className="header-logo-link">
            <img className="header-logo" src={LogoImg} alt={t('brief_adler_logo')} />
          </Link>
        </header>
        <Outlet />
      </div>
      <div className="auth-right-col">
        <img className="right-cover" src={RightImg} alt={t('cover_img')} />
      </div>
    </AuthWrapper>
  );
};

export default Auth;
