import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthState } from '../../../redux/types/auth';
import {
  ForgetPassword,
  SetAuthState
} from '../../../redux/slices/auth';

import AuthFormWrapper from './auth-form-wrapper';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Loader from '../../../components/loader';

import {
  AddToLocalStorage,
  ApplyValidation,
  EncryptCredentials
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    forgotPasswordEmailSent,
    loading
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const [formData, setFormData] = useState<{ email: string }>({
    email: ''
  });

  const [helperText, setHelperText] = useState<{ email: string }>({
    email: ''
  });

  const validateField = (
    fieldName: 'email',
    value: string
  ): Partial<{ email: string }> => {
    const errors: Partial<{ email: string }> = {};

    if (value.trim() === '') {
      errors.email = AUTH_FORMS_ERROR_MESSAGES.REQUIRED('email');
    } else if (fieldName === 'email' && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, value)) {
      errors.email = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_EMAIL);
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: 'email',
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setHelperText({
      ...helperText,
      [fieldName]: errors[fieldName] || ''
    });
  };

  const handleButtonClick = () => {
    const emailError = validateField('email', formData.email).email;

    setHelperText({
      ...helperText,
      email: emailError || ''
    });

    if (emailError) return;

    const encryptedEmail = EncryptCredentials(formData.email);

    AddToLocalStorage(['resendForgotPasswordEmail', encryptedEmail]);

    dispatch(ForgetPassword(encryptedEmail));
  };

  useEffect(() => {
    if (forgotPasswordEmailSent) {
      navigate('/verification-email');

      dispatch(SetAuthState({
        field: 'forgotPasswordEmailSent',
        value: false
      }));
    }
  }, [forgotPasswordEmailSent]);

  return (
    <>
      {loading && <Loader />}
      <AuthFormWrapper
        heading={t('forgot_password')}
        subtext={t('forgot_password_phrase')}
      >
        <Input
          authInput
          label={t('email')}
          className="form-input"
          type="email"
          helperText={helperText.email}
          value={formData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('email', e.target.value);
          }}
        />
        <Button
          text={t('send_email')}
          type="primary"
          width="100%"
          borderRadius="12px"
          disabled={false}
          className="custom-button forgot-pass-btn"
          onClick={handleButtonClick}
        />
        <div className="ba-text-align-center">
          <p>
            {t('take_me_back_to')}
            <Link to="/sign-in">{t('sign_in')}</Link>
          </p>
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default ForgotPassword;
