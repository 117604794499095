import React from 'react';
import { useTranslation } from 'react-i18next';

import Heading from './heading-box';

// import ChooseEnvelopeA5 from '../../../../assets/images/choose-envelope.svg';
import ChooseEnvelopeClassic from '../../../../assets/images/envelope-classic-1.jpg';
import ChooseEnvelopePre from '../../../../assets/images/envelope-Premium-1.jpg';

import { DrawerWrapper } from '../drawer-style';

import { AddTemplate } from '../../../../redux/types/template';

interface ChooseEnvelopeProps {
  addTemplate: AddTemplate;
  setAddTemplate: (value: AddTemplate) => void;
  handleNext: () => void;
}

const ChooseEnvelope: React.FC<ChooseEnvelopeProps> = ({
  addTemplate,
  setAddTemplate,
  handleNext
}) => {
  const { t } = useTranslation();

  const envelope = [{
    image: ChooseEnvelopePre,
    id: t('marble'),
    title: t('premium'),
    secondTitle: t('marble_style'),
    description: t('marble_style_description')
  }, {
    image: ChooseEnvelopeClassic,
    id: t('white'),
    title: t('classic'),
    secondTitle: t('white_envelope'),
    description: t('white_envelope_description')
  }];

  return (
    <div className="add-scroll">
      <Heading
        title={t('choose_envelope')}
        infoDetails={t('envelop_info')}
      />
      <DrawerWrapper>
        <div className="d-flex gap-18 add-top-space">
          {envelope.map((letter, index) => (
            <div
              role="button"
              tabIndex={0}
              key={index}
              className={
                addTemplate?.envelopeColor === letter.id
                  ? 'advertisee-box choose-envelope  active'
                  : 'advertisee-box choose-envelope'
              }
              onClick={() => setAddTemplate({ ...addTemplate, envelopeColor: letter.id })}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNext();
                }
              }}
            >
              <h3 className="text-center">{letter.title}</h3>
              <img className="type" src={letter.image} alt={letter.title} />
              <div className="d-flex bottom-space-for-envelope ">
                <h4>{letter.secondTitle}</h4>
              </div>
              <span className="description">
                {letter.description}
              </span>
            </div>
          ))}
        </div>
      </DrawerWrapper>
    </div>
  );
};

export default ChooseEnvelope;
