import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BsPlayCircle } from 'react-icons/bs';

import Clone from '../../assets/icons/duplicate.svg';
import EditIcon from '../../assets/icons/edit.svg';
import BlockedIcon from '../../assets/icons/blocked.svg';
import Trash from '../../assets/icons/trash.svg';
import PauseCampaign from '../../assets/icons/pause-campaign.svg';
import MarkAsCanBeStarted from '../../assets/icons/mark-as-can-be-started.svg';

import { CampaignState } from '../../redux/types/campaign';
import { AuthState } from '../../redux/types/auth';

import {
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPES
} from '../../constants';

interface ActionButtonProps {
  campaignId: string;
  status: string;
  setClone?: (value: boolean) => void;
  setCloneCampaignId?: (value: string) => void;
  setStartCampaign?: (value: boolean) => void;
  setStartCampaignId?: (value: string) => void;
  setCompleteCampaignId: (value: string) => void;
  setDeleteCampaign?: (value: boolean) => void;
  setMarkAsCanBeStarted?: (value: boolean) => void;
  setCanBeStartedId?: (value: string) => void;
  setDeleteCampaignId?: (value: string) => void;
  setPauseOrStopCampaign?: (value: boolean) => void;
  setCompleteCampaign?: (value: boolean) => void;
  setEditCampaignId?: (value: string) => void;
  setCampaignAction?: (value: string) => void;
  setPauseOrStopCampaignId?: (value: string) => void;
  setPauseOrStopCampaignStatus?: (value: string) => void;
  setBlockCampaign?: (value: boolean) => void;
  setBlockCampaignId?: (value: string) => void;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  campaignId,
  status,
  setClone,
  setCloneCampaignId,
  setStartCampaign,
  setStartCampaignId,
  setCompleteCampaignId,
  setDeleteCampaign,
  setMarkAsCanBeStarted,
  setCanBeStartedId,
  setDeleteCampaignId,
  setPauseOrStopCampaignId,
  setCompleteCampaign,
  setEditCampaignId,
  setCampaignAction,
  setPauseOrStopCampaign,
  setPauseOrStopCampaignStatus,
  setBlockCampaign,
  setBlockCampaignId
}) => {
  const { t } = useTranslation();
  const { campaigns } = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { user } = useSelector((state: { auth: AuthState }) => state.auth);

  return (
    <div className="d-flex gap-1 align-items-center icons-box">
      {
        user?.role !== 'employee' && (
          <>
            {![CAMPAIGN_STATUS.ACTIVE,
              CAMPAIGN_STATUS.PENDING,
              CAMPAIGN_STATUS.COMPLETED,
              CAMPAIGN_STATUS.PROCESSED,
              CAMPAIGN_STATUS.DRAFT].includes(status)
              ? setStartCampaign
                && renderActionButton(
                  <BsPlayCircle className="pointer enable" />,
                  () => {
                    setStartCampaign?.(true);
                    if (setStartCampaignId) {
                      setStartCampaignId(campaignId);
                    }
                  }
                )
              : null}

              {setClone
              && campaigns?.find((campaign) => campaign._id === campaignId)?.type === CAMPAIGN_TYPES.AUTO
              && [CAMPAIGN_STATUS.PENDING,
                CAMPAIGN_STATUS.CAN_BE_STARTED,
                CAMPAIGN_STATUS.ACTIVE].includes(status)
                && renderActionButton(
                  <img className="pointer" width="14px" src={Clone} alt={t('no_check_icon')} />,
                  () => {
                    setClone?.(true);
                    if (setCloneCampaignId) {
                      setCloneCampaignId(campaignId);
                    }
                  }
                )}

              {campaigns?.find((campaign) => campaign._id === campaignId)?.type === CAMPAIGN_TYPES.AUTO
                && renderActionButton(
                  <img className="pointer" width="14px" src={EditIcon} alt={t('no_check_icon')} />,
                  () => {
                    setCampaignAction?.('edit');
                    if (setEditCampaignId && campaignId) {
                      setEditCampaignId(campaignId);
                    }
                  }
                )}

              {[CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.PAUSED].includes(status) && renderActionButton(
                <img width="14px" className="pointer" src={PauseCampaign} alt={t('no_del')} />,
                () => {
                  setPauseOrStopCampaign?.(true);
                  if (setPauseOrStopCampaignId) {
                    setPauseOrStopCampaignId(campaignId);
                  }
                  if (setPauseOrStopCampaignStatus) setPauseOrStopCampaignStatus(status);
                }
              )}

              {/* {campaigns?.find((campaign) => campaign._id === campaignId)?.type === CAMPAIGN_TYPES.AUTO
              && [CAMPAIGN_STATUS.ACTIVE].includes(status) && renderActionButton(
                <img
                  width="14px"
                  className={status === CAMPAIGN_STATUS.COMPLETED ? 'disable' : 'pointer'}
                  src={Check}
                  alt={t('no_del')}
                />,
                () => {
                  setCompleteCampaign?.(true);
                  if (setCompleteCampaignId) {
                    setCompleteCampaignId(campaignId);
                  }
                }
              )} */}

            {![CAMPAIGN_STATUS.ACTIVE].includes(status) ? setDeleteCampaign
              && renderActionButton(
                <img width="14px" className="pointer" src={Trash} alt={t('no_del')} />,
                () => {
                  setDeleteCampaign?.(true);
                  if (setDeleteCampaignId) {
                    setDeleteCampaignId(campaignId);
                  }
                }
              )
              : null}
          </>
        )
      }

      {
        user?.role === 'employee' && (
          <>
            {renderActionButton(
              <img width="14px" className="pointer" src={MarkAsCanBeStarted} alt={t('no_del')} />,
              () => {
                setMarkAsCanBeStarted?.(true);
                if (setCanBeStartedId) {
                  setCanBeStartedId(campaignId);
                }
              }
            )}
            {renderActionButton(
              <img className="pointer" width="14px" src={EditIcon} alt={t('no_check_icon')} />,
              () => {
                const campaign = campaigns?.find((camp) => camp._id === campaignId);

                if (campaign?.type === CAMPAIGN_TYPES.AUTO) {
                  setCampaignAction?.('edit');
                  if (setEditCampaignId && campaignId) {
                    setEditCampaignId(campaignId);
                  }
                } else {
                  const url = `/templates?templateId=${campaign?.templateId}`;
                  window.open(url, '_blank');
                }
              }
            )}
            {renderActionButton(
              <img width="14px" className="pointer" src={BlockedIcon} alt={t('no_del')} />,
              () => {
                setBlockCampaign?.(true);
                if (setBlockCampaignId) {
                  setBlockCampaignId(campaignId);
                }
              }
            )}
          </>
        )
      }
    </div>
  );
};

export default ActionButtons;
