import styled from 'styled-components';

const SwitchWrapper = styled.div`
    label{
        font-size:12px;
        color:#5D5D5D;
        margin-right:8px;
    }
    .ant-switch-checked{
        background:#E2C786;

    }
    :where(.css-dev-only-do-not-override-1whptik).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
          background:#E2C786;  
    }
`;
export default SwitchWrapper;
