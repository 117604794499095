import React, {
  ReactNode
} from 'react';
import {
  Select,
  Popover,
  TreeSelect
} from 'antd';
import { useTranslation } from 'react-i18next';
import type { AntTreeNodeProps } from 'antd/es/tree';

import ChatInfo from '../../assets/images/chat-info.svg';
import ArrowDown from '../../assets/icons/select-icon.svg';
import ArrowUp from '../../assets/icons/arrow-up.svg';

import SelectWrapper from './select.style';

interface CustomSelectProps {
  defaultValue?: string | number;
  onChange?: (value: any) => void;
  options?: any;
  placeholder?: string;
  width?: string;
  label?: string;
  helperText?: string;
  className?: string;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  value?: any;
  marginBottom?: string | number,
  content?: ReactNode,
  tooltip?: boolean,
  enableCustomRender?: boolean,
  treeSelect?: boolean,
  labelVertical?: boolean,
  renderOption?: any,
  dropDownData?: any
  showSearch?: boolean
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  defaultValue,
  onChange,
  options,
  placeholder,
  width,
  label,
  className,
  mode,
  disabled,
  value,
  marginBottom,
  content,
  tooltip,
  helperText,
  treeSelect,
  enableCustomRender,
  renderOption,
  labelVertical,
  dropDownData,
  showSearch
}) => {
  const { t } = useTranslation();
  const { SHOW_PARENT } = TreeSelect;

  const switcherIcon = (props: AntTreeNodeProps) => (
    <img
      src={props.expanded ? ArrowUp : ArrowDown}
      alt={props.expanded ? 'collapse-arrow' : 'expand-arrow'}
    />
  );

  const tProps = {
    treeData: dropDownData,
    value,
    onChange,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: t('Choose'),
    switcherIcon
  };

  return (
    <SelectWrapper width={width} marginBottom={marginBottom} labelVertical={labelVertical}>
      {label && (
        <label>
          {label}
          {tooltip
            && (
              <Popover className="control-width pl-5" content={content}>
                <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
              </Popover>
            )}
        </label>
      )}
      <div className={className}>
        {treeSelect
          ? (
            <TreeSelect
              suffixIcon={<img src={ArrowDown} alt={t('no_icon')} />}
              {...tProps}
              onChange={onChange}
            />
          )
          : (
            <Select
              defaultValue={defaultValue}
              onChange={onChange}
              options={dropDownData || options}
              suffixIcon={<img src={ArrowDown} alt={t('no_icon')} />}
              placeholder={placeholder}
              mode={mode}
              disabled={disabled}
              value={value}
              showSearch={showSearch}
              optionRender={enableCustomRender && renderOption}
            />
          )}
        {helperText && <label className="helper-text" style={{ margin: '-6px' }}>{helperText}</label>}
      </div>
    </SelectWrapper>
  );
};

export default CustomSelect;
