import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Button from '../../../components/button';

import PassChange from '../../../assets/images/password-change-image.svg';

const PasswordChanged: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/sign-in');
  };

  return (
    <AuthFormWrapper
      heading={t('password_changed')}
      subtext={t('password_changed_phrase')}
    >
      <div className="ba-text-align-center">
        <img className="auth-pass-change-img" src={PassChange} alt={t('email')} />
        <Button
          text={t('sign_in')}
          type="primary"
          width="100%"
          borderRadius="12px"
          disabled={false}
          className="custom-button reset-pass-btn"
          onClick={handleButtonClick}
        />
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordChanged;
