/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  Tooltip
} from 'antd';

import EditIcon from '../../../assets/icons/edit.svg';

import Button from '../../button';
import Input from '../../input';
import Loader from '../../loader';
import Table from '../../table';
import { ChooseProductSearchComponent } from '../../search-column-header';

import {
  GetCampaignById,
  SetCampaignState
} from '../../../redux/slices/campaign';
import {
  EditNickName,
  GetProductsForAutoCampaign,
  SetProductState
} from '../../../redux/slices/product';
import { SetOtherNotifyState } from '../../../redux/slices/other';

import { CampaignState } from '../../../redux/types/campaign';
import {
  Product,
  ProductFilters,
  ProductState,
  SortBy
} from '../../../redux/types/product';

import { AUTH_FORMS_ERROR_MESSAGES } from '../../../constants';

interface AddProductProps {
  clonedCampaign: any;
  editCampaignId?: string;
  setProductsData: any;
  productsData: any;
  step: number;
}
interface CellRendererProps {
  data: {
    _id: string;
    imageUrl: string;
    title: string;
    selected: boolean;
    nickName?: string;
  };
}

interface NickNameCellRendererProps {
  content: React.ReactNode;
  product: Product;
}

const Products: React.FC<AddProductProps> = ({
  clonedCampaign,
  editCampaignId,
  setProductsData,
  productsData,
  step
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const {
    isNickNameEdited,
    productsForAutoCampaign: products,
    products: allProducts,
    selectedProductIds,
    loading
  } = useSelector((state: { product: ProductState }) => state.product);
  const { newCampaign } = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);

  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [filters, setFilters] = useState<ProductFilters>({
    searchByKeyWords: {
      title: '',
      sellerSku: '',
      asin: '',
      parentAsin: '',
      nickName: ''
    }
  });

  const handleSelectedRowData = (id: string, value: any) => {
    let productIds = [];
    if (productsData?.length) {
      const selectedProducts = productsData?.map((obj: any) => {
        if (obj._id === id) {
          return {
            ...obj,
            selected: value
          };
        }

        return { ...obj };
      });

      const selectedIds = selectedProducts.filter((obj: any) => obj.selected);
      dispatch(SetProductState({
        field: 'selectedProductIds',
        value: selectedIds
      }));
      setProductsData(selectedProducts);

      productIds = selectedProducts?.filter((obj:any) => obj.selected);
      productIds = productIds.map((obj: any) => obj._id);
    }

    dispatch(SetCampaignState({
      field: 'newCampaign',
      value: {
        ...newCampaign,
        productIds
      }
    }));
  };

  const NickNameCellRenderer: React.FC<NickNameCellRendererProps> = ({
    content,
    product
  }) => {
    const { t } = useTranslation();

    if (product?.nickName) {
      return (
        product?.nickName.length > 23 ? (
          <>
            <Tooltip title={product?.nickName}>
              {product?.nickName?.substring(0, 23)}
              ...
            </Tooltip>
            <Popover trigger="click" content={content} placement="left">
              <img
                src={EditIcon}
                alt={t('no_icon')}
                className="pointer"
              />
            </Popover>
          </>
        ) : (
          <div className="d-flex gap-1 align-items-center">
            {product?.nickName}
            <Popover trigger="click" content={content} placement="left">
              <img
                src={EditIcon}
                alt={t('no_icon')}
                className="pointer"
              />
            </Popover>
          </div>
        )
      );
    }

    return 'N/A';
  };

  NickNameCellRenderer.displayName = 'NickNameCellRenderer';

  const ContentComponent: React.FC<{ product: Product }> = ({ product }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
      nickName: product?.nickName || ''
    });
    const [formHelperText, setFormHelperText] = useState({
      nickName: ''
    });

    const validateField = (
      fieldName: keyof typeof formData,
      value: string
    ): Partial<typeof formHelperText> => {
      const errors: Partial<typeof formHelperText> = {};

      if (value.trim() === '') {
        errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
      }

      return errors;
    };

    const handleInputChange = (
      fieldName: keyof typeof formData,
      value: string
    ) => {
      const errors = validateField(fieldName, value);

      setFormData({
        ...formData,
        [fieldName]: value
      });

      setFormHelperText({
        ...formHelperText,
        [fieldName]: errors[fieldName] as string
      });
    };

    const handleSubmit = () => {
      const nickNameError = validateField('nickName', formData.nickName).nickName;

      if (nickNameError) {
        setFormHelperText({
          nickName: nickNameError
        });
        return;
      }

      if (product?.nickName === formData.nickName) {
        dispatch(SetOtherNotifyState({
          message: t('no_changes_made'),
          type: 'info'
        }));

        return;
      }

      dispatch(EditNickName({
        productId: product?._id as string,
        nickName: formData.nickName
      }));
    };

    return (
      <div>
        <h4 className="color-secondary m-0 add-bottom-space">{t('update_nickname')}</h4>
        <Input
          placeholder={t('enter')}
          marginBottom="20px"
          helperText={formHelperText.nickName}
          value={formData.nickName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('nickName', e.target.value);
          }}
        />
        <Button
          type="primary"
          text={t('update')}
          width="100%"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const createCellRenderer = (product: Product) => {
    const content = <ContentComponent product={product} />;
    const CellRenderer = () => <NickNameCellRenderer content={content} product={product} />;
    CellRenderer.displayName = 'CellRenderer';
    return <CellRenderer />;
  };

  const ProductCellRenderer:React.FC<CellRendererProps> = ({ data }) => {
    const {
      _id: id,
      imageUrl,
      title = 'N/A',
      selected = false,
      nickName
    } = data || {};

    const { t } = useTranslation();

    return (
      <div className="product-box">
        {nickName ? (
          <input
            type="checkbox"
            checked={selected}
            onChange={(e) => handleSelectedRowData(id, e.target.checked)}
          />
        ) : null}
        <img src={imageUrl} alt={t('no_product')} width="30px" height="30px" />
        {title?.length > 23 ? (
          <Tooltip title={title}>
            <span className="ellipsis-added">
              {title}
            </span>
          </Tooltip>
        ) : <span className="ellipsis-added">{title}</span>}
      </div>
    );
  };

  const getProductsForAutoCampaign = ({ applyFilters = filters }) => {
    dispatch(
      GetProductsForAutoCampaign({
        editCampaignId,
        sortBy: sortValue || undefined,
        filters: applyFilters
      })
    );
  };

  const columnDefs = [
    {
      headerName: t('product_name'),
      sortable: true,
      field: 'title',
      headerComponent: ChooseProductSearchComponent,
      pinned: 'left',
      minWidth: 300,
      cellRenderer: ProductCellRenderer,
      headerComponentParams: {
        setSortValue,
        fieldName: 'title',
        getProduct: getProductsForAutoCampaign,
        filters,
        setFilters,
        showArrow: sortValue?.title === 'asc'
          ? 'up'
          : sortValue?.title === 'desc'
            ? 'down'
            : null
      },
      flex: 1
    },
    {
      headerName: t('nick_name'),
      field: 'nickName',
      headerComponent: ChooseProductSearchComponent,
      headerComponentParams: {
        tooltip: true,
        setSortValue,
        fieldName: 'nickName',
        getProduct: getProductsForAutoCampaign,
        filters,
        setFilters,
        showArrow: sortValue?.nickName === 'asc'
          ? 'up'
          : sortValue?.nickName === 'desc'
            ? 'down'
            : null
      },
      minWidth: 180,
      flex: 1,
      cellRenderer: ({ data: product }: { data: Product }) => createCellRenderer(product)
    },
    {
      headerName: t('sku'),
      field: 'sellerSku',
      headerComponent: ChooseProductSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'sellerSku',
        getProduct: getProductsForAutoCampaign,
        filters,
        setFilters,
        showArrow: sortValue?.sellerSku === 'asc'
          ? 'up'
          : sortValue?.sellerSku === 'desc'
            ? 'down'
            : null
      },
      minWidth: 180,
      flex: 1
    },
    {
      headerName: t('asin'),
      field: 'asin',
      sortable: true,
      headerComponent: ChooseProductSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'asin',
        getProduct: getProductsForAutoCampaign,
        filters,
        setFilters,
        showArrow: sortValue?.asin === 'asc'
          ? 'up'
          : sortValue?.asin === 'desc'
            ? 'down'
            : null
      },
      minWidth: 180,
      flex: 1
    },
    {
      headerName: t('parent_asin'),
      pinned: 'right',
      sortable: true,
      field: 'parentAsin',
      headerComponent: ChooseProductSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'parentAsin',
        getProduct: getProductsForAutoCampaign,
        filters,
        setFilters,
        showArrow: sortValue?.parentAsin === 'asc'
          ? 'up'
          : sortValue?.parentAsin === 'desc'
            ? 'down'
            : null
      },
      minWidth: 180,
      flex: 1
    }
  ];

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    getProductsForAutoCampaign({ applyFilters: filters });
  }, [sortValue, step, clonedCampaign]);

  useEffect(() => {
    if (products.length && (newCampaign || clonedCampaign)) {
      const rowsData = products?.map((prod: any) => {
        const selectedIds = selectedProductIds?.map((obj: any) => obj._id);
        let matched = false;
        if (selectedIds?.length) {
          matched = selectedIds?.includes(prod._id);
        } else if (clonedCampaign?.productIds?.length) {
          matched = clonedCampaign?.productIds?.includes(prod._id);
        } else if (newCampaign?.productIds?.length) {
          matched = newCampaign?.productIds?.includes(prod._id);
        }

        return {
          ...prod,
          selected: matched
        };
      });

      setProductsData(rowsData);
    } else if (editCampaignId) {
      dispatch(GetCampaignById({
        campaignId: editCampaignId
      }));
      setProductsData(products);
    } else {
      setProductsData(products);
    }

    setIsDataRetrievalSuccessful(true);
  }, [products]);

  useEffect(() => {
    if (isNickNameEdited) {
      getProductsForAutoCampaign({ applyFilters: filters });
    }
  }, [isNickNameEdited]);

  useEffect(() => {
    if (allProducts.length) {
      setProductsData(allProducts);
    }
  }, [allProducts]);

  useEffect(() => {
    if (newCampaign) {
      const rowsData = products?.map((prod: any) => {
        const matched = newCampaign?.productIds?.includes(prod._id);

        return {
          ...prod,
          selected: matched
        };
      });

      setProductsData(rowsData);
    }
  }, [newCampaign]);

  return (
    <>
      {loading && <Loader />}
      <div className="content-header">
        <h2 className="heading">{t('choose_products')}</h2>
      </div>
      <Table
        rowData={productsData}
        columnDefs={columnDefs}
        sortable={false}
        resizable
        editable={false}
        filter={false}
        height="325px"
        rowHeight={50}
        headerHeight={40}
        isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        className="custom-table"
      />
    </>
  );
};

export default Products;
