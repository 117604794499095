/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  camelCase,
  isEmpty,
  startCase
} from 'lodash';
import moment from 'moment';
import {
  Row,
  Col,
  Space
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import Document from '../../../assets/icons/document-text.svg';
import TickCircle from '../../../assets/icons/tick-circle.svg';
import Trash from '../../../assets/icons/trash-1.svg';

import Button from '../../../components/button';
import Upload from '../../../components/upload';
import Input from '../../../components/input';
import Loader from '../../../components/loader';
import ModalUpload from '../../../components/modal';
import Progress from '../../../components/progress';
import QuickTutorial from '../../../components/modal/quick-tutorial';
import VideoPlayer from '../../../components/modal/video-player';
import Select from '../../../components/select';

import {
  CheckFileExtension,
  DownloadSampleFile,
  ExtractHeadersFromFile,
  FormatColumnName,
  UploadDocumentOnS3,
  ValidateHeaders
} from '../../../utils/helpers';

import {
  GetS3PreSignedUrl,
  SetOtherState,
  SetOtherNotifyState
} from '../../../redux/slices/other';

import {
  AddManualCampaign,
  SetCampaignState
} from '../../../redux/slices/campaign';

import { GetAllTemplatesWithVariables } from '../../../redux/slices/template';
import { CampaignState } from '../../../redux/types/campaign';
import { OtherState } from '../../../redux/types/other';
import { TemplateState } from '../../../redux/types/template';
import { UserState } from '../../../redux/types/user';

import {
  ADD_CAMPAIGN_INITIAL_STATE,
  BUCKET_NAME_VALUES,
  FILES_EXTENSION_LIST,
  SAMPLE_FILE_NAMES,
  SHEETS_MANDATORY_COLUMNS
} from '../../../constants';

interface AddCampaign {
  name: string;
  templateId: string;
  fileKey: string;
}

interface HelperText {
  [key: string]: string | undefined;
}

interface ModalProps {
  onCancel: () => void;
  open?: boolean;
  uploadNickname?: boolean;
  title?: string;
}

interface Options {
  label: string,
  value: string,
  variables: string[]
}

const Modal: React.FC<ModalProps> = ({
  onCancel, open, uploadNickname, title
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    preSignedUrl,
    preSignedUrlLoading,
    fileUploadKey
  } = useSelector((state: { other: OtherState }) => state.other);

  const {
    templatesWithVariables
  } = useSelector((state: { template: TemplateState }) => state.template);

  const {
    isCampaignAdded,
    addCampaignLoading
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [progress, setProgress] = useState<number>(0);
  const [attachment, setAttachment] = useState<any | undefined>(undefined);
  const [addCampaign, setAddCampaign] = useState<AddCampaign>(ADD_CAMPAIGN_INITIAL_STATE);
  const [addCampaignHelperText, setAddCampaignHelperText] = useState<HelperText>({});
  const [videoPlayerModal, setVideoPlayerModal] = useState<boolean>(false);
  const [videoPlayerModalLink, setVideoPlayerModalLink] = useState<string>('');
  const [options, setOptions] = useState<Options[]>([{
    label: '',
    value: '',
    variables: []
  }]);
  const [sampleFileColumns, setSampleFileColumns] = useState<string[]>([]);

  const addManualCampaign = () => {
    dispatch(AddManualCampaign({ addCampaign }));
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e?.target;
    setAddCampaign({ ...addCampaign, name: value });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'name') errors[name] = `${startCase(name)} is required!`;
    else if (!isEmpty(value) && name === 'name') {
      errors[name] = '';
    }
    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const handleSelectChange = (value: string, name: string) => {
    setAddCampaign({ ...addCampaign, [name]: value, fileKey: '' });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'templateId') errors[name] = t('template_is_required');
    else if (!isEmpty(value) && name === 'templateId') {
      errors[name] = '';
    }

    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const handleUpload = () => {
    const campaignErrors: Partial<Record<string, string>> = {};

    if (addCampaign.name === '') {
      campaignErrors.name = t('name_is_required');
    }
    if (addCampaign.templateId === '') {
      campaignErrors.templateId = t('template_is_required');
    }
    if (addCampaign.fileKey === '') {
      campaignErrors.fileKey = t('file_is_required');
    }

    const hasErrors = Object.keys(campaignErrors).length > 0;
    if (!hasErrors) {
      addManualCampaign();
    } else {
      setAddCampaignHelperText((prevHelperText: any) => ({
        ...prevHelperText,
        ...campaignErrors
      }));
    }
  };

  const handleChangeAttachment = async (info: UploadChangeParam, uploadBucket: string) => {
    setProgress(0);
    setAddCampaign({ ...addCampaign, fileKey: '' });
    const { file } = info;
    if (!file?.status) {
      const { name } = file;
      const extension = name.split('.').pop();

      const notValidExtension = CheckFileExtension({
        extension: extension as string,
        extensionList: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? FILES_EXTENSION_LIST : [],
        attachmentType: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? 'files' : ''
      });
      if (notValidExtension) {
        dispatch(SetOtherNotifyState({
          message: notValidExtension,
          type: 'error'
        }));
        return;
      }

      let headers = await ExtractHeadersFromFile(file);
      headers = headers.map((header: string) => camelCase(header.replace('\r', '')));

      const pattern = options?.find((item) => item?.value === addCampaign?.templateId)?.variables ?? [];
      const concatenatedPattern = [...pattern, ...SHEETS_MANDATORY_COLUMNS];
      let formattedColumns = concatenatedPattern.map((column) => FormatColumnName(column));
      formattedColumns = formattedColumns.map((column) => camelCase(column));
      let extraHeaders = formattedColumns.filter((header: string) => !headers.includes(camelCase(header)));
      extraHeaders = extraHeaders.map((header) => camelCase(header));
      extraHeaders = extraHeaders.filter((header: string) => ['firstName', 'lastName'].includes(camelCase(header)));

      const isValidHeaders = ValidateHeaders([...extraHeaders, ...headers], formattedColumns);
      if (!isValidHeaders) {
        dispatch(SetOtherNotifyState({
          message: t('error_in_sheet_header_format'),
          type: 'error'
        }));
        return;
      }
      setAttachment(file);
      const fileName = name.split('.')[0];
      const formattedFileName = fileName.replace(/\s/g, '-');
      dispatch(GetS3PreSignedUrl({
        fileName: `${formattedFileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}.${extension}`,
        fileType: file.type || '',
        fileExtension: extension || '',
        uploadBucket,
        id: ''
      }));
    }
  };

  const handleUploadAttachmentOnS3 = async () => {
    if (attachment) {
      try {
        await UploadDocumentOnS3({
          preSignedUrl,
          fileToUpload: attachment,
          onProgress: (prog: number) => {
            setProgress(Math.round(prog));
          }
        });
      } catch (error) {
        dispatch(SetOtherNotifyState({
          message: t('file_uploading_failed_on_S3'),
          type: 'error'
        }));
      }
    }
  };

  const resetValues = () => {
    setSampleFileColumns([]);
    setProgress(0);
    onCancel();
    setAddCampaign(ADD_CAMPAIGN_INITIAL_STATE);
    setAddCampaignHelperText({});
  };

  const handleDelete = () => {
    if (progress === 100) {
      setAddCampaign({ ...addCampaign, fileKey: '' });
    }
  };

  const getAllTemplatesWithVariables = () => {
    dispatch(GetAllTemplatesWithVariables());
  };

  const handleVideoPlayer = () => {
    setVideoPlayerModal(true);

    if (user?.language === 'en') setVideoPlayerModalLink('https://www.youtube.com/watch?v=topBLaz4zgk');
    else if (user?.language === 'de') setVideoPlayerModalLink('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');
  };

  useEffect(() => {
    if (preSignedUrl !== '') {
      if (!isEmpty(addCampaign?.fileKey)) {
        dispatch(SetOtherNotifyState({
          message: t('file_replaced_message'),
          type: 'success'
        }));
      }
      if (!isEmpty(fileUploadKey)) {
        setAddCampaign({ ...addCampaign, fileKey: fileUploadKey });
        setAddCampaignHelperText({ ...addCampaignHelperText, fileKey: '' });
      }
      handleUploadAttachmentOnS3();
      dispatch(SetOtherState({
        field: 'preSignedUrl',
        value: ''
      }));
    }
  }, [preSignedUrl]);

  useEffect(() => {
    if (open) {
      getAllTemplatesWithVariables();
    }
  }, [open]);

  useEffect(() => {
    if (templatesWithVariables?.length) {
      const optionsData = templatesWithVariables?.map((item) => ({
        label: item?.name,
        value: item?._id,
        variables: item?.variables
      }));
      if (optionsData?.length) {
        setOptions(optionsData);
      }
    }
  }, [templatesWithVariables]);

  useEffect(() => {
    if (!isEmpty(addCampaign?.templateId)) {
      const findTemplate = templatesWithVariables?.find((item) => item?._id === addCampaign?.templateId);
      setSampleFileColumns(findTemplate?.variables || []);
    }
  }, [addCampaign?.templateId]);

  useEffect(() => {
    if (isCampaignAdded) {
      resetValues();
      SetCampaignState({
        field: 'isCampaignAdded',
        value: false
      });
      dispatch(SetCampaignState({
        field: 'campaigns',
        value: []
      }));
    }
  }, [isCampaignAdded]);

  const titleHtml = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <p
        style={{
          margin: 0,
          color: 'black',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '1.5',
          wordWrap: 'break-word'
        }}
      >
        {t('upload_one_time_campaign')}
      </p>
      <Button
        type="default"
        onClick={() => handleVideoPlayer()}
        text={t('tutorial_link_text')}
      />
    </div>
  );

  return (
    <>
      <QuickTutorial
        open={false}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
      <ModalUpload
        footer
        cancelText={t('cancel')}
        onCancelClick={resetValues}
        onSubmitClick={handleUpload}
        saveText={t('upload')}
        width={513}
        open={open}
        title={title || titleHtml}
      >
        {(preSignedUrlLoading || addCampaignLoading) && <Loader />}
        <Row gutter={16}>
          {uploadNickname ? '' : (
            <>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Input
                  name="name"
                  label={t('modal_campaign_name')}
                  placeholder={t('enter')}
                  className="form-input add-employee-input"
                  value={addCampaign?.name}
                  onChange={(e: any) => handleChange(e)}
                  helperText={addCampaignHelperText.name}
                  marginBottom="24px"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Select
                  label={t('template')}
                  placeholder={t('select')}
                  marginBottom="8px"
                  options={options}
                  value={options?.find((item) => item?.value === addCampaign?.templateId)?.label}
                  onChange={(value) => {
                    handleSelectChange(value, 'templateId');
                  }}
                  helperText={addCampaignHelperText.templateId}
                />
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Upload
              handleChangeAttachment={(info: UploadChangeParam) => handleChangeAttachment(
                info,
                BUCKET_NAME_VALUES.campaign_files
              )}
              handleDownloadSampleFile={() => {
                DownloadSampleFile({
                  type: 'manual-campaign',
                  columnsToDownload: sampleFileColumns,
                  mandatoryColumns: SHEETS_MANDATORY_COLUMNS,
                  sampleFileName: SAMPLE_FILE_NAMES.CAMPAIGN_SAMPLE_FILE_NAME
                });
              }}
              templateId={addCampaign?.templateId}
              uploadNickname={uploadNickname}
            />
            {addCampaignHelperText.fileKey && (
            <div className="file-upload-helper-text">{addCampaignHelperText.fileKey}</div>
            )}
            {addCampaign?.fileKey
              ? (
                <div className="progress-box">
                  <div className="d-flex justify-content-between add-space">
                    <Space align="center">
                      <img src={Document} alt={t('no_document')} />
                      <span className="label">
                        {attachment?.name}
                      </span>
                    </Space>
                    <div
                      role="button"
                      tabIndex={0}
                      className="cursor-pointer"
                      onClick={handleDelete}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleDelete();
                        }
                      }}
                    >
                      <img
                        src={progress === 100 ? Trash : TickCircle}
                        alt={t('no_document')}
                      />
                    </div>
                  </div>
                  {progress !== 100 && <Progress percent={progress} />}
                </div>
              ) : null}
          </Col>
        </Row>
      </ModalUpload>
    </>
  );
};

export default Modal;
