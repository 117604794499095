import React from 'react';
import { Radio } from 'antd';

import ButtonGroupWrapper from './style';

interface Option {
  label: string;
  value: string | number;
}

interface ButtonGroupProps {
  options?: Option[];
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: any) => void;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  options, value, defaultValue, onChange
}) => (
  <ButtonGroupWrapper>
    <Radio.Group
      options={options}
      value={value}
      defaultValue={defaultValue}
      optionType="button"
      buttonStyle="solid"
      onChange={onChange}
    />
  </ButtonGroupWrapper>
);

export default ButtonGroup;
