import styled from 'styled-components';

interface SelectWrapperProps {
  width?: string;
  labelVertical?: boolean;
  authInput?: string;
  marginBottom?: string | number;
  children?: React.ReactNode;
}

const SelectWrapper = styled.div<SelectWrapperProps>`
   width: ${(props) => (props.width ? props.width : '100%')};
   position:relative;
   display: ${(props) => (props.labelVertical ? 'flex' : '')};
   align-items: ${(props) => (props.labelVertical ? 'center' : '')};
   .ant-select {
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '12px')};
    &:not(.ant-select-customize-input) .ant-select-selector{
      border: 1px solid ${({ theme }) => theme.colors.fieldBorderColor};
      border-radius: 4px;
      font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    }
    &:not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
      border: 1px solid ${({ theme }) => theme.colors.fieldBorderColor};
    }
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.colors.placeholderColor};
    }
    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
  .helper-text {
    position: absolute;
    font-size: 10px;
      color: ${({ theme }) => theme.colors.errorColor};
    position:absolute;
    bottom: -3px;
    left: 7px;
}
  label{
    color: ${({ theme }) => theme.colors.labelColor};
    display: inline-block;
    margin-bottom:6px;
    white-space: ${(props) => (props.labelVertical ? 'nowrap' : '')};
    margin-right: ${(props) => (props.labelVertical ? '8px' : '')};
  }
  &.fields-select {
    .ant-select {
      &:not(.ant-select-customize-input) .ant-select-selector{
        border-color: ${({ theme }) => theme.colors.dividerColor};
        font-size: ${({ theme }) => theme.fonts.baseFontSize};
        border-radius: 2px;
      }
      &:not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
        border: 1px solid ${({ theme }) => theme.colors.dividerColor};
      }
    }
  }
  span.tooltip.control-width.pl-5 {
    padding-left: 5px;
  }
  .assign-employee-input{
    .ant-select {
    width: 100%;
    margin-bottom: 10px;
}
  }
`;

SelectWrapper.displayName = 'SelectWrapper';

const CustomSelectWrapper: React.FC<SelectWrapperProps> = ({
  children,
  ...rest
}) => (
  <SelectWrapper {...rest}>
    {children}
  </SelectWrapper>
);

export default CustomSelectWrapper;
