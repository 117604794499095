import React from 'react';
import ReactPlayer from 'react-player';

import { useTranslation } from 'react-i18next';

import CrossIcon from '../../assets/icons/cross.svg';

import Modal from './index';

interface QuickTutorialProps {
  onCancel?: () => void;
  onClick?: (status: string) => void;
  open?: boolean;
  link?: string;
}

const VideoPlayer: React.FC<QuickTutorialProps> = ({
  onCancel,
  onClick,
  open,
  link
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancelClick={() => {
        if (onCancel) {
          onCancel();
        }
      }}
      width={550}
      open={open}
    >
      <div
        className="cross-icon"
        role="button"
        tabIndex={0}
        onClick={onCancel}
        onKeyDown={onCancel}
      >
        <img src={CrossIcon} alt={t('no_cross_icon')} />
      </div>
      <ReactPlayer url={link} width="520px" controls />
    </Modal>
  );
};

export default VideoPlayer;
