import { css, Interpolation } from 'styled-components';

interface MediaQueries {
  [key: string]: (
    strings: TemplateStringsArray,
    ...interpolations: Array<Interpolation<React.HTMLAttributes<HTMLElement>>>
  ) => Interpolation<React.HTMLAttributes<HTMLElement>>;
}

interface Breakpoints {
  [key: string]: string;
}

const breakpoints: Breakpoints = {
  sm: '575px',
  md: '767px',
  lg: '991px',
  xl: '1199px',
  xxl: '1399px'
};

const media: MediaQueries = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (
    strings: TemplateStringsArray,
    ...interpolations: Array<Interpolation<React.HTMLAttributes<HTMLElement>>>
  ) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(strings, ...interpolations)}
    }
  `;
  return acc;
}, {} as MediaQueries);

export default media;
