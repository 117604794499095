import React, {
  ReactNode
} from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import WarningModalWrapper from '../../../components/warning-modal/warning.style';

import ReInviteIcon from '../../../assets/icons/re-invite.svg';

interface ReInviteProps {
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  desc?: string;
  heading?: string;
  icon?: ReactNode;
  loading?: boolean;
}

const ReInviteModal: React.FC<ReInviteProps> = ({
  open,
  onConfirm,
  onCancel,
  desc,
  heading,
  icon,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} width="364px">
      {loading && <Loader />}
      <WarningModalWrapper>
        {icon || <img src={ReInviteIcon} alt={t('no_icon')} />}
        <h3>{heading || t('warning')}</h3>
        {desc && <span className="warning-desc">{desc}</span> }
        <div className="margin-top">
          <Space size="middle">
            <Button text={t('cancel')} type="default" onClick={onCancel} width="114px" />
            <Button text={t('confirm')} type="primary" width="119px" onClick={onConfirm} />
          </Space>
        </div>
      </WarningModalWrapper>
    </Modal>
  );
};

export default ReInviteModal;
