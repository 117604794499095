import React from 'react';

import WarningModal from '../../../components/warning-modal';

interface DeleteModalProps {
  loading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  open?: boolean;
  heading?: string;
  desc?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  loading,
  onConfirm,
  onCancel,
  heading,
  open,
  desc
}) => (
  <WarningModal
    loading={loading}
    onConfirm={onConfirm}
    onCancel={onCancel}
    open={open}
    heading={heading}
    desc={desc}
  />
);
export default DeleteModal;
