/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button/index';

import CloseIcon from '../../../assets/icons/close-icon.svg';
import StoreICon from '../../../assets/images/store.svg';

import { ConnectionWrapper } from './style';

import {
  GetAllStores,
  SetStoreState
} from '../../../redux/slices/store';

import { StoreState } from '../../../redux/types/store';

import {
  REGION_MAPPING,
  USER_ROLES
} from '../../../constants';

interface ConnectionsProps {
  onClose?:() => void;
  goProfile?:(event: React.MouseEvent<HTMLButtonElement>) => void;
  addStoreDrawer: boolean;
}

const APP_ID = process.env.REACT_APP_APPLICATION_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

const Connections: React.FC<ConnectionsProps> = ({
  addStoreDrawer,
  onClose,
  goProfile
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  const {
    totalStores,
    stores
  } = useSelector((state: { store: StoreState }) => state.store);

  const handleAddStore = (region: string) => {
    const state = nanoid(20);
    const payload = {
      state,
      region,
      appPage: location.pathname
    };

    dispatch(SetStoreState({ field: 'amazonStoreInfo', value: payload }));
    let url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=${REDIRECT_URI}`;

    if (region === 'eu') {
      url = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=${REDIRECT_URI}`;
    }

    if (region === 'fe') {
      url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=${REDIRECT_URI}`;
    }

    window.open(url, '_self');
  };

  useEffect(() => {
    if (addStoreDrawer) {
      dispatch(GetAllStores({}));
    }
  }, [addStoreDrawer]);

  return (
    <ConnectionWrapper>
      <div className="header">
        <div className="arrow-box">
          <div
            role="button"
            className="mt-3"
            tabIndex={0}
            onClick={onClose}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                if (onClose) {
                  onClose();
                }
              }
            }}
          >
            <img src={CloseIcon} alt="no-icon" className="pointer" />
          </div>
          <h3>{t('connections')}</h3>
        </div>
        <Button width="111px" text="Edit Profile" type="primary" onClick={goProfile} />
      </div>
      {currentUser?.role === USER_ROLES?.CUSTOMER && (
        <div className="store-added">
          <h5>{`${t('added_stores')} (${totalStores})`}</h5>
          <div className="added-store">
            {stores?.map((store: any, i: number) => (
              <div className="store" key={i}>
                <img src={StoreICon} alt="no-icon" />
                <div className="d-flex flex-direction store-details">
                  <span>{store?.name || 'N/A'}</span>
                  <span>{REGION_MAPPING[store?.region]}</span>
                </div>
              </div>
            ))}

          </div>
          <div className="add-store-btn">
            <Button
              text={t('add_store')}
              width="100%"
              type="primary"
              onClick={() => handleAddStore('eu')}
            />
          </div>
        </div>
      )}
    </ConnectionWrapper>
  );
};

export default Connections;
