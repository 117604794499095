import React from 'react';
import {
  Popover
} from 'antd';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../../../assets/images/chat-info.svg';

import Button from '../../../../components/button/index';
import Credit from '../../../../assets/icons/credit.svg';

import { HeadingBoxWrapper } from '../drawer-style';

interface HeadingBoxProps {
  title?: string;
  caption?: string;
  infoDetails?: string;
  buttonText?: string;
  width?:string;
  icon?:any
  onButtonClick?: () => void;
}

const HeadingBox: React.FC<HeadingBoxProps> = ({
  title,
  caption,
  infoDetails,
  buttonText,
  width,
  icon,
  onButtonClick
}) => {
  const { t } = useTranslation();

  const content = (
    <div className="max-width-269">
      <p>
        {infoDetails}
      </p>
    </div>
  );

  return (
    <HeadingBoxWrapper>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <span className="d-flex">
            <h3 className="add-heading-space">
              {title}
              {InfoIcon && (infoDetails || caption)
           && (
           <Popover className="tooltip-icon" content={content}>
             <span className="tooltip"><img src={InfoIcon} width="12px" height="12px" alt={t('no_info')} /></span>
           </Popover>
           ) }
            </h3>
          </span>
          <span>{caption}</span>
        </div>
        {buttonText && (
        <Button
          width={width || '120px'}
          icon={icon || <img src={Credit} alt={t('no_icon')} />}
          type="primary"
          text={buttonText}
          onClick={onButtonClick}
        />
        )}
      </div>
    </HeadingBoxWrapper>
  );
};

export default HeadingBox;
