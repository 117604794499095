import React, {
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useState } from 'react';
import { Popover } from 'antd';

import Button from '../../components/button';
import Input from '../../components/input';
import EditIcon from '../../assets/icons/edit.svg';
import Received from '../../assets/icons/received.svg';
import Trash from '../../assets/icons/trash.svg';

import { ReceiveMaxiInsert } from '../../redux/slices/maxi';
import {
  MaxiInsert,
  MaxiInsertState
} from '../../redux/types/maxi';
import { UserState } from '../../redux/types/user';

import { ApplyValidation } from '../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  MAXI_INSERTS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../constants';

interface ActionButtonProps {
  maxiInsertId?: string;
  setDeleteMaxi?: (value: boolean) => void;
  setMaxiInsertId?: (value: string) => void;
  setMaxiInsertAction?: (value: string) => void;
  setOpenMaxiLimitModal?: (value: boolean) => void;
  isPopoverOpen?: boolean;
  setIsPopoverOpen?: (value: boolean) => void;
  setOpenPopoverKey?: (value: string | null) => void;
  openPopoverKey?: string | null;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ContentComponent: React.FC<{
  maxiInsert: MaxiInsert,
  setOpenMaxiLimitModal: (value: boolean) => void,
  setIsPopoverOpen?: (value: boolean) => void,
  setOpenPopoverKey?: (value: string | null) => void
}> = ({
  maxiInsert,
  setOpenMaxiLimitModal,
  setIsPopoverOpen,
  setOpenPopoverKey
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMaxiInsertReceived } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);

  const [formData, setFormData] = useState({
    receivedQuantity: ''
  });
  const [formHelperText, setFormHelperText] = useState({
    receivedQuantity: ''
  });

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (value.toString().trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'receivedQuantity' && !ApplyValidation(REGEX_PATTERNS.MAXI_RECEIVED_QUANTITY_REGEX, value)) {
      errors[fieldName] = t(MAXI_INSERTS_ERROR_MESSAGES.INVALID_RECEIVED_QUANTITY);
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName] as string
    });
  };

  const handleSubmit = () => {
    // if (parseInt(formData.receivedQuantity, 10) > 2000) {
    //   setOpenMaxiLimitModal(true);
    //   setIsPopoverOpen?.(false);
    //   setOpenPopoverKey?.(null);
    //   return;
    // }
    // const receivedQuantityError = validateField('receivedQuantity', formData.receivedQuantity).receivedQuantity;

    // if (receivedQuantityError) {
    //   setFormHelperText({
    //     receivedQuantity: receivedQuantityError
    //   });
    //   return;
    // }

    setIsPopoverOpen?.(false);
    dispatch(ReceiveMaxiInsert({
      maxiInsertId: maxiInsert._id,
      receivedQuantity: formData.receivedQuantity
    }));
  };

  useEffect(() => {
    if (isMaxiInsertReceived) {
      setOpenPopoverKey?.(null);
    }
  }, [isMaxiInsertReceived]);

  return (
    <div>
      <h4 className="color-secondary m-0 add-bottom-space">{t('receive_quantity')}</h4>
      <Input
        placeholder={t('enter')}
        marginBottom="20px"
        helperText={formHelperText.receivedQuantity}
        value={formData.receivedQuantity}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange('receivedQuantity', e.target.value);
        }}
      />
      <Button
        type="primary"
        text={t('submit')}
        width="100%"
        onClick={handleSubmit}
      />
    </div>
  );
};

const ActionButtons: React.FC<ActionButtonProps> = ({
  maxiInsertId,
  setMaxiInsertId,
  setMaxiInsertAction,
  setDeleteMaxi,
  setOpenMaxiLimitModal,
  isPopoverOpen,
  setIsPopoverOpen,
  setOpenPopoverKey,
  openPopoverKey
}) => {
  const { t } = useTranslation();

  const handleOpenChange = (key: string, open: boolean) => {
    setOpenPopoverKey?.(open ? key : null);
    setIsPopoverOpen?.(open);
  };

  const { maxiInserts } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);
  const { user } = useSelector((state: { user: UserState }) => state.user);

  const content = (
    <ContentComponent
      maxiInsert={
        maxiInserts.find((maxiInsert) => maxiInsert._id === maxiInsertId) as MaxiInsert
      }
      setOpenMaxiLimitModal={setOpenMaxiLimitModal as (value: boolean) => void}
      setIsPopoverOpen={setIsPopoverOpen}
    />
  );

  return (
    <div className="d-flex gap-1 align-items-center icons-box">
      {user?.role !== 'customer' && renderActionButton(
        <Popover
          trigger="click"
          content={content}
          placement="left"
          open={openPopoverKey === maxiInsertId && isPopoverOpen}
          onOpenChange={(open) => handleOpenChange(maxiInsertId || '', open)}
        >
          <img
            className="pointer"
            width="14px"
            src={Received}
            alt={t('received_icon')}
          />
        </Popover>,
        () => {
          setMaxiInsertId?.(maxiInsertId || '');
        }
      )}
      {user?.role !== 'employee' && setMaxiInsertAction && renderActionButton(
        <img className="pointer" width="14px" src={EditIcon} alt={t('no_check_icon')} />,
        () => {
          setMaxiInsertId?.(maxiInsertId || '');
          setMaxiInsertAction('edit');
        }
      )}
      {user?.role !== 'employee' && setDeleteMaxi && renderActionButton(
        <img className="pointer" width="14px" src={Trash} alt={t('trash_icon')} />,
        () => {
          setMaxiInsertId?.(maxiInsertId || '');
          setDeleteMaxi(true);
        }
      )}
    </div>
  );
};

export default ActionButtons;
