import styled from 'styled-components';

const ProfileWrapper = styled.div`
h3{
    font-size:20px;
    margin-top: 3px;
}
.profile-box{
    background-color: ${({ theme }) => theme.colors.whiteColor} ;
    border:1px solid  ${({ theme }) => theme.colors.dividerColor};
    padding:40px;
    border-radius:8px 8px 0px 0px;
    padding-bottom:24px;
    .profile-img{
       background-color: ${({ theme }) => theme.colors.backgroundPrimary} ;  
       width:135px;
       height:135px;
       border-radius:50%;
       display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
       img{
            border-radius: 0px 0px 32px 33px;
          margin-top: 3px;
       }
       .edit-box{
        width:32px;
        height:32px;
        background-color: ${({ theme }) => theme.colors.whiteColor} ;
        border:1px solid  ${({ theme }) => theme.colors.dividerColor};
        border-radius:50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        bottom: -10px;
        cursor:pointer;
       }
    }
}
.profile-buttons{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    gap:16px;
    margin-top: 28px;
    margin-right: -10px;
}
`;

export default ProfileWrapper;
