import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/sf-pro-display/SFPRODISPLAYMEDIUM.OTF') format('opentype');
    font-weight:400;
  }
body {
    font-family: 'SF Pro Display', sans-serif!important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightBody};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }
  h1{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeH1};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightH1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 12px;
    margin-top: 0;
  }
  h2{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeH2};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightH2};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  h3{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeH3};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightH3};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  p{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightBody};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color:${({ theme }) => theme.colors.bodyColor} ;
    margin: 0;
  }
  button{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightSm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  label{
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    line-height: ${({ theme }) => theme.lineHeight.baseLineHeightXs};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }
  a{
    color: ${({ theme }) => theme.colors.iconLinkColor};
    text-decoration: none;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  // FOLLOWING CSS IS FOR CUSTOM GLOBAL STYLING
  .ba-w-100{
    width: 100% ;
  }
  .ba-text-align-center{
    text-align: center;
    p{
      color:${({ theme }) => theme.colors.placeholderColor} ;
    }
  }

  .w-100{
    width:100%;
  }
  .gap-1{
    gap:8px
  }
   .gap-11{
    gap:11px
  }
  .d-flex{
    display: flex;
  } 
  .justify-content-between{
    justify-content:space-between;
  }
  .align-items-center{
    align-items:center;
  }
  .flex-column{
    flex-direction:column;
  }
  .pointer{
    cursor:pointer;
  }

  // FOLLOWING CSS IS FOR ANT DESIGN COMPONENTS MANIPULATION

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
   .ant-checkbox-checked:not(.ant-checkbox-disabled) 
  .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.iconLinkColor};
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-inner, 
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.textFieldColor};
  }
  .ant-checkbox-wrapper{
    color: ${({ theme }) => theme.colors.bodyColor};
    gap: 6px;
    span{
      font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
      padding-left: 0;
      font-family: 'Inter', sans-serif ;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.colors.iconLinkColor};
        border-color: ${({ theme }) => theme.colors.iconLinkColor};
      }
    }
    .ant-checkbox{
      height: 12px;
      width: 12px;
      .ant-checkbox-inner{
        border-radius: 2px;
        height: 12px;
        width: 12px;
        &:after{
          width: 4px;
          height: 7px;
        }
      }
    }
  }
  .ant-btn{
    box-shadow: none;
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    &.ant-btn-primary{
      background-color: ${({ theme }) => theme.colors.iconLinkColor} ;
    }
    &.ant-btn-default{
      border-color: ${({ theme }) => theme.colors.iconLinkColor};
      color:  ${({ theme }) => theme.colors.iconLinkColor};
    }
  }
  .auth-password-popover{
    .ant-popover-arrow{
      display: none;
    }
    .ant-popover-content{
      .ant-popover-inner{
        border-radius: 4px;
        border: 1px solid  ${({ theme }) => theme.colors.textFieldColor};
        box-shadow: none;
        padding: 16px 16px 3px;
      }
    }
  }
  .ant-modal-content{
    .ant-modal-close{
      display: none;
    }
    .ant-modal-header{
      .ant-modal-title{
      margin-bottom: 16px;
      }
    }
    .file-upload-helper-text {
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
      line-height: 12px;
      color: ${({ theme }) => theme.colors.errorColor};
        position:absolute;
        bottom: -14px;
    }
    .progress-box{
      border:1px solid #E7E7E7;
      padding:16px;
      border-radius:4px;
      margin-top:16px;
      padding-bottom:6px;
      margin-bottom:24px;warning-desc
      .add-space{
        margin-bottom: -4px;
      }
      .label{
         color:${({ theme }) => theme.colors.labelColor};
         padding-left: 2px;
         margin-top: -9px;
         line-height: 18px;
         display: block;
         font-size:  ${({ theme }) => theme.fonts.baseFontSizeBody};

      }
    }
    .modal-footer{
      display: flex;
      align-items:center;
      justify-content:flex-end;
      gap:16px;
      margin-top:32px;
    }
  }

  .custom-header-item-tb{
    align-items: center;
    .d-flex{
      div:first-child{
        line-height: 16px;
      }
    }
  }
  .ant-popover{
    .ant-popover-inner{
      .ant-popover-title{
         font-size:  ${({ theme }) => theme.fonts.baseFontSizeH5};
      }
    }
  }
  .m-0{
    margin:0px;
  }
  .gap-3{
    gap:3px;
  }
  .pt-7{
    padding-top:7px;
  }
  .mt-3{
    margin-top:3px;
  }
  .tooltip-icon{
    padding-left: 8px;
    padding-top: 6px;
    display: inline-block;

  }
  .popover-list{
    padding-left:0px;
    margin:0px;
    list-style:none;
    max-width:200px;
    li{
    font-size:10px;
          font-size:  ${({ theme }) => theme.fonts.baseFontSizeXs};
          color:  ${({ theme }) => theme.colors.bodyColor};
    }
    
  }
  .drawer-button{
    justify-content: flex-end;
    gap: 16px;
    margin-top:30px;
  }
  .no-scroll{
    height:calc(100vh - 260px);
  }
  .add-scroll{
    height:calc(100vh - 260px);
    overflow: auto;
    .ant-row {
    margin: 0px !important;
}
  }
  .gap-15{
    gap:15px;
  }
  .gap-18{
    gap:18px;
  }
  .mb-4{
    margin-bottom:24px;
  }
  .align-items-start{
    align-items:flex-start;
  }
  .add-template-drawer{
    .ant-drawer-body{
    padding:23px;
}
  }
  .add-sheet{
    min-width:224px;
    border:1px solid ${({ theme }) => theme.colors.textFieldColor};
    padding:12px;
    border-radius:8px;
    cursor:pointer;
    height:100%;
    img{
      margin-bottom: 2px;
    margin-left: -1px;
    max-width:100%;
    }
    h4{
          margin: 0px;
    margin-left: -3px;
        margin-bottom: -4px;

    }
     &.active{
     border:1px solid ${({ theme }) => theme.colors.primaryColor};   
    }

  }
  .modal-area{
    margin-bottom:24px;
    .ant-input{
      height:120px;
    }
  }
  .add-heading-space{
            margin-top: 11px;
  }
  .tooltip-icon{
    margin-left: 3px;
  }
  .select-type{
    margin-top:20px;
  }
  .custom-select{
    .label{
      color:${({ theme }) => theme.colors.blackColor};
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
    }
    .size{
       color:${({ theme }) => theme.colors.bodyLight};
      font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
    }
    .add-between-space{
      margin-left:3px;
    }
    .divider{
       height:10px;
        width: 1px;
        margin:0 10px;
        background-color: #7E8A90
    }
  }
  .action-icon{
    gap:12px;
    img{
      cursor: pointer;
    }
  }
  .text-spacing{
    margin-bottom:16px;
  }
  .mt-0{
    margin-top:0px;
  }
  .max-width-269{
    max-width:269px;
  }
  .icons-box{
    .disable{
         filter: invert(66%) sepia(99%) saturate(1%) hue-rotate(324deg) brightness(103%) contrast(92%);
         opacity:0.5;
    }
  }
  .mr-4{
    margin-right:4px;
  }
  .cross-icon{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom:16px;
    cursor:pointer;
  }
  .quick-tutorial{
    margin-bottom: 16px;
    font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
    color${({ theme }) => theme.colors.bodyColor};
  }
  .campaign-box{
    margin:0 8px;
  .disabled-column {
    pointer-events: none;
    opacity: 0.5;
  }
  .select-campaign{
    border:1px solid #ECEFF1;
    border-radius:8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:16px;
    cursor: pointer;
    margin-bottom:24px;
    h3{
           color:${({ theme }) => theme.colors.secondaryColor};
      font-size:${({ theme }) => theme.fonts.baseFontSizeH3};
      line-height:24px;
      margin-bottom:4px;
    }
    span{
            color:${({ theme }) => theme.colors.bodyLight};
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
          line-height: 18px;
    }
  }
  .select-campaign-tutorial{
    border:1px solid #ECEFF1;
    border-radius:8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:16px;
    cursor: pointer;
    margin-bottom:24px;
    h3{
           color:${({ theme }) => theme.colors.secondaryColor};
      font-size:${({ theme }) => theme.fonts.baseFontSizeH3};
      line-height:24px;
      margin-bottom:14px;
    }
    span{
            color:${({ theme }) => theme.colors.bodyLight};
      font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
          line-height: 18px;
    }
  }
  }

  .product-box{
    display: flex;
    white-space: normal;
    gap: 10px;
    margin-bottom: 14px;
  }
  .add-bottom-space{
    margin-bottom:12px;
  }
  .button-box{
    gap:16px;
}
.add-max-width{
  max-width: 269px;
}
.add-space-between-switch{
  gap:48px;
}
.ml-4{
  margin-left:4px;
}
.justify-content-end{
  justify-content:flex-end;
}
   .download-text{
           border: 0px;
           background-color: transparent;
           color: ${({ theme }) => theme.colors.iconLinkColor};  
           font-weight:500;
           font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
            line-height:12px; 
            position:absolute;
            right:5px;
            bottom: -18px;
        }
.ellipsis-added {
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.overflow-ellipsis {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-drawer-content-wrapper:has(.change-connection-height){
  top:38px;
  right:20px;
  bottom:102px;
  box-shadow:none;
  min-height: 623px;
  max-height: 623px;
  .change-connection-height{
    border-radius:8px
  }
}
.ant-drawer-content-wrapper:has(.change-type-height){
  top:38px;
  right:45px;
  bottom:566px;
  box-shadow:none;
  max-height: 177px;
  min-height: 177px;
  .change-type-height{
    border-radius:8px
  }
}

.ant-drawer-content-wrapper:has(.change-update-height){
  top:38px;
  right:20px;
  bottom:102px;
  box-shadow:none;
  max-height: 480px;
  min-height: 480px;
  .change-update-height{
    border-radius:8px
  }
}

.ant-drawer-content-wrapper:has(.change-profile-height){
  top:38px;
  right:20px;
  bottom:360px;
  box-shadow:none;
  max-height: 364px;
  min-height:364px;
  .change-profile-height{
    border-radius:8px
  }
}
.color-Primary{
  color: ${({ theme }) => theme.colors.backgroundPrimary};  
}
.gap-2{
  gap:16px;
}

.ant-select-tree-checkbox{
    &.ant-select-tree-checkbox-checked{
        .ant-select-tree-checkbox-inner{
          background-color: ${({ theme }) => theme.colors.backgroundPrimary}; 
          border-color:${({ theme }) => theme.colors.backgroundPrimary};
        }
    }
  }
  .calender-button{
    border:1px solid ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .purchase-list{
    list-style:disc; 
    list-style-position: outside;
    padding-left:14px;
    margin-top:0px;
    margin-bottom:0px;
    li{
      font-size: ${({ theme }) => theme.fonts.baseFontSizeXs}; 
      color:#636363;
    }
  }
  .ant-popover-inner:has(.purchase-list){
    max-width:222px;

  }
  .text-center{
    text-align:center
  }
  .ant-popover-inner:has(.notification-popover){
    padding: 0px;
    min-width:365px;
    .ant-popover-title{
      padding:16px;
      border-bottom:1px solid ${({ theme }) => theme.colors.textFieldColor};
      font-size:${({ theme }) => theme.fonts.baseFontSizeH3};
      font-weight:600;
      line-height:24px;
    }
    .notification-popover{
      max-height: 391px;
      overflow: auto;
    }
    .ant-popover-inner-content{
      padding:12px 16px;
      .notification-box{
        background-color:#F9F9EB;
        border-radius:4px;
        margin-bottom:8px;
        padding:8px;
        display:flex;
        gap:8px;
        .info{
          gap:4px;
          .title{
            font-size:${({ theme }) => theme.fonts.baseFontSizeH5};
            color:${({ theme }) => theme.colors.bodyColor};
            font-weight:600;
          }
          .description{
            font-size:${({ theme }) => theme.fonts.baseFontSizeXs};
            color:#535E63;
            overflow: hidden;
            max-width:290px;
          }
          .time{
            font-size:8px;
            color:${({ theme }) => theme.colors.bodyLight};
            font-style: italic;
          }
        }
      }
    }
  }
  .custom-header-item-tb{
    &:nth-child(2){
      line-height:0.6px;
    }
  }
`;

export default GlobalStyles;
