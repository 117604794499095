/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'react-qr-code';

import Trash from '../../assets/icons/trash-1.svg';

import LiveViewCardStyleWrapper from './style';

import { ConvertDocxToPdf } from '../../redux/slices/file';

import { FileState } from '../../redux/types/files';

import { LETTER_TYPE_VALUES } from '../../constants';
import Loader from '../loader';

interface LiveViewProps {
  message?: any;
  qrCode?: string;
  logo?: string;
  children?:React.ReactNode;
  onClick?: any;
  onKeyDown?: any
  setSplitCharacters?: any;
  advertisementFormat: string;
  splitCharacters?: number | undefined;
  totalSheets: number;
  sheetType?: string;
  s3FileUrl?: string;
  setFirstMsgPart?: any;
  firstMsgPart?: string;
  setSecondMsgPart?: any;
  secondMsgPart?: string;
  qrCodeText?: string
}

const LiveViewCard: React.FC<LiveViewProps> = ({
  message,
  qrCode,
  logo,
  children,
  onClick,
  onKeyDown,
  totalSheets,
  sheetType,
  splitCharacters,
  advertisementFormat,
  setSplitCharacters,
  s3FileUrl,
  setFirstMsgPart,
  firstMsgPart,
  setSecondMsgPart,
  secondMsgPart,
  qrCodeText
}) => {
  const dispatch = useDispatch();
  const { pdfBuffer, loading } = useSelector((state: { file: FileState }) => state.file);

  const [isHeightExceeds, setIsHeightExceeds] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!s3FileUrl) {
      let heightToCheck = 410;

      if (['Maxi', 'DIN A4'].includes(advertisementFormat)) heightToCheck = 490;
      const checkHeight = () => {
        if (appRef.current && (appRef.current?.clientHeight || 0) > heightToCheck) {
          if (!splitCharacters) setSplitCharacters(message?.length - ((appRef.current?.clientHeight - heightToCheck) / (['Maxi', 'DIN A4'].includes(advertisementFormat) ? 4 : 2)));
          if (qrCode) {
            const splitPosition = (splitCharacters || 0) > 0 ? splitCharacters : message?.length - 10;

            // Find the last space before the split position to avoid splitting a word
            const lastSpaceBeforeSplit = message.lastIndexOf(' ', (splitCharacters || 0) > 0 ? splitCharacters : splitPosition);

            // If there's a space, split at that position; otherwise, split at the original position
            const actualSplitPosition = lastSpaceBeforeSplit !== -1 ? lastSpaceBeforeSplit : splitPosition;

            // Split the text
            const firstPart = message.slice(0, actualSplitPosition);
            const secondPart = message.slice(actualSplitPosition);

            setFirstMsgPart(firstPart);
            setSecondMsgPart(secondPart);
          }

          setIsHeightExceeds(true);
        } else {
          setSplitCharacters(0);
          setIsHeightExceeds(false);
        }
      };

      checkHeight();
      window.addEventListener('resize', checkHeight);
      return () => window.removeEventListener('resize', checkHeight);
    }
  }, [message, qrCodeText]);

  useEffect(() => {
    if (s3FileUrl && !loading) {
      if (s3FileUrl?.includes('docx') || s3FileUrl?.includes('doc')) {
        const fileFormat = (advertisementFormat === 'DIN A4' || advertisementFormat === 'Maxi') ? 'A4' : 'A5';
        dispatch(ConvertDocxToPdf({ s3FileUrl, fileFormat }));
      } else {
        setPdfUrl(s3FileUrl);
      }
    }
  }, [s3FileUrl]);

  useEffect(() => {
    if (pdfBuffer) {
      const uint8Array = new Uint8Array(pdfBuffer.data);
      const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });
      const pUrl = URL.createObjectURL(pdfBlob);

      setPdfUrl(pUrl);
    }

    return () => {
      if (pdfUrl && (s3FileUrl?.includes('docx') || s3FileUrl?.includes('doc'))) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfBuffer]);

  return (
    <LiveViewCardStyleWrapper>
      {loading && <Loader />}
      <div className="card-ui-wrapper">
        {!s3FileUrl ? (
          <>
            <div className="logo-wrapper">
              <img className="logo-image" src={logo} alt="" />
            </div>
            {
          totalSheets > 1 ? (
            <div
              role="button"
              tabIndex={0}
              onClick={onClick}
              onKeyDown={onKeyDown}
            >
              <img
                className="trash-icon"
                src={Trash}
                alt="Trash"
              />
            </div>
          ) : null
        }
            {/* <h4>{t('live_view')}</h4> */}
            <div ref={appRef}>
              {sheetType === LETTER_TYPE_VALUES.HANDWRITTEN
                ? (
                  !isHeightExceeds ? <div className={`written-box-ba ${['Maxi', 'DIN A4'].includes(advertisementFormat) ? 'DIN-A4-Div' : null}`} dangerouslySetInnerHTML={{ __html: message }} />
                    : !qrCode ? <div className={`written-box-ba ${['Maxi', 'DIN A4'].includes(advertisementFormat) ? 'DIN-A4-Div' : null}`} dangerouslySetInnerHTML={{ __html: message }} />
                      : (
                        <>
                          <div className={`written-box-ba ${['Maxi', 'DIN A4'].includes(advertisementFormat) ? 'DIN-A4-Div' : null}`} dangerouslySetInnerHTML={{ __html: firstMsgPart || '' }} />
                          <div className={`second-msg-part written-box-ba ${['Maxi', 'DIN A4'].includes(advertisementFormat) ? 'DIN-A4-Div' : null}`} dangerouslySetInnerHTML={{ __html: secondMsgPart || '' }} />
                        </>
                      )
                )
                : (
                  <>
                    <div
                      className={`written-box-ba ${['Maxi', 'DIN A4'].includes(advertisementFormat) ? 'DIN-A4-Div' : null}`}
                      dangerouslySetInnerHTML={{
                        __html: message
                      }}
                    />
                    {/* {isHeightExceeds && <p className="UI-added">{exceedText}</p>} */}
                  </>
                )}
            </div>
            { qrCode ? (
              <QRCode
                className="qr-code-image"
                size={70}
                value={qrCode || ''}
              />
            )
              : null }
          </>
        ) : <iframe style={{ width: '100%', height: '100%' }} title="s3-pdf" src={pdfUrl} />}

      </div>
      {children}
    </LiveViewCardStyleWrapper>
  );
};

export default LiveViewCard;
