import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  DirectPurchasePayload,
  PaymentState
} from '../types/payment';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: PaymentState = {
  loading: false,
  error: null,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  success: false,
  directPurchase: false,
  subscriptionCreated: false,
  purchasedPlan: null,
  defaultCardId: '',
  paymentCards: []
};

export const CreateSubscription = createAsyncThunk(
  'payment/create-subscription',
  async (data: DirectPurchasePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment/create-subscription', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const DirectPurchase = createAsyncThunk(
  'payment/direct-purchase',
  async (data: DirectPurchasePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment/direct-purchase', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetPaymentCards = createAsyncThunk(
  'payment/get-payment-cards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/payment/get-payment-cards');

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetPurchasedPlan = createAsyncThunk(
  'payment/get-purchased-plan',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/payment/get-purchased-plan');

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const paymentSlice = createSlice({
  name: 'paymentPlans',
  initialState,
  reducers: {
    SetPaymentState(state, action: PayloadAction<{
      field: keyof PaymentState;
      value: PaymentState[keyof PaymentState]
    }>) {
      const updateProduct = <T extends keyof PaymentState>(field: T, value: PaymentState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProduct(field, value as PaymentState[keyof PaymentState]);
    },
    SetPaymentNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateSubscription.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(CreateSubscription.fulfilled, (state, action) => {
        state.error = null;
        state.subscriptionCreated = action.payload.data.subscriptionCreated;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(CreateSubscription.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(DirectPurchase.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(DirectPurchase.fulfilled, (state, action) => {
        state.error = null;
        state.directPurchase = action.payload.data.directPurchase;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(DirectPurchase.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(GetPaymentCards.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(GetPaymentCards.fulfilled, (state, action) => {
        state.error = null;
        state.defaultCardId = action.payload.data?.defaultCardId;
        state.paymentCards = action.payload.data?.paymentCards;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(GetPaymentCards.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      })
      .addCase(GetPurchasedPlan.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
      })
      .addCase(GetPurchasedPlan.fulfilled, (state, action) => {
        state.error = null;
        state.purchasedPlan = action.payload.data?.purchasedPlan;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(GetPurchasedPlan.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
      });
  }
});

const { actions } = paymentSlice;

export const { SetPaymentState, SetPaymentNotifyState } = actions;

export default paymentSlice.reducer;
