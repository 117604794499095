import React, {
  useState,
  useEffect,
  ChangeEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';

import AlertModal from '../../../components/check-modal';
import Loader from '../../../components/loader';
import Input from '../../../components/input';

import WarningIcon from '../../../assets/icons/warning.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import SaveIcon from '../../../assets/icons/save-icon.svg';

import PaymentSettingWrapper from '../style';

import { AddOrUpdatePaymentPlans, GetPaymentPlans, SetPaymentPlanNotifyState } from '../../../redux/slices/payment-plan';
import { PaymentPlanState } from '../../../redux/types/payment-plan';

type Option = {
  name: string;
  totalLetter: number;
  pricePerLetter: number;
  totalPrice: number;
  pricePerMonth: number;
  isSaveDisabled?: boolean;
  productId?: string
};

type Plans = {
  planType: string;
  options: Option[];
};

const BrandingPlan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loading,
    paymentPlans,
    updatedPlan: uPlan
  } = useSelector((state: { paymentPlan: PaymentPlanState }) => state.paymentPlan);

  const [saveInformation, setSaveInformation] = useState(false);
  const [plansArray, setPlansArray] = useState<Plans[]>([{
    planType: '12 Month',
    options: [{
      name: '',
      totalLetter: 0,
      pricePerLetter: 0,
      totalPrice: 0,
      pricePerMonth: 0
    }]
  }, {
    planType: '6 Month',
    options: [{
      name: '',
      totalLetter: 0,
      pricePerLetter: 0,
      totalPrice: 0,
      pricePerMonth: 0
    }]
  }, {
    planType: 'Direct',
    options: [{
      name: '',
      totalLetter: 0,
      pricePerLetter: 0,
      totalPrice: 0,
      pricePerMonth: 0
    }]
  }]);
  const [updatedPlans, setUpdatedPlans] = useState<Plans[]>([]);
  const [selectedPlans, setSelectedPlans] = useState<Plans>();

  const handleAddRow = (planType: string) => {
    const newOption = {
      name: '',
      totalLetter: 0,
      pricePerLetter: 0,
      totalPrice: 0,
      pricePerMonth: 0
    };

    setPlansArray((prevPlansArray) => prevPlansArray.map((plan) => (plan.planType === planType
      ? { ...plan, options: [...plan.options, newOption] }
      : plan)));
  };

  const handleInputValueChange = (planType: string, optionIndex: number, key: string, value: string | number) => {
    if (key === 'pricePerLetter' || key === 'totalLetter') {
      const regex = /^(0|[1-9]\d*)(\.\d+)?$/;

      if (!regex.test(String(value))) {
        dispatch(SetPaymentPlanNotifyState({
          message: 'Number must be non-negative!',
          type: 'error'
        }));

        return;
      }
    }

    const updatedPlansArray = [...updatedPlans];

    let matchedIndex = updatedPlansArray.findIndex((obj: any) => obj.planType === planType);
    if (matchedIndex === -1) {
      updatedPlansArray.push({
        planType,
        options: [{
          name: '',
          totalLetter: 0,
          pricePerLetter: 0,
          totalPrice: 0,
          pricePerMonth: 0,
          [key]: value
        }]
      });
    } else {
      const { options } = updatedPlansArray[matchedIndex];

      options[optionIndex] = {
        ...options[optionIndex],
        [key]: value
      };
    }

    if (key === 'pricePerLetter' || key === 'totalLetter') {
      const tempPlansArray = [...plansArray];

      matchedIndex = updatedPlansArray.findIndex((obj: any) => obj.planType === planType);
      const { options: updateOptions } = updatedPlansArray[matchedIndex];

      const matchedPlanIndex = tempPlansArray.findIndex((obj: any) => obj.planType === planType);
      const { options } = tempPlansArray[matchedPlanIndex];

      const totalPrice = key === 'pricePerLetter'
        ? round((Number(value || 0) * updateOptions[optionIndex].totalLetter || 0), 2)
        : round((Number(value || 0) * updateOptions[optionIndex].pricePerLetter || 0), 2);

      let pricePerMonth = 0;
      if (planType === '12 Month') {
        pricePerMonth = round((totalPrice / 12), 2);
        // totalPrice *= 12;
      } else if (planType === '6 Month') {
        pricePerMonth = round((totalPrice / 6), 2);
        // totalPrice *= 6;
      }

      options[optionIndex] = {
        ...options[optionIndex],
        totalPrice,
        pricePerMonth
      };

      updateOptions[optionIndex] = {
        ...updateOptions[optionIndex],
        totalPrice,
        pricePerMonth
      };

      setPlansArray(tempPlansArray);
    }

    setUpdatedPlans(updatedPlansArray);
  };

  const handleSavePlans = () => {
    if (selectedPlans) {
      dispatch(AddOrUpdatePaymentPlans({
        category: 'Branding',
        paymentPlans: selectedPlans
      }));
    }

    setSaveInformation(false);
  };

  const handleSaveIconClick = (planType: string, optionIndex: number) => {
    setSaveInformation(true);

    const matchedIndex = updatedPlans.findIndex((obj: any) => obj.planType === planType);
    const { options } = updatedPlans[matchedIndex];
    const matchedOption = options[optionIndex];

    if (matchedOption) {
      setSelectedPlans({
        planType,
        options: [matchedOption]
      });
    }
  };

  useEffect(() => {
    if (updatedPlans.length > 0) {
      const updatedPlansWithSaveEnabled = plansArray.map((originalPlan) => {
        const updatedPlan = updatedPlans.find((plan) => plan.planType === originalPlan.planType);

        if (!updatedPlan) return originalPlan;

        const originalOptions = originalPlan.options.map((originalOption, originalOptionIndex) => {
          const updatedOption = updatedPlan.options[originalOptionIndex];

          if (originalOption.name === '') {
            if (updatedOption && (updatedOption.name !== originalOption.name
              && updatedOption.totalLetter !== originalOption.totalLetter
                && updatedOption.pricePerLetter !== originalOption.pricePerLetter)
            ) {
              return { ...originalOption, isSaveDisabled: false };
            }
          } else if ((updatedOption.name !== originalOption.name
            || updatedOption.totalLetter !== originalOption.totalLetter
            || updatedOption.pricePerLetter !== originalOption.pricePerLetter)
          ) {
            return { ...originalOption, isSaveDisabled: false };
          }

          return { ...originalOption, isSaveDisabled: true };
        });

        return { ...originalPlan, options: originalOptions };
      });

      setPlansArray(updatedPlansWithSaveEnabled);
    }
  }, [updatedPlans]);

  useEffect(() => {
    if (uPlan) {
      const tempPlansArray = [...plansArray];
      const {
        planType,
        name,
        totalLetter,
        totalPrice,
        pricePerMonth,
        productId
      } = uPlan;

      const matchedPlan = tempPlansArray.find((p) => p.planType === planType);
      const updatedPlan = updatedPlans.find((p) => p.planType === planType);
      if (matchedPlan && updatedPlan) {
        const { options } = matchedPlan;
        const matchedOptionIndex = updatedPlan.options.findIndex((op) => op.name === name);

        options[matchedOptionIndex] = {
          ...options[matchedOptionIndex],
          totalLetter,
          totalPrice,
          pricePerMonth,
          productId,
          isSaveDisabled: true
        };

        setPlansArray(tempPlansArray);
      }
    }
  }, [uPlan]);

  useEffect(() => {
    if (paymentPlans && paymentPlans.length > 0) {
      const newPlans: Plans[] = [];
      for (let i = 0; i < paymentPlans.length; i += 1) {
        const {
          planType,
          name,
          totalLetter,
          pricePerLetter,
          totalPrice,
          pricePerMonth,
          productId
        } = paymentPlans[i];

        const matchedIndex = newPlans.findIndex((p) => p.planType === planType);
        if (matchedIndex === -1) {
          newPlans.push({
            planType,
            options: [{
              name,
              totalLetter,
              pricePerLetter,
              totalPrice,
              pricePerMonth,
              productId
            }]
          });
        } else {
          newPlans[matchedIndex].options.push({
            name,
            totalLetter,
            pricePerLetter,
            totalPrice,
            pricePerMonth,
            productId
          });
        }
      }

      const planTypes = ['12 Month', '6 Month', 'Direct'];
      for (let i = 0; i < planTypes.length; i += 1) {
        const planType = planTypes[i];
        const matchedIndex = newPlans.findIndex((p) => p.planType === planType);
        if (matchedIndex === -1) {
          newPlans.push({
            planType,
            options: [{
              name: '',
              totalLetter: 0,
              pricePerLetter: 0,
              totalPrice: 0,
              pricePerMonth: 0
            }]
          });
        }
      }

      setPlansArray(newPlans);
      setUpdatedPlans(newPlans);
    } else {
      const newPlans: Plans[] = [];
      const planTypes = ['12 Month', '6 Month', 'Direct'];
      for (let i = 0; i < planTypes.length; i += 1) {
        const planType = planTypes[i];
        const matchedIndex = newPlans.findIndex((p) => p.planType === planType);
        if (matchedIndex === -1) {
          newPlans.push({
            planType,
            options: [{
              name: '',
              totalLetter: 0,
              pricePerLetter: 0,
              totalPrice: 0,
              pricePerMonth: 0
            }]
          });
        }
      }

      setPlansArray(newPlans);
      setUpdatedPlans(newPlans);
    }
  }, [paymentPlans]);

  useEffect(() => {
    dispatch(GetPaymentPlans({ category: 'Branding' }));
  }, []);

  return (
    <>
      {loading && <Loader />}
      <PaymentSettingWrapper>
        <table className="payment-table">
          <thead>
            <tr>
              <th>{t('plan')}</th>
              <th>{t('name')}</th>
              <th>{t('total_letter')}</th>
              <th>{t('price_per_letter')}</th>
              <th>{t('total_price')}</th>
              <th>{t('price_per_month')}</th>
              <th>{t('action')}</th>
            </tr>
          </thead>
          <tbody>
            {plansArray.map((plan, planIndex) => (
              <React.Fragment key={planIndex}>
                {plan.options.map((option, optionIndex) => (
                  <tr key={optionIndex}>
                    {optionIndex === 0 && (
                    <td className="add-bg" rowSpan={plan.options.length}>{plan.planType}</td>
                    )}
                    <td>
                      <Input
                        className="selected-input"
                        placeholder={option.name}
                        editable={!option.productId}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e) {
                            handleInputValueChange(
                              plan.planType,
                              optionIndex,
                              'name',
                              e.target.value || ''
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        className="selected-input no-spin"
                        type="Number"
                        placeholder={String(option.totalLetter)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e) {
                            handleInputValueChange(
                              plan.planType,
                              optionIndex,
                              'totalLetter',
                              Number(e.target.value || 0)
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        className="selected-input no-spin"
                        type="Number"
                        placeholder={String(option.pricePerLetter)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e) {
                            handleInputValueChange(
                              plan.planType,
                              optionIndex,
                              'pricePerLetter',
                              Number(e.target.value || 0)
                            );
                          }
                        }}
                      />
                    </td>
                    <td>{option.totalPrice}</td>
                    <td>{option.pricePerMonth}</td>
                    <td>
                      {plan.options.length - 1 !== optionIndex
                        ? (
                          <button
                            type="button"
                            onClick={() => handleSaveIconClick(plan.planType, optionIndex)}
                            className="icon-button"
                            disabled={!!option.isSaveDisabled}
                          >
                            <img
                              className={
                              `pointer ${option.isSaveDisabled === undefined || option.isSaveDisabled ? 'disabled' : ''}`
                            }
                              src={SaveIcon}
                              alt="save-icon"
                            />
                          </button>
                        )
                        : (
                          <div className="d-flex gap-2">
                            <button
                              type="button"
                              onClick={() => handleSaveIconClick(plan.planType, optionIndex)}
                              className="icon-button"
                              disabled={!!option.isSaveDisabled}
                            >
                              <img
                                className={
                                `pointer ${option.isSaveDisabled === undefined || option.isSaveDisabled ? 'disabled' : ''}`
                              }
                                src={SaveIcon}
                                alt="save-icon"
                              />
                            </button>
                            <button type="button" className="icon-button" onClick={() => handleAddRow(plan.planType)}>
                              <img className="pointer" src={AddIcon} alt="add-icon" />
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
                <tr className="empty-row">
                  <td colSpan={7} />
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <AlertModal
          icon={<img src={WarningIcon} alt="no-icon" />}
          heading={t('payment_alert_heading')}
          desc={t('payment_alert_desc')}
          open={saveInformation}
          onCancel={() => setSaveInformation(false)}
          onConfirm={() => handleSavePlans()}
        />
      </PaymentSettingWrapper>
    </>

  );
};

export default BrandingPlan;
