import React from 'react';

import WarningModal from '../../../components/warning-modal';

interface WarningModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
  open: boolean;
  heading: string;
  loading?: boolean;
  desc?: string;
  saveText?: string;
}

const Modal: React.FC<WarningModalProps> = ({
  onConfirm,
  onCancel,
  heading,
  open,
  loading,
  desc,
  saveText
}) => (
  <WarningModal
    loading={loading}
    onConfirm={onConfirm}
    onCancel={onCancel}
    open={open}
    heading={heading}
    desc={desc}
    saveText={saveText}
  />
);
export default Modal;
