import styled from 'styled-components';

interface PaginationWrapper {
  height?: string;
}

const TablePaginationWrapper = styled.div<PaginationWrapper>`
  width: 100%;
   padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.dividerStroke};
  border-top: 0px;
  background-color:${({ theme }) => theme.colors.whiteColor};
   label.cds--label.cds--visually-hidden {
    display: none;
  }
 .cds--select-input__wrapper svg {
    display: none;
  }
  .cds--popover {
    display: none !important;
}
 span.cds--pagination__text.cds--pagination__items-count {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 16px;
    padding-left: 15px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: -4px;
      left: 3px;
      width: 1px;
      height: 24px;
      background-color: ${({ theme }) => theme.colors.dividerStroke};
    }
  }
    select {
    color: ${({ theme }) => theme.colors.headingColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 16px;
    border: none;
    padding: 0px;
    outline:none;
    cursor: pointer;
  }
.cds--pagination.cds--pagination--md {
    display: flex;
    justify-content: space-between;
    .cds--pagination__left {
      display: flex;
      align-items: center;
      grid-gap: 4px;
    }
    .cds--pagination__right {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      .cds--select.cds--select--inline {
        display: flex;
        align-items: center;
        grid-gap: 8px;
      }
      .cds--pagination__control-buttons {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 23px;
        margin-right: -16px;
        svg {
          color: ${({ theme }) => theme.colors.primaryColor};
        }
        span {
          display: flex;
          align-items: center;
          grid-gap: 7px;
        }
      }
      .cds--select-input__wrapper {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0px;
          left: -8px;
          width: 1px;
          height: 24px;
          background-color: ${({ theme }) => theme.colors.dividerStroke};
        }
        svg {
          display: none;
        }
      }
      .cds--select-input__wrapper svg {
        display: none;
      }
      span.cds--popover-container
      .cds--popover--caret.cds--popover--high-contrast
      .cds--popover--top.cds--tooltip.cds--icon-tooltip.cds--icon-tooltip--disabled
        span {
        display: none;
      }
      span.cds--popover-container
      .cds--popover--caret
      .cds--popover--high-contrast.cds--popover--top-right
      .cds--tooltip.cds--icon-tooltip
        span {
        display: none;
      }
      button {
        padding: 0px;
        display: flex;
        cursor: pointer;
        border: none;
        background-color: transparent;
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          position: absolute;
          top: 8px;
          left: -1px;
          width: 1px;
          height: 24px;
          background-color: ${({ theme }) => theme.colors.dividerStroke};
        }
      }
    }
  }
  span.cds--pagination__text {
    color: ${({ theme }) => theme.colors.bodyColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 16px;
  }
  label {
    color: ${({ theme }) => theme.colors.bodyColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeSm};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 16px;
  }
    button.cds--btn--icon-only
    .cds--pagination__button.cds--pagination__button--backward
    .cds--pagination__button--no-index.cds--btn.cds--btn--ghost.cds--btn--disabled {
    svg {
      color: ${({ theme }) => theme.colors.iconLinkColor};
    }
  }
  .cds--pagination.cds--pagination--md .cds--pagination__right .cds--pagination__control-buttons svg{
          color: ${({ theme }) => theme.colors.iconLinkColor};   
  }
`;

export default TablePaginationWrapper;
