import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button/index';
import AssignIcon from '../../assets/icons/assign-user.svg';
import UnAssignIcon from '../../assets/icons/unassign-user.svg';
import EditIcon from '../../assets/icons/edit.svg';
import AchieveIcon from '../../assets/icons/achieve.svg';
import UnAchieveIcon from '../../assets/icons/un-achieve.svg';

import { EmployeeState } from '../../redux/types/employee';

import { DecryptCredentials } from '../../utils/helpers';

import { USER_STATUS } from '../../constants';

interface ActionButtonProps {
  employeeId?: string;
  setAssignCustomer?: (value: boolean) => void;
  setUnAssignCustomer?: (value: boolean) => void;
  setArchive?: (value: boolean) => void;
  setUnArchive?: (value: boolean) => void;
  setReInvite?: (value: boolean) => void;
  setEmployeeId?: (value: string) => void;
  setEmployeeAction?: (value: string) => void;
  setEmployeeEmail?: (value: string) => void;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  employeeId,
  setAssignCustomer,
  setUnAssignCustomer,
  setArchive,
  setUnArchive,
  setReInvite,
  setEmployeeId,
  setEmployeeAction,
  setEmployeeEmail
}) => {
  const { t } = useTranslation();
  const { employees } = useSelector((state: { employee: EmployeeState }) => state.employee);

  return (
    <div className="d-flex gap-1 align-items-center">
      {setAssignCustomer
      && employees?.find((employee) => employee._id === employeeId)?.status === USER_STATUS.ACTIVE
      && renderActionButton(
        <img className="pointer" width="14px" src={AssignIcon} alt={t('no_check_icon')} />,
        () => {
          setAssignCustomer(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setUnAssignCustomer
      && employees?.find((employee) => employee._id === employeeId)?.status === USER_STATUS.ACTIVE
      && renderActionButton(
        <img className="pointer" width="14px" src={UnAssignIcon} alt={t('no_check_icon')} />,
        () => {
          setUnAssignCustomer(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setEmployeeAction && renderActionButton(
        <img className="pointer" width="14px" src={EditIcon} alt={t('no_check_icon')} />,
        () => {
          if (setEmployeeAction) setEmployeeAction('edit');
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setArchive
      && employees?.find((employee) => employee._id === employeeId)?.status !== USER_STATUS.ARCHIVED
      && renderActionButton(
        <img className="pointer" width="14px" src={AchieveIcon} alt={t('no_check_icon')} />,
        () => {
          setArchive(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setUnArchive
      && employees?.find((employee) => employee._id === employeeId)?.status === USER_STATUS.ARCHIVED
      && renderActionButton(
        <img className="pointer" width="14px" src={UnAchieveIcon} alt={t('no_check_icon')} />,
        () => {
          setUnArchive(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setReInvite
      && employees?.find((employee) => employee._id === employeeId)?.status === USER_STATUS.INVITED
      && (
        <Button
          text={t('re-invite')}
          height="20px"
          type="default"
          onClick={() => {
            setReInvite(true);
            if (setEmployeeId && employeeId) {
              setEmployeeId(employeeId);

              const email = DecryptCredentials(employees?.find(
                (employee) => employee._id === employeeId
              )?.email || '');

              setEmployeeEmail?.(email);
            }
          }}
        />
      )}
    </div>
  );
};

export default ActionButtons;
