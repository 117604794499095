import styled from 'styled-components';

const PaymentCardWrapper = styled.div`
padding: 12px;
min-width: 331px;
max-width: 600px;
width:100%;
border-radius: 12px;
background-color: #F2F2EC;
&.active-card {
    background: #DEC78E;
    color: #fff;
    .card-num{
  p{
    color: #fff;
  }
}
.footer-wrapper{
  p{
    color: #fff;
  }
}
}
.card-header{
  display: flex;
  justify-content: space-between;
  h6{
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    margin-bottom: 8px;

  }
  .icon-wrapper{
    display: flex;
    grid-gap: 12px;
    button{
      background-color: transparent;
      padding: 0px;
      outline: none;
      border:none;
      min-width: 16px;
      &:hover{
        background-color: transparent;
        outline: none;
        border:none;
      }
    }
    img{
      cursor: pointer;
      width: 16px;
    }
  }
}
.card-num{
  display: flex;
  grid-gap:7.5px;
  margin-bottom: 6px;
  p{
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;

  }
}
.footer-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

  }
}
button{
  border-radius: 70px !important;
}

`;
export
default
PaymentCardWrapper;
