import React, { ReactNode } from 'react';

interface StepsDataProps {
  children: ReactNode;
}

const StepsData: React.FC<StepsDataProps> = ({
  children
}) => (
  <div>
    {children}
  </div>
);

export default StepsData;
