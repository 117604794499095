import React from 'react';
import {
  DateRangePicker,
  Range,
  RangeKeyDict,
  createStaticRanges
} from 'react-date-range';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addMonths,
  startOfWeek,
  endOfWeek,
  subDays,
  subYears
} from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateProps {
  dateRange: Range[]
  handleSelectDate: (value: RangeKeyDict) => void
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  startOfLastYear: startOfYear(subYears(new Date(), 1)),
  endOfLastYear: endOfYear(subYears(new Date(), 1)),
  startOf2024: new Date('2024-01-01')
};

const defaultStaticRanges = createStaticRanges([{
  label: 'Today',
  range: () => ({
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday
  })
}, {
  label: 'Yesterday',
  range: () => ({
    startDate: defineds.startOfYesterday,
    endDate: defineds.endOfYesterday
  })
}, {
  label: 'Last 7 Days',
  range: () => ({
    startDate: subDays(startOfDay(new Date()), 6),
    endDate: startOfDay(new Date())
  })
}, {
  label: 'Month to Date',
  range: () => ({
    startDate: defineds.startOfMonth,
    endDate: defineds.endOfToday
  })
}, {
  label: 'Last Month',
  range: () => ({
    startDate: defineds.startOfLastMonth,
    endDate: defineds.endOfLastMonth
  })
}, {
  label: 'Year to Date',
  range: () => ({
    startDate: defineds.startOfYear,
    endDate: defineds.endOfToday
  })
}, {
  label: 'Last Year',
  range: () => ({
    startDate: defineds.startOfLastYear,
    endDate: defineds.endOfLastYear
  })
}, {
  label: 'Lifetime',
  range: () => ({
    startDate: defineds.startOf2024,
    endDate: defineds.endOfToday
  })
}, {
  label: 'Custom',
  range: () => ({
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday
  })
}]);

const customStaticRangesList = createStaticRanges([...defaultStaticRanges]);

const DateRangeComponent: React.FC <DateProps> = ({
  dateRange,
  handleSelectDate
}) => (
  <DateRangePicker
    editableDateInputs
    moveRangeOnFirstSelection={false}
    months={2}
    rangeColors={['#94995B']}
    ranges={dateRange}
    onChange={handleSelectDate}
    direction="horizontal"
    staticRanges={customStaticRangesList}
  />
);

export default DateRangeComponent;
