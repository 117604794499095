const fsh1 = '32px';
const fsh2 = '24px';
const fsh3 = '16px';
const fsh4 = '14px';
const fsh5 = '12px';
const fsh6 = '10px';
const fssm = '14px';
const fsBody = '13px';
const fsxs = '12px';
const fsLabel = '11px';
const fsXxs = '10px';

interface Theme {
  name: string;
  colors: {
    primaryColor: string;
    secondaryColor: string;
    secondaryColor400: string;
    placeholderColor: string;
    bodyColor: string;
    whiteColor: string;
    blackColor: string;
    warningColor: string;
    errorColor: string;
    successColor: string;
    infoColor: string;
    labelColor: string;
    textFieldColor: string;
    dividerColor: string;
    iconLinkColor: string;
    borderColor: string;
    labelLightColor: string;
    tableHeaderColor: string;
    tableHeaderColor400: string;
    dividerStroke:string;
    backgroundColor:string;
    fieldBorderColor:string;
    visualLight:string;
    bodyLight: string;
    backgroundPrimary:string;
    clickAbleLight:string;
    processedStatusColor: string;
    paymentBorderColor: string;
    secondaryColorGrey: string;
    boxShadow: string;
    lightGrey: string;
    darkGrey: string;
    switchColor: string;
    canBeStartedStatusColor: string;

    // new colors
    primaryColorGold:string;
    mainHeadingColor:string;
    newLabelColor:string;
    newBgColor:string;
    labelHeading:string;
    subHeading:string;
    bodyVariant:string;
    extraTextColor:string;
    // new colors
  };
  fonts: {
    baseFontSizeH1: string;
    baseFontSizeH2: string;
    baseFontSizeH3: string;
    baseFontSizeH4: string;
    baseFontSizeH5: string;
    baseFontSizeH6: string;
    baseFontSizeBody: string;
    baseFontSize: string;
    baseFontSizeSm: string;
    baseFontSizeXs: string;
    baseFontSizeLabel: string;
  };
  lineHeight: {
    baseLineHeightBody: string;
    baseLineHeightH1: string;
    baseLineHeightH2: string;
    baseLineHeightH3: string;
    baseLineHeightSm: string;
    baseLineHeightXs: string;
  };
  fontWeight: {
    light: number;
    normal: number;
    medium: number;
    semiBold: number;
    bold: number;
  };
}

const theme: Theme = {
  name: 'theme',
  colors: {
    primaryColor: '#870003',
    secondaryColor: '#190F0F',
    secondaryColor400: '#596780',
    placeholderColor: '#5E718D',
    bodyColor: '#5D5D5D',
    whiteColor: '#FFF',
    blackColor: '#000000',
    warningColor: '#F17400',
    errorColor: '#D40001',
    successColor: '#0FB600',
    infoColor: '#00B2FF',
    labelColor: '#8D8D8D',
    textFieldColor: '#DFDEDE',
    dividerColor: '#ECEFF1',
    iconLinkColor: '#E2C786',
    borderColor: '#F0F0F0',
    labelLightColor: '#90A3BF',
    tableHeaderColor: '#F4F6F6',
    dividerStroke: '#F2F2F2',
    tableHeaderColor400: '#F6F6F6',
    backgroundColor: '#f9f9f9',
    fieldBorderColor: '#DBDFE1',
    visualLight: '#D9D9D9',
    bodyLight: '#7E8A90',
    backgroundPrimary: '#E2C786',
    clickAbleLight: '#F2F2EC',
    processedStatusColor: '#6DB6D0',
    paymentBorderColor: '#E6E9F5',
    secondaryColorGrey: '#5E5E5E',
    boxShadow: '#D7D7D780',
    lightGrey: '#787878',
    darkGrey: '#18181B',
    switchColor: '#A1A1AA',
    canBeStartedStatusColor: '#F17400',

    // new colors theme after payment screen design
    primaryColorGold: '#DEC78E',
    mainHeadingColor: '#190F0F',
    newLabelColor: '#18181B',
    newBgColor: '#262832',
    labelHeading: '#1B1D21',
    subHeading: '#5F5F5F',
    bodyVariant: '#71717A',
    extraTextColor: '#464646'
    // new colors theme after payment screen design
  },
  fonts: {
    baseFontSizeH1: fsh1,
    baseFontSizeH2: fsh2,
    baseFontSizeH3: fsh3,
    baseFontSizeH4: fsh4,
    baseFontSizeH5: fsh5,
    baseFontSizeH6: fsh6,
    baseFontSize: fssm,
    baseFontSizeBody: fsBody,
    baseFontSizeSm: fsxs,
    baseFontSizeXs: fsXxs,
    baseFontSizeLabel: fsLabel
  },
  lineHeight: {
    baseLineHeightBody: '18px',
    baseLineHeightH1: '40px',
    baseLineHeightH2: '32px',
    baseLineHeightH3: '24px',
    baseLineHeightSm: '16px',
    baseLineHeightXs: '14px'
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  }
};

export default theme;
