import React, {
  useState,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';

import Input from '../../../components/input/index';
import { ConnectionWrapper } from './style';

import { AuthState } from '../../../redux/types/auth';

import { DecryptCredentials } from '../../../utils/helpers';

interface ProfileProps {
  onIconClick?:()=>void;
}

const Profile: React.FC<ProfileProps> = ({ onIconClick }) => {
  const { user } = useSelector((state: { auth: AuthState }) => state.auth);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (user) {
      const decryptedEmail = DecryptCredentials(user?.email);
      setEmail(decryptedEmail);
    } else {
      setEmail('');
    }
  }, []);

  return (
    <ConnectionWrapper>
      <Input
        label="Name"
        placeholder={user?.name}
        marginBottom="24px"
        editable={false}
      />
      <Input
        label="Email"
        placeholder={email}
        marginBottom="24px"
        editable={false}
      />
      <Input
        label="Password"
        type="simpleInput"
        marginBottom="24px"
        onIconClick={onIconClick}
        editable={false}
        placeholder="********"
      />
    </ConnectionWrapper>
  );
};

export default Profile;
