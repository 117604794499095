import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import TableWrapper from './table.style';

interface IndexProps {
  rowData: any[];
  columnDefs: any[];
  sortable?: boolean;
  resizable: boolean;
  editable: boolean;
  height?: string;
  rowHeight?: number;
  headerHeight?: number;
  className?: string;
  pagination?: boolean;
  filter?: boolean;
  isDataRetrievalSuccessful?: boolean;
}

const Index: React.FC<IndexProps> = (props) => {
  const {
    rowData,
    columnDefs,
    resizable,
    height,
    rowHeight,
    headerHeight,
    className,
    pagination,
    isDataRetrievalSuccessful
  } = props;

  const gridOptions = {
    defaultColDef: {
      resizable,
      sortable: false
    },
    columnDefs,
    rowData
  };

  return (
    <TableWrapper height={height} className={className}>
      <div className="ag-theme-alpine table-wrapper">
        <AgGridReact
          suppressRowClickSelection
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          pagination={pagination}
          suppressDragLeaveHidesColumns
          rowSelection="multiple"
          enableCellTextSelection
          overlayNoRowsTemplate={
            isDataRetrievalSuccessful && rowData.length === 0
              ? '<span className="overlay-no-rows-template-wrapper">No Rows To Show</span>'
              : '<span></span>'
          }
          {...gridOptions}
        />
      </div>
    </TableWrapper>
  );
};

export default Index;
