import styled from 'styled-components';

interface UploadWrapperProps {
  width?: string;
  authInput?: string;
}

const UploadWrapper = styled.div<UploadWrapperProps>`
    width:100%;
    .ant-upload-drag{
        border: 1px dashed #DFDEDE;
        background-color: ${({ theme }) => theme.colors.whiteColor};   
    }
    .ant-upload-text{
        .drag-drop{
          font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
           color: ${({ theme }) => theme.colors.bodyColor};
           margin-left:4px;  
        }
         
        span{
           color: ${({ theme }) => theme.colors.iconLinkColor};  
           font-weight:500;
                font-size:${({ theme }) => theme.fonts.baseFontSizeBody};
        }
    
    }
    .ant-upload-drag-icon{
        margin-top: 4px;
        margin-bottom:10px!important;
    }
    .support-csv{
        color: ${({ theme }) => theme.colors.labelColor}; 
            font-size:${({ theme }) => theme.fonts.baseFontSizeH6}; 
            line-height:12px;  
            padding-bottom:9px;
    }
     
`;
export default UploadWrapper;
