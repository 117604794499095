import React from 'react';
import { Button } from 'antd';

import Card from '../../assets/icons/Mastercard.svg';
import DeleteIconActive from '../../assets/icons/trash-1.svg';
import DeleteIcon from '../../assets/icons/trash-2.svg';
import EditIconActive from '../../assets/icons/edit.svg';
import EditIcon from '../../assets/icons/edit-1.svg';

import ButtonComponent from '../button';

import PaymentCardWrapper from './style';

interface PaymentCardProps {
  title: string;
  description: string;
  date?: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  editButton: () => void;
  deleteButton: () => void;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  title,
  description,
  isActive,
  date,
  editButton,
  deleteButton,
  onClick
}) => (
  <PaymentCardWrapper className={isActive ? 'active-card' : ''}>
    <div className="card-header">
      <h6>{title}</h6>
      <div className="icon-wrapper">
        <Button
          role="button"
          onClick={editButton}
          shape="circle"
        >
          <img
            src={isActive ? EditIcon : EditIconActive}
            alt="Edit"
          />
        </Button>
        <Button
          role="button"
          onClick={deleteButton}
          shape="circle"
        >
          <img src={isActive ? DeleteIcon : DeleteIconActive} alt="Delete" />
        </Button>
      </div>
    </div>
    <div className="card-num">
      <img src={Card} alt="Card" />
      <p>{description}</p>
    </div>
    <div className="footer-wrapper">
      <p>{date}</p>
      <ButtonComponent
        text={isActive ? 'Default' : 'Set as Default'}
        type="default"
        onClick={onClick}
      />
    </div>
  </PaymentCardWrapper>
);

export default PaymentCard;
