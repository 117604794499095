import styled from 'styled-components';

interface TableWrapperProps {
  height?: string;
}

const ProgressWrapper = styled.div<TableWrapperProps>`
`;

export default ProgressWrapper;
