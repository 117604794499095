import React, {
  ReactNode,
  useState
} from 'react';
import { Layout } from 'antd';

import Sidebar from './sidebar/sidebar';
import Header from './header/header';

import LayoutWrapper from './style';

const {
  Sider,
  Content
} = Layout;

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LayoutWrapper>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider width={202} trigger={null} collapsible collapsed={collapsed}>
          <Sidebar collapsed={collapsed} />
        </Sider>
        <Layout>
          <Header collapsed={collapsed} onToggleCollapse={handleToggleCollapse} />
          <Content className="inner-content-wrapper">
            {children}
          </Content>
        </Layout>
      </Layout>
    </LayoutWrapper>
  );
};

export default AppLayout;
