import React, {
  ReactNode
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button';
import Modal from '../../../components/modal';
import WarningIcon from '../../../assets/icons/warning.svg';

import WarningModalWrapper from '../../../components/warning-modal/warning.style';

interface ConnectModalProps {
  open?: boolean;
  onConfirm?: () => void;
  heading?: string;
}

const ConnectModal: React.FC<ConnectModalProps> = ({
  open,
  onConfirm,
  heading
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} width="364px">
      <WarningModalWrapper>
        <img src={WarningIcon} alt={t('no_icon')} />
        <h3>{heading || t('warning')}</h3>
        <div className="margin-top w-100">
          <Button text={t('confirm')} type="primary" width="100%" onClick={onConfirm} />
        </div>
      </WarningModalWrapper>
    </Modal>
  );
};

export default ConnectModal;
