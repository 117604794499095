import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Link,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Popover,
  Space,
  Progress
} from 'antd';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthFormWrapper from './auth-form-wrapper';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Loader from '../../../components/loader';

import { StrengthWrapper } from '../style';

import {
  CheckSetPasswordTokenExpiry,
  ResendForgotPasswordEmail,
  ResetPasswordd,
  SetAuthState
} from '../../../redux/slices/auth';
import { AuthState } from '../../../redux/types/auth';

import {
  ResetPasswordDataState,
  ResetPasswordHelperTextState
} from '../../../utils/types/pages/auth';

import {
  AddToLocalStorage,
  ApplyValidation,
  CheckPasswordStrength,
  EncryptCredentials,
  GetLocalStorageItem,
  RemoveLocalStorageItems,
  TestPasswordStrength
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

import TickIcon from '../../../assets/icons/tick-icon.svg';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    emailResent,
    isTokenExpired,
    loading,
    passwordReset,
    resendForgotPasswordEmailLoading,
    user
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const setPasswordExpiredToken = GetLocalStorageItem('setPasswordExpiredToken');

  const [popoverVisible, setPopoverVisible] = useState(false);

  const [formData, setFormData] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: ''
  });

  const [helperText, setHelperText] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: ''
  });

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null);

  const validateField = (
    fieldName: keyof ResetPasswordDataState,
    value: string
  ): Partial<ResetPasswordHelperTextState> => {
    const errors: Partial<ResetPasswordHelperTextState> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'password' && !ApplyValidation(REGEX_PATTERNS.PASSWORD_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_PASSWORD);
    } else {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleConfirmPasswordChange = (value: string) => {
    setFormData({
      ...formData,
      confirmPassword: value
    });

    if (value === '') {
      setPasswordMatch(null);
    } else {
      setPasswordMatch(formData.password === value);
    }
  };

  const handleInputChange = (
    fieldName: keyof ResetPasswordDataState,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setHelperText({
      ...helperText,
      [fieldName]: errors[fieldName]
    });

    if (fieldName === 'password' && formData.confirmPassword !== '') {
      setPasswordMatch(formData.confirmPassword === value);
    }

    if (fieldName === 'confirmPassword') {
      handleConfirmPasswordChange(value);
    }

    const strength: number = TestPasswordStrength(value);
    setPasswordStrength(strength);
  };

  const handleButtonClick = () => {
    const passwordError = validateField('password', formData.password).password;
    const confirmPasswordError = validateField('confirmPassword', formData.confirmPassword).confirmPassword;

    if (passwordError || confirmPasswordError || !passwordMatch) {
      setHelperText({
        password: passwordError || '',
        confirmPassword: confirmPasswordError || ''
      });
      return;
    }

    const password = EncryptCredentials(formData.password);

    const data = {
      token,
      password
    };

    RemoveLocalStorageItems(['resendForgotPasswordEmail']);

    dispatch(ResetPasswordd(data as {
      token: string;
      password: string;
    }));
  };

  const strengthData = [{
    title: t('at_least_8_characters'),
    check: formData.password.length >= 8
  }, {
    title: (
      <>
        {t('have_uppercase_lowercase_letters')}
        <br />
        {t('numbers_and_special_characters')}
      </>
    ),
    check: CheckPasswordStrength(formData.password)
  }];

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }

    if (token) {
      dispatch(CheckSetPasswordTokenExpiry(token));
    }
  }, []);

  useEffect(() => {
    if (passwordReset) {
      RemoveLocalStorageItems(['token', 'user']);

      navigate('/password-changed');

      dispatch(SetAuthState({
        field: 'passwordReset',
        value: false
      }));
    }
  }, [passwordReset]);

  useEffect(() => {
    if (isTokenExpired) {
      if (!setPasswordExpiredToken) {
        AddToLocalStorage(['expiredToken', token as string]);
      }

      if (!emailResent) {
        dispatch(ResendForgotPasswordEmail({ expiredToken: token || setPasswordExpiredToken as string }));
      }

      if (emailResent) {
        dispatch(SetAuthState({
          field: 'isTokenExpired',
          value: false
        }));

        dispatch(SetAuthState({
          field: 'emailResent',
          value: false
        }));

        AddToLocalStorage(['resendForgotPasswordEmail', user?.email as string]);

        navigate('/verification-email');
      }
    }
  }, [isTokenExpired, emailResent]);

  return (
    <>
      {(loading || resendForgotPasswordEmailLoading) && <Loader />}
      <AuthFormWrapper heading={t('reset_password')} subtext={t('reset_password_phrase')}>
        <Popover
          open={popoverVisible}
          onOpenChange={(visible) => setPopoverVisible(visible)}
          overlayClassName="auth-password-popover"
          content={(
            <StrengthWrapper>
              <span className="strength-title">{t('your_password_must_contain')}</span>
              <Space direction="vertical">
                {strengthData.map((data, ind) => (
                  <div
                    className={`password-check ${data.check && 'check-pass'}`}
                    key={ind}
                  >
                    <img src={TickIcon} alt={t('tick')} />
                    <span>{data.title}</span>
                  </div>
                ))}
                <div className="progress-wrapper">
                  <div className="progress-header-wrapper">
                    <span>{t('strength')}</span>
                    <span
                      style={{
                        marginRight: 8,
                        color: (passwordStrength === 1 || passwordStrength === 2)
                          ? '#D40001'
                          : (passwordStrength === 3 || passwordStrength === 4)
                            ? '#F17400'
                            : passwordStrength === 5
                              ? '#0FB600'
                              : '#D40001'
                      }}
                    >
                      {(passwordStrength === 1 || passwordStrength === 2)
                        ? t('weak')
                        : (passwordStrength === 3 || passwordStrength === 4)
                          ? t('normal')
                          : passwordStrength === 5 && t('fantastic')}
                    </span>
                  </div>
                  <Progress
                    percent={(passwordStrength === 1 || passwordStrength === 2)
                      ? 20
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? 60
                        : passwordStrength === 5
                          ? 100
                          : 0}
                    status={(passwordStrength === 1 || passwordStrength === 2)
                      ? 'exception'
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? 'active'
                        : passwordStrength === 5
                          ? 'success'
                          : 'exception'}
                    showInfo={false}
                    strokeColor={(passwordStrength === 1 || passwordStrength === 2)
                      ? '#D40001'
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? '#F17400'
                        : passwordStrength === 5
                          ? '#0FB600'
                          : '#D40001'}
                    trailColor="#E7E7E7"
                  />
                  <span className="progress-message">
                    {(passwordStrength === 1 || passwordStrength === 2)
                      ? t('this_password_is_too_short')
                      : passwordStrength > 2 ? t('avoid_sequence') : ''}
                  </span>
                </div>
              </Space>
            </StrengthWrapper>
          )}
          trigger="focus"
          placement="bottomRight"
        >
          <div>
            <Input
              authInput
              type="password"
              label={t('enter_new_password')}
              className="form-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange('password', e.target.value);
              }}
              helperText={helperText.password}
            />
          </div>
        </Popover>

        <Input
          authInput
          label={t('confirm_new_password')}
          className="form-input"
          type="password"
          onFocus={() => setPopoverVisible(true)}
          onBlur={() => setPopoverVisible(false)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('confirmPassword', e.target.value);
          }}
          helperText={helperText.confirmPassword}
        />
        <div className="input-message-wrapper">
          {passwordMatch !== null && (
            <div className={`input-message ${passwordMatch ? 'password-match' : 'password-no-match'}`}>
              {passwordMatch ? t('password_matched') : t('password_doesnt_match')}
            </div>
          )}
        </div>
        <Button
          text={t('reset_password')}
          type="primary"
          width="100%"
          borderRadius="12px"
          disabled={false}
          className="custom-button"
          onClick={handleButtonClick}
        />
        <div className="ba-text-align-center">
          <p>
            {t('take_me_back_to')}
            <Link to="/sign-in">{t('sign_in')}</Link>
          </p>
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default ResetPassword;
