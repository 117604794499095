import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface ChartData {
  name: string;
  data: number[];
}

interface StatisticsChartProps {
  daysArray: string[] | undefined;
  reviewCountList: number[] | undefined;
  scannedCountList: number[] | undefined;
  lettersSentOutWithQrCodeList: number[] | undefined;
  lettersSentOutWithoutQrCodeList: number[] | undefined;
}

const StatisticsChart: React.FC<StatisticsChartProps> = ({
  daysArray,
  reviewCountList,
  scannedCountList,
  lettersSentOutWithQrCodeList,
  lettersSentOutWithoutQrCodeList
}) => {
  const [series, setSeries] = useState<ChartData[]>([{
    name: 'Letters Sent Out (QR Code)',
    data: lettersSentOutWithQrCodeList || []
  }, {
    name: 'Letters Sent Out (Without QR Code)',
    data: lettersSentOutWithoutQrCodeList || []
  }, {
    name: 'QR Codes Scanned',
    data: scannedCountList || []
  }, {
    name: 'Reviews Generated',
    data: reviewCountList || []
  }]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'line',
      height: 30,
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    xaxis: {
      categories: daysArray
    },
    tooltip: {
      x: {
        format: 'dd MMM yy'
      }
    },
    colors: ['#B6B0FF', '#C2CFFF', '#AFE3FC', '#FDA0A0']
  });

  useEffect(() => {
    if (reviewCountList?.length
        && scannedCountList?.length
          && lettersSentOutWithQrCodeList?.length
            && lettersSentOutWithoutQrCodeList?.length) {
      setSeries([{
        name: 'Letters Sent Out (QR Code)',
        data: lettersSentOutWithQrCodeList || []
      }, {
        name: 'Letters Sent Out (Without QR Code)',
        data: lettersSentOutWithoutQrCodeList || []
      }, {
        name: 'QR Codes Scanned',
        data: scannedCountList || []
      }, {
        name: 'Reviews Generated',
        data: reviewCountList || []
      }]);
    } else {
      setSeries([{
        name: 'Letters Sent Out (QR Code)',
        data: []
      }, {
        name: 'Letters Sent Out (Without QR Code)',
        data: []
      }, {
        name: 'QR Codes Scanned',
        data: []
      }, {
        name: 'Reviews Generated',
        data: []
      }]);
    }
  }, [reviewCountList, scannedCountList]);

  useEffect(() => {
    if (daysArray?.length) {
      setOptions({
        ...options,
        xaxis: {
          categories: daysArray
        }
      });
    } else {
      setOptions({
        ...options,
        xaxis: {
          categories: []
        }
      });
    }
  }, [daysArray]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default StatisticsChart;
