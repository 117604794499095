import React from 'react';

import WarningModal from '../../../components/warning-modal';

interface InfoModalProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  open?: boolean;
  heading?: string;
  desc?:string;
  loading?: boolean;
}

const InfoModal: React.FC<InfoModalProps> = ({
  onConfirm,
  onCancel,
  heading,
  open,
  desc,
  loading
}) => (
  <WarningModal
    onConfirm={onConfirm}
    onCancel={onCancel}
    open={open}
    heading={heading}
    desc={desc}
    loading={loading}
  />
);
export default InfoModal;
