import styled from 'styled-components';
import { Drawer } from 'antd';

interface DrawerWrapperProps {
  width?: string;
  authInput? : boolean;
  marginBottom? : string | undefined
}

const DrawerWrapper = styled(Drawer)<DrawerWrapperProps>`
    .ant-drawer-header{
        padding:24px 16px;
        .ant-drawer-title{
            margin-left: -10px;
        }
    }
`;

export default DrawerWrapper;
